import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

import { LightningTable } from '../../lightningTable/lightning-table.js'
import { LightningKit } from '../../lightningTable/lightning-kit.js'
import { LightningSearch } from '../../lightningTable/lightning-search.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import '../inventory.css';

const historyColumns = [
    {
        accessorKey: 'item_name',
        header: 'Item Name',
        enableSorting: true,
        width: 0.36

    }, {
        accessorKey: 'item_sku',
        header: 'Item SKU',
        enableSorting: true,
        width: 0.15
    }, {
        accessorKey: 'description',
        header: 'Change Details',
        enableSorting: true,
        width: 0.28
    }, {
        accessorKey: 'type',
        header: 'Change Type',
        enableSorting: true,
        width: 0.08

    }, {
        accessorKey: 'user',
        header: 'Updated By',
        enableSorting: true,
        width: 0.08

    }, {
        accessorKey: 'raw_time',
        header: 'Updated On',
        enableSorting: true,
        width: 0.08,
        cell: props => { return <span>{props.row.original.time}</span> },


    }

];

const CustomFilterList = ({
    onColumnToggle,
    toggles,
    icon = faSlidersH,
    title = "Column Selection"
}) => (

    <OverlayTrigger
        trigger="click"
        rootClose

        placement="bottom"
        overlay={
            <Popover id={`popover-positioned-bottom`} style={{ maxWidth: 400, zIndex: 4500 }}>
                <Popover.Header as="h3">{title}</Popover.Header>
                <Popover.Body>

                    <div data-toggle="buttons">
                        {
                            Object.entries(toggles).map(([element, value]) => (
                                <Form.Group key={`formBasicCheckboxGroupKey-${element}`} controlId={`formBasicCheckbox-${element}`}>
                                    <Form.Check key={`formBasicCheckboxKey-${element}`} onClick={() => onColumnToggle(element)} type="checkbox" label={element} defaultChecked={value} />
                                </Form.Group>
                            ))
                        }
                    </div>
                </Popover.Body>
            </Popover>
        }
    >

        <Button style={{ float: "right", color: "black", marginRight: "15px", marginLeft: "20px", height: "38px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={icon} /></Button>
    </OverlayTrigger>
);

export const ItemHistoryTab = function (props) {

    const [history, setHistory] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);

    const [loaded, setLoaded] = useState(true);
    const [typeFilters, setTypeFilters] = useState({ 'Quantity Change': true, 'FBA Hold Change': true, 'FBA Quantity Change ': true, 'Price Change': true })
    const [typeFiltersChanged, setTypeFiltersChanged] = useState(false)

    const controller = React.useRef(null)
    const options = {
        paginationSize: 5,
        sizePerPage: 50,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

        showTotal: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: '250', value: 250
        }] // A numeric array is also available. the purpose of above example is custom the text
    };


    useEffect(() => {
        return () => {
            if (controller.current) {
                controller.current.abort()
            }
        };
    }, []);
    React.useEffect(() => {
        controller.current = new AbortController();
        getUpdates()
    }, [props.item]);

    const getUpdates = () => {
        if (!props.item) {
            return
        }
        setLoaded(false)
        let payload = {
            limit: -1,
            group_id: props.item.group_id
        };
        axios.post('/api/get-updates', payload, {
            headers: {
                Authorization: 'Bearer ' + props.token //the token is a variable which holds the token
            }, signal: controller.current.signal
        })
            .then(function (response) {
                setLoaded(true)
                if (response.data) {
                    if (response.data.history) {

                        setHistory(response.data.history)
                        setFilteredHistory(response.data.history)

                    }
                }

            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    return;
                }
                console.log(error)
                setLoaded(true)

            });
    }

    React.useEffect(() => {
        filterUpdates()
    }, [typeFiltersChanged]);


    const filterUpdates = () => {
        var tmpItems = history.filter(function (item) {
            return typeFilters[item.type]
        })
        setFilteredHistory(tmpItems)
    }
    const onColumnToggle = (element) => {
        var tmp = typeFilters
        tmp[element] = !tmp[element]
        setTypeFilters(tmp)
        setTypeFiltersChanged(!typeFiltersChanged)

    }
    return (
        <div>
            <Modal.Body className="ModalBody">
                <div style={{ padding: 5 }}>
                    {history.length > 0 ?

                        <LightningKit columns={historyColumns} getRowCanExpand={() => false} in_data={filteredHistory} pageOptions={options} width="100%" >{


                            props => (
                                <div>

                                    <div id="search">
                                        <h2>Search:</h2>
                                        <LightningSearch {...props.searchProps} />
                                        <CustomFilterList onColumnToggle={onColumnToggle} toggles={typeFilters} title="Change Type Selection" />
                                    </div>

                                    <LightningTable
                                        className="lightningFixedTable smallFont"
                                        height="30vh"
                                        {...props} {...props.table}
                                        noDataIndication={loaded ? "No Matching History Found" : <Spinner animation="border" />}
                                    />
                                </div>
                            )
                        }
                        </LightningKit> : !loaded ? <Spinner animation="border" /> : <p>There is no change history for this item.</p>}
                </div>
            </Modal.Body>
        </div>
    )
}