import React from "react";
import states from "../assets/states.json";
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';


class SelectUSState extends React.Component {
  handleChange = event => {
    const { onChange } = this.props;
    if(onChange){
        onChange(event.target.value);
    }
  };

  render() {
    const { in_ref, id, className, defaultValueStart } = this.props;
    return (
    <Form.Select ref={in_ref}style={{maxWidth:200}} className={className} onChange={this.handleChange} >
       {states.map(item => (
          <option key={item.abbreviation} value={item.abbreviation}>
            {item.name}
          </option>
        ))}
      </Form.Select>
     
    );
  }
}



export default SelectUSState;