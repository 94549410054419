import React from "react";

import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Media from '../Media.js';
import FloatingLabel from 'react-bootstrap/FloatingLabel';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrash, faQuestion, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


export function OrderItemList(props) {
  var items = props.items
  var callback = props.callback
  return (

    items.map(item => (
      <Card key={item.id} className="item OrderItem orderItemList" bg="light" >
        <Card.Header style={{ padding: 0, paddingRight: 10 }}>

          <Card.Text style={{ float: "left", paddingLeft: 10, paddingTop: 8, marginBottom: 0, paddingRight: 0, color: "green" }}>Qty Received:</Card.Text>
          <Form.Control min="0" className="CostInputEdit" plaintext style={{ color: "green", float: "left", paddingLeft: 10, paddingTop: 7, marginBottom: 0, paddingRight: 10, width: 70 }} type="number" onBlur={(e) => callback(6, item, e.target.value)} defaultValue={item.orig_rec_qty} placeholder="0" />


          <Card.Text style={{ float: "left", paddingLeft: 10, paddingTop: 8, marginBottom: 0, color: "red" }}>{!item.ecommerce ? "CUSTOM STOCK ITEM" : ""}</Card.Text>
          <Button variant="link" onClick={() => callback(0, item, "")} style={{ float: "right" }}> <FontAwesomeIcon className="trashIcon" icon={faTrash} /></Button>
          <div className="form-row" style={{ float: "right", paddingRight: 10, paddingLeft: 10, width: "80px" }}>


            <Form.Control className={`CostInput${item.item_cost && item.ecommerce ? "Plain" : "Edit"}`} plaintext readOnly={item.item_cost && item.ecommerce} onBlur={(e) => callback(5, item, e.target.value)} defaultValue={`$${item.item_cost ? item.item_cost.toLocaleString(undefined, { minimumFractionDigits: 2 }) : ""}`} placeholder="Cost?" />

          </div>
        </Card.Header>
        <Card.Body style={{ padding: 10 }}>
          <Media>

            <div style={{ width: 85, height: "100%" }} className="align-self-center mr-3">
              {item.ecommerce ? <img

                className="align-self-center mr-3"
                src={item.item_img_url}
                alt="Item Image"
                width="75"
                style={{ maxHeight: 75, marginRight: 10 }}
              /> : <></>}
            </div>
            <div style={{ width: "100%" }}>
              <Form onSubmit={(e) => { e.preventDefault() }} key={`${item.id}${item.sku}${item.qty}`} >
                <Container fluid>
                  <Row style={{ padding: 5 }}>

                    <Col style={{ padding: 5 }} className={props.isInnerPage ? "EditOrderSKU" : ""} xxl={6} sm={12} >
                      <FloatingLabel controlId="floatingTextarea1" label="Order SKU">
                        <Form.Control as="textarea" rows="1" maxLength={128}
                          onBlur={(e) => callback(1, item, e.target.value)}
                          defaultValue={item.sku} />

                      </FloatingLabel>
                    </Col>
                    <Col style={{ padding: 5, maxWidth: 125 }} xxl={2} lg={4} sm={12}>
                      <FloatingLabel controlId="floatingTextarea2" label={item.ecommerce ? "ECom-Qty" : "Stock-Qty"}>
                        <Form.Control type="number" placeholder="##"
                          onBlur={(e) => callback(2, item, e.target.value)}
                          defaultValue={item.qty} min="0" />

                      </FloatingLabel>
                    </Col>
                    <Col style={{ padding: 5, maxWidth: 125 }} xxl={2} lg={4} sm={12}>
                      <FloatingLabel controlId="floatingTextarea3" label="FBA-Qty">
                        <Form.Control type="number" placeholder="##"
                          onBlur={(e) => callback(3, item, e.target.value)}
                          defaultValue={item.fba_qty} min="0" />

                      </FloatingLabel>
                    </Col>
                    <Col style={{ padding: 5, maxWidth: 125 }} xxl={2} lg={4} sm={12}>
                      <FloatingLabel controlId="floatingTextarea4" label="Cust-Qty">
                        <Form.Control type="number" placeholder="##"
                          onBlur={(e) => callback(4, item, e.target.value)}
                          defaultValue={item.cust_qty} min="0" />

                      </FloatingLabel>
                    </Col>

                  </Row>
                </Container>

                {item.buy_pack_multiple > 1 && ((item.sale_pack_qty * (parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)))) % item.buy_pack_multiple != 0 &&
                  <Row style={{ marginBottom: 10 }}>
                    <Form.Text style={{ color: "black" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationTriangle} style={{ color: "#f3ad0f" }} /> &nbsp; This item needs to be ordered in multiples of  {item.buy_pack_multiple}. </Form.Text>
                  </Row>
                }

                {item.buy_pack_qty > 1 && item.sale_pack_qty == 1 &&
                  <Row>
                    <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as being purchased in a package of {item.buy_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u>{Math.ceil((parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)) / parseInt(item.buy_pack_qty))}</u>, and when this item is received, the inventory will be increased by <u>{(parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty))}</u>  </Form.Text>
                  </Row>
                }

                {item.sale_pack_qty > 1 && item.buy_pack_qty == 1 &&
                  <Row>
                    <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as being re-sold in a bundle of {item.sale_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u> {(parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)) * parseInt(item.sale_pack_qty)}</u>, but when this item is received, the inventory will be increased by <u>{(parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty))}</u>  </Form.Text>
                  </Row>
                }
                {item.sale_pack_qty > 1 && item.buy_pack_qty > 1 &&
                  <Row>
                    <Form.Text style={{ color: "red" }}> <FontAwesomeIcon className="warningIcon" icon={faExclamationCircle} style={{ color: "red" }} /> &nbsp;This item is marked as purchased in a packaged of {item.buy_pack_qty}, AND being re-sold in a bundle of {item.sale_pack_qty}. Order forms sent to the manufacturer will show a quantity of <u>{Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)) * parseInt(item.sale_pack_qty)) / parseInt(item.buy_pack_qty))}</u>, but when this item is received, the inventory will be increased by  <u>{parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)}</u>  </Form.Text>
                  </Row>
                }


              </Form>
            </div>
          </Media>

        </Card.Body>
      </Card>
    ))

  )

}