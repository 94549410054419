import React, { Component } from "react";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Logo from '../assets/Logo-C.png'

import {withRouter} from './withRouter';

import './login.css';

//import { Card, Header, Form, Input, Icon } from "semantic-ui-react";

let endpoint = "";


class Login extends Component {
    
 constructor(props) {
    super(props);
    
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      errorMessage: "",
      redirect:false
    };
  }    
    
   
handleSubmit(event) {
    event.preventDefault();
    let currentComponent=this;
   let passwd=this.my_password.value;
   let username=this.my_username.value;
   this.setState({errorMessage:""})
   
   axios.post(endpoint + '/api/login', {
    username:  username,
    passwd: passwd
  })
  .then(function (response) {
  currentComponent.setState({redirect:true})
    //console.log(response);
    //console.log(response.headers);
    currentComponent.props.callback(response.headers.bearer, username);
    currentComponent.props.navigate('/index.html')
    //currentComponent.props.history.push('index.html')
    
  })
  .catch(function (error) {
    console.log(error)
    if(error.response && error.response.data){
      currentComponent.setState({errorMessage: error.response.data}) 
    }else{
   
      currentComponent.setState({errorMessage:"Invalid Username or Password"})
    }
  });
   
  }
  
    
 render() { 

  
    return (
    
    <div className="wrapper fullPageWrapper"> 
       <img src={Logo} width={200}/>
        <h1 style={{fontSize:"1.5rem", marginTop:10}}>Please log in to use this service</h1>
        { this.props.message &&
  <p className="error"> { this.props.message } </p> }
        <div className="loginForm">
            <Form  onSubmit={this.handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Username</Form.Label>
                <Form.Control ref={(input) => this.my_username = input} placeholder="Username" />
                
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="mb-2" style={{marginTop:10}}> 
                <Form.Label>Password</Form.Label>
                <Form.Control ref={(input) => this.my_password = input} type="password" placeholder="Password" />
              </Form.Group> 
              
              <Button variant="primary" type="submit">
                Log In
              </Button>
              { this.state.errorMessage &&
  <p className="error"> { this.state.errorMessage } </p> }
             
            </Form>
        </div>
    </div>
);}}

export default withRouter(Login);
