import React, { Component, } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import './inventory.css';

import ModalImage from "react-modal-image";
import {LightningTable} from '../lightningTable/lightning-table'
import {LightningKit} from '../lightningTable/lightning-kit'
import {LightningSearch} from '../lightningTable/lightning-search'


let endpoint = "";

const columns = [
  {
    accessorKey: 'item_img_url',
    header: 'Image',
    enableSorting: false,
    cell: props => {
      return <ModalImage
        className="smallimage"
        small={props.getValue()}
        large={props.getValue()}
        alt={props.row.original.item_sku}

      />
    },width:0.05
  }, {
    accessorKey: 'item_name',
    header: 'Item Name',
    enableSorting: true,
    width:0.36
  }, {
    accessorKey: 'item_sku',
    header: 'Item SKU',
    enableSorting: true,
   width:0.15
  }, {
    accessorKey: 'description',
    header: 'Change Details',
    enableSorting: true,
    width:0.28
  }, {
    accessorKey: 'type',
    header: 'Change Type',
    enableSorting: true,
    width:0.08

  }, {
    accessorKey: 'user',
    header: 'Updated By',
    enableSorting: true,
    width:0.08

  }, {
    accessorKey: 'raw_time',
    header: 'Updated On',
    enableSorting: true,
    width:0.08,
    cell: props => { return <span>{props.row.original.time}</span> },


  }

];








class HistoricalUpdates extends Component {

  constructor(props) {
    super(props);

    this.state = {
      serverItemUpdates: [],
      nextPage: 0,
      disableMoreButton: false
    };
    this.getUpdates = this.getUpdates.bind(this)
    this.headerBoxRef = React.createRef()
    this.filterBoxRef = React.createRef()

  }

  getUpdates() {
    var currComponent = this
    var my_limit = 200

    let payload = {
      page: this.state.nextPage,
      limit: my_limit
    };
    axios.post(endpoint + '/api/get-updates', payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }, signal: this.controller.signal
    })
      .then(function (response) {
        if (response.data) {
          if (response.data.history) {
            if (response.data.history.length < my_limit) {
              currComponent.setState({ disableMoreButton: true })
            }
            if (currComponent.state.nextPage == 0) {
              currComponent.setState({ serverItemUpdates: response.data.history, nextPage: response.data.page_number })
            } else {
              currComponent.setState({ serverItemUpdates: [...currComponent.state.serverItemUpdates, ...response.data.history], nextPage: response.data.page_number })
            }
          } else {
            currComponent.setState({ disableMoreButton: true })
          }

        }

      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }
        console.log(error)
        if (!error.response || error.response.status == "401") {
          currComponent.props.setLogcallback("", "")
          currComponent.props.logincallback("Error! You have been logged out. Please log back in")
          currComponent.props.history.push('/login')
        }
      });
  }


  componentDidMount() {
    this.controller = new AbortController();

    this.getUpdates()
  }
  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
  }




  render() {
    const options = {
      paginationSize: 5,
      sizePerPage: 50,
      pageStartIndex: 0,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

      showTotal: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '250', value: 250
      }] // A numeric array is also available. the purpose of above example is custom the text
    };




    return (


      <div className="wrapper fullPageWrapper">


        <div className="customtable widetable">
          <div className="headerBox" ref={this.headerBoxRef}>
            <h1 className=" left-align">Historical Item Updates</h1>
          </div>
          <LightningKit columns={columns} getRowCanExpand={() => false} in_data={this.state.serverItemUpdates} pageOptions={options} width="100%" >
            {
                props => (
                  <div>
                    <div className="grayheader headerBox"  ref={this.filterBoxRef}>
                      <Container fluid>
                        <Row style={{ marginBottom: 10 }}>
                          <Col md={4}>
                            <div id="search-left" >
                              <h2>Search:</h2>
                              <LightningSearch {...props.searchProps} />

                            </div>
                          </Col>
                          <Col className=" right-align">
                            <Button onClick={this.getUpdates} disabled={this.state.disableMoreButton} variant="secondary" >Load Older Events</Button>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                    <div className=" tablerow">
                    <LightningTable
                        className="lightningFixedTable"
                        height={`calc(100vh - ${props.footerRef.current ? props.footerRef.current.clientHeight : 0}px - ${props.headerRef.current ? props.headerRef.current.clientHeight : 0}px - ${this.headerBoxRef.current ? this.headerBoxRef.current.clientHeight : 0}px - ${this.filterBoxRef.current ? this.filterBoxRef.current.clientHeight : 0}px - 169px`}
                        {...props} {...props.table}  
                        noDataIndication={ this.state.serverItemUpdates.length > 0 ? "No Matching Data" : <Spinner animation="border" />}
                    />
                      
                    </div>
                  </div>
                )
              }
          </LightningKit>
        </div>


      </div>
    );
  }
}

export default HistoricalUpdates;
