import React, { Component } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';

import RechartPLGraph from "../charts/rechartPLGraph"

import { withRouter } from '../withRouter';




class ProfitAndLoss extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profit_data: [],
      loaded: false,
      authorized: true
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.pldata != prevProps.pldata) {
      this.setState({ profit_data: this.props.pldata })
    }
    if (this.props.loading != prevProps.loading) {
      this.setState({ loaded: this.props.loading })
    }
    if (this.props.authorized != prevProps.authorized) {
      console.log(this.props.authorized)
      this.setState({ authorized: this.props.authorized })
    }
  }


  componentDidMount() {
    this.controller = new AbortController();
  }

  componentWillUnmount() {

    if (this.controller) {
      this.controller.abort()
    }

  }


  render() {
    return (
      <>
        <h2 style={{marginTop:20}} >Cash Flow</h2>
        
            {this.state.profit_data && this.state.profit_data.length > 0 ?
              <RechartPLGraph data={this.state.profit_data} /> :
              !this.state.loaded ?
                <Spinner animation="border" /> :
                this.state.authorized ?
                  <p>Error. Unable to load Profit and Loss Data.</p> :
                  <p>You do not have permission to view this page.</p>
            }

          
      </>
    );
  }
}

export default withRouter(ProfitAndLoss);
