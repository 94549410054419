
import Form from 'react-bootstrap/Form';
import React, { useRef, useEffect } from 'react'

export const CHECKED = 2
export const UNCHECKED = 0
export const INDETERMINATE = 1 
export const IndeterminateCheckbox = (props) => {
    const { value, ...otherProps } = props
    const checkRef = useRef();
  
    useEffect(() => {
      checkRef.current.checked = value === CHECKED
      checkRef.current.indeterminate = value === INDETERMINATE
    }, [value])
  
    return (
      <Form.Check
        ref={checkRef}
        {...otherProps}
      />
    )
  }