import { expandingRow } from '../../lightningTable/lightning-expand'
import Badge from 'react-bootstrap/Badge';

import {restockStatusToTextColor, restockStatusToColor} from './restockValues'

import {CustomEditableCell} from '../../lightningTable/lightning-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faSitemap } from '@fortawesome/free-solid-svg-icons'

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
  return (L > 0.179) ? darkColor : lightColor;
  }

export const SalesColumns = [
    expandingRow(),    
{
    accessorKey: 'item_name',
    header: 'Item',
    enableSorting: true,
    width: .2,

    cell: props => {
        return <div> <div>{props.row.original.item_notes && props.row.original.item_notes.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} title="This item has notes" className="trashIcon" icon={faClipboardList} />} {props.row.original.components && props.row.original.components.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }}title="This item has components" className="trashIcon" icon={faSitemap} />}  {props.row.original.components_of && props.row.original.components_of.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }}title="This is a component of another item" className="trashIcon" icon={faSitemap} transform={{ rotate: 180 }}/>} {props.getValue()}</div><span>{props.row.original.selling_items.map(item => <Badge key={item.item_id} bg="none" pill  style={{ backgroundColor: item.item_color, margin:3 , color:pickTextColorBasedOnBgColorSimple(item.item_color, "white", "black")}}>{item.selling_site} </Badge>)}</span></div>
    },

}, {
    accessorKey: 'alternate_names',
    header: 'Alternate Names',
    enableSorting: true,
    hidden: true,
    hideInToggle: true,
    editable: false,
    width: .1

}, {
    accessorKey: 'alternate_skus',
    header: 'Alternate Skus',
    enableSorting: true,
    hidden: true,
    hideInToggle: true,
    editable: false,
    width: .1
}, {
    accessorKey: 'item_sku',
    header: 'SKU',
    enableSorting: true,
    width: .1
}, {
    accessorKey: 'item_vendor',
    header: 'Vendor',
    enableSorting: true,
    hidden: false,
    editable: false,
    width: .05
}, {
    accessorKey: 'sites',
    header: 'Selling Sites',
    enableSorting: true,
    hidden:true,
    editable: false,
    cell: props => {
        return <div> {props.row.original.selling_items.map(item => <span key={item.item_id} style={{ color: item.item_color }}>{item.selling_site}, </span>)}</div>
    },
    width: .05
}, {
    accessorKey: 'overall_total',
    header: 'Life Sold',
    cell: props => { return <span>{props.getValue().toLocaleString()}</span> },

    enableSorting: true,
    width: .05
}, {
    accessorKey: 'avg_profit',
    header: 'Avg Profit',
    enableSorting: true,
    cell: props => { return props.getValue() == 0 ? <span>-</span> : 
    <div>
    <div>${props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })}</div> 
    { props.row.original.avg_profit_margin == 0 ? <></> : <div><small style ={{color:"darkslategray"}}>{props.row.original.avg_profit_margin}%</small></div> }
    </div>
  },
    width: .05
}, {
  accessorKey: 'avg_profit_margin',
  header: 'Avg Margin',
  enableSorting: true,
  hidden:true,
  cell: props => { return props.getValue() == 0 ? <span>-</span> : <span>{props.getValue()}%</span> },
  width: .05
}, {
  accessorKey: 'cost_price',
  header: 'Cost Price',
  enableSorting: true,
  cell:props=> {

    if (!props.getValue() || props.getValue() == 0) {
      return "-"
    } else if (String(props.getValue()).indexOf("$") >= 0) {
      return props.getValue()
    } else {
      return "$" + props.getValue().toLocaleString(undefined, { minimumFractionDigits: 2 })
    }
  
  },
  editable: false,
  hidden: false,
  width: 0.05
}, {
    accessorKey: 'w1',
    header: 'C1 Qty',
    enableSorting: true,
    hidden: true,
    cell: props => { return props.getValue() > -1 ?  <span>{props.getValue().toLocaleString()}</span> : <span>-</span> },
    width: .05
}, {
    accessorKey: 'w2',
    header: 'C2 Qty',
    enableSorting: true,
    hidden: true,
    cell: props => { return props.getValue() > -1?  <span>{props.getValue().toLocaleString()}</span> : <span>-</span> },
    width: .05
}, {
    accessorKey: 'on_order_qty',
    header: 'On Order Qty',
    enableSorting: true,
    cell: props => { return <div><div>Total: {props.getValue().toLocaleString()}</div>{props.row.original.ecom_on_order_qty ? <div style={{ color: 'green' }}>ECom: {props.row.original.ecom_on_order_qty.toLocaleString()}</div> : <></>}{props.row.original.fba_on_order_qty ? <div style={{ color: 'blue' }}>FBA: {props.row.original.fba_on_order_qty.toLocaleString()}</div> : <></>}</div> },
    width:.06
}, {
    accessorKey: 'item_qty',
    header: 'Stock Qty',
    enableSorting: true,
    cell: props => { return props.row.original.is_regular ? <span>{props.getValue().toLocaleString()}</span> : <span>-</span> },
    width: .05
}, {
    accessorKey: 'fba_item_qty',
    header: 'FBA Qty',
    cell: props => { return props.row.original.is_fba ? <span>{props.getValue().toLocaleString()}</span> : <span>-</span> },
    enableSorting: true,
    width: .05
}, {
    accessorKey: 'item_qty_reserved',
    header: 'Hold Qty',
    enableSorting: true,
    width: .05
}, {
    accessorKey: 'inbound_qty',
    header: 'FBA INBND Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: .03
}, {
    accessorKey: 'inbound_working_qty',
    header: 'FBA INBND WKING Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: .03
}, {
    accessorKey: 'fulfillable_qty',
    header: 'FBA FFBLE Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: .03
}, {
    accessorKey: 'reserved_qty',
    header: 'FBA RSVD Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: .03
}, {
    accessorKey: 'unsellable_qty',
    header: 'FBA UNSBLE Qty',
    enableSorting: true,
    hidden: true,
    editable: false,
    width: .03
},{
  accessorKey: 'item_tags_strings',
  header: 'Item Tags',
  hidden: true,
  hideInToggle: true
},
{
  accessorKey: 'status_num',
  header: 'Status',
  enableSorting: true,
  editable: false,
  hidden:true,
  width:0.1,
  cell: props => {
    return <>{props.row.original.status ? props.row.original.status.map(stati => (<div title={props.row.original.status_reason} key={stati} style={{marginBottom:5}}><Badge key={stati} style={{ fontSize: "100%" }} text={restockStatusToTextColor(stati)} bg={restockStatusToColor(stati)}>{stati}</Badge></div>)) : <></>}<div>{props.row.original.to_order_ecom ?<div style={ { color: 'green' } }>ECom: {props.row.original.to_order_ecom}</div>:<></>}{props.row.original.to_order_fba ?<div style={ { color: 'blue' } }>FBA: {props.row.original.to_order_fba}</div>:<></>}</div></>

  }
},

];

export const removeRestockColumns=(currentCols)=>{
    return currentCols.slice(0,SalesColumns.length)
}


export const addRestockColumns=(currentCols)=>{
    return [...currentCols, {
        accessorKey: 'avg_weekly_store_sales_30',
        header: '30-Day Store Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      }, {
        accessorKey: 'avg_weekly_store_sales_60',
        header: '60-Day Store Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      },{
        accessorKey: 'avg_weekly_store_sales_90',
        header: '90-Day Store Avg',
        enableSorting: true,
        hidden:true,
        editable: false,
        width:0.03,
        cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      },
       {
        accessorKey: 'avg_weekly_store_sales_4w',
        header: '4 Week Store Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_store_sales_30 * 280) / 4) / 10}</span> },
      },{
        accessorKey: 'avg_weekly_store_sales_8w',
        header: '8 Week Store Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_store_sales_60 * 560) / 4) / 10}</span> },
      }
        , {
          accessorKey: 'avg_weekly_store_sales_12w',
          header: '12 Week Store Avg',
          enableSorting: true,
          editable: false,
          hidden:true,
          width:0.03,
          cell: props => { return !props.row.original.is_regular ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_store_sales_90 * 840) / 4) / 10}</span> },
        },{
        accessorKey: 'avg_weekly_fba_sales_30',
        header: '30-Day FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      }, {
        accessorKey: 'avg_weekly_fba_sales_60',
        header: '60-Day FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      }, {
        accessorKey: 'avg_weekly_fba_sales_90',
        header: '90-Day FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round(props.getValue() * 10) / 10}</span> },
      },{
        accessorKey: 'avg_weekly_fba_sales_4w',
        header: '4 Week FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_fba_sales_30 * 280) / 4) / 10}</span> },
      },{
        accessorKey: 'avg_weekly_fba_sales_8w',
        header: '8 Week FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_fba_sales_60 * 560) / 4) / 10}</span> },
      },{
        accessorKey: 'avg_weekly_fba_sales_12w',
        header: '12 Week FBA Avg',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => { return !props.row.original.is_fba ? <span>-</span> : <span>{Math.round((props.row.original.avg_weekly_fba_sales_90 * 840) / 4) / 10}</span> },
      }, {
        accessorKey: 'item_lead_time',
        header: 'Lead Time (Days)',
        enableSorting: true,
        editable: true,
        hidden:true,
        width:0.03,    
        dataFormatter:props=> {return props.getValue() == 0 ? 10: props.getValue() < 0 ? -1 * props.getValue(): props.getValue()},
        styleFormatter:props=>props.getValue() == 0 ? { color: 'blue' }: props.getValue() < 0 ? {color: 'green' }: {color: 'black' },
        //props => { return props.getValue() == 0 ? <span style={{ color: 'blue' }}>10</span> : props.getValue() < 0 ? <span style={{ color: 'green' }}>{-1 * props.getValue()}</span> : <span>{props.getValue()}</span> },
    
      }, {
        accessorKey: 'item_stock_weeks',
        header: 'Days of Supply',
        enableSorting: true,
        editable: true,
        hidden:true,
        width:0.03,
        dataFormatter:props=> {return props.getValue() == 0 ? 30: props.getValue() },
        styleFormatter:props=>props.getValue() == 0 ? { color: 'blue' }: {color: 'black' },
      }, {
        accessorKey: 'daysLeft',
        header: 'Supply Days Left',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
         cell: props => {
          return Math.round( props.getValue() * 10) / 10
        }
      }, {
        accessorKey: 'to_order',
        header: 'Qty To Order',
        enableSorting: true,
        editable: false,
        hidden:true,
        width:0.03,
        cell: props => {return <div><div>Total: { props.getValue()}</div>{props.row.original.to_order_ecom ?<div style={ { color: 'green' } }>ECom: {props.row.original.to_order_ecom}</div>:<></>}{props.row.original.to_order_fba ?<div style={ { color: 'blue' } }>FBA: {props.row.original.to_order_fba}</div>:<></>}</div> }
      
      },{
        accessorKey: 'fba_note',
        header: 'FBA Note',
        enableSorting: true,
        hidden:true,
        editCellClasses: 'editing-fba-note',
       
        editable: (row) => row.original.is_fba,
        width:0.15,
      },{
        accessorKey: 'alternate_names',
        header: 'Alternate Names',
        enableSorting: true,
        hidden:true,
        hideInToggle:true,
        editable: false,
        width:0.10,
      },{
        accessorKey: 'alternate_skus',
        header: 'Alternate Skus',
        enableSorting: true,
        hidden:true,
        hideInToggle:true,
        editable: false,
        width:0.10,
      }]
}

export const addColumns = (columns, accessorPrefix, headerPrefix,useItemColor)=>{
    for(let i = 0; i < 8; i++){
        let newItem = {
          accessorKey: accessorPrefix+i,
          header: headerPrefix+i,
          enableSorting: true,
          width:0.04,
         
          headerAttrs: { index: i  },
        
          cell: props=>{ return props.getValue() > 0? <strong>{ props.getValue() }</strong> : <span>{ props.getValue() }</span> },
  
          style: (cell, row) => {
            let val = 1.0-(i/10.0);
            let hexes=["70", "65", "60", "55", "50", "45", "40", "35", "30"]
            if(useItemColor){
                return {
                    backgroundColor: row.original.item_color +hexes[i]  
                };
            }else{
                return {
                backgroundColor: 'rgb(134,184,30,' +val  + ")",
                };
            }
          }  
        }
        if(columns.findIndex(x => x.accessorKey == newItem.accessorKey) == -1 ){
            columns.push(newItem)   
        }

    }
    return columns
  
}