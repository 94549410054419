import React from "react";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars} from '@fortawesome/free-solid-svg-icons'





export const CustomToggleListLightning=({
  columns,
  exclusions=[],
  icon=faBars,
  title="Column Selection"
})=>(
  <OverlayTrigger
  trigger="click"
  rootClose
  
  placement="bottom"
  overlay={
    <Popover id={`popover-positioned-bottom`} style={{maxWidth:400}}>
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>

        <div className="columnContainer" data-toggle="buttons">
          {
            columns.filter(column => !column.columnDef.hideInToggle).filter(column => !exclusions.includes(column.id))
              .map(column => {
                  if(column.id != "select"){
                    return(<Form.Group key={`formBasicCheckboxGroupKey-${column.id}`} controlId={`formBasicCheckbox-${column.id}`}>
                      <Form.Check key={`formBasicCheckboxKey-${column.id}`} onChange={column.getToggleVisibilityHandler()} type="checkbox" label={typeof column.columnDef.header === "function" ? column.columnDef.header() : column.columnDef.header} checked={column.getIsVisible()} />
                    </Form.Group>)
                  } else {
                    return(<Form.Group key={`formBasicCheckboxGroupKey-${column.id}`} controlId={`formBasicCheckbox-${column.id}`}>
                      <Form.Check key={`formBasicCheckboxKey-${column.id}`} onChange={column.getToggleVisibilityHandler()} type="checkbox" label="Item Selection" checked={column.getIsVisible()} />
                    </Form.Group>)
                  }
                  })
                }
        </div>
     </Popover.Body>
    </Popover>
  }
  >

  <Button title ={title} style={{ float: "right", color: "black", marginRight:"15px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={icon} /></Button>
      </OverlayTrigger>
)

export const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
    icon=faBars,
    title="Column Selection"
  }) => (
  
   
      <OverlayTrigger
        trigger="click"
        rootClose
        
        placement="bottom"
        overlay={
          <Popover id={`popover-positioned-bottom`} style={{maxWidth:400}}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
  
              <div className="columnContainer" data-toggle="buttons">
                {
                  columns.map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                    .map(column => (
                        !column.hideInToggle && <Form.Group key={`formBasicCheckboxGroupKey-${column.dataField}`} controlId={`formBasicCheckbox-${column.dataField}`}>
                          <Form.Check key={`formBasicCheckboxKey-${column.dataField}`} onClick={() => onColumnToggle(column.dataField)} type="checkbox" label={column.text} defaultChecked={ !column.hideInToggle && column.toggle} />
                        </Form.Group>
                    ))
                      }
              </div>
           </Popover.Body>
          </Popover>
        }
        >
  
        <Button title ={title} style={{ float: "right", color: "black", marginRight:"15px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={icon} /></Button>
            </OverlayTrigger>
  );

  export const CustomToggleListSectioned = ({
    columns,
    onColumnToggle,
    toggles,
    icon=faBars,
    titles=["Column Selection"]
  }) => (
  
  
      <OverlayTrigger
        trigger="click"
        rootClose
        
        placement="bottom"
        overlay={
          <Popover id={`popover-positioned-bottom`} style={{maxWidth:400}}>
            {titles.map((title, index)=>(
            <React.Fragment key={title}>
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
  
              <div className="columnContainer" data-toggle="buttons">
                {
                  columns[index].map(column => ({
                    ...column,
                    toggle: toggles[index][column.dataField]
                  }))
                    .map(column => (
                        !column.hideInToggle && <Form.Group key={`formBasicCheckboxGroupKey-${column.dataField}`} controlId={`formBasicCheckbox-${column.dataField}`}>
                          <Form.Check key={`formBasicCheckboxKey-${column.dataField}`} onClick={() => onColumnToggle[index](column.dataField)} type="checkbox" label={column.text} defaultChecked={ !column.hideInToggle && column.toggle} />
                        </Form.Group>
                    ))
                      }
              </div>
           </Popover.Body></React.Fragment>))}
          </Popover>
        }
        >
  
        <Button title ={titles[0]} style={{ float: "right", color: "black", marginRight:"15px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={icon} /></Button>
            </OverlayTrigger>
  );