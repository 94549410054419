import React, { Component } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Row from 'react-bootstrap/Row';
import ModalImage from "react-modal-image";

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import Col from 'react-bootstrap/Col';
import EnsureModal from '../ensureModal'

import { OrderCardList, EmptyList } from './OrderCardList'

import Spinner from 'react-bootstrap/Spinner';

import MyPagination from "../MyPagination";

import PurchaseOrderImage from '../../assets/purchase_order.png'
import InfoImage from '../../assets/info-solid.svg'

import OrderDetails from './OrderDetails'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faQuestion, faTrash, faEye, faEdit, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from '../withRouter';

import '../inventory.css';

import Split from 'react-split'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

let endpoint = "";
let items = [];







class PastOrders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      orders_display: [],
      activeFilter: 1,
      showConfirmModal: false,
      lastDelete: "",
      searchType: "order_name",
      searchStatus: "",
      lastScroll: 0,
      dateFilter: false,
      currPage: 0,
      totalPages: 0,
      startIndex: 0,
      endIndex: 0,
      selectedOrder: undefined,
      leftSize: 100,
      rightSize: 0,
      width: 0
    }
    this.state.orders = items
    this.state.orders_display = items
    this.filterOrderStatus = this.filterOrderStatus.bind(this);
    this.filterBySearch = this.filterBySearch.bind(this);
    this.navToEditPage = this.navToEditPage.bind(this);
    this.changeOrderState = this.changeOrderState.bind(this);
    this.changeOrderStateLocalOnly = this.changeOrderStateLocalOnly.bind(this)
    this.deleteOrder = this.deleteOrder.bind(this);
    this.navToViewPage = this.navToViewPage.bind(this);
    this.navToDownloadPage = this.navToDownloadPage.bind(this);
    this.setSelectedOrder = this.setSelectedOrder.bind(this);
    this.navToReceivePage = this.navToReceivePage.bind(this);
    this.confirmedDelete = this.confirmedDelete.bind(this);
    this.keyPressed = this.keyPressed.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


  }

  componentWillUnmount() {
    // Remember state for the next mount
    //items = this.state.orders;
    if (this.controller) {
      this.controller.abort()
    }

    window.removeEventListener('resize', this.updateWindowDimensions);

  }
  /*
  async getOrdersWrapper (){
    if(this.state.lastScroll !=0 && items.length > 0){
       this.filterBySearch(this.searchTerm.value,"")
       document.documentElement.scrollTop = document.body.scrollTop =this.state.lastScroll 
    }else{
      this.getOrders()
    }
    
  }
  */

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  getOrders = () => {
    let currentComponent = this;
    this.setState({ errorMessage: "Loading Data. Please Wait..." })


    axios.get(endpoint + "/api/get-all-store-orders",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
         signal: this.controller.signal
      }).then(res => {
        if (res.data) {
          //console.log(Math.ceil(res.data.length/50.0))
          this.setState({

            orders: res.data.map(order => {

              var tmp = order
              tmp.items_summary = "[" + order.items_summary + "]"
              tmp.items_summary_parsed = JSON.parse(order.items_summary)
              return tmp
            }),
            orders_display: res.data,
            totalPages: Math.ceil(res.data.length / 50.0)


          }, () => this.filterBySearch(this.searchTerm.value, "", undefined, false));

          document.documentElement.scrollTop = document.body.scrollTop = this.state.lastScroll
        } else {
          currentComponent.setState({
            orders: [],

          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        currentComponent.setState({
          orders: [],
          order_create_return: "error"
        });
        if (error.response) {
          if (error.response.status === 401) {
            currentComponent.props.setLogcallback("", "")
            currentComponent.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/login')
          }
        } else {
          currentComponent.setState({
            orders: [],

          });
        }
      });
  };

  componentDidMount() {

    this.controller= new AbortController();

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    //const params = queryString.parse(this.props.location.search);
    const params = this.props.searchParams

    if (params.get('q')) {
      this.searchTerm.value = params.q
    }
    if (params.get('filter') && params.get('filter') != "undefined") {
      this.setState({ searchStatus: params.get('filter')})
    }
    if (params.get('activeFilter') && params.get('activeFilter') != "undefined") {
      this.setState({ activeFilter:params.get('activeFilter') })
    }
    if (params.get('scroll')) {

      this.setState({ lastScroll: params.get('scroll')})

    }
    if (params.get('dateFilter') && params.get('dateFilter') == 'true') {
      this.setState({ dateFilter: true })
    }
    if (params.get('searchType') && params.get('searchType') != "undefined") {
      this.setState({ searchType: params.get('searchType') })
    }
    if (params.get('page') && params.get('page') != "undefined") {
      this.setState({ currPage: parseInt(params.get('page') ) - 1 })
    }
    /*
    var date1 = new Date(row.fba_start); 
    var date2 = Date.now()
      
    // To calculate the time difference of two dates 
    var Difference_In_Time = date2 - date1.getTime(); 
      
    // To calculate the no. of days between two dates 
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
    */
    //this.getOrdersWrapper();
    this.getOrders();




  }


  filterOrderStatus(orderStatus) {
    /*
      this.setState( {orders_display:this.state.orders.filter(function(order) { 
                  
                  return (order.order_status == searchTerm || searchTerm =="" || (searchTerm=="RECEIVED" && order.order_status == "CLOSED" ))
      })})
      return;*/

    this.setState({ searchStatus: orderStatus })
    this.filterBySearch(this.searchTerm.value, "", orderStatus)
  }

  filterBySearch(searchTerm, type, statusType, changePage = true) {
    var com = this
    var typeUse = type == "" ? com.state.searchType : type
    var status = ""
    if (statusType !== undefined) {
      status = statusType

    } else {
      status = this.state.searchStatus
    }

    if (!searchTerm) {
      searchTerm = ""
    }
    var dateFilter = this.state.dateFilter
    searchTerm = searchTerm.toLowerCase()

    var new_orders = this.state.orders.filter(function (order) {
      var order_date = new Date(order.order_date)

      var foundMatchingItem = false
      var foundMatchingSearchTerm = false
      if (typeUse == "part_name") {
        if (searchTerm == "") {
          foundMatchingItem = true
        } else {
          for (var i = 0; i < order.items_summary_parsed.length; i++) {
            if (order.items_summary_parsed[i].item_sku.toLowerCase().includes(searchTerm)) {

              if (status == "" || (status == "CREATED" && (order.items_summary_parsed[i].item_state == "ON_ORDER" ||
                order.items_summary_parsed[i].item_state == "PARTIAL_RECEIVED")) ||
                (status == "RECEIVED" && order.items_summary_parsed[i].item_state == "RECEIVED")) {
                foundMatchingItem = true;
                break;
              }
            }
          }
        }

      } else {
        foundMatchingSearchTerm = (searchTerm == "" || order.order_status.toLowerCase().includes(searchTerm) ||
          order.order_name.toLowerCase().includes(searchTerm) ||
          order.order_date.includes(searchTerm))
        var matchesOrderStatus = order.order_status == status || status == "" || (status == "RECEIVED" && order.order_status == "CLOSED") || (status == "NOTES" && order.has_notes)
        foundMatchingSearchTerm = foundMatchingSearchTerm && matchesOrderStatus
      }


      return (((typeUse == "part_name" && foundMatchingItem) || (typeUse == "order_name" && foundMatchingSearchTerm)) &&
        (!dateFilter || (((Date.now() - order_date.getTime()) / (1000 * 3600 * 24)) > 30)))
    })

    //console.log(new_orders)
    this.setState({ orders_display: new_orders, totalPages: Math.ceil(new_orders.length / 50), currPage: changePage ? 1 : this.state.currPage + 1 })
    return;
  }

  navToEditPage(order_id) {

    this.props.history.push({ search: '?q=' + this.searchTerm.value + '&page=' + this.state.currPage + '&filter=' + this.state.searchStatus + '&activeFilter=' + this.state.activeFilter + '&dateFilter=' + this.state.dateFilter + '&searchType=' + this.state.searchType + '&scroll=' + document.documentElement.scrollTop || document.body.scrollTop })
    this.props.history.push({
      pathname: "/order-edit",

      state: {
        id: order_id
      }
    });
  }
  navToViewPage(order_id) {
    this.props.history.push({ search: '?q=' + this.searchTerm.value + '&page=' + this.state.currPage + '&filter=' + this.state.searchStatus + '&activeFilter=' + this.state.activeFilter + '&dateFilter=' + this.state.dateFilter + '&searchType=' + this.state.searchType + '&scroll=' + document.documentElement.scrollTop || document.body.scrollTop })

    this.props.history.push({
      pathname: "/order-view/" + order_id,
      /*state: {
        id: order_id
      }*/
    });
  }
  navToDownloadPage(order_id) {
    this.props.history.push({ search: '?q=' + this.searchTerm.value + '&page=' + this.state.currPage + '&filter=' + this.state.searchStatus + '&activeFilter=' + this.state.activeFilter + '&dateFilter=' + this.state.dateFilter + '&searchType=' + this.state.searchType + '&scroll=' + document.documentElement.scrollTop || document.body.scrollTop })

    this.props.history.push({
      pathname: "/order-download",
      state: {
        id: order_id
      }
    });
  }
  navToReceivePage(order_id) {
    this.props.history.push({ search: '?q=' + this.searchTerm.value + '&page=' + this.state.currPage + '&filter=' + this.state.searchStatus + '&activeFilter=' + this.state.activeFilter + '&dateFilter=' + this.state.dateFilter + '&searchType=' + this.state.searchType + '&scroll=' + document.documentElement.scrollTop || document.body.scrollTop })

    this.props.history.push({
      pathname: "/receive-order",
      state: {
        id: order_id
      }
    });
  }

  setSelectedOrder(order_id) {
    if (this.state.rightSize == 0) {
      this.setState({ selectedOrder: order_id, leftSize: 50, rightSize: 50 })
    } else {
      this.setState({ selectedOrder: order_id })
    }
  }



  keyPressed(e) {
    if (e.keyCode == 38) {
      var lastOrder = ""
      for (var i = 0; i < this.state.orders_display.length; i++) {

        if (this.state.orders_display[i].order_id == this.state.selectedOrder) {
          break;
        }
        lastOrder = this.state.orders_display[i].order_id
      }
      if (lastOrder !== "") {
        this.setSelectedOrder(lastOrder)
      }
    } else if (e.keyCode == 40) {
      for (var i = 0; i < this.state.orders_display.length; i++) {

        if (this.state.orders_display[i].order_id == this.state.selectedOrder && i < this.state.orders_display.length - 1) {
          this.setSelectedOrder(this.state.orders_display[i + 1].order_id)
        }
      }
    }
  }

  changeOrderState(order_id, status) {
    var payload = { order_status: status }
    var component = this
    axios.put(endpoint + '/api/update-order-status/' + order_id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      },
       signal: this.controller.signal
    })
      .then(function (response) {
        var text = { order_status: status }
        component.setState({
          orders: component.state.orders.map(el => (el.order_id === order_id ? Object.assign({}, el, text) : el)),
          orders_display: component.state.orders_display.map(el => (el.order_id === order_id ? Object.assign({}, el, text) : el))
        })

        //console.log(response.headers);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }
      });
  }

  changeOrderStateLocalOnly(order_id, status) {
    var component = this
    var text = { order_status: status }
    component.setState({
      orders: component.state.orders.map(el => (el.order_id === order_id ? Object.assign({}, el, text) : el)),
      orders_display: component.state.orders_display.map(el => (el.order_id === order_id ? Object.assign({}, el, text) : el))
    })


  }


  confirmedDelete() {
    var order_id = this.state.lastDelete
    var component = this
    axios.delete(endpoint + '/api/delete-order/' + order_id,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
         signal: this.controller.signal
      })
      .then(function (response) {
        var new_orders = component.state.orders.filter(function (order) {

          return order.order_id !== order_id
        })

        var new_orders_display = component.state.orders_display.filter(function (order) {

          return order.order_id !== order_id
        })

        component.setState({
          orders: new_orders,
          orders_display: new_orders_display,
          selectedOrder: undefined
        })

        //console.log(response.headers);
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return;
        }
      });

    this.setState({ lastDelete: "", showConfirmModal: false })
  }
  deleteOrder(order_id) {

    this.setState({ showConfirmModal: true, lastDelete: order_id });

  }


  FilterOrdersByItem(props) {

    const radios = [
      { name: 'All Orders', value: '1', searchMthd: "" },
      { name: 'On Order', value: '2', searchMthd: "CREATED" },
      { name: 'Received', value: '3', searchMthd: "RECEIVED" },

    ];
    return (
      <>

        <div id="filters-collapse">


          <DropdownButton size="sm" variant="secondary" id="dropdown-basic-button" title={radios[props.currentFilter - 1]['name']}>
            {radios.map((radio, idx) => (
              <Dropdown.Item
                key={idx}
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={radio.value == props.currentFilter}
                onClick={(e) => {
                  props.changeActiveFilter(radio.value);

                  props.filter(radios[radio.value - 1]['searchMthd'])
                }}
              >
                {radio.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>




        </div>
      </>
    );
  }

  FilterOrders(props) {

    const radios = [
      { name: 'All Orders', value: '1', searchMthd: "" },
      { name: 'Created', value: '2', searchMthd: "CREATED" },
      { name: 'Placed', value: '3', searchMthd: "PLACED" },
      { name: 'Partially Received', value: '4', searchMthd: "PARTIAL_RECEIVED" },
      { name: 'Fully Received/Closed', value: '5', searchMthd: "RECEIVED" },
      { name: 'Order Has Notes', value: '6', searchMthd: "NOTES" },
    ];
    return (
      <>

        <div id="filters-collapse">



          <DropdownButton size="sm" variant="secondary" id="dropdown-basic-button" title={radios[props.currentFilter - 1]['name']}>
            {radios.map((radio, idx) => (
              <Dropdown.Item
                key={idx}
                variant="secondary"
                name="radio"
                value={radio.value}
                checked={radio.value == props.currentFilter}
                onClick={(e) => {
                  props.changeActiveFilter(radio.value);

                  props.filter(radios[radio.value - 1]['searchMthd'])
                }}
              >
                {radio.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>




        </div>
      </>
    );
  }


  render() {

    //    <OrderSearch onSearch={this.filterBySearch} onEnterFunc={()=>{}} />
    return (


      <div className="wrapper">
        <EnsureModal onSubmit={this.confirmedDelete} show={this.state.showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}
          message="Are you sure you want to delete this order? This cannot be undone!" />

        <div className="customtable widetable">
          <div className="headerBox" id="PurchaseOrderHeaderBox">
            <Container fluid>
              <Row>
                <Col xs={8}>
                  <h1 className=" left-align">Purchase Orders</h1>
                </Col>


                <Col xs={4}>


                  {!this.state.dateFilter && <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="left"
                    overlay={
                      <Popover style={{ maxWidth: 400, overflowY: "scroll", maxHeight: "95vh", overflowX: "hidden" }} >
                        <Popover.Header as="h3">How to Use this Page</Popover.Header>
                        <Popover.Body>
                          <strong>Purchase Orders</strong>
                          <hr />
                          <p>This screen allows you to interact with placed parts orders. Each order has a state - created, placed, partially received, and received/closed. When an order is created, it is in the create state. Click the 'Mark Placed' button to move the item to the 'placed' state. This lets you know that someone actually placed the order once it was created. When the order is received using the receive page, the states are automatically changed to partially received/received. To mark an open order as closed (manually received/cancelled, etc), click 'Mark Closed' (visible once in the 'placed' state).</p>
                          <p>To change the state of an order, click on the three vertical dots on the right of each order card, and click 'Mark Placed' or 'Mark Closed'.</p>

                          <strong>Filter Orders</strong>
                          <hr />
                          <p>To find an order, type in any part of the order in the search box (order date, status, name, etc), and/or click on a filter based on state.</p>
                          <p>To find an order by an item in the order, select the 'Part' search option, type in any part of the item name in the search box , and/or click on a filter based on state.</p>

                          <strong>Order Operations</strong>
                          <hr />
                          <p>To view order details, click on an order card from the list on the left. To switch between orders in the list, click on any other orders, or use your keyboard up/down arrow keys.</p>
                          <p>To receive the order, click the receive button (<FontAwesomeIcon className="trashIcon" icon={faShare} title="Receive Order" />). To edit an order, click the edit button (<FontAwesomeIcon className="trashIcon" icon={faEdit} title="Edit Order" />). To download the order, click on the download button (<FontAwesomeIcon className="trashIcon" icon={faDownload} title="Receive Order" />). Once on the download page, select 'print to pdf' as the printer, and click print. This will save it as a pdf. To view the order, including status and order links, click the view button (<FontAwesomeIcon className="trashIcon" icon={faEye} title="View Order" />). To delete the order, click the three dots on the order card, and click 'Delete'. Note, you cannot undo the delete action, so be careful. </p>

                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button style={{ float: "right", color: "black" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faQuestion}></FontAwesomeIcon></Button>



                  </OverlayTrigger>}
                  <ModalImage
                    className="smallimage"
                    small={InfoImage}
                    large={PurchaseOrderImage}
                    alt={"Tutorial Image"}

                  />
                  {this.state.dateFilter && <Button style={{ float: "right" }} variant="info" onClick={() => { this.setState({ dateFilter: false }, () => this.filterBySearch(this.searchTerm.value, "")) }}>Only showing orders older than 30 days. Click to show all</Button>}
                </Col>
              </Row>
            </Container>
          </div>

          <div className="tablerow">
            {this.state.width > 700 ?
              <Split style={{ display: "flex" }} sizes={[25, 75]} minSize={325}>
                {this.LeftOrderPanel(this.FilterOrders, this.FilterOrdersByItem)}
                <OrderDetails token={this.props.token} setLogcallback={this.props.setLogcallback} logincallback={this.props.logincallback} order_id={this.state.selectedOrder} receiveStateCallback={this.changeOrderStateLocalOnly} cart_items={this.props.cart_items} overwriteCart={this.props.overwriteCart} itemcallback={this.props.itemcallback} />
              </Split> :
              this.LeftOrderPanel(this.FilterOrders, this.FilterOrdersByItem)
            }
          </div>

        </div>
      </div>
    );
  }

  LeftOrderPanel(FilterOrders, FilterOrdersByItem) {
    return <div className="LeftOrderPanel">
      <div id="search-left">
        <Form onSubmit={(e) => e.preventDefault()} style={{ width: "100%", marginBottom: "10px" }}>
          <Row>
            <Col xs={9}>
              <Form.Control id="orderSearch" className="form-control" placeholder={this.state.searchType == "order_name" ? "Search Order Info" : "Search Part Name"} ref={(input) => this.searchTerm = input} onChange={(e) => this.filterBySearch(e.target.value, "")} />
            </Col>
            <Col xs={3} style={{ textAlign: "left" }}>
              <Form.Check onChange={(e) => { this.setState({ searchType: "order_name" }, () => this.filterBySearch(this.searchTerm.value, "order_name")); }}  label="Order" type="radio" id="check1" name="typeradio" checked={this.state.searchType == "order_name"} />
              <Form.Check onChange={(e) => { this.setState({ searchType: "part_name" }); this.filterBySearch(this.searchTerm.value, "part_name"); }}  label="Part" type="radio" id="check2" name="typeradio" checked={this.state.searchType == "part_name"} />
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "left" }}>
              {this.state.searchType == "order_name" ?
                <FilterOrders filter={this.filterOrderStatus} currentFilter={this.state.activeFilter} changeActiveFilter={(i) => this.setState({ activeFilter: i })} />
                :
                <FilterOrdersByItem filter={this.filterOrderStatus} currentFilter={this.state.activeFilter} changeActiveFilter={(i) => this.setState({ activeFilter: i })} />}
            </Col>
          </Row>
        </Form>

      </div>
      <div className="orderList" tabIndex="0" onKeyDown={this.keyPressed}>
        {this.state.orders_display.length ?
          <OrderCardList items={this.state.orders_display}
            editCallback={this.navToEditPage}
            stateCallBack={this.changeOrderState}
            deleteCallBack={this.deleteOrder}
            viewCallBack={this.navToViewPage}
            downloadCallback={this.navToDownloadPage}
            clickCallback={this.setSelectedOrder}
            startIndex={(this.state.currPage * 50) - 50}
            endIndex={(this.state.currPage * 50)}
            selectedOrderID={this.state.selectedOrder}
            receiveCallback={this.navToReceivePage} />
          : this.state.orders.length ? <EmptyList my_text="No Orders Found That Match Search Criteria" /> : <Spinner animation="border" />}
      </div>
      <div>

        <MyPagination
          totPages={this.state.totalPages}
          currentPage={this.state.currPage}
          pageClicked={(ele) => {
            this.setState({ startIndex: (ele * 50) - 50, currPage: ele });
          }} />
        {this.state.orders.length && this.state.orders_display.length > 0 ? <span style={{ fontSize: ".75rem" }}>Displaying orders {(this.state.currPage * 50) - 49}-{Math.min((this.state.currPage * 50) + 1, this.state.orders_display.length)} of {this.state.orders_display.length}</span> : <span></span>}
      </div>

    </div>;
  }
}

export default withRouter(PastOrders);
