import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from "react";
import { LightningTable } from '../../lightningTable/lightning-table.js'
import { LightningKit } from '../../lightningTable/lightning-kit.js'
import { LightningSearch } from '../../lightningTable/lightning-search.js'
import '../inventory.css';

const columnsMerge = [{
    accessorKey: 'item_name',
    header: 'Product Name',
    enableSorting: true,
    editable: false,
    width: .35
}, {
    accessorKey: 'item_sku',
    header: 'Item Sku',
    enableSorting: true,
    editable: false,
    width: .2
}, {
    accessorKey: 'item_qty',
    header: 'Stock Qty',
    enableSorting: true,
    editable: false,
    width: .07
}, {
    accessorKey: 'item_qty_incoming',
    header: 'FBA Qty',
    enableSorting: true,
    editable: false,
    width: .07
}, {
    accessorKey: 'num_linked',
    header: '# Link Items',
    enableSorting: true,
    editable: false,
    width: .07
}, {
    accessorKey: 'item_type',
    header: 'Selling Sites',
    enableSorting: true,
    editable: false,
    width: .1
}
];

export const ItemMergeTab = function (props) {

    const [selected_merge_itemName, setMergeItemName] = useState("");
    const [selected_merge_item, setMergeItem] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const componentQty = React.useRef(0)


    const options = {
        paginationSize: 5,
        sizePerPage: 100,
        pageStartIndex: 0,
        showTotal: true,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '100', value: 100
        }, {
            text: 'All', value: props.items_minified.length
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.group_id == props.item.group_id) {
            style.backgroundColor = 'coral';
        }
        return style;
    };

    const my_handleSubmit_merge = (event) => {
        event.preventDefault()
        if (selected_merge_item == null) {
            setErrorMessage("Must select an item to merge")
            return
        } else {
            setErrorMessage("")
        }
        if (props.item.group_id == selected_merge_item.group_id) {
            setErrorMessage("Must select an item other than the current item to merge with")
            return
        } else {
            setErrorMessage("")
        }

        if (props.type == "Component" && (!componentQty.current.value || componentQty.current.value === 0)) {
            setErrorMessage("Must enter in a component quantity")
            return
        } else {
            setErrorMessage("")
        }
        var payload = { group_id1: props.item.group_id, group_id2: selected_merge_item.group_id, item_name: selected_merge_item.item_name, item_sku: selected_merge_item.item_sku, item_img_url: selected_merge_item.item_img_url, stock_quantity: selected_merge_item.item_qty }
        setMergeItem(null)
        props.onHide()
        props.onMergeSubmit(payload, componentQty.current.value)
    }

    return (
        <div>
            <Modal.Body className="ModalBody" style={{ padding: 0 }}>
                <div style={{ paddingTop: 5 }}>


                    <LightningKit columns={columnsMerge} pageOptions={options} getRowCanExpand={() => false} in_data={props.items_minified} width="100%" >
                        {
                            props => (

                                <div className="customtable widetable">
                                    <div id="search" className="grayheader headerBox" >
                                        <h2>Search:</h2>
                                        <LightningSearch {...props.searchProps} />
                                    </div>

                                    <LightningTable
                                        className="lightningFixedTable smallFont"
                                        height="30vh"
                                        {...props} {...props.table}
                                        noDataIndication="No Data to Show"
                                        rowStyle={rowStyle}
                                        onRowDoubleClick={(row) => {
                                            setMergeItem(row.original)
                                            setMergeItemName(row.original.item_name)
                                            setErrorMessage("")
                                        }}
                                    />


                                </div>
                            )
                        }

                    </LightningKit>

                    {selected_merge_itemName &&
                        <p style={{ color: "darkgreen" }}>{props.type == "Component" ? "Component to Add:" : "Item to Link:"} {selected_merge_itemName}</p>
                    }

                    {props.type == "Component" && selected_merge_itemName &&
                        <Form.Group as={Row} style={{ marginTop: 20, marginBottom: 20 }}>
                            <Col style={{ textAlign: "right" }}>
                                <Form.Label style={{ textAlign: "right", marginTop: 2 }}>
                                    Quantity of Component:
                                </Form.Label>
                            </Col>
                            <Col sm="8">
                                <Form.Control type="number" min="1" style={{ maxWidth: "75px" }} required ref={componentQty}

                                />
                            </Col>
                        </Form.Group>}

                    {errorMessage &&
                        <p className="error"> {errorMessage} </p>}
                </div>
            </Modal.Body>
            <Modal.Footer>

                <Button onClick={props.onHide}>Cancel</Button>
                <Button type="submit" onClick={my_handleSubmit_merge} disabled={selected_merge_item == null} >{props.type == "Component" ? "Add Component" : "Link Items"}</Button>
            </Modal.Footer>
        </div>
    )
}