  import React, { Component } from "react";
import axios from "axios";

import Container from 'react-bootstrap/Container';
import { useHistory, useParams } from 'react-router-dom'

import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import {withRouter} from './withRouter';

import './inventory.css';

let endpoint = "";


 const ItemStatusToName = (status) => {
  var background = ""
  if (status == "ON_ORDER") {
    background = "On Order"
  } else if (status == "RECEIVED") {
    background = "Received"
  } else if (status == "PARTIAL_RECEIVED"){
    background = "Partially Received"
  }
  return background
}


class ViewOrder extends Component {

 constructor(props) {
    super(props);

    this.state={

        order_details:{},
        order_create_return:"",
        pageWidth:0

    }
       this.updateWindowDimensions = this.updateWindowDimensions.bind(this);



 }

updateWindowDimensions() {
  this.setState({ pageWidth: window.innerWidth});
}

  statusToName = (status) => {
  var background = ""
  if (status == "CREATED") {
    background = "Created"
  } else if (status == "PLACED") {
    background = "Placed"
  } else if (status == "CLOSED"){
    background = "Closed"
  }else if(status == "RECEIVED"){
    background = "Received"
  } else if (status == "PARTIAL_RECEIVED") {
    background = "Partially Received"
  }
  return background
}

 

    getOrderData = (id) => {
    let currentComponent=this;
    if(!id){
        return;
    }
    this.setState({errorMessage :"Loading Data. Please Wait..."})
    axios.get(endpoint + "/api/get-order-items/"+id,
    {
     headers: {
       Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
     }
    }).then(res => {
      if (res.data) {
        currentComponent.setState({
          order_details: res.data

        });


      } else {
        currentComponent.setState({
          order_details: {}

        });
      }
    }).catch(error => {
    // handle error
    currentComponent.setState({
          order_details: {},
          order_create_return:"error"
          });
          if (error.response){
              if(error.response.status===401){
                this.props.setLogcallback("","")
                  this.props.logincallback("Error! You have been logged out. Please log back in")
                  if(currentComponent.props.history){
                    currentComponent.props.navigate('/index.html')

                  }
              }
          }else{
              currentComponent.setState({
                  order_details: {}

              });
          }
  });
  }

  getCost(items){
    var res = 0

    items.forEach(item=>{
      if(!item.cust_qty){
        item.cust_qty = 0
      }
      if(!item.qty){
        item.qty = 0
      }
      if (!item.fba_qty){
        item.fba_qty = 0
      }
      if (item.item_cost > 0){
        res+=(item.item_cost*(parseInt(item.cust_qty) + parseInt(item.qty)+parseInt(item.fba_qty)))
         }
    })
    return Math.round(res*100)/100
  }

   componentDidMount() {

     if(this.props.order_details){
       this.setState({order_details:this.props.order_details})
     }else if(this.props.order_id){
      this.getOrderData(this.props.order_id);
     }else{
      const id = this.props.params.id
      this.getOrderData(id);
     }
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
  }
 componentDidUpdate(prevProps, prevState) {

  if( this.props.order_id !=prevProps.order_id){
      this.getOrderData(this.props.order_id);
  }else if( this.props.order_details !=prevProps.order_details){
      this.setState({order_details:this.props.order_details})
  }
  
}

  componentWillUnmount() {
  
    window.removeEventListener('resize', this.updateWindowDimensions);

  }




 render() {


    function OrderItemTable(props){
       
        if( ! props.order.items){
            return(
                <>
                <p>There are no items in this order</p>
                </>
                )

        }
        return(
             <>
                <Table responsive  striped bordered  className={props.innerPage? " fixedTable  fullContainerViewOrder mobileTable viewTable" : " fixedTable mobileTable viewTable"} size="sm" >
                  <thead>
                     {props.pageWidth > 550 ? <tr>
                      <th>SKU</th>
                      <th>Total Qty</th>
                      <th>ECom-Qty</th>
                      <th>FBA-Qty</th>
                      <th>Cust-Qty</th>
                      <th className="itemUrlCol">Order URL</th>
                      <th>Per Unit Cost</th>
                      <th>Status</th>
                      <th>Qty Received</th>
                      <th>Receive Date</th>

                    </tr> :

                    <tr>  
                      <th> Item</th>
                    </tr>}
                  </thead>
                  <tbody>
                  {props.order.items.map(item => (
                    props.pageWidth > 550 ?  
                     <tr key = {`${item.sku}-${item.qty}`}>
                      <td>{item.sku}</td>
                      <td>{Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) * item.sale_pack_qty) / item.buy_pack_qty) == (parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) ? <div>{(parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty))}</div> : <div> <div> <span>Order Qty: </span><span>{Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) * item.sale_pack_qty) / item.buy_pack_qty)}</span></div> <div><span>Unit Qty: </span> <span> {(parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty))}</span></div></div>}</td>
                      <td>{item.qty}</td>

                      <td>{item.fba_qty}</td>
                      <td>{item.cust_qty}</td>
                      <td className="itemUrlCol">{item.item_url && <a className="order_url" href={item.item_url} target="_blank">Order Link</a>}</td>
                      <td>${item.item_cost>0? item.item_cost : "?"}</td>
                      <td>{ItemStatusToName(item.order_status)}</td>
                      <td>{item.orig_rec_qty}</td>
                      <td>{item.rec_date}</td>
                    </tr> 
                    
                     :
                    <tr key = {`${item.sku}-${item.qty}`}>
                      <td>
                      <div className="narrowColumnCell">
                        <div className="narrowColumnTitle">{item.sku}</div>
                        <div className="narrowColumnContentL1">Total Order Quantity: &nbsp; {Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) * item.sale_pack_qty) / item.buy_pack_qty) == (parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) ? <span>{(parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty))}</span> : <span> <span> <span>Order Qty: </span><span>{Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty)) * item.sale_pack_qty) / item.buy_pack_qty)}</span></span> <span><span>, Unit Qty: </span> <span> {(parseInt(item.qty) + parseInt(item.fba_qty)+parseInt(item.cust_qty))}</span></span></span>} </div>
                        <div className="narrowColumnContentL1">&nbsp;&nbsp; Ecommerce Quantity: &nbsp; {item.qty} </div>
                        <div className="narrowColumnContentL1">&nbsp;&nbsp; FBA Quantity: &nbsp; {item.fba_qty} </div>
                        <div className="narrowColumnContentL1">&nbsp;&nbsp; Customer Quantity: &nbsp; {item.cust_qty} </div>
                        <div className="narrowColumnContentL1 itemUrlCol">Order URL: &nbsp; {item.item_url && <a className="order_url" href={item.item_url} target="_blank">Order Link</a>} </div>
                        <div className="narrowColumnContentL1">Per Unit Cost: &nbsp; ${item.item_cost>0? item.item_cost : "?"} </div>
                        <div className="narrowColumnContentL1">Item Status: &nbsp; {ItemStatusToName(item.order_status)} </div>
                        <div className="narrowColumnContentL1">Received Quantity: &nbsp; {item.orig_rec_qty} </div>
                        <div className="narrowColumnContentL1">Received Date: &nbsp; {item.rec_date} </div>
                      </div>
                      </td>
                      
                    </tr> 
                  
                    
                    ))
                  }

                  </tbody>
            </Table>
            </>
        )
    }

     if(Object.keys(this.state.order_details).length==0 ){
            return(
                <>
                <p>Select an order to view</p>
                </>
                )

        }

    return (


    <div className={this.props.innerPage? "wrapper fullHeightWrapper" : "wrapper"} style={{padding:this.props.innerPage?0:120 , backgroundColor:this.props.innerPage?"white":"#FaFaFa"}}>

        <div id="tableWrapper">
        <h1 className={`ViewOrderShow-${this.props.innerPage}`}>Order Information</h1>
        <h2 className={`ViewOrderShow-${this.props.innerPage}`} style={{marginBottom:"30px"}}>{this.state.order_details.order_name}</h2>
        <Container id="orderContainer" style={{textAlign:"left"}}>
          <Row>
              <Col sm={6} >
                <h3 style={{fontSize:"1.25rem"}}>Order Date: {this.state.order_details.order_date}</h3>
                <h3 style={{fontSize:"1.25rem"}}>Order Status: {this.statusToName(this.state.order_details.order_status)}</h3>
                {this.state.order_details.items && <h3 style={{fontSize:"1.25rem"}}>Distinct Items: {this.state.order_details.items.length}</h3>}
                {this.state.order_details.items && <h3 style={{fontSize:"1.25rem"}}>Total Cost: ${this.getCost(this.state.order_details.items).toLocaleString(undefined, { minimumFractionDigits: 2 })}</h3>}
                
              </Col>
              <Col sm={6} >
                {this.state.order_details.vendor_name &&<h3 style={{fontSize:"1.25rem"}}>Vendor: {this.state.order_details.vendor_name}</h3>}
                {this.state.order_details.account_num &&<h3 style={{fontSize:"1.25rem"}}>Account# {this.state.order_details.account_num}</h3>}
                {this.state.order_details.contact && <h3 style={{fontSize:"1.25rem"}}>Vendor Contact: {this.state.order_details.contact}</h3>}
                {this.state.order_details.vendor_notes&&<h3 style={{fontSize:"1.25rem"}}>Vendor Notes: {this.state.order_details.vendor_notes}</h3>}
              </Col>
          </Row> 
                    
          {this.state.order_details.order_notes &&
            <Row>
              <Col>
                <h3 style={{fontSize:"1.25rem",display:"inline-block"}}>Order Notes: </h3>
                <p style={{display:"inline-block", marginLeft:"5px"}}> {this.state.order_details.order_notes}</p>
              </Col>
            </Row>
          }
        </Container>

            <div id="itemWrapper">
                <OrderItemTable  order={this.state.order_details} innerPage = {this.props.innerPage} pageWidth = {this.state.pageWidth}/>
            </div>
        </div>
    </div>
);}}

export default withRouter(ViewOrder);
