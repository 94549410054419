import React, { Component } from "react";
import axios from "axios";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from './Media';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { withRouter } from './withRouter';

import './inventory.css';

let endpoint = "";

class DownloadOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order_details: {},
      order_create_return: "",
      includeCost: false
    }

  }

  getOrderData = (id) => {
    let currentComponent = this;
    if (!id) {
      return;
    }
    this.setState({ errorMessage: "Loading Data. Please Wait..." })
    axios.get(endpoint + "/api/get-order-items/" + id,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          currentComponent.setState({
            order_details: res.data

          });
          window.print();


        } else {
          currentComponent.setState({
            order_details: {}

          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          order_details: {},
          order_create_return: "error"
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
          }
        } else {
          currentComponent.setState({
            order_details: {}

          });
        }
      });
  }

  componentDidMount() {

    if (this.props.order_details) {
      this.setState({ order_details: this.props.order_details })
    } else if (this.props.order_id) {
      this.getOrderData(this.props.location.state.id);
    }
  }
  componentDidUpdate(prevProps, prevState) {

    if (this.props.order_details != prevProps.order_details) {
      this.setState({ order_details: this.props.order_details })
    }

  }

  render() {

    function OrderItemTable(props) {
      if (!props.order || !props.order.items) {
        return (
          <>
            <p>There are no items in this order</p>
          </>
        )

      }
      return (
        <>
          <Table responsive size="sm" className="left " >
            <thead>
              <tr>
                <th>SKU</th>
                {props.includeCost && <th>Cost</th>}
                <th>Qty</th>
              </tr>

            </thead>

            <tbody >
              {props.order.items.map(item => (
                <tr className="tableNoBorder" key={`${item.sku}-${item.qty}`}>
                  <td className="tableNoBorder">{item.sku.replace(/ *\([^)]*\) */g, " ")}</td>
                  {props.includeCost && <td className="tableNoBorder">${item.item_cost > 0 ? item.item_cost.toLocaleString(undefined, { minimumFractionDigits: 2 }) : ""}</td>}
                  <td className="tableNoBorder">{Math.ceil(((parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)) * item.sale_pack_qty) / item.buy_pack_qty)}</td>
                </tr>
              ))
              }

            </tbody>
          </Table>
        </>
      )
    }

    return (
      <>
        <Form.Switch
          id="price-switch"
          label="Include Cost Price"
          className="HideOnPrint"
          style={{ textAlign: "left", float: "right", marginRight: 10 }}
          checked={this.state.includeCost}
          onChange={() => { this.setState({ includeCost: !this.state.includeCost }) }}
        />
        <div className="wrapper fullHeightWrapper monospace" style={{ padding: this.props.innerPage ? 0 : 120, backgroundColor: this.props.innerPage ? "white" : "#FaFaFa" }}>

          <div id="tableWrapper" className="downloadWrapper">
            <h1>* * * * * * P U R C H A S E   &nbsp; O R D E R * * * * * *</h1>

            <Container id="orderContainer">
              <Row>
                <Col xs={4} ><h3 className="left">{this.state.order_details.order_date}</h3></Col>
              </Row>
              <Row>
                <Col xs={4} >
                  <h2 className="left" >Order #: {this.state.order_details.order_name}</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={4} >
                  <Media className="left">
                    <p>From: &nbsp;</p>
                    <div className="left">
                      {this.props.store_info ?
                        <>
                          <p style={{ marginBottom: 0 }} className="left">
                            {this.props.store_info.store_name}
                          </p>
                          <p style={{ marginBottom: 0 }} className="left">
                            {this.props.store_info.address}
                          </p>
                          <p className="left">
                            {this.props.store_info.city}, {this.props.store_info.state} {this.props.store_info.zip}
                          </p>
                        </>
                        :

                        <p className="left">
                          Set Store Address
                          In the settings page
                        </p>
                      }
                    </div>
                  </Media>
                </Col>

              </Row>
              <Row>
                <Col xs={5} >

                  {this.state.order_details.account_num &&
                    <h3 className="left" >Account# {this.state.order_details.account_num}</h3>
                  }

                </Col>
                <Col xs={{ span: 5, offset: 2 }} ><h3 className="right">Vendor: {this.state.order_details.vendor_name}</h3></Col>

              </Row>

              <Row>
                <Col xs={5} ><h3 className="left">Phone#: {this.props.store_info ? this.props.store_info.phone_num : "(631)-499-3355"}</h3></Col>
                <Col xs={{ span: 5, offset: 2 }} >
                  {this.props.store_info.fax_num.trim().length > 0 ? <h3 className="right">Fax#:  {this.props.store_info ? this.props.store_info.fax_num : "(631)-499-5458"}</h3> : <></>}
                </Col>

              </Row>
              <Row>
                
                {/*this.props.store_info ? <h3>{this.props.store_info.po_notes}</h3> : <></>*/}
                {this.props.store_info ? <Form.Control  className="PONotes" plaintext style={{ textAlign:"center"}} type="string"  defaultValue={this.props.store_info.po_notes} placeholder="" /> : <></>}
              </Row>
            </Container>

            <div id="downloadItemWrapper">
              <OrderItemTable order={this.state.order_details} includeCost={this.state.includeCost} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DownloadOrder);
