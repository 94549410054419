

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
library.add( faChevronRight, faChevronDown)

  export function expandingRow(){
 
    var column = {
      id: 'expander',
      accessorKey:'expander',
      size:30,
      enableSorting:false,
      hideInToggle:true,
      header: ({table})=>(
        <button
        className="expandingButton"

        {...{
          onClick: table.getToggleAllRowsExpandedHandler(),
        }}
      >
        { table.getIsAllRowsExpanded()? <FontAwesomeIcon icon={faChevronDown} /> : table.getIsSomeRowsExpanded() ? <FontAwesomeIcon icon={faChevronRight} transform={{rotate:55}} /> : <FontAwesomeIcon icon={faChevronRight} />}
      </button>
      ),
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <button
            {...{
              onClick: (e)=>{e.stopPropagation();row.getToggleExpandedHandler()()},
              style: { cursor: 'pointer' },
            }}
            className="expandingButton"
          >
            {row.getIsExpanded() ?  <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} /> }
          </button>
        ) : (
          '🔵'
        )
       
      }
    }

    return column;
  
}