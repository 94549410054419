

export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

export function formatDate(date){
    var month = date.getMonth()+1
    var year = date.getFullYear() %1000
    var day = date.getDate()
    var strdate = month + '/' + day + '/'+ year
    return strdate
}

export function formatDateTime(date){ 
    return formatDate(date) + ' '+ formatAMPM(date) 
}