import React, { useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import "react-resizable/css/styles.css";
import { RangePicker } from "./DateRange";
import { createDate } from "../filters/dateFilter";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DataFilter } from "./dataFilter";

export function SalesGraphSettings({
  selectedDate, setDate, onSearch, token, filterElements, filterCallback, starting_selectedElements
}) {

  const [selected_type, setSelectedType] = useState("Default View");
  const [all_vendors, setAllVendors] = useState([])
  var search_term = React.createRef();
  var search_term_type = React.createRef();

  React.useEffect(() => {
    if (selected_type == 'View By Vendor') {
      getAllVendors()
    }
  }, [selected_type]);

  const my_handle_submit = (e) => {
    e.preventDefault()
    var searchType = ""
    var searchTerm = search_term.value
    const [startDate, endDate] = selectedDate;
    if (selected_type == 'Group By Vendor') {
      searchType = "Vendor"
      searchTerm = search_term_type.value
    } else if (selected_type == 'View By Vendor') {
      searchType = "VendorItem"
    } else if (selected_type == 'Group By Tag') {
      searchType = "Tag"
    } else if (selected_type == 'View By Item Keyword') {
      searchType = "ITEM"
    } else {
      searchType = "__Default"
    }
    onSearch(searchTerm, searchType, startDate,endDate)
  }
  const getAllVendors = () => {

    axios.get('/api/get-unique-item-vendors',
      {
        headers: {
          Authorization: 'Bearer '
        }
      })
      .then(function (response) {
        setAllVendors(response.data)

      })
      .catch(function (error) {
        setAllVendors([])

      });
  }

  return (
    <div>
      <Form onSubmit={my_handle_submit} className="RowsNoFullWidth">
        <Row style={{ margin: "0px" }}>

          <Col md={5} style={{ maxWidth: "450px", marginBottom: 10 }}>
            <RangePicker input_startDate={createDate(-180, 0, 0, true, true, true)} input_endDate={selectedDate[1]} callback = {(d)=>{setDate(d)}} />
          </Col>
          <Col md={5} style={{ maxWidth: "450px", marginBottom: 10 }}>
            <Form.Group as={Row}  style={{ paddingLeft: 10, paddingRight: 10 }}  controlId="form">

              <Form.Select style={{ width: "225px", height: 38 }} defaultValue={"Default View"} onChange={(e) => { setSelectedType(e.target.value); }}>
                <option>Group By Account</option>
                <option>Group By Vendor</option>
                <option>Group By Tag</option>
                <option>View By Item Keyword</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {selected_type == 'View By Item Keyword' ? <Col md={4} >
            <Form.Group as={Row} style={{ marginBottom: 10 }}>
              <Form.Label style={{ padding: 5 }}> Search:  </Form.Label>
              <Form.Control type="string" placeholder="Search Term"
                ref={(input) => search_term = input}
                style={{ width: "250px", height: 38 }} />
            </Form.Group>
          </Col>
            : selected_type == 'View By Vendor' ?

              <Col md={4} style={{ marginBottom: 10 }} >
                <Form.Group as={Row} style={{ paddingLeft: 10, paddingRight: 10 }} controlId="formSite2">

                  <Form.Select defaultValue="unselected_default" style={{ width: "200px", height: 38 }} required ref={(input) => search_term = input}>
                    <option disabled value="unselected_default">--Select a Vendor--</option>
                    {all_vendors.map(vendor => <option key={vendor['vendor']}>{vendor['vendor']}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
              : 
                <></>
          }
          <Button type="submit" variant="secondary" style = {{height: 38, marginLeft: 10}}><FontAwesomeIcon className="trashIcon" icon={faArrowRight} /></Button>
          {filterElements ? <DataFilter elements = {filterElements.map(element=>{return element['data_label'];}).sort(function (a, b) {return a.toLowerCase().localeCompare(b.toLowerCase());})} onSelectionChange = {filterCallback} starting_selectedElements = {starting_selectedElements}/> : <></>}




        </Row>
      </Form>
    </div>
  );
}
