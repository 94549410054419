import axios from "axios";

export const GetProfitRevData = async (payload, token, controller, logOutActions, onErrorAction) => {
    try {
        const response = await axios.post('/api/get-monthly-profit-rev-data',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            },
            signal: controller.signal
        })
        if(response.data){
            return response.data
        }else{
            return {}
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            throw new Error("Cancel");
        }
        if(error.response){
            if(error.response.status == "401"){
                logOutActions();
                throw new Error("Logged Out");
            }else{
                onErrorAction("Unknown Error. Unable to get profit and revenue data.");
                return {}
            }   
        }
    }
}

export const GetTopSelling = async (payload, token, controller, logOutActions, onErrorAction) => {
    try {
        const response = await axios.post('/api/get-top-item-sales-data',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            },
            signal: controller.signal
        })
        if(response.data){
            return response.data
        }else{
            return {}
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            throw new Error("Cancel");
        }
        if(error.response){
            if(error.response.status == "401"){
                logOutActions();
                throw new Error("Logged Out");
            }else{
                onErrorAction("Unknown Error. Unable to get profit and revenue data.");
                return {}
            }   
        }
    }
}


export const GetPLData = async (token, controller, logOutActions, onErrorAction) => {
    try {
        const response = await  axios.get( "/api/get-profit-loss-data",
           {
            headers: {
                Authorization: 'Bearer ' + token
            },
            signal: controller.signal
        })
        if(response.data){
            return response.data['profit_and_loss_records']
        }else{
            return []
        }
    } catch (error) {
        if (axios.isCancel(error)) {
            throw new Error("Cancel");
        }
        if(error.response){
            if(error.response.status == "401"){
                logOutActions();
                throw new Error("Logged Out");
            }else if(error.response.status == "403"){
                onErrorAction("");
                throw new Error("Unauthorized");
                return []
            } else{
                onErrorAction("Unknown Error. Unable to get profit and revenue data.");
                return []
            }   
        }
    }
}