import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import '../inventory.css';

import { updateItemProperties } from "./utilFunctions";
/**
 * 
 * @param {*} props item (required) 
 * @param {*} props readOnly (required) whether or not fba notes field is enabled or disabled
 * @param {*} props.my_updateItem (optional) function to call after updating the item
 * @param {*} props.onHide (required) function to hide modal
 * @param {*} props.token (required if my_updateItem is passed) token to use for updating item
 * @returns 
 */
export const FBAPropertiesTab = function (props) {

    var notes_val = React.createRef();

    const my_handleSubmit = (event) => {
        var payload = { updated_field: [], updated_item: props.item }

        if (notes_val.value != props.item.fba_note) {
            payload.updated_item.fba_note = notes_val.value
            payload.updated_field.push('fba_note')
        }


        if (payload.updated_field.length) {
            updateItemProperties(payload.updated_item, payload.updated_field, props.token).then((retVal) => {
                if(props.my_updateItem){
                    props.my_updateItem(payload.updated_field, payload.updated_item, retVal)
                }
            })
        }

        props.onHide();
    }

    return (
        <div>
            <Modal.Body className="ModalBody">
                <Form onSubmit={my_handleSubmit} className="ItemModalDetailsForm">

                    <Row>
                        <Form.Group as={Col} controlId="formValue1">
                            <Form.Label>Inbound Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.inbound_qty} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue1d">
                            <Form.Label>Fulfillable Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.fulfillable_qty} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue1e">
                            <Form.Label>Unsellable Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.unsellable_qty} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue1f">
                            <Form.Label>Reserved Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.reserved_qty} />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="formValue1a">
                            <Form.Label>Inbound Working Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.inbound_working_qty} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formValue1a">
                            <Form.Label>Researching Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.researching_qty} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue1b">
                            <Form.Label>Reserved Future Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.reserved_future_qty} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formValue1c">
                            <Form.Label>Future Buyable Qty</Form.Label>
                            <Form.Control disabled type="string" defaultValue={props.item.reserved_future_qty} />
                        </Form.Group>
                    </Row>

                    <Row>
                        <Form.Group as={Col} controlId="formValue1g">
                            <Form.Label>FBA Notes</Form.Label>
                            <Form.Control disabled={props.readOnly} size="sm" as="textarea" maxLength="256" rows={3} ref={(input) => notes_val = input} type="string" placeholder="FBA Notes" defaultValue={props.item.fba_note} />
                        </Form.Group>
                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer>

                <Button onClick={props.onHide}>Cancel</Button>
                <Button type="submit" disabled={props.readOnly} onClick={my_handleSubmit}>Save</Button>
            </Modal.Footer>
        </div>
    )
}