import React, { Component } from "react";

import axios from "axios";

import ViewOrder from '../viewOrder'
import DownloadOrder from '../downloadOrder'
import EditOrder from '../EditOrder/editOrder'

import ReceiveOrder from '../receiveOrder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import {withRouter} from '../withRouter';

import { faTrash, faEye, faEdit, faDownload, faShare } from '@fortawesome/free-solid-svg-icons'




const  NavButtons = ({currentPage, onSelect, loading})=>{

  return(
    <ToggleButtonGroup value={currentPage} name="OrderNav">

        <ToggleButton id="1"  name="OrderNav" value="VIEW" onChange={(e) => {onSelect(e.currentTarget.value)}} type="radio" variant="light" onClick={(e) => {e.stopPropagation();}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faEye} title="View Order" /></ToggleButton>
        <ToggleButton id="2"  name="OrderNav" disabled={loading} value="DOWNLOAD" onChange={(e) => {onSelect(e.currentTarget.value)}} type="radio" variant="light" onClick={(e) => {e.stopPropagation();}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faDownload} title="Download Purchase Order" /></ToggleButton>
        <ToggleButton id="3"  name="OrderNav" disabled={loading} value="EDIT" onChange={(e) => {onSelect(e.currentTarget.value)}} type="radio" variant="light" onClick={(e) => {e.stopPropagation();}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faEdit} title="Edit Order" /></ToggleButton>
        <ToggleButton id="4"  name="OrderNav" disabled={loading} value="RECEIVE"  onChange={(e) => {onSelect(e.currentTarget.value)}} type="radio" variant="light" onClick={(e) => {e.stopPropagation();}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faShare} title="Receive Order" /></ToggleButton>
        
    </ToggleButtonGroup>
  )
  }

class OrderDetails extends Component {

 constructor(props) {
    super(props);

    this.state={
        order_details:{},
        currentPage:"VIEW",
        loading:false,
        all_items_less_cart:[],
        edit_data:{},
        store_info:{}

    }



 }

 componentDidMount() {
  this.controller= new AbortController();

    if(this.props.order_id){
        this.getOrderData(this.props.order_id);
    }else if(this.props.match){
        const { id } = this.props.match.params
        this.getOrderData(id);
    }
 }

 componentDidUpdate(prevProps, prevState) {

  if( this.props.order_id !=prevProps.order_id){
      if(this.props.order_id){
        this.getOrderData(this.props.order_id);
        this.getSettings()
      }else{
        this.setState({currentPage:"VIEW", order_details:{}})
      }
  }
}
  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
  }


getSettings=()=>{
    var com = this
     axios.get( '/api/get-settings',
          {
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
        com.setState({store_info:response.data})

        })
        .catch(function (error) {
      
        });
           
            
  }

getOrderData = (id) => {
    let currentComponent=this;
   
    if(!id){
        return;
    }
    this.setState({loading:true})
    axios.get( "/api/get-order-items/"+id,
    {
     headers: {
       Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
     },
      signal: this.controller.signal
    }).then(res => {
      if (res.data) {
        currentComponent.setState({
          order_details: res.data,
          loading:false,
          edit_data:{}

        });


      } else {
        currentComponent.setState({
          order_details: {},
          loading:false,
          edit_data:{}

        });
      }
      
    }).catch(error => {
    // handle error
    if (axios.isCancel(error)) {
      return;
    }
    currentComponent.setState({
          order_details: {},
          loading:false,
          edit_data:{}
          });
          if (error.response){
              if(error.response.status===401){
                this.props.setLogcallback("","")
                  this.props.logincallback("Error! You have been logged out. Please log back in")
                    currentComponent.props.navigate('/index.html')

                  
              }
          }else{
              currentComponent.setState({
                  order_details: {},
                    loading:false,
                    edit_data:{}

              });
          }
  });
  }

render() {


    return (
        <div className="order_details_content">
             <div id="PurchaseOrderHeaderBox">
                <Container fluid>
                    <Row>
                        <Col sm={8}>
                            <h2 style={{fontSize:"1.75rem"}} className=" left-align">{this.state.order_details.order_name}</h2>
            
                        </Col>
                        <Col sm={4} style={{textAlign:"right"}}>
                            <NavButtons currentPage={this.state.currentPage} onSelect={(value)=>{this.setState({currentPage:value})}} loading={this.state.loading || Object.keys(this.state.order_details).length ==0}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            {this.state.loading ? <Spinner animation="border" style={{marginTop:"20px"}} /> : 
            this.state.currentPage=="VIEW" ? <ViewOrder  innerPage={true} order_details={this.state.order_details} />:
            this.state.currentPage=="DOWNLOAD" ?<DownloadOrder store_info={this.state.store_info} innerPage={true} order_details={this.state.order_details} />:
            this.state.currentPage=="RECEIVE" ?<ReceiveOrder receiveStateCallback = {this.props.receiveStateCallback} innerPage={true} order_details={this.state.order_details} reloadParentData = {()=>this.getOrderData(this.props.order_id)}token={this.props.token} setLogcallback={this.props.setLogcallback} logincallback={this.props.logincallback} />:
            this.state.currentPage=="EDIT" ?<EditOrder storeItems={(items)=>this.setState({all_items_less_cart:items})} all_items_less_cart={this.state.all_items_less_cart} cart_items = {this.props.cart_items} overwriteCart = {this.props.overwriteCart} itemcallback={this.props.itemcallback} receiveStateCallback = {this.props.receiveStateCallback} innerPage={true} order_details={this.state.order_details} reloadParentData = {()=>this.getOrderData(this.props.order_id)}token={this.props.token} setLogcallback={this.props.setLogcallback} logincallback={this.props.logincallback} priorData={this.state.edit_data} saveEditWork={(data)=>this.setState({edit_data:data})}/>:<></>}

        
        
        
        </div>
   
);}}

export default withRouter(OrderDetails);