import React, { Component } from 'react';
import logo from '../logo.svg';
import axios from "axios";
import './inventory.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnsureModal from './ensureModal'


import { faTrash, faLessThanEqual } from '@fortawesome/free-solid-svg-icons'

let endpoint = "";

class UserSettings extends Component {
  constructor(props) {
    super(props);
    var new_username = React.createRef()
    var new_password1 = React.createRef()
    var old_password = React.createRef()
    var new_password2 = React.createRef()
    this.accountTypeRef = React.createRef()
    this.financialTypeRef = React.createRef()
    this.state = {
      selectedState: "REGULAR",
      showEnsure: false,
      selectedStateAccess: "0",
      toDelete: ""
    }


    this.onAddUser = this.onAddUser.bind(this)
    this.onDeleteUser = this.onDeleteUser.bind(this)
    this.confirmedDelete = this.confirmedDelete.bind(this)
  }
  confirmedDelete = () => {
    var component = this;
    this.setState({ showEnsure: false })
    var payload = { username: this.props.username, passwd: this.old_password.value, newusername: this.state.toDelete }
    axios.post(endpoint + '/api/delete-user',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {

        component.props.updateAlert({
          errorMessage: "Successfully Deleted User",
          showAlert: true,
          isError: false
        })
        component.props.reloadUsers();

        //console.log(response.headers);
      })
      .catch(function (error) {
        if (!error.response || error.response.status == "401") {
          component.props.updateAlert({
            errorMessage: "Error Deleting User  - Either your old password is incorrect, or you have been logged out",
            showAlert: true,
            isError: true
          })
        } else {
          component.props.updateAlert({
            errorMessage: "Error Deleting User - Server Error. Username may already exist.",
            showAlert: true,
            isError: true
          })

        }


      });
  }

  onDeleteUser = (e, uname) => {
    e.preventDefault();
    if (this.old_password.value.length < 1) {
      this.props.updateAlert({
        errorMessage: "Error Deleting User - Must enter your current password",
        showAlert: true,
        isError: true
      })
      return;
    }
    this.setState({
      showEnsure: true,
      toDelete: uname
    })

  }

  onAddUser = (e) => {
    e.preventDefault()
    if (this.old_password.value.length < 1) {
      this.props.updateAlert({
        errorMessage: "Error Creating User - Must enter your current password",
        showAlert: true,
        isError: true
      })
      return;
    }
    if (this.new_password1.value != this.new_password2.value) {
      this.props.updateAlert({
        errorMessage: "Error Creating User - Passwords Don't Match",
        showAlert: true,
        isError: true
      })
      return;
    }
    var component = this;
    var payload = { username: this.props.username, passwd: this.old_password.value, newusername: this.new_username.value, newpassword: this.new_password1.value, newusertype: this.accountTypeRef.current.value, canviewfinancials: this.financialTypeRef.current.value }

    axios.post(endpoint + '/api/add-user',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {

        component.props.updateAlert({
          errorMessage: "Successfully Added User",
          showAlert: true,
          isError: false
        })
        component.props.reloadUsers();

        //console.log(response.headers);
      })
      .catch(function (error) {
        if (!error.response || error.response.status == "401") {
          component.props.updateAlert({
            errorMessage: "Error Adding User  - Either your old password is incorrect, or you have been logged out",
            showAlert: true,
            isError: true
          })
        } else {
          component.props.updateAlert({
            errorMessage: "Error Adding User - Server Error. Username may already exist.",
            showAlert: true,
            isError: true
          })

        }


      });

  }

  UserTable(props) {
    if (!props.users) {
      return (
        <>
          <p>There are no users.</p>
        </>
      )

    }
    return (
      <>
        <Table responsive size="sm" >
          <thead>
            <tr>
              <th>UserName</th>
              <th>Account Type</th>
              <th>Financial Access</th>
              <th>Delete</th>


            </tr>
          </thead>
          <tbody>
            {props.users.map(user => (
              <tr key={user.username}>
                <td>{user.username}</td>
                <td>{user.usertype == "ADMIN"? "Admin" : "Regular"}</td>
                <td>{user.canviewfinancials == "0" ? "No Access" : user.canviewfinancials == "1" ? "Individual Items Only" : "Full Access"}</td>
                <td><Button variant="link" onClick={(e) => { props.onDeleteUser(e, user.username) }}><FontAwesomeIcon className="trashIcon" icon={faTrash} title="Delete User" /></Button></td>
              </tr>
            ))
            }

          </tbody>
        </Table>
      </>
    )
  }


  render() {
   
    return (
      <div className="tabbed-content">
        <h2>User Accounts</h2>
        <hr></hr>
        <p>Enter your password below to make any changes:</p>
        <Form.Group as={Row} controlId="formPlaintextPasswordCurr">
          <Form.Label column sm="4">
            Your ({this.props.username}) Password
          </Form.Label>
          <Col sm="8">
            <Form.Control ref={(input) => this.old_password = input} type="password" placeholder="Current Password" autoComplete="current-password" required />
          </Col>
        </Form.Group>



        <hr />

        <Form onSubmit={this.onAddUser}>
          <h3>Add User</h3>
          <Form.Text>Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</Form.Text>
          <Form.Group as={Row} controlId="formUsername" className="mb-2">
            <Form.Label column sm="4">
              New Username
            </Form.Label>
            <Col sm="8">
              <Form.Control required ref={(input) => this.new_username = input}
                type="string" placeholder="New Username"
                pattern="[*\dA-Za-z]{5,}"
                title="Must contain at least 5 characters with no special symbols." />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextPasswordNew1" className="mb-2">
            <Form.Label column sm="4">
              New Password
            </Form.Label>
            <Col sm="8">
              <Form.Control ref={(input) => this.new_password1 = input}
                type="password" placeholder="New Password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                autoComplete="new-password"
                required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextPasswordNew2" className="mb-2">
            <Form.Label column sm="4">
              New Password
            </Form.Label>
            <Col sm="8">
              <Form.Control ref={(input) => this.new_password2 = input}
                type="password" placeholder="Re-enter New Password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                autoComplete="new-password"
                required />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextType" className="mb-2">
            <Form.Label column sm="4">
              User Type
            </Form.Label>
            <Col sm="4">
              <Form.Select ref={this.accountTypeRef}>
                <option key="Regular" value="REGULAR">Regular</option>
                <option key="Admin" value="ADMIN">Admin</option>
              </Form.Select>


            </Col>

          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextType2" className="mb-2">
            <Form.Label column sm="4">
              Financial View Access
            </Form.Label>
            <Col sm="4">
              <Form.Select ref={this.financialTypeRef}>
                <option key="None" value="0">No Access</option>
                {/*<option key="Some" value="1">Individual Items Only</option>*/}
                <option key="All" value="2">Full Access</option>
              </Form.Select>

            </Col>

          </Form.Group>
          <Button type="submit" >Add User</Button>
        </Form>

        <hr />
        <h3>View/Delete Users</h3>
        {this.UserTable({users:this.props.users ,onDeleteUser:this.onDeleteUser})}
        <EnsureModal onSubmit={this.confirmedDelete} show={this.state.showEnsure} onHide={() => this.setState({ showEnsure: false })}
          message="Are you sure you want to delete this User? This cannot be undone!" />
      </div>




    )
  }
}

export default UserSettings;

/*
 
*/