import React, { Component } from "react";
import { useState } from "react";
import axios from "axios";


import './inventory.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CustomToggleListLightning, CustomToggleListSectioned } from "./customToggleList.js"

import Badge from 'react-bootstrap/Badge';

import Spinner from 'react-bootstrap/Spinner';
import ItemPropertiesModal from './ItemModal/itemModal.js'

import {withRouter} from './withRouter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilter } from '@fortawesome/free-solid-svg-icons'


import {LightningTable} from '../lightningTable/lightning-table'
import {LightningKit} from '../lightningTable/lightning-kit'
import {LightningSearch} from '../lightningTable/lightning-search'
import { LightningCSV,DateSort } from '../lightningTable/lightning-misc';


import { formatDateTime, formatDate, formatAMPM } from "./dateFormatter";

import {FilterByDate,createDate} from './filters/dateFilter'
let endpoint = "";



const options = {
  paginationSize: 5,
  sizePerPage: 100,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

  showTotal: true,
  sizePerPageList: [{
    text: '50', value: 50
  }, {
    text: '100', value: 100
  }, {
    text: '200', value: 200
  }] // A numeric array is also available. the purpose of above example is custom the text
};

const narrow_columns = [

  {
    accessorKey: 'order_date',
    cell: props=>{return <><div>{formatDate(new Date(props.getValue()))}</div> <div>{formatAMPM(new Date(props.getValue()))}</div></>},

    header: 'Order Date',
    enableSorting: true,
    width:.25
  }, {
    accessorKey: 'item_name',
    header: 'Details',
    enableSorting: true,

    cell: props => {
      return (

        <div className="narrowColumnCell">
          <div className="narrowColumnTitle">{props.getValue()}</div>
          <div className="narrowColumnContentL1">SKU: &nbsp; {props.row.original.item_sku} </div>
          <div className="narrowColumnContentL1">Order Number: &nbsp; {props.row.original.order_number} </div>
          <div className="narrowColumnContentL1">Region: &nbsp; {props.row.original.region} </div>
          <div className="narrowColumnContentL1">Order Quantity: &nbsp; {props.row.original.item_qty} </div>
          <div className="narrowColumnContentL1">Purchase Price: &nbsp; {props.row.original.purchase_price != 0 ? `$${props.row.original.purchase_price}` : " - "} </div>
          <div className="narrowColumnContentL1">Selling Site: &nbsp; {props.row.original.buy_method} </div>
          <div className="narrowColumnContentL1">Order Status: &nbsp; {props.row.original.amazon_status} </div>
          <div className="narrowColumnContentL1">Selling Fee: &nbsp; {props.row.original.selling_fee ? `$${props.row.original.selling_fee}` : " - "} </div>
          <div className="narrowColumnContentL1">Shipping Cost: &nbsp; {props.row.original.shipping_fee ? `$${props.row.original.shipping_fee}` : " - "} </div>
          <div className="narrowColumnContentL1">Item Cost: &nbsp; {props.row.original.item_cost > 0 ? `$${props.row.original.item_cost}` : " - "} </div>
          <div className="narrowColumnContentL1">Profit: &nbsp; {props.row.original.profit ? `$${props.row.original.profit}` : " - "} </div>
        </div>

      )
    },
    width:.75
  }

]

const orderToColor=(status)=>{
  var background = "warning"
  if (status == "Pending" ) {
    background = "warning"
  } else if ( status=="Unshipped") {
    background = "primary"
  } else if (status == "Shipped" || status=="Delivered") {
    background = "success"
  } else if (status == "Canceled" || status=="Returned") {
    background = "danger"
  }
  return background
}

const orderToTextColor = (status)=>{
  if (status == "Pending"){
    return "dark"
  }
  return "light"
}

const createColumns = () => {
  return [{
    accessorKey: 'order_number',
    header: 'Order Number',

    cell: props=> { return props.row.original.region != "None" ? <span>{`${props.getValue()} (${props.row.original.region})`}</span> : <span>{props.getValue()}</span> },
    enableSorting:true,
    width:.06,
  
  }, {
    accessorKey: 'item_name',
    header: 'Item Name',
    enableSorting:true,
    width:.16,
  }, {
    accessorKey: 'item_sku',
    header: 'Item SKU',
    enableSorting:true,
    width:.11,
  }, {
    accessorKey: 'order_date',
    header: 'Order Date',
    enableSorting:true,
    width:.04,
    cell: props=>{return <><div>{formatDate(new Date(props.getValue()))}</div> <div>{formatAMPM(new Date(props.getValue()))}</div></>},
    sortingFn:DateSort
  }, {
    accessorKey: 'customer_name',
    header: 'Customer Name',
    hidden: true,
    enableSorting:true,
    width:.07,
  }, {
    accessorKey: 'customer_address',
    header: 'Customer Address',
    hidden: true,
    enableSorting:true,
    width:.08,
  }, {
    accessorKey: 'buy_method',
    header: 'Site',
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'amazon_status',
    header: 'Status',
    cell: props=> { return <Badge pill text={orderToTextColor(props.row.original.order_status_common)} bg={orderToColor(props.row.original.order_status_common)} title={props.getValue()}>{props.row.original.order_status_common}</Badge> },
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'item_qty',
    header: 'Order Qty',
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'purchase_price',
    header: 'Item Price',
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'shipping_price',
    header: 'Cust Ship Price',
    hidden: true,
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'tax_price',
    header: 'Tax Price',
    hidden: true,
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },
    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'selling_fee',
    header: 'Selling Fee',
    
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },

    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'shipping_fee',
    header: 'Ship Cost',
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },

    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'item_cost',
    header: 'Cost',
    cell: props=> { return props.getValue() == -1 ? <span>-</span> : <span>${props.getValue()}</span> },

    enableSorting:true,
    width:.04,
  }, {
    accessorKey: 'profit',
    header: 'Profit',
    cell: props=> { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },

    enableSorting:true,
    width:.04,
  }


  ];
}


const defaultSorted = [{
  dataField: 'order_date',
  order: 'desc'
}];



function FilterBySite(props) {
  const [toggles, setToggleValues] = useState({})
  const [labels, setLabelValues] = useState([])

  const [region_toggles, setRegionToggleValues] = useState({})
  const [region_labels, setRegionLabelValues] = useState([])

  React.useEffect(() => {
    if (props.sellingSites) {
      var tmplabels = Object.entries(props.sellingSites).map(([element, value]) => ({ dataField: element, hideInToggle: false, text: element }))
      //var tmplabels = props.sellingSites.map(element => ({dataField:element, hideInToggle:false, text:element}))

      /* var tmptoggles={}
       props.sellingSites.forEach(element => {
         tmptoggles[element]=true
       })*/

      setToggleValues(props.sellingSites)
      setLabelValues(tmplabels)

    }

  }, [props.sellingSites]);
  React.useEffect(() => {
    if (props.sellingRegions) {
      var tmplabels = Object.entries(props.sellingRegions).map(([element, value]) => ({ dataField: element, hideInToggle: false, text: element }))
      //var tmplabels = props.sellingSites.map(element => ({dataField:element, hideInToggle:false, text:element}))

      /* var tmptoggles={}
       props.sellingSites.forEach(element => {
         tmptoggles[element]=true
       })*/

      setRegionToggleValues(props.sellingRegions)
      setRegionLabelValues(tmplabels)

    }

  }, [props.sellingRegions]);
  return (
    <CustomToggleListSectioned toggles={[toggles, region_toggles]} columns={[labels, region_labels]} onColumnToggle={[props.onSiteToggles, props.onRegionToggles]} icon={faFilter} titles={["Filter By Selling Site", "Filter By Selling Region"]} />
  )


}



class OrderPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      filteredItems: [],
      errorMessage: "",
      customerShow: false,
      filteredSites: {},
      filteredRegions: {},
      showModal: false,
      selectedItem: {},
      pageWidth: 0,
      scrollbarWidth: 17,
      columns: createColumns(),
      loading:true
    };

    this.headerBoxRef = React.createRef()
    this.filterBoxRef = React.createRef()

    this.filterItems = this.filterItems.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


  }
  updateWindowDimensions() {
    this.setState({ pageWidth: window.innerWidth });
  }

  getOrders = (firstDate, secondDate, first = false) => {
    let currentComponent = this;
    this.setState({ errorMessage: "", filteredItems: [], items: [], loading:true })
    axios.post(endpoint + "/api/orders", { startDate: firstDate, endDate: secondDate },
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }, signal: this.controller.signal
      }).then(res => {
        if (res.data) {
         
          this.setState({

            items: res.data.orders,
            filteredSites: first || Object.keys(this.state.filteredSites).length ===0 ? res.data.sites : this.state.filteredSites,
            filteredRegions: first || Object.keys(this.state.filteredRegions).length===0 ? res.data.regions : this.state.filteredRegions,
            errorMessage: "",
            loading:false
          }, () => currentComponent.filterItems());

        } else {
          currentComponent.setState({
            items: [],
            errorMessage: "Server Error Fetching Orders",
            loading:false

          });
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        currentComponent.setState({
          items: [],
          errorMessage: "Error Fetching Items. Consider logging back in",
          loading:false
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/login')
          }
        } else {
          currentComponent.setState({
            items: [],
            errorMessage: "Server Error. Could not fetch orders",
            loading:false
          });
        }
      });
  };


  getItemByID = (group_id) => {
    let currentComponent = this;
    axios.get(endpoint + "/api/items?item-id=" + group_id,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
        signal: this.controller.signal
      }).then(res => {

        if (res.data) {

          var items = res.data.map(item => {
            var tmp = item.item_summary

            tmp['linked_items'] = item.linked_items
            return tmp;
          })
          if (items.length > 0) {
            this.setState({ showModal: true, selectedItem: items[0] })
          }


          //console.log(res.data.map(item=>item.item_summary))
        } else {

        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        console.log(error)
      })
  };



  filterItems = () => {
    var _filteredItems = []

    if(this.state.items){
      this.state.items.forEach(element => {

        if (this.state.filteredSites[element['buy_method']] && this.state.filteredRegions[element['region']]) {
          _filteredItems = [..._filteredItems, element]
        }
      })
      this.setState({ filteredItems: _filteredItems })
    }
  }




  componentDidMount() {
   this.controller= new AbortController();

    this.getOrders(createDate(-30, 0, 0, true), createDate(0, 0, 0, true), true);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
    window.removeEventListener('resize', this.updateWindowDimensions);

  }


  onSiteToggles = (element) => {
    var tmpSites = this.state.filteredSites
    tmpSites[element] = !tmpSites[element]
    this.setState({ filteredSites: tmpSites }, this.filterItems())

  }


  onRegionToggles = (element) => {
    var tmpRegions = this.state.filteredRegions
    tmpRegions[element] = !tmpRegions[element]
    this.setState({ filteredRegions: tmpRegions }, this.filterItems())

  }

  onRowDoubleClick =  (row)=>{

    this.getItemByID(row.original.group_id)

  }





  render() {
    return (


      <div className="wrapper">
        <ItemPropertiesModal
          show={this.state.showModal}
          onHide={() => this.setState({
            showModal: false
          })}
          item={this.state.selectedItem}
          token={this.props.token}
          showMergePanels={false}
          readOnly={true}

        />


      <LightningKit columns={ this.state.pageWidth > 550 || this.state.pageWidth==0 ?this.state.columns :narrow_columns}  pageOptions={options} getRowCanExpand={()=>false}  in_data = {this.state.filteredItems} width="100%" >


       {
            props => (
              <div>

                <div className="customtable widetable">

                  <div className="headerBox" ref={this.headerBoxRef}>
                    <Container fluid >
                      <Row>
                        <Col sm={8}>
                          <h1 className="left-align">Online Orders</h1>
                        </Col>
                        <Col sm={4}>

                        <CustomToggleListLightning columns={props.table.getAllLeafColumns()} />

                          <FilterBySite sellingSites={this.state.filteredSites} sellingRegions={this.state.filteredRegions} onSiteToggles={this.onSiteToggles} onRegionToggles={this.onRegionToggles} />

                          <LightningCSV style={{ float: "right", marginRight: 15 }} text={<FontAwesomeIcon className="trashIcon" icon={faDownload} />} filename="Orders.csv" table={props.table} ></LightningCSV>    

                          

                        </Col>

                      </Row>
                    </Container>
                  </div>
                  <div className="grayheader headerBox" ref={this.filterBoxRef}>
                    <Container fluid>
                      <Row >
                        <Col lg={6} md={12} style={{ marginBottom: 5 }}>
                          <div id="search-left" >
                            <h2>Search:</h2>
                            <LightningSearch {...props.searchProps}/>
                          </div>
                        </Col>
                        




                        <Col lg={6} md={12} style={{ marginBottom: 5 }}>
                          <FilterByDate getItems={this.getOrders} />
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <LightningTable className="lightningFixedTable" height={`calc(100vh - ${props.footerRef.current ?props.footerRef.current.clientHeight : 0 }px - ${props.headerRef.current ?props.headerRef.current.clientHeight : 0 }px - ${this.headerBoxRef.current ?this.headerBoxRef.current.clientHeight : 0 }px - ${this.filterBoxRef.current ?this.filterBoxRef.current.clientHeight : 0 }px - 133px`} {...props} {...props.table}  onRowDoubleClick={this.onRowDoubleClick} noDataIndication={props.table.getCoreRowModel().rows.length >0 ||(this.state.filteredItems.length===0 && !this.state.loading) ? "No Matching Data" : <div><Spinner animation="border" /></div>} />
                 
                </div>
              </div>
            )
          }

        </LightningKit>

        {this.state.errorMessage &&
          <p className="errorTable"> {this.state.errorMessage} </p>}
      </div>
    );
  }
}

export default withRouter(OrderPage);
