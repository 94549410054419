

import React from "react";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const createDate = (days, months, years, reverse = true, forceFirstDay = false, offsetFirstDay = false) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    date.setMonth(date.getMonth() + months);
    date.setFullYear(date.getFullYear() + years);
    if(forceFirstDay){
      if(offsetFirstDay) {
        date.setDate(2)
      }else{
        date.setDate(1)
      
      }
    }
    var formattedDate
    var month = parseInt(date.getMonth() + 1).toString()
    var my_date = date.getDate().toString()
    if (month.length < 2) {
      month = "0" + month
    }
    if (my_date.length < 2) {
      my_date = "0" + my_date
    }
    if (reverse) {
      formattedDate = date.getFullYear() + "-" + month + "-" + my_date
    } else {
      formattedDate = formattedDate = month + "/" + my_date + "/" + date.getFullYear()
    }
    return formattedDate;
  }

export function FilterByDate(props) {

    const [error, setError] = React.useState("")
    var date1_ref = React.createRef();
    var date2_ref = React.createRef();
  
  
    var minDate = createDate(-30, 0, 0).toString()
    var maxDate = createDate(0, 0, 0).toString()
    const my_onSubmit = (e) => {
      e.preventDefault()
      var date1 = new Date(date1_ref.value)
      var date2 = new Date(date2_ref.value)
      var maxDate = new Date('2030-01-01')
      var minDate = new Date('2019-01-01')
  
      if (date2 < date1) {
        setError("Error! Second date in range cannot be less than first date.")
      } else if (date2 > maxDate) {
        setError("Error! Second date is too far in the future. Maximum allowed date is 2030-01-01.")
      } else if (date1 < minDate) {
        setError("Error! First date is too far in the past. Minimum allowed date is 2019-01-01.")
      } else {
        setError("")
        props.getItems(date1_ref.value, date2_ref.value)
        return
      }
      window.setTimeout(() => {
        setError("")
      }, 5000)
  
    }
  
  
    return (
      <div>
        <Form className="justify-content-end"  onSubmit={my_onSubmit}>
          <Form.Group >
            {props.hideLabel ? <></>:<Form.Label style={{display:"inline-block", width:"auto", marginRight:5}} htmlFor="Control1" className="mr-sm-2" >Date Range</Form.Label>}
            <Form.Control  style={{display:"inline-block", width:"auto", marginRight:5}} required ref={(input) => date1_ref = input} id="Control1" type="date" defaultValue={minDate} />
            <Form.Label style={{display:"inline-block", width:"auto", marginRight:5}} htmlFor="Control2" >-</Form.Label>
  
            <Form.Control style={{display:"inline-block", width:"auto", marginRight:5}} required ref={(input) => date2_ref = input} id="Control2" type="date" defaultValue={maxDate} />
            <Button style={{display:"inline-block", width:"auto", marginRight:5}} type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>
          </Form.Group>
        </Form>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
    )
  }