import React, { Component }  from 'react';
import logo from '../logo.svg';
import axios from "axios";
import '../App.css';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import {useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HomeChart from './charts/homeChart.js';
import HomeChartRadial from './charts/homeChartRadial.js';
import Spinner from 'react-bootstrap/Spinner';

import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  BarChart,
  Bar,
  YAxis,
  ReferenceLine
} from "recharts";
import {  faArrowUp, faArrowDown, faExclamationTriangle, faSquare } from '@fortawesome/free-solid-svg-icons'

let endpoint = "";

function PercentIndicator(props){

  const [percent, setPercent]= useState(0)
    const [diff, setDiff]= useState("")

  const [my_color, setColor]= useState("black")

 React.useEffect(() => {

   if(props.arg1 && props.arg2){
    var _percent =Math.round((props.arg1/ props.arg2 -1)*100) 
    
    var _my_color="black"
    if(_percent > 0){
      _my_color="rgb(0,128,0,0.65)"
    }else if (_percent <0){
      _my_color="#ff0000bd"
    }
    setPercent(_percent)
    setColor(_my_color)
    var _diff=Math.round(props.arg1- props.arg2)
    if(_diff > 0){
      setDiff("(+" + _diff + ")" )
    }else if(_diff < 0){
      setDiff("(" + _diff + ")" )
    }

   }
    
    
  }, [props.arg1, props.arg2]); 


    return(
      <Col style={{color:my_color, fontSize:props.small?"1rem" : "1.5rem", paddingTop:props.small?"0px" : "10px"}}>
      {percent > 0 ?
       <FontAwesomeIcon className="frontArrowBig" icon={faArrowUp} />: percent < 0 ?
        <FontAwesomeIcon   className="frontArrowBig" icon={faArrowDown} /> : <></>}
          {percent}% {diff}
      </Col>
    )
  }

  function SalesGraph(props){

    const [graphData, setGraphData]= useState([])
    React.useEffect(() => {

   if(props.data['platform_raw_numbers'] && props.type){
     var tmpData= {'name':'Data'}
     props.data['platform_raw_numbers'].forEach(raw=>{
     tmpData[raw['label']]=raw[props.type]})
      setGraphData([tmpData])

   }
   
  }, [props.data]); 
  return(
    <div className="SalesBarGraph">
  <BarChart
      width={150}
      height={225}
      data={graphData}
      margin={{
        top: 20,
        right: 0,
        left: 0,
        bottom: 5
      }}
    >
      
      <YAxis className="SalesYAxis" tickCount={10} type="number" domain={[0, dataMax => (dataMax < props.data['average_total_val'] ? parseInt(props.data['average_total_val'])+20 : dataMax)]}/>
     
       {props.data['platform_raw_numbers'] &&props.data['platform_raw_numbers'].map(bar=>
          <Bar key={bar['label']} dataKey={bar['label']} stackId="a" fill={bar['color'] + "70"} />
       )}
      {props.data['average_total_val'] &&<ReferenceLine y={props.data['average_total_val']} label="Average" stroke="#727272"  />}
    </BarChart></div>)
  }

 class FrontPageSales extends Component{
   constructor(props) {
    super(props);
    this.state={
        data:props.data,
        loaded:false
    }

  } 
  

  componentWillUnmount() {
   
  }
   

    
 componentDidUpdate(prevProps, prevState) {

    if(prevProps.data != this.props.data){
        this.setState({data:this.props.data, loaded:true})
    }

}

  
 
  render(){
      
      return(
      
          <div className="customtable" style={{marginBottom:20}}>
          <h2 className="left-align headerBox">Order Summary</h2>
          <div className="SalesStats tablerow CollapseOnSmall">
          {this.state.data['yesterday_total_val'] !== undefined && this.state.loaded ? <Container fluid>
          <Row>
          <Col style={{borderRight:"2px solid lightgray", paddingLeft:30, paddingRight:30}}>
             <Row style={{justifyContent:"left"}}>
              Yesterday
             </Row>
             <Row >
                <Col  style={{textAlign:"left"}}>
                  <Row>
                    <Col>
                      <strong style={{fontSize:"2.25rem"}}>{this.state.data['yesterday_total_val']}</strong>
                    </Col>
                    
                    <PercentIndicator arg1={this.state.data['yesterday_total_val']} arg2={this.state.data['average_total_val']} small={false} />
                  </Row>
                  <hr style={{marginTop:"2px"}}/>
                  { this.state.data['platform_raw_numbers'] && this.state.data['platform_raw_numbers'].map(platform =>
                      <Row key={platform['label']} style={{backgroundColor:platform['color']+"40"}}>
                       
                       <Col xs={5}>
                          <p className="homeAlerts" >{platform['label']}: </p>
                       </Col>
                       <Col xs={2}>
                           <p className="homeAlerts" >{platform['yesterday_val']}</p>
                       </Col>
                        <PercentIndicator arg1={platform['yesterday_val']} arg2={platform['average_val']} small={true}/>
                      </Row>
                    )}
                </Col>
                <Col className="CollapseOnSmall colCollapse" style={{justifyContent:"center"}}sm={4}>
                    <SalesGraph data={this.state.data} type="yesterday_val" />
                </Col>
               
              </Row>
              <Row style={{padding:"5px"}}>
               <span style={{fontSize:".9rem", color:"gray", width:"100%", textAlign:"left"}}>{this.state.data['yesterday_label']}</span>
              </Row>
            </Col>
            <Col sm={6} style={{ paddingLeft:30, paddingRight:30}}>
             <Row style={{justifyContent:"left"}}>
              Today
             </Row>
             <Row >
                <Col  style={{textAlign:"left"}}>
                  <Row>
                    <Col>
                      <strong style={{fontSize:"2.25rem"}}>{this.state.data['today_total_val']}</strong>
                    </Col>
                    
                  </Row>
                  <hr style={{marginTop:"2px"}}/>
                  { this.state.data['platform_raw_numbers'] && this.state.data['platform_raw_numbers'].map(platform =>
                      <Row key={platform['label']} style={{backgroundColor:platform['color']+"40"}}>
                       
                       <Col xs={5}>
                          <p className="homeAlerts" >{platform['label']}: </p>
                       </Col>
                       <Col xs={2}>
                           <p className="homeAlerts" >{platform['today_val']}</p>
                       </Col>                  
                        <PercentIndicator arg1={platform['today_val']} arg2={platform['average_val']} small={true}/>
                      </Row>
                    )}
                </Col>
                <Col className="CollapseOnSmall colCollapse" style={{justifyContent:"center"}}sm={4}>
                    <SalesGraph data={this.state.data} type="today_val" />
                </Col>
               
              </Row>
              <Row style={{padding:"5px"}}>
               <span style={{fontSize:".9rem", color:"gray", width:"100%", textAlign:"left"}}>{this.state.data['today_label']}</span>
              </Row>
            </Col>
            </Row>
          </Container>: !this.state.loaded? <Spinner animation="border" /> : <></>}
          </div>
          </div>
    
    
              
              
              
           
              
           
  )
  }
 }

  export default FrontPageSales;
  
  /*
  
  */