import React, { Component } from "react";
import { withRouter } from '../withRouter.js';
import '../inventory.css';
import { GetProfitRevData, GetTopSelling, GetPLData } from "./apiCalls.js";
import { ContainerTabs } from "./containerTabs.js";
import { createDate } from "../filters/dateFilter";

class FinancialPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);


    this.state = {
      profitRevData: {},
      topSelling: {},
      plData: [],
      graphType: false,
      showSettings: false,
      label: "20 Items",
      start_date: "",
      end_date: "",
      plAuthorized: true,
      plLoaded: false,
      productLoaded: false
    };

    this.unathorizedCallback = this.unathorizedCallback.bind(this)
    this.onErrorCallback = this.onErrorCallback.bind(this)

  }
  componentWillUnmount() {
    if (this.controller) {
      this.controller.abort()
    }
    this._isMounted = false
  }


  unathorizedCallback() {
    this.props.setLogcallback("", "")
    this.props.logincallback("Error! You have been logged out.")
    this.props.navigate('/index.html')
  }

  onErrorCallback(message) {
    this.setState({ errorMessage: message })
  }

  refreshTopSelling = (search_term, search_type, start_date, end_date) => {
    var payload = { "search_term": search_term, "search_type": search_type, "start_date": start_date, "end_date": end_date }
    let currentComponent = this;
    this.setState({ productLoaded: false, topSelling: [] }, () => {
    GetTopSelling(payload, this.props.token, this.controller, this.unathorizedCallback, this.onErrorCallback).then((res) => {
      currentComponent.setState({ topSelling: res , productLoaded: true})
    }).catch(error => {
      console.log(error.message)
      this.setState({ productLoaded: true })
    });
  })

  }

  getItemSalesStats = (search_term, search_type, start_date, end_date) => {
    let currentComponent = this;

    var payload = { "search_term": search_term, "search_type": search_type, "start_date": start_date, "end_date": end_date }
    this.setState({ productLoaded: false, profitRevData: [] }, () => {
      GetProfitRevData(payload, this.props.token, this.controller, this.unathorizedCallback, this.onErrorCallback).then((res) => {
        console.log("Loaded")
        currentComponent.setState({ profitRevData: res, label: search_type == 'Vendor' ? "Vendors" : "20 Items", start_date: start_date, end_date: end_date, productLoaded: true })
      }).catch(error => {
        console.log(error.message)
        this.setState({ productLoaded: true })
      });
    })


    this.refreshTopSelling(search_term, search_type, start_date, end_date)
    

  }




  componentDidMount() {
    this.controller = new AbortController();

    this._isMounted = true;

    this.getItemSalesStats("", "__Default", createDate(-180, 0, 0, true, true).toString(), createDate(0, 0, 0).toString());

    let currentComponent = this;

    GetPLData(this.props.token, this.controller, this.unathorizedCallback, this.onErrorCallback).then((res) => {
      currentComponent.setState({ plData: res, plLoaded: true, plAuthorized: true })
    }).catch(error => {
      if (error.message == "Unauthorized") {
        currentComponent.setState({ plAuthorized: false })
      }
      currentComponent.setState({ plLoaded: true })
      console.log(error.message)
    });


  }

  render() {

    return (


      <div className=" wrapper fullPageWrapper">

        <div className="customtable widetable graphDivBox" style={{ marginBottom: "10px" }}>
          <div className="left-align headerBox" style={{ height: "60px" }}>
            <h2 style={{ float: "left" }}>Financial Overview</h2>
          </div>
          <div style={{ clear: "both", padding: 10 }} className="SalesStats tablerow" >
            <ContainerTabs ProfitandRev={this.state.profitRevData} ProfitandRevLoaded={this.state.productLoaded} topSelling={this.state.topSelling} plInfo={{ plAuthorized: this.state.plAuthorized, plLoaded: this.state.plLoaded, plData: this.state.plData }} reloadCallback={this.getItemSalesStats} refreshTopSellingOnly = {this.refreshTopSelling}/>
          </div>

        </div>


      </div>
    );
  }
}

export default withRouter(FinancialPage);
