import React, { Component, useContext }  from 'react';
import logo from '../logo.svg';
import axios from "axios";
import './inventory.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import EnsureModal from './ensureModal'

import { useState, useEffect } from "react";

import Button from 'react-bootstrap/Button'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle , faCircle, faExclamationCircle, faEdit, faPlus, faTrash} from '@fortawesome/free-solid-svg-icons'
let endpoint = "";
/*
type WebAccount struct {
	Account_ID     string `json:"account_id"`
	Site           string `json:"site"`
	Linked_Account string `json:"linked_account"`
	Unique_Val     string `json:"unique_val"`
	Uname          string `json:"uname"`
	Key1           string `json:"key1"`
	Key2           string `json:"key2"`
	Key_File       string `json:"key_file"`
	Oauth_URL      string `json:"oauth_url"`
	Friendly_Name  string `json:"friendly_name"`
	Display_Color  string `json:"display_color"`
	Site_Short     string `json:"site_short"`
	Site_Type      string `json:"site_type"`
	Valid          string `json:"valid"`
    Active         string `json:"active"`

  
    */


   function AccountModal(props) {

    const [selectedSite, setSelectedSite] = useState("Select an Account");
    var friendly_name_ref = React.createRef();
    var display_color_ref = React.createRef();
    var uname_ref = React.createRef();
    var key1_ref = React.createRef();
    var key2_ref = React.createRef();
    var url = ""
    
    React.useEffect(() => {
      if (props.show){
        props.clearFields()
      }
      if(props.isedit && props.show){
        
        props.getaccountfields(props.item.site)
        setSelectedSite(props.item.site)
      }
    }, [props.show]);
    

    const isfieldInMap = (field) => {
      if(props.fields){
        for (var i = 0; i < props.fields.length; i++) { 
          if (props.fields[i]['db_field']==field){
            return true
          }
        }
      }
      return false
    }
    const getLabelFromField = (field) => {
      if(props.fields){
        for (var i = 0; i < props.fields.length; i++) { 
          if (props.fields[i]['db_field']==field){
            return props.fields[i]['label_name']
          }
        }
      }
      return field
    }
    const getDefaultFromField = (field) => {
      if(props.fields){
        for (var i = 0; i < props.fields.length; i++) { 
          if (props.fields[i]['db_field']==field){
            return props.fields[i]['default_value']
          }
        }
      }
      return field
    }

    
    const pushForEbay = () =>{
      var str = '&state=;page=web-accounts;account_site=' + selectedSite + ';friendly_name=' + friendly_name_ref.value + ';display_color=' 
      if (display_color_ref.value){
        str+=display_color_ref.value.substring(1) 
      } 
      if (props.item &&props.item.account_id){
        str+=";account_id=" + props.item.account_id
      } 
      str+=';uname=' + uname_ref.value
      str+=';edit='+props.isedit
      return str;
  
      }
  

    const onSubmit = (e)=>{
      e.preventDefault();
      var item
      if(props.isedit){
        item = props.item
      }else{
        item={}
      }
      item['site']=selectedSite
      item['friendly_name']=friendly_name_ref.value ? friendly_name_ref.value : selectedSite
      item['display_color']=display_color_ref.value
      item['uname']=uname_ref.value
      item['key1']=key1_ref.value
      item['key2']=key2_ref.value
      if(props.isedit && !props.item.second_stage){
        
        props.onEditSave(item)
      }else{
        props.onNewSave(item)
      }
      props.onHide()
    }
    return (
      <Modal
      show={props.show}
      onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.isedit && (props.item && !props.item.second_stage)? "Edit Existing Account" : "Link New Account"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.item &&<h4>{props.item.site}</h4>}
          {((props.isedit && props.fields && props.fields.length>0 ) || !props.isedit)  ? <Form id="mainform"  onSubmit={onSubmit}>
            <Row>
              <Form.Group as={Col} controlId="formSite" className="mb-2">
                <Form.Label>Site</Form.Label>
                <Form.Select defaultValue={props.isedit && props.item ? props.item.site: "Select an Account"} disabled={props.isedit} onChange={(e)=>{ setSelectedSite(e.target.value);  props.getaccountfields(e.target.value)}}>
                 <option>Select an Account</option>
                  <option>Amazon (FBA)</option>
                  <option>Amazon (MFN)</option>
                  <option>ebay</option>
                  <option>Walmart</option>
                  <option>UPS</option>
                  <option>USPS</option>
                  <option>Ship Station</option>
                </Form.Select>
              </Form.Group>
              {isfieldInMap('friendly_name') && <Form.Group as={Col} controlId="formfriendly">
                  <Form.Label>{getLabelFromField('friendly_name')}</Form.Label>
                  <Form.Control required ref={(input) => friendly_name_ref = input} type="string"   maxLength="64" defaultValue ={props.item? props.item.friendly_name : getDefaultFromField('friendly_name')}/>
                </Form.Group>}
                {isfieldInMap('color') && <Form.Group as={Col} controlId="formColor">
                  <Form.Label>{getLabelFromField('color')}</Form.Label>
                  <Form.Control required  ref={(input) => display_color_ref = input} type="color" defaultValue ={props.item?props.item.display_color:"#" + Math.floor(Math.random()*16777215).toString(16)}/>
                </Form.Group>}
            </Row>

            <Row>
            {isfieldInMap('uname') && <Form.Group as={Col} controlId="formUname">
                <Form.Label>{getLabelFromField('uname')}</Form.Label>
                <Form.Control autoComplete="off" required ref={(input) => uname_ref = input} type="string" maxLength="64" defaultValue={props.item?props.item.uname:""} />
                 
              </Form.Group>}

            {isfieldInMap('key1') && <Form.Group as={Col} controlId="formKey1">
                  <Form.Label>{getLabelFromField('key1')}</Form.Label>
                  <Form.Control autoComplete="off" required ref={(input) => key1_ref = input} type="password"maxLength="64"  defaultValue={props.item?"default":""}/>
                </Form.Group>}
            
            {isfieldInMap('key2') && <Form.Group as={Col} controlId="formKey2">
                  <Form.Label>{getLabelFromField('key2')}</Form.Label>
                  <Form.Control autoComplete="off" required  ref={(input) => key2_ref = input} type="password" maxLength="128" defaultValue={props.item?"default":""}/>
                </Form.Group>}

            {isfieldInMap('oauth_url') && <Form.Group as={Col} controlId="formoauth">
                  <Form.Label>Auth Code {props.loadingLWA ? <Spinner /> : <></>}</Form.Label>
                  <a className="order_url" href={url} style= {{marginLeft:"10px"}} onClick = {()=>window.location.href = getLabelFromField('oauth_url') +pushForEbay() } target="_blank">Click to Get New Code</a>
                  <Form.Control disabled autoComplete="off"  type="password" required={!props.isEdit} defaultValue={props.item?props.item.code? props.item.code : "default":""}/>
                  {props.LWATokenError? <Form.Text style={{color:"red"}}>{props.LWATokenError}</Form.Text> : <></>}
                </Form.Group>}
            
            </Row>
          
            
           
          </Form> : <Spinner animation="border" />}
        </Modal.Body>
        <Modal.Footer>
          {"Select an Account"!==selectedSite &&
         <Button type="submit" disabled={props.loadingLWA || props.LWATokenError!==""} form="mainform">{props.isedit && (props.item && !props.item.second_stage)? "Update Account" : "Link Account"}</Button>
          }
          <Button onClick={props.onHide} >Cancel</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  

   function ContextAwareToggle({ children, eventKey, callback, color }) {
    const currentEventKey = useContext(AccordionContext);
  
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );
  
    const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
  
    return (
      <Card.Header
        style={{ backgroundColor: isCurrentEventKey ? '#e7f1ff' : 'white' , color:isCurrentEventKey ? '#0c63e4' : 'black' ,borderLeftWidth:"4px", borderLeftColor:color, borderLeftStyle:"solid",paddingTop:25, paddingBottom:25}}
        onClick={decoratedOnClick}
      >
        {children}
      </Card.Header>
    );
  }

const WebHeaderBar = ({item})=>(
  <>
    <Container fluid>
      <Row>
        <Col xs={5} className="text-left" >{item.friendly_name}</Col>
        <Col xs={5} className="text-left">{item.site_type}</Col>
        <Col xs={2} style={{textAlign:"right"}}> {parseInt(item.valid) ? <FontAwesomeIcon title= "No issues detected with this account." className="trashIcon" icon={faCheckCircle} style={{color:"green", fontSize:"1.25rem"}}/> : <FontAwesomeIcon title = "An error is preventing this account from being synced. Try re-authenticating or editing the account keys." className="trashIcon" icon={faExclamationCircle} style={{color:"red", fontSize:"1.25rem"}}/> }</Col>
      </Row>
    </Container>
  </>
);

const WebContentBar = ({item, onEditClicked, onDeleteClicked})=>(
  <>
    <Container fluid> 
      <Row>
        <Col xs={5} className="text-left buttonHeight" >Site: {item.site}</Col>
<Col xs={5} className="text-left buttonHeight">{item.display_color && <span>Display Color: <FontAwesomeIcon className="trashIcon" icon={faCircle} style={{color:item.display_color, fontSize:"1.25rem"}}/></span>} </Col>
        <Col xs={2} style={{textAlign:"right"}}>
          <Button variant="link" onClick={(e) => {e.stopPropagation();onEditClicked(item)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faEdit} title="Edit Account" /></Button>
          <Button variant="link" onClick={(e) => {e.stopPropagation();onDeleteClicked(item)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faTrash} title="Delete Account" /></Button>
        </Col>
        

      </Row>
      {!parseInt(item.valid)  &&<Row>
        <Col xs={12} className="text-center font-weight-bold text-danger "> An error is preventing this site from being synced. Try {item.oauth_url ? <Button variant="link" onClick={(e) => {e.stopPropagation();onEditClicked(item)}} style={{paddingTop:2, paddingLeft:1}}>Re-Authenticating</Button> : "editing the account keys"}.</Col>
      </Row>}

      

    </Container>
  </>
);
const WebAccountList = ({items, onEditClicked,onDeleteClicked}) => (
    <>
       
       <Accordion    >
      {items.map((item, index) => (
    
        <Accordion.Item key = {index} >
            <ContextAwareToggle as={Card.Header}  eventKey={item.account_id} color={item.display_color}>
              <WebHeaderBar item ={item}  />
            </ContextAwareToggle>
          <Accordion.Collapse eventKey={item.account_id}>
            <div style={{padding:25}}>
              <WebContentBar item={item} onEditClicked={onEditClicked} onDeleteClicked={onDeleteClicked}/>
            </div>
          </Accordion.Collapse>
            
        </Accordion.Item>
      ))}
      </Accordion>
    </>
    
  );
 class WebAccountSettings extends Component{
   constructor(props) {
    super(props);
    this.state={accounts:[], modalShow:false, edit:false, fields:{}, selectedItem:null,showConfirmModal:false,itemToDelete:{}, LWATokenError:"", loadingLWA:false}
    this.onEdit = this.onEdit.bind(this)
    this.getAccountFields = this.getAccountFields.bind(this)
    this.onEditAccount = this.onEditAccount.bind(this)
    this.onDelete=this.onDelete.bind(this)
    this.getLWAToken=this.getLWAToken.bind(this)
  }    

      
  componentDidUpdate(prevProps, prevState) {

    if(prevProps.accounts != this.props.accounts){
        this.setState({accounts:this.props.accounts})
    }
    if(prevProps.qString ===""){
      var item={}
      if(this.props.qString.account_site && this.props.qString.account_site != "undefined"){
        item['site']=this.props.qString.account_site
      }
      if(this.props.qString.friendly_name && this.props.qString.friendly_name != "undefined"){
        item['friendly_name']=this.props.qString.friendly_name
      }
      if(this.props.qString.display_color && this.props.qString.display_color != "undefined"){
        item['display_color']='#' +this.props.qString.display_color
      }
      if(this.props.qString.uname && this.props.qString.display_color != "undefined"){
        item['uname']=this.props.qString.uname
      }
      if(this.props.qString.code && this.props.qString.code != "undefined"){
        item['code']=this.props.qString.code
      }
      if(this.props.qString.spapi_oauth_code && this.props.qString.spapi_oauth_code != "undefined"){
        this.setState({loadingLWA:true})
        this.getLWAToken(this.props.qString.spapi_oauth_code)
        //item['code']=this.props.qString.spapi_oauth_code
      }
      if(this.props.qString.account_id && this.props.qString.account_id != "undefined"){
        item['account_id']=this.props.qString.account_id
      }if(this.props.qString.edit && this.props.qString.edit != "undefined"){
        item['second_stage']=this.props.qString.edit!=="true"
      }
      if(Object.keys(item).length !== 0 ){
        this.onEdit(item)
      }
  }
}
 
  componentDidMount() { 
   
}




getLWAToken = (auth) =>{
  var component = this;
    
      var payload={token:auth }

        axios.post(endpoint + '/api/get-LWA-token',
        payload,{
         headers: {
           Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
         }
        })
      .then(function (response) {
        var tmpitem = {...component.state.selectedItem}
        tmpitem['code'] = response.data.token
        component.setState({selectedItem:tmpitem,LWATokenError:"", loadingLWA:false})
        
      })
      .catch(function (error) {
        component.setState({LWATokenError:"Error Getting Token. Please try again later", loadingLWA:false})
          
      });
}


  getAccountFields = (_site) =>{
    var component = this;
      
        var payload={site:_site }
        
          axios.post(endpoint + '/api/get-web-account-fields',
          payload,{
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
          component.setState({fields:response.data})
          
        })
        .catch(function (error) {
          component.setState({fields:{}})
            
        });
  }

  onEditAccount = (item) =>{
        var component = this;
          axios.put(endpoint + '/api/update-web-account/' + item.account_id,
          item,{
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
        
          component.props.updateAlert({errorMessage:"Successfully Edited Account",
                            showAlert:true,
                            isError:false})
          component.props.reloadAccounts()
        })
        .catch(function (error) {
            if(!error.response || error.response.status=="401"){
                component.props.updateAlert({errorMessage:"Error Updating Account - Try loggin back in.",
                showAlert:true,
                isError:true})
            }else if(!error.response || error.response.status=="400"){
              component.props.updateAlert({errorMessage:"Error Updating Account - Could not connect to the account with given credentials. Please check your credentials, and try again.",
              showAlert:true,
              isError:true})
              
            }else{
                component.props.updateAlert({errorMessage:"Error Updating Account -  Server Issue.",
                showAlert:true,
                isError:true})
            
            }
           
            
        });
  }

  onNewAccount = (item) =>{
    var component = this;
      axios.post(endpoint + '/api/create-web-account' ,
      item,{
       headers: {
         Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
       }
      })
    .then(function (response) {
    
      component.props.updateAlert({errorMessage:"Successfully Created Account",
                        showAlert:true,
                        isError:false})
                        component.props.reloadAccounts()
      //this.props.reloadAccounts()
    })
    .catch(function (error) {
        if(!error.response || error.response.status=="401"){
            component.props.updateAlert({errorMessage:"Error Creating Account - Try loggin back in.",
            showAlert:true,
            isError:true})
        } else if(!error.response || error.response.status=="400"){
          component.props.updateAlert({errorMessage:"Error Creating Account - Could not connect to the account with given credentials. Please check your credentials, and try again.",
          showAlert:true,
          isError:true})
          
        }else{
            component.props.updateAlert({errorMessage:"Error Creating Account -  Server Issue.",
            showAlert:true,
            isError:true})
        
        }
       
        
    });
}

onDelete=()=>{
  var item = this.state.itemToDelete
  this.setState({itemToDelete:{},showConfirmModal:false})
  var component = this;
      axios.delete(endpoint + '/api/delete-web-account/' + item.account_id  ,
    {
       headers: {
         Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
       }
      })
    .then(function (response) {
    
      component.props.updateAlert({errorMessage:"Successfully Deleted Account",
                        showAlert:true,
                        isError:false})
      component.props.reloadAccounts()
      //this.props.reloadAccounts()
    })
    .catch(function (error) {
        if(!error.response || error.response.status=="401"){
            component.props.updateAlert({errorMessage:"Error Deleting Account - Try loggin back in.",
            showAlert:true,
            isError:true})
        }else{
            component.props.updateAlert({errorMessage:"Error Deleting Account -  Server Issue.",
            showAlert:true,
            isError:true})
        
        }
       
        
    });
} 
  onEdit = (item) =>{
    this.setState({selectedItem: item, modalShow:true, edit:true})
  } 
   render(){


     /* */
      return (
          <div className="tabbed-content">
            <AccountModal
              show={this.state.modalShow}
              isedit={this.state.edit}
              item = {this.state.selectedItem}
              onHide={() => {this.setState({modalShow:false, selectedItem:null, LWATokenError:""}); this.props.onPushToHistory("page", "web-accounts")}}
              getaccountfields={this.getAccountFields}
              fields={this.state.fields}
              pushToNav = {this.props.onPushToHistory}
              onEditSave = {this.onEditAccount}
              onNewSave = {this.onNewAccount}
              clearFields={()=>this.setState({fields:[]})}
              loadingLWA = {this.state.loadingLWA}
              LWATokenError = {this.state.LWATokenError}
            />
            <EnsureModal onSubmit={this.onDelete} show={this.state.showConfirmModal} onHide={()=>this.setState({showConfirmModal:false})}
                    message="Are you sure you want to unlink this account? This cannot be undone. Note: All data associated with this account will remain" />
              <h2>Linked Web Accounts</h2>
              <hr></hr>
              <div style={{width:"100%" , display:"block"}}><Button variant="link" onClick={(e) => {e.stopPropagation();this.setState({modalShow:true, edit:false})}} style={{ float: "right", padding:0 , marginBottom:"10px"}}><FontAwesomeIcon style={{fontSize:"1.5rem"}} className="trashIcon" icon={faPlus} title="Add Account" /></Button></div> 
          
              <div style={{clear:"both"}}><WebAccountList items={this.state.accounts} onEditClicked={this.onEdit} onDeleteClicked={(item)=>this.setState({showConfirmModal:true, itemToDelete:item})} /></div>
           
          </div>

      )
  }
 }

  export default WebAccountSettings;
  
  /*
  
  */