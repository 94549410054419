import React from 'react'

import {

    sortingFns,
  } from '@tanstack/react-table'
import {CSVLink, CSVDownload} from 'react-csv';
import Button from 'react-bootstrap/Button';


export  function LightningCSV  (props){
  
    const rows = props.table.getFilteredRowModel().rows
    const csvData = React.useMemo(() => rows.map(row=>{ let tmpRow = {...row.original}; delete tmpRow['linked_items'];; delete tmpRow['description_id']; delete tmpRow['group_id'];  delete tmpRow['item_id'];  delete tmpRow['item_tags']; delete tmpRow['item_links']; return tmpRow}), [rows]);    

    const LightningCSVMemo = React.useMemo(()=>
    <CSVLink {...props} className="btn btn-light" filename={props.filename !==undefined? props.filename : "export.csv"} data={csvData}>{props.text}</CSVLink>
        
    ,[csvData, props.filename])
    return LightningCSVMemo

}

export const DateSort =(rowA, rowB, columnId) => {
  
    return sortingFns.datetime(rowA, rowB, columnId) 

  }