
import React, { useEffect, useRef,useCallback } from "react";

import {logMessage} from './lightning-kit'
import { DndProvider, useDrag, useDrop } from 'react-dnd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSort ,  faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { getWidth } from "./lightning-table";
import {
    flexRender,
  } from '@tanstack/react-table'

library.add(faSort,faSortUp, faSortDown)


export const SortUp = ()=>{
    return(<span className="fa-layers fa-fw fa-1x sortIcon ml-2" style={{display:"inline"}}>
    <FontAwesomeIcon icon={faSortUp}style={{opacity:1}} />
    <FontAwesomeIcon icon={faSortDown}  style={{opacity:.3}}/>
    </span>)
  }
  
export const SortDown = ()=>{
    return(<span className="fa-layers fa-fw fa-1x sortIcon" style={{display:"inline"}} >
    <FontAwesomeIcon icon={faSortUp}style={{opacity:.3}} />
    <FontAwesomeIcon icon={faSortDown}  style={{opacity:1}}/>
    </span>)
  }
  
export const SortNone = ()=>{
    return(<span className="fa-layers fa-fw fa-1x sortIcon" style={{display:"inline"}}>
    <FontAwesomeIcon icon={faSortUp}style={{opacity:.3}} />
    <FontAwesomeIcon icon={faSortDown}  style={{opacity:.3}}/>
    </span>)
  }

export function useSkipper() {
  const shouldSkipRef = useRef(true)
  const shouldSkip = shouldSkipRef.current

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}



const reorderColumn = (
    draggedColumnId,
    targetColumnId,
    columnOrder
  )=> {
    columnOrder.splice(
      columnOrder.indexOf(targetColumnId),
      0,
      columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] 
    )
    return [...columnOrder]
  }

  export const DraggableColumnHeader = ({ header, table }) => {
    const { getState, setColumnOrder } = table
    const { columnOrder } = getState()
    const { column } = header
  
    const [, dropRef] = useDrop({
      accept: 'column',
      drop: (draggedColumn) => {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrder
        )
        setColumnOrder(newColumnOrder)
      },
    })


    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
        item: () => column,
        type: 'column',
      })
    
      return (
        <th
        
          colSpan={header.colSpan}
          style={{ opacity: isDragging ? 0.5 : 1 }}
          title={header.column.columnDef.headerAttrs !==undefined ? header.column.columnDef.headerAttrs.title : undefined}
          {...{
                      
            key: header.id,
            colSpan: header.colSpan,
            style: {
              width:getWidth( header, header.getSize()),// header.column.columnDef.width ? (header.column.columnDef.width*1000).toString() + "%" : header.getSize(),
            },
            className: (header.column.getCanSort()
              ? `cursor-pointer select-none`
              : ''),
            onClick: header.column.getToggleSortingHandler(),
          }}
        >
          <div ref={previewRef}>
            <div  /*ref={dragRef}*/>
            {header.isPlaceholder
              ? null
              : <>

              <span ref={(node) => dragRef(dropRef(node))} style={{display:"inline", paddingRight:5}}>{flexRender(
                header.column.columnDef.header,
                header.getContext()
              )}</span>


              {header.column.getCanSort() && ({
                asc: <SortUp />,
                desc: <SortDown />,
              }[header.column.getIsSorted()] ?? <SortNone />)} 
              <div
                {...{
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  className: `resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                  style: {
                    transform: '',
                  },
                }} /> 
            </>}
            </div>
          </div>
        
        </th>
      )
    }
  
export function ResizeColumns(bodyRef, tableRef, columns_ref, column_vis_ref, setColumns) {

  return () => {
      var scrollWidth = bodyRef.current.offsetWidth - bodyRef.current.clientWidth;

      var parent_width = tableRef.current.parentElement.offsetWidth - scrollWidth;
      var temp = [];

      var underCounter = 0;
      var underCounterCols = 0;
      var sizes = [];
      logMessage(parent_width);
      var totalPercent = 0;
      var eligableColumns = 0;
      columns_ref.current.forEach(element => {
          logMessage(element);
          logMessage(column_vis_ref.current);
          if (('id' in element && column_vis_ref.current[element['id']] !== false) || ('accessorKey' in element && column_vis_ref.current[element['accessorKey']] !== false)) {
              if (element['width'] !== undefined) {
                  totalPercent += element['width'];

              }
              eligableColumns++;
          }
      });
      logMessage(totalPercent);

      columns_ref.current.forEach(element => {
          //  logMessage(element)
          if (('id' in element && column_vis_ref.current[element['id']] === false) || ('accessorKey' in element && column_vis_ref.current[element['accessorKey']] === false)) {
              sizes.push(-2);
          } else if (element['width'] !== undefined) {
              element['size'] = Math.round((parent_width) * (Math.floor((element['width'] / totalPercent) * 1000)) / 1000);
              sizes.push(element['size']);
              logMessage((element['width'] / totalPercent));
          } else {
              underCounter += element['size'];
              underCounterCols++;
              sizes.push(-1);
          }
          temp.push(element);


      });
      // logMessage(temp)
      logMessage(sizes);

      var freeSpaceLeft = [];

      for (var i = 0; i < sizes.length; i++) {
          if (temp[i]['minSize'] !== undefined && sizes[i] > 0 && sizes[i] <= temp[i]['minSize']) {
              underCounter += (temp[i]['minSize'] - sizes[i]);
              underCounterCols++;
              freeSpaceLeft[i] = 0;
          } else {
              freeSpaceLeft[i] = (sizes[i] - (temp[i]['minSize'] ? temp[i]['minSize'] : 10));
          }
      }

      var pixelsPerColumnList = [];


      var pixelsPerColumn = Math.ceil(underCounter / (eligableColumns - underCounterCols));
      logMessage(underCounter);
      logMessage(underCounterCols);
      logMessage(pixelsPerColumn);

      for (i = 0; i < sizes.length; i++) {
          if (temp[i]['width'] !== undefined) {
              pixelsPerColumnList[i] = pixelsPerColumn;
          }
      }

      logMessage(freeSpaceLeft);
      var foundBig = false;
      do {
          foundBig = false;
          underCounter = 0;
          for (i = 0; i < sizes.length; i++) {
              if (freeSpaceLeft[i] > 0 && freeSpaceLeft[i] < pixelsPerColumnList[i]) {

                  underCounter += (pixelsPerColumnList[i] - freeSpaceLeft[i]);
                  pixelsPerColumnList[i] = freeSpaceLeft[i];
                  eligableColumns--;
                  foundBig = true;
              }
          }
          if (foundBig) {
              pixelsPerColumn = Math.ceil(underCounter / (eligableColumns - underCounterCols));
              logMessage(pixelsPerColumn);
              for (i = 0; i < sizes.length; i++) {
                  logMessage(i + " , " + freeSpaceLeft[i] + " , " + pixelsPerColumnList[i]);

                  if (freeSpaceLeft[i] > pixelsPerColumnList[i]) {
                      pixelsPerColumnList[i] += pixelsPerColumn;
                  }
              }
          }



      } while (foundBig && eligableColumns > 0);


      logMessage(pixelsPerColumnList);

      for (i = 0; i < sizes.length; i++) {
          if (temp[i]['width'] !== undefined && pixelsPerColumnList[i] > 0) {
              temp[i]['size'] -= (pixelsPerColumnList[i]);  
          }
          if(sizes[i]===-2){//means hidden, set width to 1 for better re-rendering when reenabled
            temp[i]['size']=0
            temp[i]['autoHide']=true
          }else{
            temp[i]['autoHide']=false
          }
      }
      logMessage(temp);
      setColumns(temp);
  };
}