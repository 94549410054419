import React, { Component } from 'react';
// eslint-disable-next-line
import { Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Logo-C.png'
import './header.css';

class Header extends Component {
  state = {
    auth: false,
    slide: 0,
    lastScrollY: 0,
    display: 'none'
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    let scroll = {
      x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
    };

    if (scroll.y > 300) { // 3000px (arbitrary - put whatever point you need there.)
      this.setState({ slide: '200px' });
    }

    if (scroll.y < 300) {
      this.setState({ slide: '0px' });
      this.setState({ display: 'none' });
    }
  };

  render() {
    return (
      <Navbar className="slide_down_header" bg="dark" fixed="top" variant="dark" collapseOnSelect style={{
        transform: `translate(0, ${this.state.slide})`,
        transition: 'transform 0.30s linear'
      }}>
        <Navbar.Brand>
          <Link to="/"><img className="logo" src={Logo} alt="logo" /></Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" >

          <Nav className="mr-auto">
            {/*<Nav.Link as={Link} className="homeLink" to='/'>Home</Nav.Link>*/}
            <NavDropdown title="Purchase Orders" id="basic-nav-dropdown">
              <LinkContainer className="linkContainer" to="/past-orders">
                <NavDropdown.Item >Existing Orders</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/order-edit">
                <NavDropdown.Item >Create Order</NavDropdown.Item>
              </LinkContainer>

            </NavDropdown>

            {/*<NavDropdown  title="Receive" id="basic-nav-dropdown">
                <LinkContainer className="linkContainer" to="/receive-order">
                  <NavDropdown.Item >Receive Order</NavDropdown.Item>
                </LinkContainer> 
                <LinkContainer className="linkContainer" to="/receive-parts">
                  <NavDropdown.Item >Receive Loose parts</NavDropdown.Item>
                </LinkContainer>
          </NavDropdown>*/}

            <Nav.Link as={Link} className="homeLink" to='/finances'>Finances</Nav.Link>

            <NavDropdown title="E-Commerce" id="basic-nav-dropdown" align="end"  >
              <LinkContainer className="linkContainer" to="/inventory">
                <NavDropdown.Item >Manage Inventory</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/item-select">
                <NavDropdown.Item >Inventory Sales Data</NavDropdown.Item>
              </LinkContainer>


              <LinkContainer className="linkContainer" to="/orders">
                <NavDropdown.Item >Customer Orders</NavDropdown.Item>
              </LinkContainer>

            </NavDropdown>





          </Nav>
        </Navbar.Collapse>
      </Navbar>

    )
  }
}
export default Header;