import React, { useState, useEffect, useRef,useCallback } from "react";


import 'bootstrap/dist/css/bootstrap.min.css'

import './index.css'

import {useSkipper} from './lightning-utils'

import Form from 'react-bootstrap/Form';

import { ResizeColumns } from './lightning-utils'



import {
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
    getSortedRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
} from '@tanstack/react-table'



const makeInitialVisibility = (columns) => {
    var ret = {}
    columns.forEach(column => {
        var key = column['id'] ? column['id'] : column['accessorKey']
        ret[key] = column.hidden ? false : true
    })
    return ret
}

export const logMessage = (message) => {
   // console.log(message)
}



export function LightningKit({ in_data, columns, defaultSort, width, onEditCallback, getRowCanExpand, expandRow, pageOptions, height, afterFilter, ...other_props }) {
    const tableRef = useRef(null)
    const bodyRef = useRef(null)
    const headerRef = useRef(null)
    const footerRef = useRef(null)

    const lastSize=useRef(0)
    const [columns_state, _setColumns] = useState([]);
    const [columnOrder, setColumnOrder] = React.useState(
        columns.map(column => column.accessorKey) //must start out with populated columnOrder so we can splice
      )
    const [sorting, setSorting] = React.useState(defaultSort ? defaultSort : [])
    const [data, setData] = React.useState(in_data)
    const [globalFilter, setGlobalFilter] = useState('')
    const [columnVisibility, setColumnVisibility] = React.useState(() => makeInitialVisibility(columns))
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()
    const [rowSelection, setRowSelection] = React.useState({})


    const searchProps = { setGlobalFilter, globalFilter }

    const columns_ref = React.useRef(columns_state)
    const column_vis_ref = React.useRef(columnVisibility)

    const setColumns = data => {
        columns_ref.current = data
        _setColumns(data)
    }
   // const resizeCols = useCallback(()=>{ResizeColumns(bodyRef, tableRef, columns_ref, column_vis_ref, setColumns)},[columns_ref,column_vis_ref,bodyRef,tableRef]);


    const resizeCols = ResizeColumns(bodyRef, tableRef, columns_ref, column_vis_ref, setColumns)


    useEffect(() => {
        setColumns(columns)
        //setColumnVisibility(makeInitialVisibility(columns))
        //resizeCols()
    }, [columns])

 
    useEffect(() => {
        column_vis_ref.current = columnVisibility
        //resizeCols()
    }, [columnVisibility])


/*    useEffect(() => {

        window.addEventListener('resize', resizeCols);
        return () => {
            window.removeEventListener('resize', resizeCols);
        };
    }, []);*/

    useEffect(() => {
        setData(in_data)
    }, [in_data])


    const defaultColumn = {

        style:({cell,row})=>{{}},
        cell: ({ getValue, row, column, table }) => {
            return (<span>{getValue()}</span>)
        },
        Cell: ({ getValue, row, column, table }) => {
            const initialValue = column.columnDef.dataFormatter ? column.columnDef.dataFormatter({getValue, row, column, table}) : getValue()
            // We need to keep and update the state of the cell normally
            const [value, setValue] = React.useState(initialValue)
            // When the input is blurred, we'll call our table meta's updateData function
            const onBlur = () => {

                if (onEditCallback) {
                    table.options.meta.updateData(row['index'], column['id'], value)
                    const result = onEditCallback(value, initialValue, row, column);
                    if (result instanceof Promise) {
                        result.then(result => {
                            if (!result) {
                                setValue(initialValue)
                            }
                        })
                    } else if (!result) {
                        setValue(initialValue)
                    }
                    // if (onEditCallback(value, initialValue, row, column)) {
                    //     //table.options.meta.updateData(row['index'], column['id'], value)
                    // } else {
                    //     setValue(initialValue)
                    // }
                }
            }

            // If the initialValue is changed external, sync it up with our state
            React.useEffect(() => {
                setValue(initialValue)
            }, [initialValue])

            return (

                <Form.Control
                    className="tableCellEdit"
                    type={column.columnDef.editType? column.columnDef.editType : "text"}
                    min={column.columnDef.editMin? column.columnDef.editMin : ""}
                    placeholder="-"
                    value={value}
                    plaintext
                    onBlur={onBlur}
                    onChange={e => setValue(e.target.value)}
                    onDoubleClick={e=> e.stopPropagation()}
                    style={column.columnDef.styleFormatter ?column.columnDef.styleFormatter({getValue, row, column, table}) : {} }
                />


            )
        },
    }

    const table = useReactTable({
        data,
        columns: columns_state,
        state: {
            sorting,
            globalFilter,
            columnVisibility,
            columnOrder,
            rowSelection
        },
        
        onGlobalFilterChange: setGlobalFilter,
        getFilteredRowModel: getFilteredRowModel(),

        globalFilterFn: (row, columnId, filterValue) => {
            const safeValue = (() => {
                const value = row.getValue(columnId);
                return typeof value === 'number' ? String(value) : value;
            })();

            return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
        },
        defaultColumn,
        getRowCanExpand,
        onColumnOrderChange: setColumnOrder,
        onSortingChange: setSorting,
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        autoResetPageIndex,
        meta: {
            updateData: (rowIndex, columnId, value) => {
                // Skip age index reset until after next rerender
                skipAutoResetPageIndex()
                setData(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            }
                        }
                        return row
                    })
                )
            },
        },
    })


    const toggleProps = { columnVisibility, setColumnVisibility, table }
    //console.log(table.getState())

    React.useEffect(() => {
        if(pageOptions){
            table.setPageSize(pageOptions.sizePerPage)
        }else{
            table.setPageSize(1000)
        }
    }, [pageOptions])


    const filteredRows = table.getRowModel().rows
    React.useEffect(() => {
        if (afterFilter && table.getCoreRowModel().rows.length > 0) {
            afterFilter(globalFilter, filteredRows, table.getFilteredRowModel().rows)
        }
    }, [filteredRows])
    /*
      React.useEffect(() => {
        console.log(table.getState())
        console.log(table.getHeaderGroups()[0]['headers'])
    
        table.getHeaderGroups()[0]['headers'].forEach(h=>
        console.log(h.getSize()))
    
        var i=-1
        for ( i = 0; i <table.getHeaderGroups()[0]['headers'].length;i++ ){
          if (table.getState().columnSizingInfo.isResizingColumn && 
          table.getState().columnSizingInfo.isResizingColumn == table.getHeaderGroups()[0]['headers'][i]['id']){
            break
          }
        }
        console.log(i)
    
        if(i != -1){
          var temp = []
          for( var j =0; j < columns_ref.current.length; j++){
            if(j != i+1){
              columns_ref.current[j]['size']=columns_ref.current[j]['size']
            }else{
              console.log(table.getState().columnSizingInfo.deltaOffset)
              columns_ref.current[j]['size'] -=table.getState().columnSizingInfo.deltaOffset
            }
    
            temp.push(columns_ref.current[j])
          }
          setColumns(temp);
        }
        
      }, [table.getState().columnSizingInfo.deltaOffset])
    */


      React.useEffect(() => {

        
        if(table.getState().columnSizingInfo.isResizingColumn){
            var temp = []
            var totalPercent = 0
            var foundIndex = -1
            var scrollWidth = bodyRef.current.offsetWidth - bodyRef.current.clientWidth;
                        
            var parent_width = tableRef.current.parentElement.offsetWidth - scrollWidth;
            for( var j =0; j < columns_ref.current.length; j++){
                var element = {...columns_ref.current[j]}
                if(element.width){
                    
                    if(columns_ref.current[j].accessorKey === table.getState().columnSizingInfo.isResizingColumn){
                        foundIndex=j;
                        
                        element.width +=(table.getState().columnSizingInfo.deltaOffset - lastSize.current)/parent_width
                        lastSize.current = table.getState().columnSizingInfo.deltaOffset
                    }
                    totalPercent += element.width
                }
                temp.push(element)
            }
              if(foundIndex>=0){
                if((temp[foundIndex].width/totalPercent) * parent_width < element.minSize){
                    temp[foundIndex].useFixed = true
                }else{
                    temp[foundIndex].useFixed = false
                }
              }
              setColumns(temp);
        }else{
            lastSize.current = 0
        }
        
        //table.getHeaderGroups()[0]['headers'].forEach(h=>
        //console.log(h.getSize()))
    
         
        //}
        
      }, [table.getState().columnSizingInfo.deltaOffset])
    return (
        <>
            {other_props.children({
                ...other_props, searchProps, toggleProps, width, tableRef, table, bodyRef, headerRef,footerRef, expandRow, pageOptions
            })}
          
        </>
    )
}
