import React from "react";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ProductOverview } from "./productOverview.js";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import ProfitAndLoss from "./ProfitAndLoss.js";
import '../inventory.css';

export const ContainerTabs  = ({ProfitandRev,ProfitandRevLoaded, topSelling,plInfo,reloadCallback, refreshTopSellingOnly}) =>{
    console.log(plInfo)
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="underline" style={{marginLeft:15, borderBottom:"1px solid gray"}} className="FinancialTabs">
            <Nav.Item style={{marginLeft:25}}>
              <Nav.Link eventKey="first">Product View</Nav.Link>
            </Nav.Item>
           
            <Nav.Item style={{marginLeft:25}}>
              <Nav.Link eventKey="second">Company View</Nav.Link>
            </Nav.Item>
          </Nav>
          <Row>
          <Tab.Content>
            <Tab.Pane eventKey="first"><ProductOverview ProfitandRev={ProfitandRev} ProfitandRevLoaded = {ProfitandRevLoaded}  topSelling={topSelling} reloadCallback = {reloadCallback} refreshTopSellingOnly = {refreshTopSellingOnly} authorized={plInfo.plAuthorized}/></Tab.Pane>
            <Tab.Pane eventKey="second"><ProfitAndLoss pldata={plInfo.plData} loading={plInfo.plLoaded} authorized={plInfo.plAuthorized} /></Tab.Pane>
          </Tab.Content>
      </Row>
    </Tab.Container>
      );
}