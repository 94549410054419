import React, { Component, PureComponent } from 'react';
import { AreaChart, LineChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../inventory.css';



class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}





export default class RechartLineGraph extends Component {


  constructor(props) {
    super(props);

    this.state = {
      data: [],
      type: false,
      rawData: [],
      isMoney: true,
      item: "",
    };
    this.formatXAxis = this.formatXAxis.bind(this);
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.data != prevProps.data) {
      this.setState({ data: this.formatData(this.props.data), rawData: this.props.data })
    }
    if (this.props.type != prevProps.type) {

      this.setState({ type: this.props.type })
    }



  }

  componentDidMount() {
    this.setState({ data: this.formatData(this.props.data), rawData: this.props.data, type: this.props.type })
    if (this.props.isMoney !== undefined) {
      this.setState({ isMoney: this.props.isMoney })
    }
  }

  formatData = (data) => {
    var tmpData = {}
    data.forEach(seriesData => {
      if (seriesData['data_points']) {
        seriesData['data_points'].forEach(point => {
          if (!tmpData[point['x']]) {
            var tmpObj = {}
            tmpObj[seriesData['data_label']] = point['y']
            tmpObj['name'] = point['x']
            tmpData[point['x']] = tmpObj
          } else {
            tmpData[point['x']][seriesData['data_label']] = point['y']
          }
        })
      }
    })
    return Object.values(tmpData).sort((a, b) => new Date('20' + a.name.substring(3, 5), a.name.substring(0, 2), 1) - new Date('20' + b.name.substring(3, 5), b.name.substring(0, 2), 1));

  }

  mouseEnterHandler(key) {
    this.state.item = key;
  }
  formatXAxis(tickItem) {
    // If using moment.js
    return (this.state.isMoney ? "$" : "") + tickItem.toLocaleString(undefined, { minimumFractionDigits: 2 })
  }

  CustomTooltip = ({ active, payload, label }) => {

    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="custom-tooltip-title"> {label} {this.props.titleKey}</p>

          {payload.map(
            (v) =>
              v.name === this.state.item && (
                <div key={v['name']}>
                  <div className='color-box' style={{ backgroundColor: v['stroke'] }} />
                  <p >{v['name']}: {this.state.isMoney ? "$" : ""} {v['value'].toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                </div>
              )
          )}
          {payload && payload.length < 10 &&

            <>
              {this.state.item !== "" && <hr style = {{margin:5}} />}
              {payload.map(v =>
                v.name !== this.state.item && (<div key={v['name']}>
                  <div className='color-box' style={{ backgroundColor: v['stroke'] }} />
                  <p >{v['name']}: {this.state.isMoney ? "$" : ""} {v['value'].toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                </div>)

              )}
            </>
          }

        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <div style={{ width: '100%', marginBottom: "20px" }}>
        {this.props.title ? <h3>{this.props.title}</h3> : <></>}
        {this.state.rawData && <ResponsiveContainer width="100%" height={400}>
          {!this.state.type ? <LineChart
            width={500}
            height={400}
            data={this.state.data}
            margin={{
              top: 15,
              right: 30,
              left: 50,
              bottom: 0,
            }}
            
            syncId={this.props.syncId}
            onMouseMove={(props) => { if (this.props.tooltipCallback) this.props.tooltipCallback(props) }}
          >

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
            <YAxis padding={{ left: 60 }} tickFormatter={this.formatXAxis} />
            <Tooltip content={<this.CustomTooltip />} cursor={{ stroke: '#47d3d9', strokeWidth: 1 }} />
            {this.props.data && this.props.data.length < 10 ? <Legend /> : <></>}

            {this.state.rawData.map(selling_source =>
              <Line key={selling_source['account_id']} 
              animationDuration={500}
              connectNulls type="monotone" dataKey={selling_source['data_label']} stroke={selling_source['data_color']} strokeWidth={2} activeDot={{
                onMouseOver: () => this.mouseEnterHandler(selling_source['data_label']),
                onMouseLeave: () => this.mouseEnterHandler("")
              }} />
            )}
          </LineChart>

            :
            <AreaChart
              width={500}
              height={400}
              data={this.state.data}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 0,
              }}
              syncId={this.props.syncId}
            >

              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
              <YAxis padding={{ left: 60 }} tickFormatter={this.formatXAxis} />
              <Tooltip content={<this.CustomTooltip />} cursor={{ stroke: '#47d3d9', strokeWidth: 1 }} />
              <Legend />

              {this.state.rawData.map(selling_source =>
                <Area stackId="1" key={selling_source['account_id']} type="monotone" dataKey={selling_source['data_label']} stroke={selling_source['data_color']}
                  fill={selling_source['data_color']} strokeWidth={2} />
              )}
            </AreaChart>
          }

        </ResponsiveContainer>}


      </div>
    );
  }
}
