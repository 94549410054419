import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Tabs from 'react-bootstrap/Tabs';
import '../inventory.css';

import Tab from 'react-bootstrap/Tab';

import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Media from '../Media.js';


import { FBAPropertiesTab } from "./FBAProperties.js";
import { ItemPropertiesTab } from "./ItemProperties.js";
import { ItemCartTab } from "./itemCart.js";
import { ComponentTab } from "./itemComponents.js";
import { ItemHistoryTab } from "./itemHistory.js";
import { LinkedItemTab } from "./itemLinks.js";
import { PurchaseOrderTab } from "./itemPurchaseOrders.js";
import { ItemSalesTab } from "./itemSalesStats.js";
import EnsureModal from '../ensureModal.js';


var ItemPropertiesModal = function (props) {


    const [key, setKey] = useState(props.defaultTab || 'details');

    React.useEffect(() => {
        setKey(props.defaultTab || 'details');
    }, [props.show]);



    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"

            animation={false}
        >


            <Modal.Header closeButton>
                <Media>
                    {props.item.item_img_url && <img
                        className="mr-3"
                        src={props.item.item_img_url}
                        alt="Item Image"
                        style={{ maxWidth: 75, maxHeight: 75 }}
                    />}
                    <div>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>{props.item.item_name ? props.item.item_name : "New Item"}</h2>
                        </Modal.Title>
                    </div>
                </Media>

            </Modal.Header>

            <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="uncontrolled-tab-example">
                {!(props.item.custom && props.item.custom == 1) && <Tab eventKey="details" title={<div>Item Details {props.item.item_notes && props.item.item_notes.length > 0 && <FontAwesomeIcon style={{ color: "#3a6db8" }} className="trashIcon" icon={faClipboardList} />}</div>}>
                    <ItemPropertiesTab
                        updateItemQty={props.updateItemQty}
                        item={props.item}
                        onHide={props.onHide}
                        my_updateItem={props.my_updateItem}
                        readOnly={props.readOnly}
                        allTags={props.allTags}
                        token={props.token}
                    />
                </Tab>}

                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && (props.item && (props.item.is_fba)) && <Tab eventKey="fbadetails" title="FBA Details">
                    <FBAPropertiesTab
                        item={props.item}
                        onHide={props.onHide}
                        my_updateItem={props.my_updateItem}
                        readOnly={props.readOnly}
                        token={props.token}
                    />
                </Tab>}

                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && props.item.item_type != "" && <Tab eventKey="ilinkeditem" title="Linked Items">
                    <LinkedItemTab item={props.item}
                        onHide={props.onHide}
                        all_items={props.all_items}
                        items_minified={props.items_minified}
                        onMergeSubmit={props.onMergeSubmit}
                        showMergePanels={props.showMergePanels}
                        my_updateItem={props.my_updateItem}
                        token={props.token} />
                </Tab>}

                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && props.item.item_type != "" && <Tab eventKey="components" title="Components">
                    <ComponentTab item={props.item}
                        onHide={props.onHide}
                        all_items={props.all_items}
                        items_minified={props.items_minified}
                        showMergePanels={props.showMergePanels}
                        updateRootComponents={props.updateRootComponents}
                        token={props.token} />
                </Tab>}


                {props.handleCartAdd && !props.item.newItem && <Tab eventKey="addcart" title="Add To Cart">
                    <ItemCartTab
                        my_ei={props.my_ei}
                        item={props.item}
                        handleCartAdd={props.handleCartAdd}
                        onHide={props.onHide}
                        my_qty={props.my_qty}
                    />
                </Tab >}

                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && <Tab eventKey="eventHistory" title="Change Log">
                    <ItemHistoryTab
                        token={props.token}
                        item={props.item} />
                </Tab>}
                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && <Tab eventKey="itemsales" title="Sales Stats">
                    <ItemSalesTab
                        token={props.token}
                        item={props.item}
                        my_key={key} />

                </Tab>}

                {!(props.item.custom && props.item.custom == 1) && !props.item.newItem && <Tab eventKey="itemorders" title="Purchase Orders">
                    <PurchaseOrderTab
                        token={props.token}
                        item={props.item}
                    />
                </Tab>}

            </Tabs>

        </Modal>
    );
}
export default ItemPropertiesModal;
