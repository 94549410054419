import React from "react";
import { useState, useEffect } from "react";

import "react-resizable/css/styles.css";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";

import { faSearch } from '@fortawesome/free-solid-svg-icons'

export default function SalesGraphSettings({
  onSearch, token
}) {

  const [selected_type, setSelectedType] = useState("Default View");
  const [all_vendors, setAllVendors] = useState([])
  var search_term = React.createRef();
  var search_term_type = React.createRef();
  var date_weeks = React.createRef();

  React.useEffect(() => {
    if (selected_type == 'View By Vendor') {
      getAllVendors()
    }
  }, [selected_type]);

  const my_handle_submit = (e) => {
    e.preventDefault()
    var searchType = ""
    var searchTerm = search_term.value
    if (selected_type == 'Group By Vendor') {
      searchType = "Vendor"
      searchTerm = search_term_type.value
    } else if (selected_type == 'View By Vendor') {
      searchType = "VendorItem"
    } else if (selected_type == 'Group By Tag') {
      searchType = "Tag"
    } else if (selected_type == 'View By Item Keyword') {
      searchType = "Item"
    } else {
      searchType = "__Default"
    }
    onSearch(searchTerm, searchType, date_weeks.value)
  }
  const getAllVendors = () => {

    axios.get('/api/get-unique-item-vendors',
      {
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
      })
      .then(function (response) {
        setAllVendors(response.data)

      })
      .catch(function (error) {
        setAllVendors([])

      });
  }


  return (
    <div>
      <Form onSubmit={my_handle_submit} className="RowsNoFullWidth">
        <Row>

          <Col md={5} style={{ maxWidth: "450px", marginBottom: 10 }}>
            <Form.Group as={Row} controlId="form">
              <Form.Label style={{ padding: 5 }}>Data Display Type:</Form.Label>
              <Form.Select style={{ width: "225px" }} defaultValue={"Default View"} onChange={(e) => { setSelectedType(e.target.value); }}>
                <option>Default View</option>
                <option>Group By Vendor</option>
                <option>Group By Tag</option>
                <option>View By Vendor</option>
                <option>View By Item Keyword</option>
              </Form.Select>
              {/*selected_type =='Default View' && <Button type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>*/
              }
            </Form.Group>
          </Col>



          {selected_type == 'View By Item Keyword' ? <Col md={4} >
            <Form.Group as={Row} style={{ marginBottom: 10 }}>
              <Form.Label style={{ padding: 5 }}> Search:  </Form.Label>
              <Form.Control type="string" placeholder="Search Term"
                ref={(input) => search_term = input}
                style={{ width: "250px" }} />
              {/*<Button type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>*/}
            </Form.Group>
          </Col>
            : selected_type == 'View By Vendor' ?

              <Col md={4} style={{ marginBottom: 10 }} >
                <Form.Group as={Row} style={{ paddingLeft: 10, paddingRight: 10 }} controlId="formSite2">

                  <Form.Select defaultValue="unselected_default" style={{ width: "200px", height: 42 }} required ref={(input) => search_term = input}>
                    <option disabled  value="unselected_default">--Select a Vendor--</option>
                    {all_vendors.map(vendor => <option key={vendor['vendor']}>{vendor['vendor']}</option>)}
                  </Form.Select>
                  {/*<Button type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>*/}
                </Form.Group>
              </Col>
              : selected_type == 'Group By Vendor' ?
                <Col md={4} style={{ marginBottom: 10 }}>
                  <Form.Group as={Row} style={{ paddingLeft: 10, paddingRight: 10 }} controlId="formSite3">

                    <Form.Select style={{ width: "275px", height: 42 }} defaultValue={"Include Items With No Vendor"} ref={(input) => search_term_type = input}>
                      <option>Include Items With No Vendor</option>
                      <option>Exclude Items With No Vendor</option>
                    </Form.Select>
                    {/*<Button type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>*/}
                  </Form.Group>
                </Col>
                :
                <></>
          }
          <Col md={3} style={{ maxWidth: "250px", marginBottom: 10 }}>
            <Form.Group as={Row} style={{ paddingLeft: 10, paddingRight: 10 }} controlId="formWeeks">
              <Form.Label style={{ padding: 5 }}>Weeks:</Form.Label>
              <Form.Control style={{ width: "75px", marginRight: 10 }} type="number" min={1} max={26} defaultValue={8} ref={(input) => date_weeks = input} />

              <Button type="submit" variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faSearch} /></Button>

            </Form.Group>
          </Col>



        </Row>
      </Form>
    </div>
  );
}
