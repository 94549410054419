import React, { Component, } from "react";
import { useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import BarcodeReader from 'react-barcode-reader'
import EnsureModal from './ensureModal'
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from './withRouter';

import { LightningTable } from '../lightningTable/lightning-table'
import { LightningKit } from '../lightningTable/lightning-kit'

import { v4 as uuidv4 } from 'uuid';

import './inventory.css';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { faQuestion } from '@fortawesome/free-solid-svg-icons'


let endpoint = "";

const ItemStatusToName = (status) => {
  var background = ""
  if (status == "ON_ORDER") {
    background = "On Order"
  } else if (status == "RECEIVED") {
    background = "Received"
  } else if (status == "PARTIAL_RECEIVED") {
    background = "Partially Received"
  } else {
    background = status
  }
  return background
}


const columns = [{
  accessorKey: 'sku',
  header: 'Item',
  width:.4,
  editable: false,
  cell: props => {
    return (
      <div>
        <div>
          {props.getValue()}
        </div>
        {props.row.original.item_warehouse_loc && <div>
          <small style={{color:"gray"}}>Location: {props.row.original.item_warehouse_loc}</small>
        </div>}
      </div>
    
    )
  }
}, {
  accessorKey: 'order_status',
  header: 'Status',
  enableSorting: true,
  cell: props => { return (<span>{ItemStatusToName(props.getValue())}</span>) },
  width:.15,
  editable: false
}, {
  accessorKey: 'ecommerce',
  header: 'Item Type',
  enableSorting: true,
  width:.15,
   cell: props => {
    var row = props.row.original
    var totalRec = ((parseInt(row.rec_qty) * row.buy_pack_qty) / row.sale_pack_qty) + parseInt(row.orig_rec_qty)
    var totalOrder = parseInt(row.qty) + parseInt(row.fba_qty) + parseInt(row.cust_qty)
    return (
      <div>

        {row.cust_qty != "" && row.cust_qty != "0" && <div style={{ color: 'purple' }}>Customer Part: {totalRec >= parseInt(row.cust_qty) ? parseInt(row.cust_qty) : totalRec} / {parseInt(row.cust_qty)}, </div>}


        {props.getValue() == true && row.qty != "" && row.qty != "0" &&

          <div style={{ color: 'green' }}>E-Commerce: {totalRec >= parseInt(row.cust_qty) + parseInt(row.qty) ? parseInt(row.qty) : Math.max(totalRec - parseInt(row.cust_qty), 0)} / {parseInt(row.qty)}</div>
        }
        {props.getValue() == false && row.qty != "" && row.qty != "0" &&
          <div style={{ color: 'red' }}>Store Stock: {totalRec >= parseInt(row.cust_qty) + parseInt(row.qty) ? parseInt(row.qty) : Math.max(totalRec - parseInt(row.cust_qty), 0)} / {parseInt(row.qty)}</div>
        }

        {props.getValue() == true && row.fba_qty != "" && row.fba_qty != "0" &&

          <div style={{ color: 'blue' }}>FBA: {Math.max(totalRec - parseInt(row.cust_qty) - parseInt(row.qty), 0)} / {parseInt(row.fba_qty)}</div>
        }
        {props.getValue() == false && row.fba_qty != "" && row.fba_qty != "0" &&
          <div style={{ color: 'red' }}>New FBA: {Math.max(totalRec - parseInt(row.cust_qty) - parseInt(row.qty), 0)} / {parseInt(row.fba_qty)}</div>
        }


      </div>
    )
  },
  editable: false
}, {
  accessorKey: 'qty',
  header: 'Qty Ordered',
  enableSorting: true,
  width:.1,
 cell: props => {
  var row = props.row.original
    var raw = parseInt(row.qty) + parseInt(row.fba_qty) + parseInt(row.cust_qty)
    var totalRec = Math.floor(((parseInt(row.qty) + parseInt(row.fba_qty) + parseInt(row.cust_qty)) * row.sale_pack_qty) / row.buy_pack_qty)
    var isBundled = Math.floor(((parseInt(row.qty) + parseInt(row.fba_qty) + parseInt(row.cust_qty)) * row.sale_pack_qty) / row.buy_pack_qty) != (parseInt(row.qty) + parseInt(row.fba_qty) + parseInt(row.cust_qty))

    return isBundled ? <div><div>{totalRec}</div><div style={{ color: "red", fontSize: ".75rem" }}>This item is bought in packs of {row.buy_pack_qty} and sold in packs of {row.sale_pack_qty}. Receiving {totalRec} of this item will increase inventory by {raw}  </div></div> : <span>{totalRec}</span>

  },
  editable: false
}

  , {
  accessorKey: 'orig_rec_qty',
  header: 'Qty Already Received',
  enableSorting: true,
  width:.1,
  cell:props => {
    var raw = parseInt(props.row.original.orig_rec_qty)
    var totalRec = Math.floor(((raw) * props.row.original.sale_pack_qty) / props.row.original.buy_pack_qty)


    return <span>{totalRec}</span>

  },
  editable: false
}, {
  accessorKey: 'rec_qty',
  header: 'Qty Received',
  enableSorting: true,
  editable:true,
  editType:"number",
  width:.1
}

];


const options = {
  paginationSize: 5,
  sizePerPage: 100,
  pageStartIndex: 0,
  // alwaysShowAllBtns: true, // Always show next and previous button
  // withFirstAndLast: false, // Hide the going to First and Last page button
  // hideSizePerPage: true, // Hide the sizePerPage dropdown always
  hidePageListOnlyOnePage: true, // Hide the pagination list when only one page

  showTotal: true,
  sizePerPageList: [{
    text: '50', value: 50
  }, {
    text: '100', value: 100
  }, , {
    text: '200', value: 200
  }] // A numeric array is also available. the purpose of above example is custom the text
};

function MyVerticallyCenteredModal(props) {

  const [selected_item, setSelectedItem] = useState({});
  const [selected_item_name, setSelectedItemName] = useState("No Item Selected");
  const my_handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(selected_item)
    setSelectedItem({})
    setSelectedItemName("")
  }

  const columns = [{
    accessorKey: 'sku',
    header: 'Item',
    enableSort: true,
    width: 1

  }]
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >


      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>Register Item to Barcode</h1>
          <p style={{ fontSize: "1rem" }}>An unknown barcode was scanned. Select an item below to match this barcode.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={(e) => e.preventDefault()} >

          <Form.Group controlId="formValue">

            <LightningKit columns={columns} getRowCanExpand={() => false} in_data={props.items} width="100%" >
              {
                props => (


                  <LightningTable
                    className="lightningFixedTable smallFont"
                    height="20vh"
                    {...props} {...props.table}
                    noDataIndication="No Data to Show"
                    onRowDoubleClick={(row) => {
                      setSelectedItem(row.original)
                      setSelectedItemName(row.original.sku)
                    }}
                  />


                )
              }

            </LightningKit>


            <Form.Text>Selected Item: {selected_item_name} </Form.Text>
          </Form.Group>

        </Form>


      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { setSelectedItem({}); setSelectedItemName(""); props.onHide() }}>Cancel</Button>
        <Button type="submit" onClick={my_handleSubmit}>Add Mapping</Button>
      </Modal.Footer>
    </Modal>
  );
}


function OrderSearch(props) {

  var order_num = React.createRef()
  const my_handleSubmit = (e) => {
    e.preventDefault()
    props.handleSubmit(order_num.value)
  }

  const handleSave = (e) => {
    props.handleSave()
  }

  return (
    <Form onSubmit={my_handleSubmit} style={{ marginBottom: 5 }}>


      <Form.Group controlId="formBasicPassword">
        <Form.Label style={{ marginRight: 10 }}>Order Number:</Form.Label>
        <Form.Control type="string" ref={(input) => order_num = input}
          style={{ marginRight: 10 }}
          placeholder="Order Number"
          onKeyPress={event => {
            if (event.key === "Enter") {
              my_handleSubmit(event);
            }
          }} />
      </Form.Group>

      <Button variant="primary" type="submit">Search</Button>
      <Button style={{ marginLeft: 40 }} variant="success" onClick={handleSave} disabled={props.component.state.buttonDisabled}>{props.component.state.buttonText}</Button>
    </Form>
  )

}

function AlertOrderStatus(props) {

  if (props.isError) {


    return (
      <Alert style={{ zIndex: 2000 }} show={props.show} variant="danger" onClose={props.hideAlert} dismissible>

        {props.mesage}

      </Alert>
    );

  } else {

    return (
      <Alert style={{ zIndex: 2000 }} show={props.show} variant="success" onClose={props.hideAlert} dismissible>
        {props.mesage}
      </Alert>
    );
  }
}


class ReceiveOrder extends Component {

  constructor(props) {
    super(props);





    this.state = {
      order_details: {},
      order_items: [],
      showAlert: false,
      isError: true,
      message: "",
      orders: [],
      recItems: [],
      buttonDisabled: false,
      buttonText: 'Save',
      lastScan: '',
      showConfirmModal: false,
      color: "inherit",
      showMappingModal: false,
      tableState: "None",
      qtyCheckMessage:"",
      showqtyCheckModal:false,
      qtyCheckOKCallback:()=>{},
      qtyCheckCancelCallback:()=>{}
    };
    this.findOrderIDFromName = this.findOrderIDFromName.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleRecItems = this.handleRecItems.bind(this);
    this.handleScannedItem = this.handleScannedItem.bind(this);
    this.handleNotFound = this.handleNotFound.bind(this);
    this.mapItem = this.mapItem.bind(this);
    this.updateItemBarcode = this.updateItemBarcode.bind(this);

  }




  getOrders = () => {
    let currentComponent = this;

    axios.get(endpoint + "/api/get-all-store-orders",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          this.setState({

            orders: res.data


          });

        } else {
          currentComponent.setState({
            orders: [],

          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          orders: []

        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')

          }
        } else {
          currentComponent.setState({
            orders: [],

          });
        }
      });
  };

  getOrderData = (id) => {
    let currentComponent = this;
    if (!id) {
      return;
    }
    this.setState({ errorMessage: "Loading Data. Please Wait..." })
    axios.get(endpoint + "/api/get-order-items/" + id,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          currentComponent.setState({
            order_details: res.data,
            order_items: res.data.items ? res.data.items : [],
            showAlert: false,
            errorMessage: "",
            tableState: "Done"


          });


        } else {
          currentComponent.setState({
            order_details: {},
            order_items: [],
            showAlert: true,
            isError: true,
            message: "Error: Empty Response",
            errorMessage: "",
            tableState: "Done"
          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          order_details: {},
          tableState: "Done"
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')

          }

          if (error.response.status == 400) {
            currentComponent.setState({
              showAlert: true,
              isError: true,
              message: "Order Number Not Found",
              errorMessage: "",


            });
          }
        } else {
          currentComponent.setState({
            order_details: {},
            showAlert: true,
            isError: true,
            message: "Error: Empty Response",
            errorMessage: ""


          });
        }
      });
  }

  componentDidMount() {
    if (this.props.order_details) {
      this.setState({ order_details: this.props.order_details, order_items: this.props.order_details.items })
    } else if (this.props.location.state && this.props.location.state.id) {
      this.setState({ tableState: "Loading" })
      this.getOrderData(this.props.location.state.id);
    } else {
      this.getOrders();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.order_details != prevProps.order_details) {
      this.setState({ order_details: this.props.order_details, order_items: this.props.order_details.items })
    }
  }


  findOrderIDFromName(name) {
    var new_items = this.state.orders.filter(function (order) {

      return order.order_name.toLowerCase() === name.toLowerCase()
    })
    if (new_items.length > 0) {
      this.setState({
        showAlert: false
      })
      this.getOrderData(new_items[0].order_id)
    } else {

      this.setState({
        showAlert: true,
        isError: true,
        message: "Order Number Not Found"
      })
    }
  }

  updateItemBarcode(item) {
    var payload = { updated_fields: ['item_barcode'], updated_item: item }
    axios.put(endpoint + '/api/update-item/' + item.description_id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {


        //console.log(response.headers);
      })
      .catch(function (error) {

      });
  }

  handleRecItems() {
    var payload = this.state.order_details
    payload.items = this.state.order_items;
    var numFullReceive = 0
    var numPartialReceive = 0
    for (var i = 0; i < payload.items.length; i++) {
      payload.items[i].rec_qty = Math.floor(((payload.items[i].rec_qty) / payload.items[i].sale_pack_qty) * payload.items[i].buy_pack_qty)
      if (parseInt(payload.items[i].rec_qty) + parseInt(payload.items[i].orig_rec_qty) >= parseInt(payload.items[i].qty) + parseInt(payload.items[i].cust_qty) + parseInt(payload.items[i].fba_qty)) {
        payload.items[i].order_status = "RECEIVED"
        numFullReceive += 1

      } else if (parseInt(payload.items[i].rec_qty) + parseInt(payload.items[i].orig_rec_qty) > 0) {
        payload.items[i].order_status = "PARTIAL_RECEIVED"
        numPartialReceive += 1
      }
      payload.items[i].rec_qty = payload.items[i].rec_qty.toString()
    }
    if (numFullReceive == payload.items.length) {
      payload.order_status = "RECEIVED"
    } else if (numPartialReceive + numFullReceive > 0) {
      payload.order_status = "PARTIAL_RECEIVED"
    }
    //console.log(payload)


    this.setState({ buttonText: 'Saving...', buttonDisabled: true })
    var requestId = uuidv4()
    var component = this;
    axios.put(endpoint + '/api/receive-order/' + this.state.order_details.order_id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token, 
        RequestId: requestId
      }
    })
      .then(function (response) {
        if (!component.props.innerPage) {
          component.setState({ showConfirmModal: false, showAlert: false, order_details: {}, order_items: [], buttonText: 'Save', buttonDisabled: false })
        } else {
          component.setState({ showConfirmModal: false, showAlert: true, isError: false, message: "Successfully Received Order.", buttonText: 'Save', buttonDisabled: false })
          if (component.props.receiveStateCallback) {
            component.props.receiveStateCallback(component.state.order_details.order_id, payload.order_status)
          }
          window.setTimeout(() => {
            component.props.reloadParentData();
          }, 1500)

        }

      })
      .catch(function (error) {
        component.setState({
          showAlert: true,
          isError: true,
          showConfirmModal: false,
          message: "Error receiving order. Received quantities were saved, but item quantities were not able to be adjusted for non-FBA items. Please manually adjust the stock item quantities on the 'Manage Inventory' page.",
          buttonText: 'Save', buttonDisabled: false
        })
      });




  }

  handleSave() {
    this.setState({ showConfirmModal: true })
  }




  handleScannedItem(item) {
    this.setState({
      color: 'green',
      order_items: this.state.order_items.map(e => (item.id === e.id ? Object.assign({}, e, { rec_qty: (parseInt(e.rec_qty) + 1) }) : e))
    })
    setTimeout(() => {
      this.setState({ color: 'inherit' })
    }, 1000)
  }

  mapItem(new_item) {
    this.setState({ showMappingModal: false })
    new_item['item_barcode'] = this.state.lastScan
    this.updateItemBarcode(new_item)
    this.setState({
      showMappingModal: false,
      order_items: this.state.order_items.map(e => (new_item.id === e.id ? Object.assign({}, e, { barcode: this.state.lastScan }) : e)),
      color: 'inherit'
    },
      () => this.handleScan(this.state.lastScan))

  }

  handleNotFound(data) {
    this.setState({ color: 'red', showMappingModal: true })

  }

  handleScan(data) {

    if (this.state.order_items.length < 1) {
      this.setState({ showAlert: true, isError: true, message: "You Must Select an Order Before Scanning Item!" })
      return;
    } else {
      this.setState({ showAlert: false })
    }
    var found = false
    this.state.order_items.forEach(item => {
      if (data == item.barcode) {
        this.handleScannedItem(item);
        found = true
      }
    })
    if (!found) {
      this.handleNotFound(data)
    }

    this.setState({
      lastScan: data,
    })
  }
  handleError(err) {
    console.error(err)
  }






  render() {








    return (


      <div className={this.props.innerPage ? "wrapper fullHeightWrapper" : "wrapper"} style={{ padding: this.props.innerPage ? 0 : 120, backgroundColor: this.props.innerPage ? "white" : "#FaFaFa" }} >
        <AlertOrderStatus show={this.state.showAlert} isError={this.state.isError} mesage={this.state.message} hideAlert={() => this.setState({ showAlert: false })} />
        <MyVerticallyCenteredModal items={this.state.order_items}
          show={this.state.showMappingModal}
          onHide={() => this.setState({ showMappingModal: false })}
          onSubmit={this.mapItem} />
        <EnsureModal onSubmit={this.handleRecItems} show={this.state.showConfirmModal} onHide={() => this.setState({ showConfirmModal: false })}
          message="Once you receive this order, it cannot be undone. Are you sure you want to continue?" />

        <EnsureModal onSubmit={this.state.qtyCheckOKCallback} show={this.state.showqtyCheckModal} onCancel={this.state.qtyCheckCancelCallback}  onHide={() => this.setState({ showqtyCheckModal: false })} 
          message={this.state.qtyCheckMessage} />
        <div className="customtable " style={{ width: this.props.innerPage ? "100%" : "90%" }}>
          <h2 className="HiddenUnlessPrint">{this.state.order_details.order_name}</h2>
          {!this.props.innerPage && <div className="headerBox" >
            <Container fluid>
              <Row>
                <Col xs={4}>
                  <h1 className=" left-align">Receive Order</h1>
                </Col>
                <Col xs={4}>
                  {this.state.order_details.order_name && <h2>{this.state.order_details.order_name}</h2>}
                </Col>
                <Col xs={4}>
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="left"
                    overlay={
                      <Popover style={{ maxWidth: 400, overflowY: "scroll", maxHeight: "95vh", overflowX: "hidden" }} >
                        <Popover.Header as="h3">How to Use this Page</Popover.Header>
                        <Popover.Body>
                          <strong>Receiving an Order</strong>
                          <hr />
                          <p>The point of this screen is to receive items from an order. Don't use this screen to receive loose items not part of an order. Check your work carefully - once you click save the save button, the quantites will be updated on ecommerce platforms. If you make a mistake, you will have to change the quantity of the item on the inventory tab. You must wait a few minutes for this receiving update to be reflected on the inventory page before you try to fix it.</p>

                          <strong>Selecting an Order</strong>
                          <hr />
                          <p>To select an order, type in the order name and click search. If the order is found, the table will populate. If you are coming to this page from the past orders screen, the order will already be populated.</p>

                          <strong>Receiving Items (Non-Barcode)</strong>
                          <hr />
                          <p>To receive an item, enter the quantity received in the Qty Received column. Note the Qty Already Received column, which reflects how many of the part has already been received if the order came in multiple batches.</p>

                          <strong>Receiving Items (Barcode)</strong>
                          <hr />
                          <p>To receive an item that has a barcode, scan the barcode. If the barcode is recognized, the background will change to green temporarily, and the Qty Received column will increment. If the barcode is not recognized, the background will change to red, and a window will pop up allowing you to select the item that corresponds to the barcode. Double click the item, and click 'Add Mapping'. Note this mapping will be persisted - to edit the mapping, change it in the inventory page.</p>
                          <strong>Finishing Receiving</strong>
                          <hr />
                          <p>When you are finished receiving an order, click the save button. On success, the page will clear - on error, an error alert will pop up. Once the order is received, the item state and order state will be updated to reflect received status.</p>

                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button style={{ float: "right", color: "black" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faQuestion}></FontAwesomeIcon></Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Container>
          </div>}




          <BarcodeReader
            onError={this.handleError}
            onScan={this.handleScan}
          />
          {!this.props.innerPage ?
            <div className="grayheader headerBox">
              <OrderSearch handleSubmit={this.findOrderIDFromName} handleSave={this.handleSave} component={this} />
            </div > :
            <Button className="HideOnPrint" style={{ marginLeft: 40, marginTop: 10, marginRight: 20, float: "right" }} variant="success" onClick={this.handleSave} disabled={this.state.buttonDisabled}>{this.state.buttonText}</Button>
          }
          {this.state.order_items && this.state.order_items.length > 0 ? <div className=" tablerow" style={{ clear: "both", border: this.props.innerPage ? "none" : "1px solid lightgray" }}>

            <LightningKit columns={columns} pageOptions={options} getRowCanExpand={() => false} in_data={this.state.order_items} width="100%"
              onEditCallback={(newValue, oldValue, row, column,) => {
                return new Promise((resolve, reject) => {
                  console.log("IN PROMISE")
                  console.log(newValue, oldValue, row, column, row.original.rec_qty, row.original.orig_rec_qty)
                if (oldValue != newValue) {

                  if (Number.isNaN(parseInt(newValue))) {
                    this.setState({
                      showAlert: true, isError: true,
                      message: "Error: Value of '" + newValue + "' is not parsable! Ensure there are no special symbols in your input", color: 'inherit'
                    })
                    resolve(false)
                  } else {
                    if (parseInt(newValue) < 0) {
                      this.setState({
                        showAlert: true, isError: true,
                        message: "Error: Value cannot be less than 0", color: 'inherit'
                      })
                      resolve(false)
                    } else {

                      const recqty = parseInt(newValue)+parseInt(row.original.orig_rec_qty)
                      const orderqty = (Math.floor(((parseInt(row.original.qty) + parseInt(row.original.fba_qty) + parseInt(row.original.cust_qty)) * row.original.sale_pack_qty) / row.original.buy_pack_qty))
                      console.log(recqty, orderqty, parseInt(newValue), parseInt(row.original.orig_rec_qty))
                      if(recqty > orderqty * 2){
                        this.setState({
                          qtyCheckMessage: "You are receiving more than double the amount of this item than was ordered (Received " + recqty + ", Ordered " + orderqty+  "). Are you sure you want to continue?",
                          showqtyCheckModal:true,
                          qtyCheckOKCallback:()=>{
                            this.setState({ showAlert: false, showqtyCheckModal: false, color: 'inherit' })
                            row.original.rec_qty = String(parseInt(newValue))

                            this.setState({
                              order_items: this.state.order_items.map(e => (e.id === row.original.id ? Object.assign({}, e, { rec_qty: row.original.rec_qty }) : e))
                            })
                            resolve(true)
                          },
                          qtyCheckCancelCallback:()=>{
                            resolve(false)
                            this.setState({
                              order_items: this.state.order_items.map(e => (e.id === row.original.id ? Object.assign({}, e, { rec_qty: oldValue }) : e))
                            })
                          }
                        })

                      }else{
                        this.setState({ showAlert: false, color: 'inherit' })
                        row.original.rec_qty = String(parseInt(newValue))

                        this.setState({
                          order_items: this.state.order_items.map(e => (e.id === row.original.id ? Object.assign({}, e, { rec_qty: row.original.rec_qty }) : e))
                        })
                        resolve(true)
                      }
                    }
                  }

                }
              })}}>
              {
                props => (



                  <LightningTable
                    className={this.props.innerPage ? " lightningFixedTable fullContainer recTable " : " lightningFixedTable recTable"}
                    height="30vh"
                    {...props} {...props.table}
                    noDataIndication={this.state.tableState == "Done" ? "No Matching Data" : this.state.tableState == "Loading" ? <Spinner animation="border" /> : "No Order Selected"}

                  />


                )
              }

            </LightningKit>


          </div> : <p style={{height:100, paddingTop:30}}>There are no items in this order.</p>}
          {this.state.errorMessage &&
            <p className="errorTable"> {this.state.errorMessage} </p>}
        </div>
      </div>
    );
  }
}

export default withRouter(ReceiveOrder);
