import React from "react";
import Pagination from 'react-bootstrap/Pagination';


export default function MyPagination(props) {
  const [pageArray, setPageArray] = React.useState([]);

  React.useEffect(() => {
    var totPages = parseInt(props.totPages);
    var currentPage = parseInt(props.currentPage);
    var pageArr = [];
    //console.log("here")

    //console.log(props.currentPage)
    //console.log(props.totPages)

    if (totPages > 1) {
      if (totPages <= 4) {
        var i = 1;
        while (i <= totPages) {
          pageArr.push(i);
          i++;
        }
      } else {
        if (currentPage <= 3) pageArr = [1, 2, 3, 4];
        else if (totPages - currentPage < 4)
          pageArr = [
            
            totPages - 3,
            totPages - 2,
            totPages - 1,
            totPages
          ];
        else
          pageArr = [
           
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage + 2,
           
          ];
      }
    }
    setPageArray(pageArr);
  }, [props.totPages, props.currentPage]);

  return (
    <React.Fragment>
      {props.children}
      <div style={{ margin: "0px" }}>
        <Pagination style={{ justifyContent: "center" }}>
          {pageArray.map((ele, ind) => {
            const toReturn = [];

            if (ind === 0) {
              toReturn.push(
                <Pagination.First
                  key={"firstpage"}
                  onClick={
                    props.currentPage === 1
                      ? () => {}
                      : () => {
                          props.pageClicked(1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Prev
                  key={"prevpage"}
                  onClick={
                    props.currentPage === 1
                      ? () => {}
                      : () => {
                          props.pageClicked(props.currentPage - 1);
                        }
                  }
                />
              );
            }

            if (ele === "") toReturn.push(<Pagination.Ellipsis key={ind} />);
            else
              toReturn.push(
                <Pagination.Item
                  key={ind}
                  active={props.currentPage === ele ? true : false}
                  onClick={
                    props.currentPage === ele
                      ? () => {}
                      : () => {
                          props.pageClicked(ele);
                        }
                  }
                >
                  {ele}
                </Pagination.Item>
              );

            if (ind === pageArray.length - 1) {
              toReturn.push(
                <Pagination.Next
                  key={"nextpage"}
                  onClick={
                    props.currentPage === ele
                      ? () => {}
                      : () => {
                          props.pageClicked(props.currentPage + 1);
                        }
                  }
                />
              );

              toReturn.push(
                <Pagination.Last
                  key={"lastpage"}
                  onClick={
                    props.currentPage === props.totPages
                      ? () => {}
                      : () => {
                          props.pageClicked(props.totPages);
                        }
                  }
                />
              );
            }

            return toReturn;
          })}
        </Pagination>
      </div>
    </React.Fragment>
  );
}
