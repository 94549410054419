import React, { PureComponent } from 'react';
import { AreaChart, Area, ComposedChart, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import '../inventory.css'



export default class RechartPLGraph extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/mixed-bar-chart-q4hgc';


  constructor(props) {
    super(props);

      this.state = {
          data:[],
          rawData:[]
      };

      this.gradientOffset = this.gradientOffset.bind(this)
    } 
     componentDidUpdate(prevProps, prevState) {
        if(this.props.data != prevProps.data ){
            this.setState({data:this.formatData(this.props.data), rawData:this.props.data})
        }
       
    }   

     componentDidMount() { 
        this.setState({data:this.formatData(this.props.data), rawData:this.props.data})
     }

     formatData = (data)=>{
        data.forEach(seriesData =>{
          seriesData['name'] = seriesData['start_date'] + " - " + seriesData['end_date']
          seriesData['total'] = Math.round((seriesData['order_costs'] + seriesData['amazon_costs'] + seriesData['shipping_costs'] + seriesData['sales_revenue'])*100)/100

        })
        return data
        
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          console.log(payload)
            return (
            <div className="custom-tooltip" style={{paddingRight:20}}>
            <p className="custom-tooltip-title"> {label} {this.props.titleKey}</p>
                {payload.map(v => 
                <div>
                        <div class='color-box' style={{backgroundColor:v['fill']}}/>
                        <p key={v['name']}>{v['name']}: ${v['value'].toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                </div>
                    
                )}
               
            </div>
            );
        }

        return null;
    };

     formatAxis(tickItem) {
        // If using moment.js
        return "$" + tickItem.toLocaleString(undefined, { minimumFractionDigits: 2 })
        }

   gradientOffset = () => {
    const dataMax = Math.max(...this.state.data.map((i) => i.total));
    const dataMin = Math.min(...this.state.data.map((i) => i.total));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

  return dataMax / (dataMax - dataMin);
};

  render() {
    return (
      <ResponsiveContainer className="PLChart" width="100%" height={700}>
        <ComposedChart
          width={500}
          height={800}
          data={this.state.data}
          margin={{
            top: 20,
            right: 30,
            left: 50,
            bottom: 50,
          }}
        >


          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"  angle={-45} textAnchor="end" interval = {0} />
          <YAxis interval={0} minTickGap={1000} tickCount={16}  tickFormatter={this.formatAxis}/>
          <Tooltip  content={<this.CustomTooltip />} />
          <Legend margin={{
            top: 50,
            right: 0,
            left: 0,
            bottom: 10,
          }}/>
          <Bar dataKey="sales_revenue" name = "Sales Revenue" fill="#82ca9d" />
          <Bar dataKey="order_costs" name = "Order Costs" stackId="a" fill="#58a6ff" />
          <Bar dataKey="amazon_costs" name = "Amazon Fees" stackId="a" fill="#ff9b58" />
          <Bar dataKey="shipping_costs" name = "Shipping Costs" stackId="a" fill="#ffb758" />
          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={this.gradientOffset()} stopColor="green" stopOpacity={1} />
              <stop offset={this.gradientOffset()} stopColor="red" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="total" name = "Total Profit" stroke="#000" fill="url(#splitColor)" />

        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}
