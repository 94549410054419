import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import EnsureModal from '../ensureModal.js'
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import { DismissableAlert } from "../dismissableAlert";

import { ItemMergeTab } from "./itemMerge.js";
import {onComponentAddRemote, onComponentDeleteRemote} from "./utilFunctions.js"
import '../inventory.css';

/**
 * 
 * @param {*} props.item (required)
 * @param {*} props.onHide (required)
 * @param {*} props.all_items (required)
 * @param {*} props.items_minified (required) 
 * @param {*} props.updateRootComponents (optional) Optional callback function to call when a component is deleted. 
 * @param {*} props.showMergePanels (required) boolean to show merge panels
 * @param {*} props.token (required) token to use for updating item
 * @returns 
 */
export const ComponentTab = function (props) {

    const [my_data, setData] = useState([]);
    const [componentsOfData, setComponentsOfData] = useState([]);
    const [itemSelectedForUnlink, setItemSelectedForUnlink] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)
    const [errorMessage, setErrorMessage] = useState("");
    const [show_merge_tab, setShowMergeTab] = useState(false);
    const [showType, setShowType] = useState("Child Components")

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    React.useEffect(() => {

        var tmp_data = props.item && props.item.components ? props.item.components : []
        setData(tmp_data);

        var tmp_data_2 = props.item && props.item.components_of ? props.item.components_of : []
        setComponentsOfData(tmp_data_2);

        if (tmp_data.length > 0) {
            setShowType("Child Components")
        } else if (tmp_data_2.length > 0) {
            setShowType("Parent Components")
        } else {
            setShowType("Child Components")
        }

    }, [props.item]);

    React.useEffect(() => {
        const timeout = null
        if (errorMessage != "") {
            const timeout = setTimeout(() => {
                setErrorMessage("")
            }, 5000)
        }

        return () => { if (timeout) { clearTimeout(timeout) } }
    }, [errorMessage])


    const componentAlreadyUsed = (component) => {

        for (var i = 0; i < my_data.length; i++) {
            if (my_data[i].child_group_id == component.group_id2) {
                return true
            }
        }
        return false
    }
    const componentAdded = (item, qty) => {
        if (componentAlreadyUsed(item)) {
            setErrorMessage("The chosen component is already assigned to this item. Please pick a different component.")
            return
        }
        var payload = { "parent_group_id": item.group_id1, "child_group_id": item.group_id2, "quantity": parseInt(qty), "item_sku": item.item_sku, "item_name": item.item_name, 'item_img_url': item.item_img_url, "stock_quantity": item.stock_quantity }

        onComponentAddRemote(payload, props.token).then(result => {
            if(result){
                setAlertMessage("Successfully added component. The quantity of this item may have changed as a result, and may take a few minutes to update.")
                setAlertType("success")
                setShowAlert(true)
                if(props.updateRootComponents){
                    props.updateRootComponents([payload, ...my_data], props.item, null)
                }
                setData([payload, ...my_data])
            }else{
                setAlertMessage("Failed to add component")
                setAlertType("danger")
                setShowAlert(true)
            }
        })
    }

    const my_handleSubmit_unmerge = () => {
        var payload = { "parent_group_id": props.item.group_id, "child_group_id": itemToDelete.child_group_id }

        onComponentDeleteRemote(payload, props.token).then(result => {
            if(result){
                setAlertMessage("Successfully deleted component. The quantity of this item may have changed as a result, and may take a few minutes to update.")
                setAlertType("success")
                setShowAlert(true)
                if(props.updateRootComponents){
                    props.updateRootComponents(my_data.filter(function (item) {
                        return item.child_group_id !== itemToDelete.child_group_id
                    }), props.item, itemToDelete)
                }
                setData(my_data.filter(function (item) {
                    return item.child_group_id !== itemToDelete.child_group_id
                }))
            }else{
                setAlertMessage("Failed to delete component")
                setAlertType("danger")
                setShowAlert(true)
            }
        })
        setItemSelectedForUnlink(false)
    }

    return (
        <div>
            <EnsureModal onSubmit={my_handleSubmit_unmerge} show={itemSelectedForUnlink} onHide={() => setItemSelectedForUnlink(false)}
                message="Are you sure you want to delete this component?" />

            <Modal.Body className="ModalBody LinkedItemModal" >
            <DismissableAlert show={showAlert} type={alertType} hideCallback={() => setShowAlert(false)} message={alertMessage} />
                <Row>
                    <Col sm={show_merge_tab ? 4 : 12} style={{ backgroundColor: "#fafafa" }}>
                        <Row style={{ padding: 10, paddingLeft: 30 }}>
                            <Col sm={10}>
                                <h2 style={{ fontSize: "1.15rem", paddingTop: "5px" }}>
                                    {showType}
                                </h2>
                            </Col>
                            {props.showMergePanels && showType === "Child Components" && <Col sm={2} style={{ textAlign: "right" }}>
                                <div title={props.item.is_regular ? (props.item.components_of && props.item.components_of.length > 0) ? "This item is a component of another item, and cannot have its own components" : "Click to add a component." : "Items sold on FBA only cannot have components"} >
                                    <Button variant="light" disabled={!props.item.is_regular || (props.item.components_of && props.item.components_of.length > 0)} onClick={(e) => { e.stopPropagation(); setShowMergeTab(!show_merge_tab) }} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6 }}><FontAwesomeIcon style={{ fontSize: "1.10rem" }} className="trashIcon" icon={faPlus} /></Button>
                                </div>
                            </Col>}
                        </Row>
                        <Row>

                            <small style={{ marginLeft: 10, marginBottom: 5, color: "gray" }}>{showType === "Child Components" ? "Components that make up this item" : "Items that use this item as a component"}</small>

                        </Row>
                        <hr style={{ margin: 0 }} />
                        <Row className="RowNoStretch" style={{ maxHeight: "550px", minHeight: "450px", overflowY: "auto" }}>

                            {showType === "Child Components" ?
                                my_data.length > 0 ? my_data.map(item => (
                                    <Row key={item.child_group_id} style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px", marginBottom: "10px" }}>

                                        {!show_merge_tab && <Col lg={1} xs={2}>
                                            {item.item_img_url && <img
                                                className="mr-3"
                                                src={item.item_img_url}
                                                alt="Item Image"
                                                style={{ maxWidth: 75, maxHeight: 80 }}
                                            />}
                                        </Col>}
                                        <Col>
                                            <h2 style={{ fontSize: "1rem" }}><Link to={"/inventory?selectedItem=" + item.child_group_id} target="_blank">{item.item_name}</Link></h2>
                                            <h3 style={{ fontSize: ".75rem" }}>{item.item_sku}</h3>
                                            <h3 style={{ fontSize: ".75rem" }}>Quantity: {item.quantity}</h3>
                                            <h3 style={{ fontSize: ".75rem" }}>Stock Quantity: {item.stock_quantity}</h3>

                                        </Col>
                                        {!show_merge_tab && props.showMergePanels ? <Col xs={1}>
                                            <Button variant="light" onClick={() => { setItemToDelete(item); setItemSelectedForUnlink(true) }}><FontAwesomeIcon className="trashIcon" icon={faTrash} title="Remove" /> </Button>
                                        </Col> : <></>}

                                    </Row>
                                ))
                                    :
                                    <Row style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
                                        <p>There are no child components for this item.</p>
                                    </Row>

                                :
                                componentsOfData.length > 0 ? componentsOfData.map(item => (
                                    <Row key={item.parent_group_id} style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px", marginBottom: "10px" }}>

                                        {!show_merge_tab && <Col lg={1} xs={2}>
                                            {item.item_img_url && <img
                                                className="mr-3"
                                                src={item.item_img_url}
                                                alt="Item Image"
                                                style={{ maxWidth: 75, maxHeight: 70 }}
                                            />}
                                        </Col>}
                                        <Col>
                                            <h2 style={{ fontSize: "1rem" }}><Link to={"/inventory?selectedItem=" + item.parent_group_id} target="_blank">{item.item_name}</Link></h2>
                                            <h3 style={{ fontSize: ".75rem" }}>{item.item_sku}</h3>
                                            <h3 style={{ fontSize: ".75rem" }}>Quantity:{item.quantity}</h3>

                                        </Col>
                                    </Row>
                                ))
                                    :
                                    <Row style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
                                        <p>There are no parent components for this item.</p>
                                    </Row>
                            }

                        </Row>
                    </Col>
                    {show_merge_tab && <Col sm={8}>
                        <Row style={{ padding: 10, paddingLeft: 30 }}>

                            <Col sm={12}>
                                <h2 style={{ fontSize: "1.15rem" }}>
                                    Select Component to Add
                                </h2>
                                <small>Double click an item to add it as a component</small>
                            </Col>

                            {my_data.length > 1 && props.showMergePanels && !show_merge_tab && <Col sm={4} style={{ textAlign: "right" }}>
                                <Button variant="secondary" onClick={() => setItemSelectedForUnlink(true)}>Unlink </Button>
                            </Col>}

                        </Row>
                        <hr style={{ margin: 0 }} />
                        <Row >

                            <ItemMergeTab items_minified={props.items_minified.filter(function (e) { return e.components.length === 0 && e.is_regular })}
                                item={props.item}
                                onHide={() => setShowMergeTab(false)}
                                onMergeSubmit={componentAdded}
                                type="Component"
                                onClose={() => { }} />

                        </Row>
                    </Col>}

                    {errorMessage &&
                        <p className="error"> {errorMessage} </p>}
                </Row>
            </Modal.Body>
            {!show_merge_tab && <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>}
        </div>
    )
}