import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useState , useEffect} from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import '../inventory.css';


const statusToColor = (status) => {
    var background = ""
    var title = status
    if (status == "ON_ORDER") {
        background = "warning"
        title = 'On Order'
    } else if (status == "RECEIVED") {
        background = "success"
        title = 'Recieved'
    } else if (status == "PARTIAL_RECEIVED") {
        background = "danger"
        title = "Partially Received"
    }
    return { "background": background, "title": title }
}

const statusToTextColor = (status) => {
    var background = ""
    if (status == "ON_ORDER") {
        background = "dark"
    } else {
        background = "light"
    }
    return background
}


const loadPurchaseOrderFromRemote = async (item, token, controller) => {
    const response = await axios.get('/api/get-all-store-orders-id/' + item.group_id, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }, signal: controller.current.signal
    })
    return response
}

/**
 * 
 * @param {*} props  token, item 
 */
export const PurchaseOrderTab = function (props) {

    const [loaded, setLoaded] = useState(true);
    const [orders, setOrders] = useState([])
    const controller = React.useRef(null)

    useEffect(() => {

        return () => {
            if (controller.current) {
                controller.current.abort()
            }
        };
    }, []);

    React.useEffect(() => {
        controller.current = new AbortController();
        getPurchaseOrders()
    }, [props.item]);

    const getPurchaseOrders = () => {
        if (!props.item) {
            return
        }
        setLoaded(false)
        console.log(props.item)
        loadPurchaseOrderFromRemote(props.item, props.token, controller)
            .then(function (response) {
                setLoaded(true)
                if (response.data) {
                    setOrders(response.data)
                } else {
                    setOrders([])
                }


            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    return;
                }
                console.log(error)
                setLoaded(true)
                setOrders([])

            });
    }
    return (
        <div>
            <Modal.Body className="ModalBody">
                {props.item.components && props.item.components.length > 0 && <p>This item has multiple components. Purchase orders for the item components are not listed below.</p>}
                {loaded ?
                    <Table striped bordered hover className=" fixedTable viewTable mediumTable" size="sm">
                        <thead style={{ textAlign: "center" }} >
                            <tr>
                                <th>Order Name</th>
                                <th>Order Date</th>
                                <th>Item Status</th>
                                <th>Order Qty</th>
                                <th>Outstanding Qty</th>
                                <th>View Order</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length > 0 ? orders.map((order, index) => (
                                <tr key={`${order.order_id}-${index}`}>
                                    <td>{order.order_name}</td>
                                    <td>{order.order_date}</td>
                                    <td style={{ textAlign: "center" }}><Badge text={statusToTextColor(order.items[0].order_status)} bg={statusToColor(order.items[0].order_status)['background']}>{statusToColor(order.items[0].order_status)['title']}</Badge></td>
                                    <td><div>E-Com: {order.items[0].qty}</div><div> FBA: {order.items[0].fba_qty}</div> <div>Cust: {order.items[0].cust_qty}</div></td>

                                    <td>{order.items[0].rec_qty}</td>

                                    <td> <Link to={"/order-details/" + order.order_id} target="_blank"><FontAwesomeIcon className="trashIcon" icon={faExternalLinkAlt} title="Go To Purchase Page" /></Link></td>
                                </tr>))
                                :
                                <tr>
                                    <td style={{ textAlign: "center" }} colSpan="6">There are no purchase orders for this item.</td>
                                </tr>
                            }

                        </tbody>
                    </Table>

                    : <Spinner animation="border" />}
            </Modal.Body>
        </div>
    )
}