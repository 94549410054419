import React, { useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';

function ColDropdown(props) {

    const [selected_col, setSelectedCol] = useState("Select Column");

    const onClickHandler = (name, key) => {
        if (name == "") {
            setSelectedCol("Select Column")
        } else {
            setSelectedCol(name)
        }
        props.setSelectedCol(key)

    }
    return (
        <DropdownButton variant="info" title={selected_col} style={{ marginLeft: 10 }}>
            <Dropdown.Item key={1} onClick={() => onClickHandler("", -1)}>&nbsp;</Dropdown.Item>
            {props.cols.map(col =>
                <Dropdown.Item key={col.key} onClick={() => onClickHandler(col.name, col.key)}>{col.name}</Dropdown.Item>

            )}
        </DropdownButton>

    )

}

export function FileModal(props) {

    const [selected_part_col, setSelectedPartCol] = useState(-1);
    const [selected_w1_col, setSelectedW1Col] = useState(-1);
    const [selected_w2_col, setSelectedW2Col] = useState(-1);
    const [show_alert, setAlertShow] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [button_text, setButtonText] = useState("Upload");
    const [isLoading, setLoading] = useState(false);


    const getVal = (row, idx) => {
        if (idx < 0) {
            return "No Column Selected"
        } else {
            return row[idx]
        }

    }

    useEffect(() => {
        setLoading(false)
        setButtonText("Upload")
        setButtonEnabled(true)

    }, [props.show])

    useEffect(() => {
        if (isLoading) {
            props.handleSubmit({ data: props.data, part_col: selected_part_col, w1_col: selected_w1_col, w2_col: selected_w2_col })
        }
    }, [isLoading]);

    const my_handleSubmit = () => {
        if (selected_part_col == -1 || (selected_w1_col == -1 && selected_w2_col == -1)) {
            setAlertShow(true)
        } else {
            setAlertShow(false)
            setButtonText("Uploading")

            setButtonEnabled(false)

            setLoading(true);

        }
    }
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
        >


            <Modal.Header closeButton>
                <Modal.Title >
                    <h2>File Upload</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert show={show_alert} variant="danger" onClose={() => setAlertShow(false)} dismissible>
                    Error! Must provide part number column and at least 1 custom value column
                </Alert>
                <h3>{props.data.name}</h3>

                {props.data.cols &&
                    <Table striped bordered responsive size="sm">
                        <thead>
                            <tr>
                                {props.data.cols.map(c =>

                                    <th key={c.key}>{c.name}</th>
                                )
                                }
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {props.data.rows[0].map((r, index) =>

                                    <td key={`0-${r}-${index}`}>{r}</td>
                                )
                                }
                            </tr>
                            <tr>
                                {props.data.rows[2].map((r, index) =>

                                    <td key={`1-${r}-${index}`}>{r}</td>
                                )
                                }
                            </tr>
                            <tr>
                                {props.data.rows[3].map((r, index) =>

                                    <td key={`2-${r}-${index}`}>{r}</td>
                                )
                                }
                            </tr>
                            <tr>
                                {props.data.rows[4].map((r, index) =>

                                    <td key={`3-${r}-${index}`}>{r}</td>
                                )
                                }
                            </tr>
                        </tbody>
                    </Table>
                }
                {props.data.cols &&
                    <Form onSubmit={props.my_handleSubmit} >
                        <Container>
                            <Row>
                                <Col>
                                        <Form.Group controlId="formValue" >

                                    <Form.Label>Column with part number:</Form.Label>
                                    <Form.Control as={ColDropdown} cols={props.data.cols} setSelectedCol={setSelectedPartCol} />
                                    <Form.Label>{getVal(props.data.rows[0], selected_part_col)}</Form.Label>

                                </Form.Group>
                                </Col>

                                <Col>
                                <Form.Group controlId="formValue2" >

                            <Form.Label>Column with C1 Qty:</Form.Label>
                            <Form.Control as={ColDropdown} cols={props.data.cols} setSelectedCol={setSelectedW1Col} />
                            <Form.Label>{getVal(props.data.rows[0], selected_w1_col)}</Form.Label>

                        </Form.Group>
                                </Col>

                                <Col>
                                <Form.Group controlId="formValue3" >

                            <Form.Label>Column with C2 Qty:</Form.Label>
                            <Form.Control as={ColDropdown} cols={props.data.cols} setSelectedCol={setSelectedW2Col} />
                            <Form.Label>{getVal(props.data.rows[0], selected_w2_col)}</Form.Label>

                        </Form.Group>
                                </Col>
                            </Row>


                        </Container>

                        

                        

                        

                    </Form>
                }
            </Modal.Body>
            <Modal.Footer>
                <p className="errorTable">{props.my_err}</p>
                <Button onClick={props.onHide}>Cancel</Button>
                <Button type="submit" onClick={my_handleSubmit} disabled={!buttonEnabled}>{button_text}</Button>
            </Modal.Footer>
        </Modal>
    );
}