import React, { Component }  from 'react';
import logo from '../logo.svg';
import axios from "axios";
import './inventory.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnsureModal from './ensureModal'
import SelectUSState from './selectUSState';

import {  faTrash, faLessThanEqual } from '@fortawesome/free-solid-svg-icons'

let endpoint = "";

 class GenericSettings extends Component{
   constructor(props) {
    super(props);
    this.endTime=React.createRef()
    this.address=React.createRef()
    this.city=React.createRef()
    this.store_state=React.createRef()
    this.zip=React.createRef()
    this.phone_num=React.createRef()
    this.fax_num=React.createRef()
    this.store_name=React.createRef()
    this.po_notes = React.createRef()

    this.state={}
   
   this.onSettingsSubmit=this.onSettingsSubmit.bind(this)

  }    


  getSettings=()=>{
    var com = this
     axios.get(endpoint + '/api/get-settings',
          {
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
         com.endTime.current.value=response.data['cutoff_time']
         com.address.current.value=response.data['address']
         com.phone_num.current.value=response.data['phone_num']

         com.fax_num.current.value=response.data['fax_num']

         com.store_name.current.value=response.data['store_name']
         com.city.current.value = response.data['city']
         com.zip.current.value = response.data['zip']
         com.store_state.current.value = response.data['state']
         com.po_notes.current.value = response.data['po_notes']

          
         
        })
        .catch(function (error) {
          console.log(error);
        });
           
            
  }


  onSettingsSubmit=(e)=>{
    e.preventDefault()
   
       var component = this;
        var payload={cutoff_time:this.endTime.current.value, address:this.address.current.value, city:this.city.current.value, state:this.store_state.current.value, zip:this.zip.current.value, phone_num:this.phone_num.current.value, fax_num:this.fax_num.current.value, store_name:this.store_name.current.value, po_notes:this.po_notes.current.value}
          axios.post(endpoint + '/api/update-settings',
          payload,{
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
        
          component.props.updateAlert({errorMessage:"Successfully Updated Settings",
                            showAlert:true,
                            isError:false})
          
        })
        .catch(function (error) {
            if(!error.response || error.response.status=="401"){
                component.props.updateAlert({errorMessage:"Error Updating Settings - Unknown Error",
                showAlert:true,
                isError:true})
            }else{
                component.props.updateAlert({errorMessage:"Error Updating Settings - Unknown Server Error.",
                showAlert:true,
                isError:true})
            
            }
           
            
        });

  }
      
  componentDidMount() { 
    this.getSettings()
   
  }

     


  
  render(){
     
      return (
          <div className="tabbed-content">
              <h2>Store Settings</h2>
              <hr></hr>
           
            
              <Form onSubmit={this.onSettingsSubmit}>                 
                    
                  <Form.Group as={Row}>
                    <Col>
                      <Form.Label>
                        Shipping Cutoff Time
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control style={{maxWidth:"150px"}} required ref ={this.endTime}
                           type="time"   pattern="[0-9]{2}:[0-9]{2}"
                      />
                    </Col>
                  </Form.Group>
                  <Row>
                    <Form.Text style={{marginLeft:"50px"}}>This time is used as the cutoff for sales numbers shown on the home page.</Form.Text>
                  </Row>

                  <Form.Group as={Row} style={{marginTop:20}}>
                    <Col>
                      <Form.Label>
                          Company Name
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control as="textarea" rows="1"  style={{maxWidth:"450px"}} required ref ={this.store_name }
                        
                      />
                    </Col>
                  </Form.Group>
                  
                  <Form.Group as={Row} style={{marginTop:10}} >
                    <Col>
                      <Form.Label>
                        Street Address
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control  style={{maxWidth:"450px"}} required ref ={this.address}
                        
                      />
                    </Col>
                  </Form.Group>   
                  <Form.Group as={Row} style={{marginTop:10}} >
                    <Col>
                      <Form.Label>
                        City
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control  style={{maxWidth:"450px"}} required ref ={ this.city}
                        
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginTop:10}} >
                    <Col>
                      <Form.Label>
                        State
                      </Form.Label>
                    </Col>
                    <Col sm="8" style={{textAlign:"left"}}>
                      <SelectUSState  in_ref ={this.store_state} className="stateSelector" />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginTop:10}} >
                    <Col>
                      <Form.Label>
                        Zip Code
                     </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control style={{maxWidth:"200px"}} pattern="[0-9]*"  maxLength="5" required ref ={this.zip }
                        
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginTop:10}}>
                    <Col>
                      <Form.Label>
                        Phone Number
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control type="string"  style={{maxWidth:"450px"}} required ref ={this.phone_num}
                        
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginTop:10}}>
                    <Col>
                      <Form.Label>
                        Fax Number
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Control type="string"  style={{maxWidth:"450px"}}  ref ={this.fax_num}
                          
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginTop:10}}>
                    <Col>
                      <Form.Label>
                        Purchase Order Notes
                      </Form.Label>
                    </Col>
                    <Col sm="8">
                    <Form.Control as="textarea" rows="2"  style={{maxWidth:"450px"}}  ref ={this.po_notes }
                          
                      />
                    </Col>
                  </Form.Group>
                    
                <Button type="submit" >Save</Button>
              </Form>
          </div>




      )
  }
 }

  export default GenericSettings;
  
  /*
  
  */