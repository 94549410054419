import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { ProfitTab, RevenueTab } from "./profitTab";
import { UnitTab } from "./unitTab";
import '../inventory.css';
import { TopSelling } from "./topSelling";
import { SalesGraphSettings } from "./salesGraphSettings";
import { createDate } from "../filters/dateFilter";

const ItemsSold = ({ itemsTotal, perSite, loaded, dataFound }) => {

    return (<div className="FinancialSummaryBox">
        <h2 style={{ fontSize: "1rem", textAlign: "left" }}>Items Sold</h2>
        {itemsTotal ? <>
            <p style={{ fontSize: "2rem", marginBottom: 0, textAlign: "left" }}>{itemsTotal.toLocaleString()}</p>
            <div style={{ padding: 10, textAlign: "left", height: "130px", overflowX: "hidden", overflowY: "auto" }} >
                {perSite && perSite.map((platform, index) => (
                    <Row key={platform['data_label']} style={{ backgroundColor: platform['data_color'] + "40" }}>

                        <Col xs={8}>
                            <p className="homeAlerts" >{platform['data_label']}: </p>
                        </Col>
                        <Col xs={4} style={{ textAlign: "right" }}>
                            <p className="homeAlerts" >{platform['total'].toLocaleString()}</p>
                        </Col>
                    </Row>
                ))}
            </div>
        </>
            : dataFound ? "No Data Selected" : loaded ? "No Matching Data Found" :
                <Spinner animation="border" />
        }
    </div>);
}


const AvgMargin = ({ revenue, profit, margin, loaded, dataFound }) => {
    return (<div className="FinancialSummaryBox">
        <h2 style={{ fontSize: "1rem", textAlign: "left" }}>Average Margin</h2>
        {margin ? <>
            <p style={{ fontSize: "2rem", marginBottom: 0, textAlign: "left" }}>{margin.toLocaleString()}%</p>
            <div style={{ padding: 10, textAlign: "left" }}>
                <Row style={{ backgroundColor: "#f6f6f6" }}>
                    <Col xs={6}>
                        <p className="homeAlerts">Profit: </p>
                    </Col>
                    <Col xs={6} style={{ textAlign: "right" }}>
                        <p className="homeAlerts">${profit.toLocaleString()} </p>
                    </Col>
                </Row>
                <Row style={{ backgroundColor: "white" }}>
                    <Col xs={6}>
                        <p className="homeAlerts">Revenue: </p>
                    </Col>
                    <Col xs={6} style={{ textAlign: "right" }}>
                        <p className="homeAlerts">${revenue.toLocaleString()} </p>
                    </Col>
                </Row>

            </div>
        </>
            : dataFound ? "No Data Selected" : loaded ? "No Matching Data Found" :
                <Spinner animation="border" />
        }
    </div>);
}

const reduceItemsSoldBySite = (monthly_totals) => {
    let perSite = [];
    let total = 0;
    if (!monthly_totals || Object.keys(monthly_totals).length < 1) {
        return { total, perSite };
    }
    monthly_totals.forEach((site) => {
        let site_total = 0
        site['data_points'].forEach((month) => {
            site_total += month['y'];
        });
        total += site_total;
        perSite.push({
            data_label: site['data_label'], total: site_total, data_color: site['data_color']
        });
    });
    monthly_totals.forEach((item) => {

    });

    perSite = perSite.sort(function (a, b) { return a['data_label'].toLowerCase().localeCompare(b['data_label'].toLowerCase()); })

    return { itemsTotal: total, perSite };
}

const reduceMarginsBySite = (montly_profit, monthly_revenue) => {
    let revenue = 0;
    let profit = 0;
    let margin = 0;
    if (!montly_profit || Object.keys(montly_profit).length < 1) {
        return { revenue, profit, margin };
    }
    montly_profit.forEach((site) => {
        let site_profit = 0;
        site['data_points'].forEach((month) => {
            site_profit += month['y'];
        });
        profit += site_profit;
    });

    monthly_revenue.forEach((site) => {
        let site_revenue = 0;
        site['data_points'].forEach((month) => {
            site_revenue += month['y'];
        });
        revenue += site_revenue;
    });

    return { revenue, profit, margin: Math.round((profit / revenue) * 1000) / 10 };
}



const ProductTabs = ({ ProfitandRev, topSelling, label,ProfitandRevLoaded, authorized  }) => {
    return (
        <>
        {authorized ? <Tabs
            defaultActiveKey="Profit"
            id="uncontrolled-tab-example"
            className="productTabs"
            justify
        >
           <Tab eventKey="Profit" title="Profit">
                <ProfitTab ProfitandRev={ProfitandRev} />
            </Tab>
            <Tab eventKey="Revenue" title="Revenue">
                <RevenueTab ProfitandRev={ProfitandRev} />
            </Tab>
            <Tab eventKey="Units" title="Units">
                <UnitTab ProfitandRev={ProfitandRev} />
            </Tab>
            <Tab eventKey="Top" title="Top Selling">
                <TopSelling topSelling={topSelling} weeks={8} label={label} ProfitandRevLoaded = {ProfitandRevLoaded} />
            </Tab>

        </Tabs> : <TopSelling topSelling={topSelling} weeks={8} label={label} ProfitandRevLoaded = {ProfitandRevLoaded} />}
        </>
    );
}

export const ProductOverview = ({ ProfitandRev, ProfitandRevLoaded, topSelling, reloadCallback, refreshTopSellingOnly, authorized }) => {


    const [originalData, setOriginalData] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [selectedElements, setSelectedElements] = useState([]);
    const [selectedDate, setDate] = useState([createDate(-180, 0, 0, true, true, false).toString(), createDate(0, 0, 0).toString()])
    const [label, setLabel] = useState("Items")
    const [labelType, setLabelType] = useState("Items")

    React.useEffect(() => {
        setOriginalData(ProfitandRev);
        setFilteredData(ProfitandRev);
        setSelectedElements(ProfitandRev.monthly_profit ? ProfitandRev.monthly_profit.map(element => { return element['data_label']; }).sort(function (a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()); }) : [])
    }, [ProfitandRev]);
    return (
        <div style={{ marginTop: 10 }}>
            <div className="RangePickerWrapper" >
                <SalesGraphSettings selectedDate={selectedDate} setDate={setDate} starting_selectedElements={selectedElements} onSearch={
                    (searchTerm, searchType, startDate, endDate) => {
                        setLabel(searchType == 'Vendor' ? "Vendors" : searchType == "Tag" ? "Tags" : "Items");
                        setLabelType(searchType == 'Vendor' ? "Vendors" : searchType == "Tag" ? "Tags" : "Items");
                        reloadCallback(searchTerm, searchType, startDate, endDate);
                    }

                } filterElements={ProfitandRev.monthly_profit} filterCallback={(elems) => {
                    setSelectedElements(elems);
                    setFilteredData(
                        {
                            monthly_profit: originalData['monthly_profit'].filter((data) => elems.includes(data.data_label)),
                            monthly_revenue: originalData['monthly_revenue'].filter((data) => elems.includes(data.data_label)),
                            monthly_totals: originalData['monthly_totals'].filter((data) => elems.includes(data.data_label)),
                            monthly_summary: originalData['monthly_summary']
                        }
                    )
                    if(elems.length === originalData['monthly_profit'].length && labelType == "Vendors"){
                        refreshTopSellingOnly("", "Vendor", selectedDate[0], selectedDate[1])
                        setLabel(labelType)
                    }else if ( labelType == "Vendors" && elems.length > 0){
                        refreshTopSellingOnly(elems.toString(), "VendorItem", selectedDate[0], selectedDate[1])
                        setLabel("Items For Selected Vendors")
                    }

                    if(elems.length === originalData['monthly_profit'].length && labelType == "Tags"){
                        refreshTopSellingOnly("", "Tag", selectedDate[0], selectedDate[1])
                        setLabel(labelType)
                    }else if ( labelType == "Tags" && elems.length > 0){
                        refreshTopSellingOnly(elems.toString(), "TagItem", selectedDate[0], selectedDate[1])
                        setLabel("Items For Selected Tags")
                    }
                }} />

            </div>

            <Container fluid>
                {authorized ? <Row style={{ marginBottom: 20 }}>
                    <Col md={6} sm={12}>
                        <ItemsSold {...reduceItemsSoldBySite(filteredData.monthly_totals)} dataFound={Object.keys(originalData).length > 0} loaded={ProfitandRevLoaded} />
                    </Col>
                    <Col md={6} sm={12}>
                        <AvgMargin {...reduceMarginsBySite(filteredData.monthly_profit, filteredData.monthly_revenue)} dataFound={Object.keys(originalData).length > 0} loaded={ProfitandRevLoaded} />
                    </Col>

                </Row> : <></>}
                <Row className="productTabRow">
                    <ProductTabs ProfitandRev={filteredData} topSelling={topSelling} label={label} ProfitandRevLoaded={ProfitandRevLoaded} authorized = {authorized}/>
                </Row>

            </Container>
        </div>
    );
}