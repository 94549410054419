import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import "../inventory.css";
import { createDate } from "../filters/dateFilter";

export const RangePicker  = ({input_startDate, input_endDate, callback}) => {
    const [dateRange, setDateRange] = useState([input_startDate, input_endDate]);
    const [startDate, endDate] = dateRange;
    return (
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        minDate = {createDate(-370,0,0)} 
        showIcon
        className = "form-control"
        todayButton="Jump To Today"
        showMonthDropdown
        onChange={(update) => {
          setDateRange(update);
          if(callback != undefined){
            callback(update)
          }
        }}
        isClearable={false}
      />
    );
  };