
import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
export const RestockHelp = () => (

    <OverlayTrigger

        rootClose
        trigger="click"
        placement="left"
        overlay={
            <Popover style={{ maxWidth: 400, overflowY: "scroll", maxHeight: "95vh", overflowX: "hidden" }} >
                <Popover.Header as="h3">How to Use this Page</Popover.Header>
                <Popover.Body>
                    <strong>Adding Items to Cart</strong>
                    <hr />
                    <p>The point of this screen is to add items to the order cart. Adding items to the cart allows you to add items to an order at a later point, allowing you to select items accross vendors now, and sort them into orders later.</p>

                    <strong>Selecting Items</strong>
                    <hr />
                    <p>To select an item to add to the cart, double click on the item. When the modal window pops up, modify the sku to include only what you want to appear on the order sheet, and input the quantity you wish to order. Then, hit 'save'. This will add it to your cart, which you can see in the right panel.</p>

                    <strong>Searching for Items/Non-Standard Items</strong>
                    <hr />
                    <p>To search for an item, type a search term in the search text box. Note how the table filters to meet your search critera. At any point, double click on an item to add that item to your order, or hit enter to add the top element to your order (if your search returns only 1 result, that will be the item added to the order). If your search returns no results, hitting enter will add a non-e-ecomerce item to your cart with the name you typed. When received, this item will not be tracked in inventory</p>

                    <strong>Sorting Data</strong>
                    <hr />
                    <p>On load, data is sorted first by when an item last sold (items sold in last week first, then items sold last week but not this week, and so on). Within the items that sold for a given week, the items are sorted alphabetically by title. To sort by a different column, click the column header.</p>

                    <strong>Upload Inventory Data</strong>
                    <hr />
                    <p>Drag a file into the drop box, or click to upload file. Follow on screen prompts to select the data columns.</p>

                    <strong>Export Data</strong>
                    <hr />
                    <p>Click 'Export CSV' to export the on-screen data as a csv.</p>
                </Popover.Body>
            </Popover>
        }
    >
        <Button style={{ float: "right", color: "black", marginRight: 15 }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faQuestion}></FontAwesomeIcon></Button>
    </OverlayTrigger>
)