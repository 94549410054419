import axios from "axios";

export const handleCartAddCallback = (existingItem, payload, itemupdate, itemcallback) => {
    if (!payload.item_cost) {
        payload.item_cost = 0
    }
    if (existingItem && existingItem != "") {
        itemupdate(payload, false)
    } else {
        itemcallback(payload)
    }
}


export const updateItemProperties = async (item, _updated_fields, token) => {
    var payload = { updated_fields: _updated_fields, updated_item: item }
    try {
        const response = await axios.put('/api/update-item/' + item.description_id,
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return true;
    } catch (error) {
        console.log(error)
        return false;
    }
}
export const updateItemPropertiesBulk = async (payload, token) => {
    try {
        const response = await axios.put('/api/update-items-new',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return true;
    } catch (error) {
        console.log(error)
        return false;
    }
}



export const updateItemQtyRemote = async (payload, token) => {
    try {
        const response = await axios.post('/api/update-item-qty',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return true;
    } catch (error) {
        console.log(error)
        return false
    }
}

export const unmergeItem = async (payload, token) => {
    try {
        const response = await axios.post('/api/split-item',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return true;
    } catch (error) {
        console.log(error)
        return false
    }

}


export const onMergeSubmitRemote = async (payload, token) => {
    try {
        const response = await axios.post('/api/merge-item',
            payload, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })
        return true;
    } catch (error) {
        console.log(error)
        return false
    }

}


export const onComponentAddRemote = async(payload, token) =>{
    try {
      const response = await axios.post( '/api/add-component',
        payload, {
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
      });
      return true;
    } catch (error) {
        console.log(error)
        return false
    }
  }


  export const onComponentDeleteRemote = async(payload, token) =>{
    try {
      const response = await axios.post( '/api/delete-component',
        payload, {
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
      });
      return true;
    } catch (error) {
        console.log(error)
        return false
    }
  }