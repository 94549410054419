import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from "react";
import { Tags } from "../tags.js";
import { v4 as uuidv4 } from 'uuid';
import { IndeterminateCheckbox } from '../inderterminateCheckbox.js'
import '../inventory.css';
import { updateItemPropertiesBulk} from "../ItemModal/utilFunctions";
import EnsureModal from '../ensureModal.js';


const checkTagEquality = (tl1, tl2) => {

    if (tl1.length != tl2.length) {
        return false
    }
    for (var i = 0; i < tl1.length; i++) {
        if (tl1[i].id != tl2[i].id) {
            return false
        }
    }
    return true
}
/**
 * 
 * @param {*} props.updateItemQty (optional) function to call after updating the item qty ONLY
 * @param {*} props.item (required) 
 * @param {*} props.onHide (required) function to hide modal
 * @param {*} props.my_updateItem (optional) function to call after updating the item
 * @param {*} props.allTags (required) list of all tags
 * @param {*} props.readOnly (required) whether or not the modal is read only
 * @returns 
 */
export const BulkEditModal = function ({ items, show, onHide, my_updateItem, allTags, token }) {
    var lead_time_ref = React.createRef();
    var days_of_supply_ref = React.createRef();
    var cost_val = React.createRef();
    var notes_val = React.createRef();
    var warehouse_loc = React.createRef();
    var overstock_loc = React.createRef();
    var item_vendor = React.createRef();
    var map_cost_ref = React.useRef(0);


    const [showBulkSureModal, setShowBulkSureModal] = useState(false)

    React.useEffect(() => {

        if (show) {
            setTags([])
            lead_time_ref.current.value = ""
            days_of_supply_ref.current.value = ""
            cost_val.value = ""
            notes_val.value = ""
            warehouse_loc.value = ""
            overstock_loc.value = ""
            item_vendor.value = ""
            map_cost_ref.current.value = ""
        }
    }, [show])


    const [tags, setTags] = useState([])

    const my_handleSubmit = (event) => {
        setShowBulkSureModal(false)

                var ret = []

                for (var i = 0; i < items.length; i++) {
        
                    var payload = { updated_fields: [], updated_item: items[i].original }
            
                    if (notes_val.value != "") {
                        payload.updated_item.item_notes = notes_val.value
                        payload.updated_fields.push('item_notes')
                    }
            
                    var cost_val_use = cost_val.value
                    var index = cost_val_use.indexOf("$")
                    if (index >= 0) {
                        cost_val_use = cost_val_use.substring(index + 1, cost_val.length)
                    }
                    if (cost_val_use.length == 0) {
                        cost_val_use = "0"
                    }
                    cost_val_use = parseFloat(cost_val_use)
            
                    if (cost_val_use != 0 && !Number.isNaN(cost_val_use)) {
                        payload.updated_item.cost_price = cost_val_use
                        payload.updated_fields.push('cost_price')
                    }

                   
                    if (warehouse_loc.value != "" ) {
                        payload.updated_item.item_warehouse_loc = warehouse_loc.value
                        payload.updated_fields.push('item_warehouse_loc')
                    }
                    if (overstock_loc.value != "") {
                        payload.updated_item.item_overstock_loc = overstock_loc.value
                        payload.updated_fields.push('item_overstock_loc')
                    }
            
                    
                    if (item_vendor.value != "") {
                        payload.updated_item.item_vendor = item_vendor.value
                        payload.updated_fields.push('item_vendor')
                    }

                    if (lead_time_ref.current.value != '') {
                        payload.updated_item.item_lead_time = parseInt(lead_time_ref.current.value)
                        payload.updated_fields.push('item_lead_time')
                    }

                    if (days_of_supply_ref.current.value != '') {
                        payload.updated_item.item_stock_weeks = parseInt(days_of_supply_ref.current.value)
                        payload.updated_fields.push('item_stock_weeks')
                    }
            
                  
            
                    var map_cost_val_use = map_cost_ref.current.value
                    var index = map_cost_val_use.indexOf("$")
                    if (index >= 0) {
                        map_cost_val_use = map_cost_val_use.substring(index + 1, map_cost_val_use.length)
                    }
                    if (map_cost_val_use.length == 0) {
                        map_cost_val_use = "0"
                    }
                    map_cost_val_use = parseFloat(map_cost_val_use)
            
                    if (map_cost_val_use != 0 && !Number.isNaN(map_cost_val_use)) {
                        payload.updated_item.map_cost = map_cost_val_use
                        payload.updated_fields.push('map_cost')
                    }
            
                  
            
            
                    if (tags.length !=0) {
                        payload.updated_fields.push('item_tags')
                        payload.updated_item.item_tags = tags
                    }
                    if (payload.updated_fields.length) {
                        ret.push(payload)
                    }
                  
                }
                if (ret.length>0) {
                    updateItemPropertiesBulk(ret, token).then((retVal) => {
                        if(my_updateItem){
                            my_updateItem(ret, retVal)
                        }
                    })
                }
        onHide();
    }




    return (
        <>
        <EnsureModal onSubmit={my_handleSubmit} show={showBulkSureModal} onHide={() => setShowBulkSureModal(false) }
           message={"Warning! You are about to bulk edit " + items.length + " items. This action cannot be undone. Are you sure you want to continue?"} />
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"

            animation={false}
        >
            <div>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>Bulk Item Edit</h2>
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={(e)=>{e.preventDefault(); setShowBulkSureModal(true)}} className="ItemModalDetailsForm">
                    <Modal.Body className="ModalBody">
                        <Row>
                            <Form.Group as={Col} controlId="formValue16c">
                                <Form.Label>Vendor</Form.Label>
                                <Form.Control ref={(input) => item_vendor = input} type="string" placeholder="Vendor" />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formValue8">
                                <Form.Label>Purchase Cost</Form.Label>
                                <Form.Control ref={(input) => cost_val = input} placeholder="Cost Price" type="string" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formValue8">
                                <Form.Label>MAP Cost</Form.Label>
                                <Form.Control ref={map_cost_ref} placeholder="MAP Cost" type="string" />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} controlId="formValue125">
                                <Form.Label>Warehouse Location</Form.Label>
                                <Form.Control ref={(input) => warehouse_loc = input} type="string" placeholder="Warehouse Location" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formValue126">
                                <Form.Label>Overstock Location</Form.Label>
                                <Form.Control ref={(input) => overstock_loc = input} type="string" placeholder="Overstock Location" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formValue126">
                                <Form.Label>Lead Time</Form.Label>
                                <Form.Control ref={lead_time_ref} type="number" placeholder={0} min={0}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formValue126">
                                <Form.Label>Days of Supply</Form.Label>
                                <Form.Control ref={days_of_supply_ref} type="number" placeholder={0} min={0}/>
                            </Form.Group>
                        </Row>

                        <Row style={{ padding: 10 }}>
                            <Form.Group as={Col} sm={12} style={{ padding: 0 }} controlId="formtags">
                                <Form.Label>Item Tags</Form.Label>
                                <Tags suggestTags={allTags} tags={tags} setTags={setTags} onChange={(new_tags) => setTags(new_tags)} />
                            </Form.Group>

                        </Row>
                        <Row style={{ paddingLeft: 5 }}>
                            <Form.Group as={Col} sm={9} controlId="formValue18">
                                <Form.Label>Item Notes</Form.Label>
                                <Form.Control size="sm" as="textarea" maxLength="512" rows={4} ref={(input) => notes_val = input} type="string" placeholder="Item Notes" />
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide}>Cancel</Button>
                        <Button type="submit" >Edit {items.length} Items</Button>
                    </Modal.Footer>
                </Form>
            </div>
        </Modal>

        </>
    )
}