import React , {useState} from "react";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { FilterSegment } from "./filterSegment";
import '../inventory.css';




const getAllLeafFilters=(leafs,filterList)=>{
    if(filterList.filters){
        filterList.filters.forEach(function(filter){getAllLeafFilters(leafs, filter)})
    }else{
        leafs.push(filterList)
    }
}

const BuildFilter=({filterList,filterCallback,filterStates,changeFilterState, disabledList})=>{
    if(filterList.filters){
        return (
            <div className="FilterSection" key={filterList.id}>
                {filterList.label.length > 0 ? <span id="filterListLabel">{filterList.label}</span>:<></>}
                {filterList.filters.map(filter=>
                    <BuildFilter key ={filter.id} filterList={filter} filterCallback = {filterCallback} filterStates={filterStates} changeFilterState={changeFilterState} disabledList={disabledList}/>
                    )}
            </div>
        )
        
    }else{
        return <FilterSegment key={filterList.id} filterSeg={filterList} filterCallback = {filterCallback} value={filterStates[filterList.id]} stateChange={changeFilterState} disabledList={disabledList}/>
    }
}



export const FilterMenu = ({inputFilters,filterCallback, refreshFilterCallback, disabledList={}}) => {

    const createInitialFilters = (filters) =>{
        var leafs = []
        getAllLeafFilters(leafs, filters)
        var ivs = {}
        leafs.forEach(leaf =>{
            ivs[leaf.id] = leaf.initialValue
        })
        return ivs
        
    }

    const [filterStates, _setFilterSates] = useState(() =>createInitialFilters(inputFilters))

    const filterStates_ref = React.useRef(filterStates)

    const [refreshState, setRefreshState]=React.useState(refreshFilterCallback)

    const setFilterSates = data => {
        filterStates_ref.current = data
        _setFilterSates(data)
    }




  

    const changeFilterState = (filter, newState, callback, subFilter )=>{
        var tmpStates = {...filterStates_ref.current}
       
        if(filter !== undefined){
            tmpStates[filter.id] = newState
        }
    
       
        Object.keys(tmpStates).forEach(function(key, index) {

            if( typeof  tmpStates[key] === "function"){
                tmpStates[key]= tmpStates[key]()
            } 
          
          });
        setFilterSates(tmpStates)
      
        callback(tmpStates,filter, subFilter )
    }

    React.useEffect(()=>{
        
        if(refreshState !== refreshFilterCallback){
            setRefreshState(refreshFilterCallback)
        

            changeFilterState(undefined, undefined, filterCallback, undefined)
        }

    }, [refreshFilterCallback])


    return(
    <OverlayTrigger
        trigger="click"
        rootClose

        placement="bottom"
        overlay={
            <Popover id={`popover-positioned-bottom`} style={{ width: 400 }}>
                <Popover.Header as="h3">Filters</Popover.Header>
                <Popover.Body style={{padding:"5px"}}>

                    <div className="">
                        <BuildFilter filterList={inputFilters} filterCallback = {filterCallback} filterStates={filterStates} changeFilterState={changeFilterState} disabledList={disabledList}/>
                    </div>
                </Popover.Body>
            </Popover>
        }
    >

        <Button title="Filters" style={{ float: "right", color: "black", marginRight: "15px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faFilter} /></Button>
    </OverlayTrigger>
)
    }