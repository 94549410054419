
import React from "react";
import { useState, useEffect } from "react";

import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-bootstrap/Modal';

import { LightningTable } from '../../lightningTable/lightning-table'
import { LightningKit } from '../../lightningTable/lightning-kit'
import { LightningSearch } from '../../lightningTable/lightning-search'

import { ItemCartTab } from "../ItemModal/itemCart";


const columns = [{
  accessorKey: 'item_name',
  header: 'Item',
  enableSorting: true,
  width: .6

}, {
  accessorKey: 'item_sku',
  header: 'SKU',
  enableSorting: true,
  width: .4
}, {
  accessorKey: 'alternate_names',
  header: 'Alternate Names',
  enableSorting: false,
  hidden: true,
  width: .1
}, {
  accessorKey: 'alternate_skus',
  header: 'Alternate Skus',
  enableSorting: false,
  hidden: true,
  width: .1
}
];

const defaultSorted2 = [{
  id: 'item_name',
  desc: false
}];


export function AddItemToCartModal(props) {
  const [customSku, setCustomSku] = useState("");

  const [selected_item, setSelectedItem] = useState({});

  const [filteredItems, setFilteredItems] = useState({});
  const [enabledSKU, setEnabledSKU] = useState(false)

  const options = {
    paginationSize: 5,
    sizePerPage: 50,
    pageStartIndex: 0,

    showTotal: true,
    sizePerPageList: [{
      text: '50', value: 50
    }, {
      text: '100', value: 100
    }, {
      text: '250', value: 250
    }]

  }


  var handleCustomItem = (e) => {
    e.preventDefault()
    setEnabledSKU(true);
    setSelectedItem({ 'item_sku': customSku, 'custom': true, 'group_id': uuidv4(),'buy_pack_qty': 1, 'sale_pack_qty': 1, 'item_img_url': "" })

  }

  var handleItem = (e) => {
    e.preventDefault()
    handleEnterOnSearch(customSku)

  }

  var handleEnterOnSearch = (typed_val) => {
    if (Object.keys(filteredItems).length < 1) {
      setEnabledSKU(true);
      setSelectedItem({ 'item_sku': typed_val, 'custom': true, 'group_id': uuidv4(),'buy_pack_qty': 1, 'sale_pack_qty': 1, 'item_img_url': "" })
    } else {
      setEnabledSKU(true);
      setSelectedItem(filteredItems)
    }
  }

  const afterSearch = (filter, newRows) => {
    setCustomSku(filter)
    var tmp = newRows.map(row => { return row.original })
    if(tmp.length>0){
      setFilteredItems(tmp[0]);
    }else{
      setFilteredItems({})
    }
    
  };


  const clearFields = () => {
    setCustomSku("")
    setEnabledSKU(false)
    setSelectedItem({})
    setFilteredItems([])
  }


  return (


    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >


      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h1>Add Item</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form onSubmit={props.my_handleSubmit} >

          <Form.Group controlId="formValue">
            <LightningKit columns={columns} pageOptions={options} getRowCanExpand={() => false} in_data={props.data} width="100%" afterFilter={afterSearch}>
              {
                props => (
                  <div>

                    <div id="table">
                      <div id="search-instruct">
                        {/* <small>Enter a search term, then hit enter to add the first item in the list, or a custom item if there are no results. Or, double click on a row to select the item. Then, edit the SKU to display on the order form in the box below.</small>*/}
                      </div>
                      <div id="search" className="smallSearch">
                        <Form.Label style={{ paddingRight: 10 }}>Search Term:</Form.Label>
                        <LightningSearch className="AddOtherItemSearch" {...props.searchProps} focus={true} onEnterFunc={handleEnterOnSearch} />
                        <Button style={{ height: "40px", marginLeft: 10, fontSize: ".75rem", padding: "3px" }} disabled={Object.keys(filteredItems).length < 1} variant="secondary" onClick={handleItem}>Add Item</Button>
                        <Button style={{ height: "40px", marginLeft: 10, fontSize: ".75rem", padding: "3px" }} variant="secondary" onClick={handleCustomItem}>Add Custom</Button>
                      </div>


                      <LightningTable
                        className="lightningFixedTable smallFont"
                        height="30vh"
                        {...props} {...props.table}
                        noDataIndication="No Data to Show"
                        onRowDoubleClick={(_row) => {
                          var row = _row.original                         
                          setEnabledSKU(true);
                          setSelectedItem(row)
                        }}
                      />


                    </div>
                  </div>
                )
              }

            </LightningKit>
          </Form.Group>
          </Form>

          
           {enabledSKU && <>
           {selected_item.custom ? <h3>Custom Item</h3> : <></>}
           <ItemCartTab
              item={selected_item}
              onHide={ ()=> {clearFields(); props.onHide() }}
              handleCartAdd={(payload, ei) =>{props.my_handleSubmit(payload);clearFields()} }
              saveButtonText="Add To Order"

          /></>}
        


      </Modal.Body>
    </Modal>
  );
}