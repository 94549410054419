import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class NotFound extends Component {
  render(){
    return(
      <div className="wrapper">
        
        
          <div className="errorContainer container">
            <h1 className="errorHeader">Error. Page not Found</h1>
          </div>
      </div>
    )
  }
}

export default NotFound;  