 import React, { Component,useCallback,useMemo } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';

import SalesGraphSettings  from './salesGraphSettings.js'

import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import HomeChartRadial from './charts/homeChartRadial.js';
import RechartLineGraph from './charts/rechartLineGraph.js'

import Spinner from 'react-bootstrap/Spinner';
import {withRouter} from './withRouter';

import './inventory.css';

import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';


import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {  faQuestion, faDownload ,faSlidersH } from '@fortawesome/free-solid-svg-icons'


let endpoint = ""


const ItemSummaryList = ({items, setSelectedItemCallback, chartID, selectedItem, money, label}) => (
  <>

  <Table striped borderless size="sm" style={{fontSize:".75rem"}}>
    <thead>
      <tr>
        <th>{label.split(" ").pop().slice(0, -1)}</th>
        <th>{money? "Profit" : "Qty Sold"}</th>
        <th>Percent</th>
      </tr>
    </thead>
    <tbody>
    {items && items.map(item => (
      <tr key = {item.id} /*onMouseOver={setSelectedItemCallback}*/ data-info={JSON.stringify({"chartID":chartID, "itemID":item.id})} className={selectedItem == item.id? "hoveredtablerow":"nonhovertablerow"}>
        <td>{item.label}</td>
        <td>{money? "$" : ""}{item.theta? money? item.theta.toLocaleString(undefined, { minimumFractionDigits: 2 }): item.theta.toLocaleString():"XX.XX"}</td>
        <td>{item.percent}%</td> 
      </tr>

    ))}
    </tbody>
  </Table>
  
    
  </>
  
);



class ItemSalesSummary extends Component {
      _isMounted = false;

 constructor(props) {
    super(props);


  
    
    
    this.state = {
       summary:{},
       selectedItem:{},
       graphType:false,
       showSettings:false,
       label:"20 Items",
       weeks:"8"
    };
    
    this.setSelectedItemCallback=this.setSelectedItemCallback.bind(this)
    this.chartSelectedItem = this.chartSelectedItem.bind(this)

  }    
componentWillUnmount() {
  if (this.controller) {
    this.controller.abort()
  }
    this._isMounted = false
  }

getItemSalesStats = (search_term, search_type, search_weeks) => {
  let currentComponent=this;

  

    this.setState({errorMessage :"Loading Data. Please Wait...", summary: {}})
    var url = "/api/get-item-sales-data"
    axios.post(endpoint + url, {"search_term":search_term, "search_type":search_type, "search_weeks":search_weeks},
    {
    headers: {
      Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
    },      signal: this.controller.signal
    }).then(res => {
      if (res.data) {
        
        this.setState({
          summary: res.data, label: search_type=='Vendor'? "Vendors" : "20 Items" , weeks:search_weeks})
      } else {
        currentComponent.setState({
          summary: {}
          
        });
      } 
    }).catch(error => { 
    // handle error 
      if (axios.isCancel(error)) {
        return;
      }
    if (error.response){
                if(error.response.status===401){
                    this.props.setLogcallback("","")
                    this.props.logincallback("Error! You have been logged out. Please log back in")
                    currentComponent.props.navigate('/index.html')
                }
            }else{
                currentComponent.setState({
                    summary: [],
                    errorMessage: "Server Error. Could not fetch orders"
                });
            }
  }); 
    
}
  
     
   componentDidMount() { 
    this.controller= new AbortController();

      this._isMounted = true;
  
    this.getItemSalesStats("", "__Default", "8");
     
    
  } 

  setSelectedItemCallback(e){
    var tempItems = this.state.selectedItem
    tempItems[JSON.parse(e.currentTarget.dataset.info).chartID]=JSON.parse(e.currentTarget.dataset.info).itemID
    this.setState({selectedItem:tempItems})
  }
  chartSelectedItem(item, chartID){
    var tempItems = this.state.selectedItem
    tempItems[chartID]=item.id
    this.setState({selectedItem:tempItems})
  }
  
    render() {

            
            
  
    return (
    
   
    <div className=" wrapper fullPageWrapper"> 

          <div className="customtable widetable graphDivBox" style={{marginBottom:"10px"}}>
              <div className="left-align headerBox" style={{height:"60px"}}> 
              <h2 style={{float: "left"}}>Platform Sales Data</h2>

              <Button disabled={!this.state.summary.monthly_revenue && Object.keys(this.state.summary).length <= 0} onClick={()=>this.setState({showSettings:!this.state.showSettings})} style={{ float: "right", color: "black", marginRight:"15px",marginLeft:"20px", height:"38px" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faSlidersH} /></Button>
              {this.state.summary.monthly_revenue ? <BootstrapSwitchButton
                  checked={this.state.graphType}
                  onlabel='Stacked Graph'
                  offlabel='Line Graph'
                  onstyle="primary" 
                  offstyle="secondary"
                  width="150"
                  height="31"
                  onChange={(checked) => {
                      this.setState({ graphType: checked })
                  }}
              /> : <></>}
              </div>
              <div className="grayheader headerBox" style={{display: this.state.showSettings? "block":"none"}}> 
                 <SalesGraphSettings onSearch={this.getItemSalesStats} token = {this.props.token}/>
              </div> 
              
              <div style={{clear:"both"}} className="SalesStats tablerow">
                {this.state.summary.monthly_revenue ?
                <div>
                <Container fluid>
                <Row>
                <Col md={6}>
                <RechartLineGraph titleKey="Profit" syncId="mgraph" type={this.state.graphType} title="Monthly Profit" data={this.state.summary.monthly_profit}/>
                </Col>
                <Col md={6}>
                <RechartLineGraph titleKey="Revenue"  syncId="mgraph" type={this.state.graphType} title="Monthly Revenue" data={this.state.summary.monthly_revenue}/>
                </Col>
                </Row>
                </Container>
                </div>
                 : Object.keys(this.state.summary).length > 0 ?  <><span>You do not have permission to view certain data on this page.</span></>:  <><span><b><em>Generating data. This may take a while.</em></b></span> <Spinner animation="border" /></>}
              </div>
            </div>

          <div className="customtable widetable" style={{marginBottom:"20px"}}>
          <h2 className="left-align headerBox">Top Item Selling Data</h2>
          <div className="SalesStats tablerow">
         
           {Object.keys(this.state.summary).length > 0 ?
           
              
            <Container fluid>
              <Row>
                <Col lg={4} md={12} style={{borderRight:"2px solid lightgray", paddingLeft:30, paddingRight:30}} >
                <Row style={{justifyContent:"center"}}>
                  <div className="PieWrapper">
                    
                     {this.state.summary.top_number && <div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                       {this.state.weeks} Week Sales - Top {this.state.label} By Quantity
                      </small>
                    </div>}
                   <HomeChartRadial data={this.state.summary.top_number} padAngle ={0.01} delimiter={"Sold "} text={"Percent of Quantity"} selectedItem={this.state.selectedItem['8weeksale']} setSelectedItem={this.chartSelectedItem} chartID={"8weeksale"}/>
                  </div>
                  </Row>
                  <Row>
                  <ItemSummaryList label ={this.state.label} items={this.state.summary.top_number} setSelectedItemCallback={this.setSelectedItemCallback} chartID={"8weeksale"} selectedItem={this.state.selectedItem['8weeksale']} money={false} />
                  </Row> 

                </Col>
                
                <Col lg={4} md={12} style={{borderRight:"2px solid lightgray",paddingLeft:30, paddingRight:30}} > 
                <Row style={{justifyContent:"center"}}>
                  <div className="PieWrapper">
                    
                     {this.state.summary.top_profit_recent &&<div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                       {this.state.weeks} Week Sales - Top  {this.state.label} By Profit
                      </small>
                    </div>}
                   <HomeChartRadial data={this.state.summary.top_profit_recent} padAngle ={0.01} selectedItem={this.state.selectedItem['8weeksaleprofit']} setSelectedItem={this.chartSelectedItem} chartID={"8weeksaleprofit"}/>
                  </div>
                  </Row>
                  <Row>
                  <ItemSummaryList label ={this.state.label}  items={this.state.summary.top_profit_recent} setSelectedItemCallback={this.setSelectedItemCallback} chartID={"8weeksaleprofit"} selectedItem={this.state.selectedItem['8weeksaleprofit']} money={true}/>
                  </Row>
                </Col>
                <Col  lg={4} md={12} style={{paddingLeft:30, paddingRight:30}} >
                <Row style={{justifyContent:"center"}}>
                   <div className="PieWrapper">
                    
                     {this.state.summary.top_profit &&<div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                      Lifetime Sales - Top  {this.state.label} By Profit
                      </small>
                    </div>}
                   <HomeChartRadial data={this.state.summary.top_profit} padAngle ={0.01}  selectedItem={this.state.selectedItem['totalsaleprofit']} setSelectedItem={this.chartSelectedItem} chartID={"totalsaleprofit"}/>
                  </div>
                  </Row>
                  <Row>
                  <ItemSummaryList label ={this.state.label}  items={this.state.summary.top_profit} setSelectedItemCallback={this.setSelectedItemCallback} chartID={"totalsaleprofit"} selectedItem={this.state.selectedItem['totalsaleprofit']} money={true}/>
                  </Row>
                </Col>
              </Row>
            </Container>
           
           
            
           
            :<><span><b><em>Generating data. This may take a while.</em></b></span> <Spinner animation="border" /></>}
             
            </div>
            </div>
            

    </div>
);}}

export default withRouter(ItemSalesSummary);
