import React, { Component } from 'react';
// eslint-disable-next-line
import { Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Logo from '../../assets/Logo-C.png'
import './header.css';
import { faShoppingCart, faHistory, faUserCircle, faRotate } from '@fortawesome/free-solid-svg-icons'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Media from '../Media.js';
import { withTimer } from '../withTimer';
import { SearchBar } from './searchBar';





const UpdateList = ({ items }) => (
  <>

    {items.map(item => (
      <Card key={item.action_id} className="updateItems" bg="light" >
        <Card.Header className="tightHeader">
          <Media>
            <div style={{ width: 65, height: "100%" }} className="align-self-center mr-3">
              <img

                className="align-self-center mr-3"
                src={item.item_img_url}
                alt="Item Image"
                width="50"
                style={{ maxHeight: 50 }}
              />
            </div>
            <div>
              <Container fluid>
                <Row>
                  <Card.Title style={{ textAlign: "left" }}>{item.item_name}</Card.Title>
                </Row>

              </Container>
            </div>
          </Media>
        </Card.Header>
        <Card.Body style={{ padding: 5 }}>
          <Container fluid>
            <Row>
              <Col>{item.description}</Col>

            </Row>
            <Row>
              <Col><small><strong> Updated By: {item.user}</strong></small></Col>
              <Col className="align-right"><small><strong> Updated On: {item.time}</strong></small> </Col>
            </Row>
          </Container>


        </Card.Body>
      </Card>
    ))}
  </>

);

const UpdateLogList = ({ items }) => {
  
  return(
  <>

    {items.length > 0 ? items.map(item => (
      <Card key={item.item_sku} className="updateItems" bg="light" >
        <Card.Header className="tightHeader">

          <Container fluid>
            <Row>
              <Card.Title style={{ textAlign: "left" }}>{item.item_sku}</Card.Title>
            </Row>

          </Container>
        </Card.Header>
        <Card.Body style={{ padding: 5 }}>
          <Container fluid>
            <Row>
              <Col>Quantity changed by {item.item_qty_delta}. New quantity is {Math.max(0, item.item_qty_db + item.item_qty_delta)}</Col>

            </Row>
            <Row>
              <Col><small><strong> Updated By: {item.user}</strong></small></Col>
            </Row>
          </Container>


        </Card.Body>
      </Card>
    ))

      :
      <p>
        <em>
          No pending updates
        </em>

      </p>
    }
  </>

)};

const UpdateLog = ({ syncStatus, SyncTimer }) => (
  <OverlayTrigger
    trigger="click"
    placement='bottom'
    rootClose
    overlay={
      <Popover style={{ maxWidth: 600, overflowY: "scroll", maxHeight: "50vh", overflowX: "hidden" }} >
        <Popover.Header as="h3">
          <Container fluid>
            Ecommerce Sync

          </Container>
        </Popover.Header>
        <Popover.Body style={{ maxHeight: "50vh", overflowX: "hidden" }}>
          <Row>
            {SyncTimer < 1 ?
              <p style={{ marginBottom: "2px" }}>
                <b>
                  Sync in progress...
                </b>

              </p>
              :
              <p style={{ marginBottom: "2px" }}>
                <b>
                  Time until next sync:
                </b> {SyncTimer > 60 ? Math.floor(SyncTimer / 60) > 1 ? `${Math.floor(SyncTimer / 60)} minutes` : `${Math.floor(SyncTimer / 60)} minute` : `${SyncTimer} seconds`}
              </p>
            }

          </Row>
          <hr></hr>
          <b>
            Pending Updates:
          </b>
          <UpdateLogList items={syncStatus.pending_updates ? JSON.parse(syncStatus.pending_updates) : []} />



        </Popover.Body>
      </Popover>
    }
  >
    <Button className="ButtonTop ButtonLeft" style={{right:100}}variant="link">
      <FontAwesomeIcon className="cartIcon" icon={faRotate} spin={SyncTimer < 1} />
    </Button>
  </OverlayTrigger>
)



const HistoryIcon = (props) => {

  const [show, setShow] = React.useState(false)

  return (
    <OverlayTrigger
      trigger="click"
      placement='bottom'
      rootClose
      onToggle={(next_state) => setShow(next_state)}
      show={show}
      overlay={
        <Popover style={{ maxWidth: 600 }} >
          <Popover.Header as="h3">
            <Container fluid>
              <Row>
                <Col style={{ padding: ".5rem 1rem" }}>
                  Recent Item Updates
                </Col>
                <Col>
                  <Nav.Link onClick={() => setShow(false)} as={Link} className="align-right HistoryAllLink" to='/all-updates'>View All</Nav.Link>
                </Col>

              </Row>
            </Container>
          </Popover.Header>
          <Popover.Body style={{ overflowY: "scroll", maxHeight: "50vh", overflowX: "hidden" }}>
            {props.serverItemUpdates ? <UpdateList items={props.serverItemUpdates} /> : "Error Fetching Updates"}
            <Card className="updateItems" bg="light" >
              <Card.Header onClick={props.loadMoreCallback} className="tightHeader loadMoreUpdates">
                Load More
              </Card.Header>
            </Card>
          </Popover.Body>
        </Popover>
      }
    >
      <Button className="ButtonTop ButtonLeft" variant="link">
        <FontAwesomeIcon className="cartIcon" icon={faHistory} />
      </Button>
    </OverlayTrigger>)
}

const UserSection = ({ username, logoutCallback }) => {
  if (username != "") {
    return (
   <NavDropdown style={{ marginTop: 5 }} title={<FontAwesomeIcon className="cartIcon" icon={faUserCircle} />} id="basic-nav-dropdown">
      <NavDropdown.Item disabled >Hi {username.toLowerCase()}</NavDropdown.Item>
      <Dropdown.Divider />

      <LinkContainer className="linkContainer" to="/user-settings">
        <NavDropdown.Item >Settings</NavDropdown.Item>
      </LinkContainer>

      <NavDropdown.Item onClick={logoutCallback} >Log Out</NavDropdown.Item>

    </NavDropdown>
    )
  } else {
    return (<Navbar.Text>You are not logged in</Navbar.Text>)
  }
}

class StaticHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedOut: false,
      loggedIn: "here",
      syncStatus: {},
      timerToggle: false,
      timerValue: 0,
    };

  }

  componentDidMount() {

    this.props.updateClock(this.state.syncStatus.time_till_next)

  }
  componentDidUpdate(prevProps, prevState) {

    if (prevProps.serverItemUpdates != this.props.serverItemUpdates) {
      this.setState({ serverItemUpdates: this.props.serverItemUpdates });
    }

    if (prevProps.syncStatus != this.props.syncStatus) {

      this.setState({ syncStatus: this.props.syncStatus, timerToggle: !this.state.timerToggle });
      this.props.updateClock(this.props.syncStatus.time_till_next)
    }
  }
  doLogOut = (data, usrname) => {
    this.setState({ loggedIn: "" })
    this.props.callback("", "");


  }

  handleClick = () => {
    this.props.openCart();
  }


  render() {

    return (

      <Navbar expand="md" className="front_page_nav mr-auto" variant="dark" fixed="top" collapseOnSelect>

        <Navbar.Brand>
          <Link to="/"><img className="logo" src={Logo} alt="logo" /></Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" >

          <Nav className="mr-auto">
            <Nav.Link as={Link} className="homeLink" to='/'>Home</Nav.Link>
            <NavDropdown title="Purchase Orders" id="basic-nav-dropdown">
              <LinkContainer className="linkContainer" to="/past-orders">
                <NavDropdown.Item >Existing Orders</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/order-edit">
                <NavDropdown.Item >Create Order</NavDropdown.Item>
              </LinkContainer>

            </NavDropdown>
            <Nav.Link as={Link} className="homeLink" to='/finances'>Finances</Nav.Link>
            {/*<NavDropdown title="Finances" id="basic-nav-dropdown">

              <LinkContainer className="linkContainer" to="/item-sales-summary">
                <NavDropdown.Item >Item Sales Graphs</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/profit-loss-summary">
                <NavDropdown.Item >Profit and Loss</NavDropdown.Item>
              </LinkContainer>


            </NavDropdown>
    */}

            <NavDropdown title="E-Commerce" id="basic-nav-dropdown">
              <LinkContainer className="linkContainer" to="/inventory">
                <NavDropdown.Item >Manage Inventory</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/item-select">
                <NavDropdown.Item >Inventory Sales Data</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer className="linkContainer" to="/orders">
                <NavDropdown.Item >Customer Orders</NavDropdown.Item>
              </LinkContainer>

            </NavDropdown>



            {/* <Nav.Item>
            <Nav.Link eventKey="disabled" disabled style={{color:"white"}}>
              SITE PREVIEW - MAKE NO EDITS
            </Nav.Link>
    </Nav.Item>*/}

          </Nav>
        </Navbar.Collapse>

        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" >

          <Nav className="mr-auto grayscale-nav profileNav">
          <SearchBar searchData={this.props.searchData} />
          <UserSection username={this.props.username} logoutCallback={this.doLogOut} />
          </Nav>
        </Navbar.Collapse>

          <UpdateLog syncStatus={this.state.syncStatus} SyncTimer={this.props.clock} />

          <HistoryIcon serverItemUpdates={this.state.serverItemUpdates} loadMoreCallback={this.props.loadMoreUpdates} />

          <Button className="ButtonTop ButtonRight" variant="link" onClick={this.handleClick}>
            <span className="fa-layers fa-fw" >
              <FontAwesomeIcon className="cartIcon" icon={faShoppingCart} />
              <span className="fa-layers-text fa-inverse" data-fa-transform="shrink-8 down-3" style={{ fontSize: ".6rem", fontWeight: 600, top: "30%", left: "80%", color: "rgb(255 236 224 / 1)" }} >
                {this.props.cartCount < 10 ? this.props.cartCount : "9+"}
              </span>
            </span>
          </Button>

      </Navbar>


    )
  }
}

export default withTimer(StaticHeader);