import React, { useState } from 'react';
import './tags.css';

import { WithContext as ReactTags } from 'react-tag-input';

  
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  
  export const Tags = ({onChange, tags, setTags,suggestTags, disabled}) => {

    

    React.useEffect(()=>{
        onChange(tags)
    },[tags])
    const handleDelete = i => {
      setTags(tags.filter((tag, index) => index !== i));
    };
  
    const handleAddition = tag => {
      setTags([...tags, tag]);
    };
  
    const handleDrag = (tag, currPos, newPos) => {
      const newTags = tags.slice();
  
      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);
  
      // re-render
      setTags(newTags);
    };
  
    const handleTagClick = index => {
    };
  
    return (
    
          <ReactTags
            tags={tags}
            suggestions={suggestTags}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete
            autofocus={false}
            readOnly={disabled}
            maxLength={36}
          />
      
    );
  };