
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import React from "react";

export const CartItemList = ({items, itemRemoveCallback}) => (
    <>
      
      {items.map(item => (
    
       <Card key={item.id} onDoubleClick={()=>itemRemoveCallback(item)}     className="cartItem"    >
       
            <Card.Body style={{padding:5}}>
              <Container>
                <Row>
                  <Col xl={3}>
                 
                    {item.ecommerce? <img
                        
                        className="align-self-center mr-3"
                        src={item.item_img_url}
                        alt="Item Image"
                        width="65"
                        style={{maxHeight:65}}
                      /> : "Non-Ecommerce Item"}
  
                  </Col>
                  <Col>
                <Card.Title> {item.sku}</Card.Title>
                 <Card.Text>Qty: {parseInt(item.qty) + parseInt(item.fba_qty) + parseInt(item.cust_qty)} </Card.Text>
                 </Col>
                 </Row>
                 </Container>
            </Card.Body> 
      </Card>
      ))}
      
    </>
    
  );
  
  
  
   
  export const EmptyList = ({my_text}) => (
    <>
      
          <Card className="emptyItem"  bg="light" >
       
        <Card.Body>
          <Card.Title>{my_text}</Card.Title>
         
         
        </Card.Body>
      </Card>
      
    </>
  ); 
  