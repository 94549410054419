import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import EnsureModal from '../ensureModal.js'
import { useState } from "react";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { DismissableAlert } from "../dismissableAlert";

import { ItemMergeTab } from "./itemMerge.js";
import {unmergeItem, onMergeSubmitRemote} from "./utilFunctions.js"
import '../inventory.css';

const ItemInfoComponent = function (props) {
    return (
        <Row>
            <Col sm={3}>
                <h3 style={{ fontSize: "1rem" }}>{props.label}:</h3>
            </Col>
            <Col sm={9}>
                {props.prefix}{props.content}
            </Col>
        </Row>
    )
}

const ItemInfoSubComponent = function (props) {
    return (
        <Row>
            <Col sm={3}>
                <h3 style={{ fontSize: ".8rem", marginLeft: 2 }}>{props.label}:</h3>
            </Col>
            <Col sm={9} style={{ fontSize: ".8rem" }}>
                {props.prefix}{props.content}
            </Col>
        </Row>
    )
}

const format_ids = (item) => {

    var parsed = JSON.parse("[" + item.item_identifiers + "]")

    var tmp = ""
    parsed.forEach(element => {
        tmp += element['id_type'] + ": " + element['id_value'] + "; "
    });

    return tmp.slice(0, -2)
}

/**
 * 
 * @param {*} props.item (required)
 * @param {*} props.onHide (required)
 * @param {*} props.onMergeSubmit (optional) function to call back to after merging an item
 * @param {*} props.showMergePanels (required) whether or not to show merge panels
 * @param {*} props.all_items (required) list of items
 * @param {*} props.items_minified (required) list of items to show available for merge
 * @param {*} props.token (required) token to use for updating item
 * @param {*} props.my_updateItem (optional) function to call after updating the item
 * 
 * 
 * @returns 
 */
export const LinkedItemTab = function (props) {

    const [my_data, setData] = useState([]);
    const [key, setKey] = useState('home');
    const [itemSelectedForUnlink, setItemSelectedForUnlink] = useState(false)
    const [show_merge_tab, setShowMergeTab] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");


    React.useEffect(() => {

        var tmp_data = props.item && props.item.linked_items ? props.item.linked_items : []
        console.log("Item Changed")
        setData(tmp_data);
        if (tmp_data.length > 0) {
            setKey(tmp_data[0].item_id);
        }
    }, [props.item, props.item.linked_items]);
    const my_handleSubmit_unmerge = () => {
        if (key == "home") {
            return
        } 
        setItemSelectedForUnlink(false)
        var payload = { item_id: key, item_qty: props.item.item_qty, description_id: props.item.description_id, group_id: props.item.group_id, item_qty_incoming: props.item.item_qty_incoming, item_qty_reserved: props.item.item_qty_reserved }
        console.log(props.item)
        console.log(payload)
        unmergeItem(payload, props.token).then((retVal) => {
            if(retVal){
                setAlertMessage("Successfully unlinked item")
                setAlertType("success")
                setShowAlert(true)
                props.item.linked_items = props.item.linked_items.filter((item) => item.item_id != key)
                var tmp_data = props.item && props.item.linked_items ? props.item.linked_items : []
                props.item.num_linked = tmp_data.length
                setData(tmp_data);
                console.log(props.item.linked_items)

                if(props.my_updateItem){
                    props.my_updateItem(['linked_items', 'num_linked'], props.item, retVal)
                }
            }else{
                setAlertMessage("Error unlinking item")
                setAlertType("danger")
                setShowAlert(true)
            }
        })
    }

    const my_handleSubmit_merge = (payload) => {
        onMergeSubmitRemote(payload, props.token).then((retVal) => {
            if(retVal){
                setAlertMessage("Successfully merged item")
                setAlertType("success")
                setShowAlert(true)
            }else{
                setAlertMessage("Error merging item")
                setAlertType("danger")
                setShowAlert(true)
            }

            if(props.onMergeSubmit){
                props.onMergeSubmit(payload, retVal)
            }
        }).catch((error) => {
            setAlertMessage("Error merging item")
            setAlertType("danger")
            setShowAlert(true)
        })
    }

    return (
        <div>

            <EnsureModal onSubmit={my_handleSubmit_unmerge} show={itemSelectedForUnlink} onHide={() => setItemSelectedForUnlink(false)}
                message="Are you sure you want to unlink these items?" />

            <Modal.Body className="ModalBody LinkedItemModal" >
            <DismissableAlert show={showAlert} type={alertType} hideCallback={() => setShowAlert(false)} message={alertMessage} />


                <Row>
                    <Tab.Container fluid id="Linked-Items" activeKey={key} onSelect={(k) => setKey(k)}>
                        <Col sm={4} style={{ backgroundColor: "#fafafa" }}>
                            <Row style={{ padding: 10, paddingLeft: 30 }}>
                                <Col sm={10}>
                                    <h2 style={{ fontSize: "1.15rem", paddingTop: "5px" }}>
                                        Linked Items
                                    </h2>
                                </Col>
                                {props.showMergePanels && <Col sm={2} style={{ textAlign: "right" }}>
                                    <Button variant="light" onClick={(e) => { e.stopPropagation(); setKey("home"); setShowMergeTab(true) }} style={{ paddingTop: 3, paddingBottom: 3, paddingLeft: 6, paddingRight: 6 }}><FontAwesomeIcon style={{ fontSize: "1.10rem" }} className="trashIcon" icon={faPlus} title="Link New Item" /></Button>
                                </Col>}
                            </Row>
                            <hr style={{ margin: 0 }} />
                            <Row className="RowNoStretch" style={{ maxHeight: "550px", minHeight: "450px", overflowY: "auto" }}>

                                <Nav variant="pills" fill className="flex-column">

                                    {my_data.map(item => (
                                        <Nav.Item key={item.item_id}>
                                            <Nav.Link key={item.item_id} eventKey={item.item_id}>
                                                <Row style={{ paddingTop: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
                                                    {item.item_name}
                                                </Row>

                                                <Row style={{ paddingTop: "2px", paddingLeft: "10px", paddingRight: "10px" }}>
                                                    <small style={{ fontSize: ".75rem" }}>{item.item_sku}  </small>
                                                </Row>
                                                <Row style={{ paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px" }}>
                                                    <small style={{ fontSize: ".75rem" }} >{item.item_type} </small>
                                                </Row>

                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}

                                </Nav>
                            </Row>
                        </Col>
                        <Col sm={8}>
                            <Row style={{ padding: 10, paddingLeft: 30 }}>
                                {!show_merge_tab ? <Col sm={8}>
                                    <h2 style={{ fontSize: "1.15rem" }}>
                                        Item Details
                                    </h2>
                                </Col>

                                    :

                                    <Col sm={12}>
                                        <h2 style={{ fontSize: "1.15rem" }}>
                                            Select Item To Link
                                        </h2>
                                        <small>Double click an item to link; the final quantities will be the sum of both linked item quantities</small>
                                    </Col>
                                }
                                {my_data.length > 1 && props.showMergePanels && !show_merge_tab && key != "home" && <Col sm={4} style={{ textAlign: "right" }}>
                                    <Button variant="secondary" onClick={() => setItemSelectedForUnlink(true)}>Unlink </Button>
                                </Col>}

                            </Row>
                            <hr style={{ margin: 0 }} />
                            <Row >
                                <Tab.Content>
                                    {!show_merge_tab ? my_data.map(item => (
                                        <Tab.Pane key={item.item_id} eventKey={item.item_id} style={{ padding: "10px" }}>
                                            <ItemInfoComponent label="Item Name" content={item.item_name} />
                                            <ItemInfoComponent label="Item Sku" content={item.item_sku} />
                                            <ItemInfoComponent label="Selling Site" content={item.item_type} />
                                            <ItemInfoComponent label="Web ID" content={item.web_id} />
                                            <ItemInfoComponent label="Identifier(s)" content={format_ids(item)} />
                                            <ItemInfoComponent label="Weight" content={`${item.item_weight} lbs`} />
                                            {item.item_depth ? <ItemInfoComponent label="Dimensions" content={`${item.item_depth}in x ${item.item_width}in x ${item.item_length}in`} /> : <></>}

                                            <ItemInfoComponent label="List Price" content={item.web_price} prefix="$" />
                                            <ItemInfoComponent label="Start Date" content={item.start_date} />
                                            {item.is_fba ? <ItemInfoComponent label="FBA Qty" content={item.fulfillable_qty + item.reserved_qty + item.inbound_qty} /> : <></>}
                                            {item.fulfillable_qty ? <ItemInfoSubComponent label="Fulfillable Qty" content={item.fulfillable_qty} /> : <></>}
                                            {item.reserved_qty ? <ItemInfoSubComponent label="Reserved Qty" content={item.reserved_qty} /> : <></>}
                                            {item.inbound_qty ? <ItemInfoSubComponent label="Inbound Qty" content={item.inbound_qty} /> : <></>}
                                            {item.inbound_working_qty ? <ItemInfoSubComponent label="Inbound Working Qty" content={item.inbound_working_qty} /> : <></>}
                                            {item.unsellable_qty ? <ItemInfoSubComponent label="Unsellable Qty" content={item.unsellable_qty} /> : <></>}
                                            {item.researching_qty ? <ItemInfoSubComponent label="Researching Qty" content={item.researching_qty} /> : <></>}
                                            {item.reserved_future_qty ? <ItemInfoSubComponent label="Reserved Future Qty" content={item.reserved_future_qty} /> : <></>}
                                            {item.future_buyable_qty ? <ItemInfoSubComponent label="Future Buyable Qty" content={item.future_buyable_qty} /> : <></>}


                                            <ItemInfoComponent label="Sale Link" content={<a href={item.web_sale_link} target="_blank"> {item.web_sale_link}</a>} />

                                        </Tab.Pane>
                                    )) :
                                        <ItemMergeTab items_minified={props.items_minified}
                                            item={props.item}
                                            onHide={() => setShowMergeTab(false)}
                                            onMergeSubmit={my_handleSubmit_merge}
                                            type="Link" />}

                                </Tab.Content>
                            </Row>
                        </Col>

                    </Tab.Container>
                </Row>

            </Modal.Body>
            {!show_merge_tab && <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>}
        </div>
    )
}
