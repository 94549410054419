import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';

const ElementList = ({ elements, onSelectionChange, starting_selectedElements }) => {
    const [selectedElements, setSelectedElements] = useState(starting_selectedElements);
    const [first, setFirst] = useState(true)
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedElements((prevSelectedElements) => [
                ...prevSelectedElements,
                value,
            ]);
        } else {
            setSelectedElements((prevSelectedElements) =>
                prevSelectedElements.filter((element) => element !== value)
            );
        }
    };

    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        if (checked) {
            setSelectedElements(elements);
        } else {
            setSelectedElements([]);
        }
    };

    React.useEffect(() => {
        if (first) {
            setFirst(false)
        }else{
            onSelectionChange(selectedElements);
        }
    }, [selectedElements]);


    return (
        <div>
            <div>
                <Form.Switch
                    type="switch"
                    checked={selectedElements.length === elements.length}
                    onChange={handleSelectAllChange}
                    label="Select All"
                />
            </div>
            {elements.map((element) => (
                <div key={element}>
                    <Form.Check
                        type="checkbox"
                        value={element}
                        checked={selectedElements.includes(element)}
                        onChange={handleCheckboxChange}
                        label={element}
                    />
                </div>
            ))}
        </div>
    );
}

export const DataFilter = ({ elements, onSelectionChange, starting_selectedElements }) => {
    const popover = (
        <Popover id="element-list-popover" style={{maxWidth:400}}>
            <Popover.Header as="h3">Data Filter</Popover.Header>
            <Popover.Body style={{maxWidth:400, maxHeight:500, overflowY:"auto"}}>
                <ElementList elements={elements} onSelectionChange={onSelectionChange} starting_selectedElements= {starting_selectedElements} />
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Button title="Filters" style={{ float: "right", color: "white", marginRight: "15px",height: 38, marginLeft: 10 }} variant="secondary"><FontAwesomeIcon className="trashIcon" icon={faFilter} /></Button>
        </OverlayTrigger>
    );
}




