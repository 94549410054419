
import React from 'react';

import Alert from 'react-bootstrap/Alert';

export const DismissableAlert = ({show, hideCallback, type,message})=>{


    React.useEffect(() => {
        let  timer =  null
        if(show){
          timer = setTimeout(()=> hideCallback(), 5000)
        }else{
            if (timer) {
                clearInterval(timer);
            }
        }
    
        return () => {
            if (timer) {
              clearInterval(timer);
            }
        };
      }, [show]);

    return(
    <Alert show={show} variant={type} onClose={hideCallback} dismissible>
        {message}
    </Alert>)
}