const filterNameToField = (filterName) => {
    var field = ""
    switch (filterName) {
        case "Item Name":
            field = "item_name"
            break;
        case "Item Sku":
            field = "item_sku"
            break;
        case "Part Number":
            field = "item_part_num"
            break;
        case "Vendor":
            field = "item_vendor"
            break;
        case "Item Tag":
            field = "item_tags"
            break;
        default:
            field = ""
    }
    return field
}

const doesLinkedMatchFilter = (item, filter_field, comparisonLambda) => {
    var linkedItems = item.linked_items
    if (linkedItems) {
        for (var i = 0; i < linkedItems.length; i++) {

            //check if filter_field is in the linked_item object
            if (filter_field in linkedItems[i]) {
                if(comparisonLambda(linkedItems[i]))
                    return true
            }
        }
    }
    return false;
}



export const advancedSearchFilterHelper = (filters, tmpFilteredItems) => {

    for (var i = 0; i < filters.length; i++) {
        var filter = filters[i]
        var filter_field = filterNameToField(filter.field)
        var filterLambda = undefined;
        switch (filter.operator) {
            case "Equals":
                if (filter.ignoreCase) {
                    filterLambda = (item) => {
                        return item[filter_field].toLowerCase() === filter.value.toLowerCase()
                    }
                } else {
                    filterLambda = (item) => {
                        return item[filter_field] === filter.value
                    }
                }
                break;
            case "Contains":                  
                if (filter.ignoreCase) {
                    filterLambda = (item) => {
                        return item[filter_field].toLowerCase().indexOf(filter.value.toLowerCase()) >= 0
                    }
                } else {
                    filterLambda = (item) => {
                        return item[filter_field].indexOf(filter.value) >= 0
                    }
                }
                break;
            case "Starts With":
                if (filter.ignoreCase) {
                    filterLambda = (item) => {
                        return item[filter_field].toLowerCase().startsWith(filter.value.toLowerCase())
                    }
                } else {
                    filterLambda = (item) => {
                        return item[filter_field].startsWith(filter.value)
                    }
                }
                    
                break;
            case "Ends With":
                if (filter.ignoreCase) {
                    filterLambda = (item) => {
                        return item[filter_field].toLowerCase().endsWith(filter.value.toLowerCase())
                    }
                } else {
                    filterLambda = (item) => {
                        return item[filter_field].endsWith(filter.value)
                    }
                }
                break;

            case "Does Not Contain":
                if (filter.ignoreCase) {
                    filterLambda = (item) => {
                        return item[filter_field].toLowerCase().indexOf(filter.value.toLowerCase()) < 0
                    }
                } else {
                    filterLambda = (item) => {
                        return item[filter_field].indexOf(filter.value) < 0
                    }
                }
                break;

            case "Does Not Start With":
                    if (filter.ignoreCase) {
                        filterLambda = (item) => {
                            return !item[filter_field].toLowerCase().startsWith(filter.value.toLowerCase())
                        }
                    } else {
                        filterLambda = (item) => {
                            return !item[filter_field].startsWith(filter.value)
                        }
                    }
                break;

            case "Does Not End With":
                    if (filter.ignoreCase) {
                        filterLambda = (item) => {
                            return !item[filter_field].toLowerCase().endsWith(filter.value.toLowerCase())
                        }
                    } else {
                        filterLambda = (item) => {
                            return !item[filter_field].endsWith(filter.value)
                        }
                    }

                break;
            default:
                break;
        }
        if (filterLambda) {
            tmpFilteredItems = tmpFilteredItems.filter(item =>
                filterLambda(item) || doesLinkedMatchFilter(item, filter_field, filterLambda)   
            )

        }   
    }
    return tmpFilteredItems
}