import React, { Component } from 'react';
import axios from "axios";
import './inventory.css';
import Button from 'react-bootstrap/Button';
import { LightningTable } from '../lightningTable/lightning-table'
import { LightningKit } from '../lightningTable/lightning-kit'

let endpoint = "";



const columns = [
  {
    accessorKey: 'key',
    header: 'Key',
    enableSorting: false,
    editable: false,
    hidden: true

  }, {
    accessorKey: 'vendor_name',
    header: 'Vendor Name',
    enableSorting: true,
    editable: true,
    width: .5
  }, {
    accessorKey: 'account_num',
    header: 'Account Num',
    enableSorting: true,
    editable: true,
    width: .5
  }, {
    accessorKey: 'contact',
    header: 'Vendor Contact Info',
    enableSorting: true,
    editable: true,
    width: .5
  }, {
    accessorKey: 'vendor_notes',
    header: 'Vendor Notes',
    enableSorting: true,
    editable: true,
    width: .5
  }];

class AppSettings extends Component {
  constructor(props) {
    super(props);
    this.state = { accounts: [] }

    this.onAddRow = this.onAddRow.bind(this)
  }


  componentDidUpdate(prevProps, prevState) {

    if (prevProps.accounts != this.props.accounts) {
      this.setState({ accounts: this.props.accounts })
    }
  }

  componentDidMount() {


  }
  onAddRow = () => {
    var blank = { vendor_name: "", account_num: "", contact: "" , vendor_notes : "" }
    this.setState({ accounts: [...this.state.accounts, blank] })
  }


  onSave = () => {
    var component = this;
    axios.post(endpoint + '/api/update-account-nums',
      this.state.accounts, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {

        component.props.updateAlert({
          errorMessage: "Successfully Updated Accounts",
          showAlert: true,
          isError: false
        })
        //this.props.reloadAccounts()
      })
      .catch(function (error) {
        if (!error.response || error.response.status == "401") {
          component.props.updateAlert({
            errorMessage: "Error Updating Accounts - Try loggin back in.",
            showAlert: true,
            isError: true
          })
        } else {
          component.props.updateAlert({
            errorMessage: "Error Updating Accounts -  Server Issue. Ensure you have no duplicate entries.",
            showAlert: true,
            isError: true
          })

        }


      });
  }


  render() {

    /* */
    return (
      <div className="tabbed-content">
        <h2>Vendor Settings</h2>
        <hr></hr>
        <p>Enter vendor account numbers below. Click a cell to edit the value.</p>



        <LightningKit columns={ columns}  getRowCanExpand={()=>false}  in_data = {this.state.accounts}   width="100%" 
        
        onEditCallback={(newValue, oldValue, row, column,) => {
          if(newValue != oldValue){
            var  newVal  ={}
            newVal[column.id] = newValue
            this.setState({
              accounts: this.state.accounts.map(el => (el.key === row.original.key ? Object.assign({}, el,  newVal ) : el))
            });
          }
          return true
        }}
        >{

                  
          props => (
             

                  <LightningTable
                  className="lightningFixedTable smallFont"
                  height="100%"
                  {...props} {...props.table}  
                  noDataIndication="No Stored Vendors"
                  />
            
          )
          }
          </LightningKit>




        <Button onClick={this.onAddRow}>Add Row</Button>
        <Button onClick={this.onSave} style={{ marginLeft: 10 }}>Save</Button>
      </div>

    )
  }
}

export default AppSettings;

/*
 
*/