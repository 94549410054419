import React from "react";


const JuneReleaseNotes = () => {
    return (
        <>
            <h4 style={{ fontSize: "1.35rem", margin: 0 }}>June 15, 2024</h4>
            <p style={{ fontSize: "1rem", margin: 0 }}>Link 2.1.1_1</p>
            <p style={{ fontSize: "1rem", margin: 0 }}>Lynx 3.1.1_4</p>
            <p style={{ fontSize: "1rem", margin: 0 }}>GServe 3.1.1_1</p>

            <h5>New Features</h5>
            <ul>
                <li>Added tracking of Ebay cancellations / returns</li>
            </ul>
            <h5>Bug Fixes</h5>
            <ul style={{marginBottom:30}}>
                <li>Fix incorrect items being shown on Restock page when linked from home page</li>
                <li>Fix restock numbers being rounded up to a minimum of 15</li>
                <li>Don't show DNR for restock if only fba or only ecommerce are DNR</li>
                <li>Revert received date back to none if received quantity changed to 0</li>
                <li>Lock out ensure modal confirm button until action is completed.</li>
            </ul>

        </>
    )
}

const MayReleaseNotes = () => {
    return (
        <>
         <h4 style={{ fontSize: "1.35rem", margin: 0 }}>May 5, 2024</h4>
            <p style={{ fontSize: "1rem", margin: 0 }}>Lynx 3.1.1</p>
            <p style={{ fontSize: "1rem", margin: 0 }}>GServe 3.1.1</p>
            <p style={{ fontSize: "1rem", margin: 0 }}>Link 2.1.0_1</p>
            <h5>New Features</h5>
            <ul >
                <li>Cost Price is defaulted to visible on Restock page</li>
                <li>Add Advanced Search on Manage Inventory page</li>
                <li>Add Bulk Edit on Manage Inventory page</li>
                <li>API Updated for UPS authentication</li>
            </ul>
            <h5>Bug Fixes</h5>
            <ul style={{marginBottom:30}}>
                <li>Don't show Move to FBA or Send to FBA status if the item is marked as DNR for FBA</li>
            </ul>
        </>
    )
}
const AprilReleaseNotes = () => {
    return (
        <>


           


            <h4 style={{ fontSize: "1.35rem", margin: 0 }}>April 3, 2024</h4>
            <p style={{ fontSize: "1rem", margin: 0 }}>Lynx 3.1.0_1</p>
            <h5>Bug Fixes</h5>
            <ul style={{marginBottom:30}}>
                <li>Fixed issue where some restock suggestions were showing up as 'Unknown'</li>
                <li>Add Restock filter for 'Other' to prevent Unknown and DNR items from disappearing once Restock is enabled</li>
            </ul>

            <h4 style={{ fontSize: "1.35rem", margin: 0 }}>April 2, 2024</h4>
            <p style={{ fontSize: "1rem", margin: 0 }}>Lynx 3.1.0</p>
            <p style={{ fontSize: "1rem", margin: 0 }}>GServe 3.1.0</p>
            <p style={{ fontSize: "1rem" }}>Link 2.1.0</p>
            <h5>New Features</h5>
            <ul>
                <li>New redesigned financial overview pages</li>
                <li>Addition of Cost of Goods in financial graphs</li>
                <li>Addition of Cost Price on Inventory Sales Data page</li>
                <li>Addition of Profit Margin on Inventory Sales Data page</li>
                <li>Addition of Daily Average Sales columns on Inventory Sales Data page (complementing the existing Weekly Average Sales columns)</li>
                <li>Use longer timeframe for Average Profit on Inventory Sales Data page when there are no sales in the shorter 4 week timeframe</li>
                <li>Addition of Out of Stock status for Restock quantities on Inventory Sales Data page</li>
                <li>Automatically round Restock Suggestions to nearest round number</li>
                <li>Addition of warning on Receive Order page if a received quantity is entered that is higher than expected</li>
                <li>Addition of Amazon Case Pack Quantity field</li>
                <li>Add ability to put a custom note for each purchase order on the download Purchase Order page</li>
                <li>Significant page load time improvements and database rework</li>
            </ul>
            <h5>Bug Fixes</h5>
            <ul>
                <li>Fixed issue with FBA quantities being too high due to duplicating old, removed listings</li>
                <li>Fixed issue with Amazon Profit using expected ship quantites, not the actual shipped quantity</li>
                <li>Fixed issue with improper print format on Purchase Order pages</li>
                <li>Fixed issue with Amazon used items (Grade and Resell) appearing in the MAP violation warnings</li>
                <li>Fixed issue with Amazon used items (Grade and Resell) appearing in the average selling price on Inventory Sales Data Page</li>
                <li>Fixed issue with incorrect shipping cost when shipping to Puerto Rico</li>
            </ul>
        </>
    )
}

export const ReleaseNotes = () => {
    return (
        <div>
            <h3 style={{ fontSize: "1.5rem" }}>Release Notes</h3>
            <hr></hr>
            <div style={{ maxHeight: "350px", overflowY: "auto", textAlign: "left" }}>
                <JuneReleaseNotes />
                <MayReleaseNotes />
                <AprilReleaseNotes />
                
            </div>

        </div>
    );
}