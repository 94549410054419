import React, { Component,useCallback,useMemo } from "react";



import HomeChartRadial from '../charts/homeChartRadial.js';

import Spinner from 'react-bootstrap/Spinner';

import '../inventory.css';

import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';


import Col from 'react-bootstrap/Col';

const ItemSummaryList = ({items, setSelectedItemCallback, chartID, selectedItem, money, label}) => {


    return(
  
    <Table striped borderless size="sm" style={{fontSize:".75rem"}}>
      <thead>
        <tr>
          <th>{label.split(" ").pop().slice(0, -1)}</th>
          <th>{money? "Profit" : "Qty Sold"}</th>
          <th>Percent</th>
        </tr>
      </thead>
      <tbody>
      {items && items.map(item => (
        <tr key = {item.id} /*onMouseOver={setSelectedItemCallback}*/ data-info={JSON.stringify({"chartID":chartID, "itemID":item.id})} className={selectedItem == item.id? "hoveredtablerow":"nonhovertablerow"}>
          <td>{item.label}</td>
          <td>{money? "$" : ""}{item.theta? money? item.theta.toLocaleString(undefined, { minimumFractionDigits: 2 }): item.theta.toLocaleString():"XX.XX"}</td>
          <td>{item.percent}%</td> 
        </tr>
  
      ))}
      </tbody>
    </Table>
    
      
    )
    
      };


  



  export const TopSelling = ({topSelling, label,ProfitandRevLoaded}) => {

    const [selectedItem, setSelectedItem]   = React.useState([])

    const setSelectedItemCallback = (e) =>{
        var tempItems = [...selectedItem]
        tempItems[JSON.parse(e.currentTarget.dataset.info).chartID]=JSON.parse(e.currentTarget.dataset.info).itemID
        setSelectedItem(tempItems)
      }
      
    const chartSelectedItem = (item, chartID) =>{
        var tempItems = [...selectedItem]
        tempItems[chartID]=item.id
        setSelectedItem(tempItems)
      }


   return(<Container fluid>
              <Row>
                <Col lg={4} md={12} style={{borderRight:"2px solid lightgray", paddingLeft:30, paddingRight:30}} >
                <Row style={{justifyContent:"center", minHeight: 200}}>
                  {ProfitandRevLoaded ? <div className="PieWrapper">
                    
                     {topSelling.top_number && <div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                       Top {label} By Quantity
                      </small>
                    </div>}
                   <HomeChartRadial data={topSelling.top_number} padAngle ={0.01} delimiter={"Sold "} text={"Percent of Quantity"} selectedItem={selectedItem['8weeksale']} setSelectedItem={chartSelectedItem} chartID={"8weeksale"}/>
                  </div> : <Spinner style={{marginTop:50}} animation="border" />}
                  </Row>
                  <Row>
                  {ProfitandRevLoaded && <ItemSummaryList label ={label} items={topSelling.top_number} setSelectedItemCallback={setSelectedItemCallback} chartID={"8weeksale"} selectedItem={selectedItem['8weeksale']} money={false} /> }
                  </Row> 

                </Col>
                
                <Col lg={4} md={12} style={{borderRight:"2px solid lightgray",paddingLeft:30, paddingRight:30}} > 
                <Row style={{justifyContent:"center"}}>
                {ProfitandRevLoaded ?  <div className="PieWrapper">
                    
                     {topSelling.top_profit_recent &&<div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                       Top  {label} By Profit
                      </small>
                    </div>}
                   <HomeChartRadial data={topSelling.top_profit_recent} padAngle ={0.01} selectedItem={selectedItem['8weeksaleprofit']} setSelectedItem={chartSelectedItem} chartID={"8weeksaleprofit"}/>
                  </div> : <Spinner style={{marginTop:50}} animation="border" />}
                  </Row>
                  <Row>
                  {ProfitandRevLoaded && <ItemSummaryList label ={label}  items={topSelling.top_profit_recent} setSelectedItemCallback={setSelectedItemCallback} chartID={"8weeksaleprofit"} selectedItem={selectedItem['8weeksaleprofit']} money={true}/> }
                  </Row>
                </Col>
                <Col  lg={4} md={12} style={{paddingLeft:30, paddingRight:30}} >
                <Row style={{justifyContent:"center"}}>
                {ProfitandRevLoaded ? <div className="PieWrapper">
                    
                     {topSelling.top_profit &&<div className= "dot" style={{paddingTop:"50px", paddingRight:"15px", paddingLeft:"15px"}} >
                    <small className="salesFigures sfLarge">
                      Lifetime Sales - Top  {label} By Profit
                      </small>
                    </div>}
                   <HomeChartRadial data={topSelling.top_profit} padAngle ={0.01}  selectedItem={selectedItem['totalsaleprofit']} setSelectedItem={chartSelectedItem} chartID={"totalsaleprofit"}/>
                  </div> : <Spinner style={{marginTop:50}} animation="border" />}
                  </Row>
                  <Row>
                  {ProfitandRevLoaded && <ItemSummaryList label ={label}  items={topSelling.top_profit} setSelectedItemCallback={setSelectedItemCallback} chartID={"totalsaleprofit"} selectedItem={selectedItem['totalsaleprofit']} money={true}/> }
                  </Row>
                </Col>
              </Row>
            </Container>)
  }
  