
import './searchBar.css'
import Fuse from 'fuse.js'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React from 'react';
import { Link } from 'react-router-dom';


import CloseButton from 'react-bootstrap/CloseButton';


const formatResult=(result, key, default_val)=>{

    
    var match=null
    for(var i =0; i < result.matches.length;i++){
        if (result.matches[i].key===key){
            match = result.matches[i]
        }
    }

    if (match===null){
        return default_val
    }

    var text = match.value
    var result = []
    var counter=0
    var pair = match.indices[counter++]
    var boldsession=false
    for (var i = 0; i < text.length; i++) {
        var char = text.charAt(i)
        if (pair && i == pair[0]) {
            boldsession=true
        }
        

        if(boldsession){
            result.push(<u key={i}>{char}</u>)
        }else{
            result.push(<span key={i}>{char}</span>)
        }
        if (pair && i == pair[1]) {
            boldsession=false
          pair = match.indices[counter++]
        }
      }
      
      return result
}


const ResultCard = ({result,clickCallback})=>{


    const Formatter = React.useMemo(() => {
        return formatResult(result, 'item_name', result.item.item_name)
      }, [result])

      const FormatterSKU = React.useMemo(() => {
        return formatResult(result, 'item_sku', result.item.item_sku)
      }, [result])

    return (
        <div className="SearchResultItem" onClick={clickCallback}>
            <Link to={result.item.type==="ITEM"? "/inventory?selectedItem="+result.item.group_id : "/order-details/"+result.item.group_id} target="_blank" style={{color:"black"}}>
            <div><b>
             {Formatter}
             </b>
            </div>
            <div><small>
             {result.item.type==="ITEM" ? FormatterSKU : "Purchase Order"}
             </small>
            </div>
            </Link>
            
            
        </div>
    )
}

export const SearchBar = ({searchData}) => {

    const [value, setValue] = React.useState("")
    const target = React.useRef(null);
    const [results,setResults] = React.useState([])

    const fuse = React.useMemo(() => {
        const options = {
          includeScore: true,
          includeMatches:true,
          minMatchCharLength:2,
          ignoreLocation:true,
          keys: ['item_name', 'item_sku', 'alternate_names', 'alternamte_skus']

          //keys:['items.item_name', 'items.item_sku','items.alternate_names','items.alternate_skus'],
        }
    
        return new Fuse( searchData, options)
      }, [searchData])

      React.useEffect(()=>{
        const searchResults = fuse.search(value, {limit:10})
        setResults(searchResults)
      },[value, fuse])

  
    return (
        <div className="searchWrapper">
        <div className={results.length > 0 ? "search-box-active search-box" : "search-box" }>

           
            
                <a href="#" className="search-btn">
                    <FontAwesomeIcon className="trashIcon" icon={faSearch} />
                </a>
                <input autoComplete="new-password" ref={target} className="search-text" type="text" placeholder="Search Items or Orders" value={value} onChange={e => setValue(e.target.value)} />

                <a href="#" className="search-btn close-btn">
                <CloseButton onClick={()=>setValue("")}/>
                </a>

                
                
                
        </div>
        <div className={results.length > 0 ? "search-results-active" : "search-results" }>
            <hr></hr>
            {results.map(result=>(
                result.score <.6?
                <ResultCard key={result.refIndex} clickCallback={()=>setValue("")} result={result}/>
                :
                <></>))}
        </div>
  
        </div>
    )
}