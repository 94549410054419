import React, { Component } from "react";

import Card from 'react-bootstrap/Card';

import {OrderCard} from './OrderCard'

import '../inventory.css';




export const OrderCardList = ({ items, downloadCallback, editCallback, stateCallBack, deleteCallBack, viewCallBack, receiveCallback, startIndex, endIndex,clickCallback,selectedOrderID }) => (
  <>

    {items.slice(startIndex, endIndex).map(item => (
        <OrderCard item={item}
                key={item.order_id}
                editCallback={editCallback}
                  stateCallBack={stateCallBack}
                  deleteCallBack={deleteCallBack}
                  viewCallBack={viewCallBack}
                  downloadCallback={downloadCallback}
                  clickCallback={clickCallback}
                  isSelected={selectedOrderID==item.order_id}
                  />
      
    ))}

  </>

);

export const EmptyList = ({ my_text }) => (
  <>

    <Card className="orderItem" bg="light" >

      <Card.Body>
        <Card.Title>{my_text}</Card.Title>


      </Card.Body>
    </Card>

  </>
);