import React, { Component } from 'react';
import './header.css';
class Footer extends Component {
 

    
 render(){

      
      
   return(
        <div className="Footer">
            &#169;	 2020-24 Justin Barish; All Rights Reserved
        </div>

    )
}
}

export default Footer;