import React from "react"


export const InventoryFilters = (initialValues)=>{
     return React.useMemo(()=>
        ({
            id:"filterGroup",
            label:"",
            filters:[

                {
                    id:"specialChecks",
                    label:"Only show items with:",
                    filters:[

                       {
                            values:["Quantity = 0"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"quantityIs0",
                            initialValue:initialValues["quantityIs0"]

                        },{
                            values:["Missing Cost"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"missingCost",
                            initialValue:initialValues["missingCost"]

                        },
                        {
                            values:["MAP Violation"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"isMapViolation",
                            initialValue:initialValues["isMapViolation"]

                        },
                        {
                            values:["Has Notes"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"hasNotes",
                            initialValue:initialValues["hasNotes"]

                        },
                        {
                            values:["Has Components"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"hasComponents",
                            initialValue:initialValues["hasComponents"]

                        }, { 
                            values:["Is Component"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"isComponents",
                            initialValue:initialValues["isComponents"]

                        }
                    ]
                },{
                    id:"date",
                    label:"Created Date",
                    filters:[

                       {
                            values:[],
                            type:"Date",
                            label:"",
                            callbackName:"createdDate",
                            id:"createdDate",
                            initialValue:initialValues["createdDate"]

                        }
                    ]
                }
                
                
            ]
        })

    ,[])
}
