import React , { Component } from "react";
import ReactDOM from "react-dom";
// import { timeDay } from "d3";
import axios from "axios";
import '../inventory.css';



import '../../../node_modules/react-vis/dist/style.css';
import {FlexibleWidthXYPlot , XAxis, Crosshair, YAxis,DiscreteColorLegend, HorizontalGridLines, LineMarkSeries} from 'react-vis';


let endpoint = "";


class HomeChart extends Component{
  
    constructor(props) {
        super(props);
    
        
    
        
        
        this.state = {
           crosshairValues:[]
           
        };
    }


  

    componentDidMount(){
      
    
    }

      
     /*
     
type OrderGraphDataPoint struct {
	Order_Date   string  `json:"order_date"`
	Sales_Amount float64 `json:"sales_amount"`
}
type OrderGraphData struct {
	Data_Points []OrderGraphDataPoint `json:"data_points"`
	Data_Label  string                `json:"data_label"`
}

*/ 
    
_onNearestX = (value, {index}) => {
 
 if(value['title'] !='Walmart'){
    this.setState({crosshairValues: this.props.items.map(series=>{
      for (var i = 0; i < series.data.length; i++){
        var d = series.data[i] 
        if(d.x.toDateString() == value.x.toDateString()){
                
            d['title']=series.label
            return d
        }
      }
    })

  })
 }
}

defaultItemsFormat(values) {
  return values.map((v, i) => {
    if (v) {
      return {value: "$" + v.y, title: v.title};
    }
  });
}


_onMouseLeave = () => {
  this.setState({crosshairValues: []});
};
    render(){
      

        return(
          
           <FlexibleWidthXYPlot 
           height={500}
              onMouseLeave={this._onMouseLeave}
              xType="time"
              margin={{bottom: 100, left:50}}>
              
              <HorizontalGridLines />


              {this.props.items.map(serie => (
                 serie.data && <LineMarkSeries
                  data={serie.data}
                  key = {serie.label}
                  color={serie.color}
                  curve={'curveMonotoneX'}
                  onNearestX={this._onNearestX}
                />

              ))}

               
                    
              <XAxis tickLabelAngle={-90}
               />
              <YAxis  tickFormat={v => `$${v}`} />
              {this.props.items &&
              this.state.crosshairValues && 
                <Crosshair values={this.state.crosshairValues}
                itemsFormat={this.defaultItemsFormat}
                >
                 
                </Crosshair>
              
    }
              <DiscreteColorLegend items={this.props.items.map(data=> {
                  var newObj = {} 
                  newObj['title'] = data.label
                  newObj['color'] = data.color
                  return newObj
                  })}  orientation="horizontal"/>
            </FlexibleWidthXYPlot >
                    )}
            }
export default HomeChart;
 
