import React, { Component }  from 'react';
import axios from "axios";
import './inventory.css';

import Table from 'react-bootstrap/Table';
import {withRouter} from './withRouter';

let endpoint = "";

 class AmazonOffset extends Component{
   constructor(props) {
    super(props);
    this.state={
        amazonWarnings:[]
    }
  }    

      
  componentDidMount() { 
    this.getAmazonStatus()
   
  }

     /*
  handleUpdate=(id, type)=>{
    var component = this;
    var diff = 0
    if (type=="amzn"){
      diff=
    }
    var payload={username:this.props.username, passwd:this.old_password.value, newusername: this.new_username.value, newpassword: this.new_password1.value, newusertype:this.state.selectedState }
      axios.post(endpoint + '/api/add-user',
      payload,{
       headers: {
         Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
       }
      })
    .then(function (response) {
    
    })
    .catch(function (error) {
        
    });
  }
*/
  getAmazonStatus = () => { 
    let currentComponent=this;
     axios.get(endpoint + "/api/get-amazon-status",
    {
     headers: {
       Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
     }
    }).then(res => {
      //console.log(res.data)
      if (res.data) {
        
        this.setState({
          amazonWarnings:res.data.filter(function(item) { 
                  
            return item.count >5
      })
      
          })    
      } else {
        currentComponent.setState({
          amazonWarnings: [], 
        });
      } 
    }).catch(error => { 
    // handle error 
    if(!error.response || error.response.status=="401"){
      this.props.setLogcallback("","")
      this.props.logincallback("Error! You have been logged out. Please log back in")
      currentComponent.props.navigate('/index.html')
  }
    currentComponent.setState({
      amazonWarnings: []
    });
  }); 
  };  

  

  render(){



      return(
      <div className="wrapper">
        <div className="customtable widetable">
            <div className="headerBox">
                <h1 className="left-align">Amazon Inventory Errors</h1>
            </div>

            <div className="  grayheader headerBox">
                <h2 style={{fontSize:"1rem"}}>Items on this page have a different quantity listed on Amazon than in this application. This can be a result of customers cancelling their Amazon orders or if the quantity was changed directly on Amazon.com. For items listed below, quantities should be fixed directly on Amazon.com if the dashboard quantity is correct, or in the dashboard application if the Amazon quantity is correct. The data on this page is calculated every 30 minutes; exact quantities may differ from what is shown below if items have sold since it was last updated. </h2>


            </div>
            <div className="   tablerow">
                <Table responsive  striped bordered  className="  viewTable" size="sm" >
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>ASIN</th>
                        <th>Dashboard Qty</th>
                        <th>Amazon Qty</th>
                      
                    </tr>
                </thead>
                <tbody>
                    {this.state.amazonWarnings.map(item=>(
                        <tr key = {item.name}>
                            <td>{item.name}</td>
                            <td>{item.web_id}</td>
                            <td>{item.dbqty}</td>
                            <td>{item.qty}</td>
                            {/*<td><Button variant="info" onClick={()=>this.handleUpdate(item.item_id, 0)}>Use Dashboard Qty</Button></td>
                            <td><Button variant="info" onClick={()=>this.handleUpdate(item.item_id,item.qty-item.dbqty)} >Use Amazon Qty</Button></td>*/}

                        </tr>
                    ))}
                   

                </tbody>
                </Table>
            </div>
        </div>
      </div>

              
           
  )
  }
 }

  export default withRouter(AmazonOffset);
  