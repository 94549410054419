import React, { Component } from "react";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';

import RechartPLGraph from "./charts/rechartPLGraph"

import {withRouter} from './withRouter';




class ProfitAndLoss extends Component {

  constructor(props) {
    super(props);
    this.state={
        profit_data:[],
        loading:false,
        authorized:true
    }
    this.getOrders = this.getOrders.bind(this)
  }

   
  


  componentDidMount() {
    this.controller= new AbortController();

     this.getOrders()
    }

  componentWillUnmount() {
    
    if (this.controller) {
      this.controller.abort()
    }
     
  }
    
 getOrders = () => {
    let currentComponent = this;
    this.setState({loading:true})
    axios.get( "/api/get-profit-loss-data",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        },
          signal: this.controller.signal
      }).then(res => {
        if (res.data) {
          currentComponent.setState({loading:false, profit_data: res.data['profit_and_loss_records']})
        } else {
          currentComponent.setState({loading:false, profit_data: []});
        }
      }).catch(error => {
        // handle error 
        if (axios.isCancel(error)) {
          return;
        }
        currentComponent.setState({
          loading:false, profit_data: []
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
          }

           if (error.response.status === 403) {
             currentComponent.setState({
            authorized:false, loading:false, profit_data: []

          });
          }

        } else {
          currentComponent.setState({
            loading:false, profit_data: []

          });
        }
      });
  };

  
render(){
    return (
        <div className="wrapper fullPageWrapper">
          <div className="customtable widetable">
            <div className="headerBox">
           
                  <h1 className=" left-align">Profit and Loss Overview</h1>
                  
            </div>
            <div className="tablerow">
                {this.state.profit_data && this.state.profit_data.length>0 ?
                  <RechartPLGraph data={this.state.profit_data}/> :
                  this.state.loading ?
                  <Spinner animation="border" /> :
                  this.state.authorized? 
                  <p>Error. Unable to load Profit and Loss Data.</p> :
                  <p>You do not have permission to view this page.</p>
                }

            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(ProfitAndLoss);
