import React, {useMemo, useCallback} from "react";

import { useDropzone } from 'react-dropzone'
import { ExcelRenderer } from 'react-excel-renderer';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    margin: 0,
    cursor: "pointer"
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function MyDropzone(props) {
    const onDropAccepted = useCallback(acceptedFiles => {


        ExcelRenderer(acceptedFiles[0], (err, resp) => {
            if (err) {
            }
            else {
                /*this.setState({
                   cols: resp.cols,
                   rows: resp.rows
                 });*/
                props.setData(acceptedFiles[0].name, resp.cols, resp.rows)

            }
        });



    }, [])
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 'application/vnd.ms-excel': ['.xls'] }, onDropAccepted });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);



    //const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {isDragReject && (<p>This file is not allowed</p>)}
            {
                isDragActive ?

                    <p style={{ marginBottom: 0 }}>Drop the inventory file here, or click to select file</p> :
                    <p style={{ marginBottom: 0 }}>Drag 'n' drop inventory file here, or click to select file</p>

            }
        </div>
    )
}