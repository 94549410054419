
import React from "react";



import Form from 'react-bootstrap/Form';


function DebouncedInput({
   value: initialValue,
   onChange,
   onEnterFunc,
   debounce = 200,
   className="",
   focus=false,
   ...props
 }) {
   const [value, setValue] = React.useState(initialValue)
 
   /*React.useEffect(() => {
     setValue(initialValue)
   }, [initialValue])*/
 
   React.useEffect(() => {
     const timeout = setTimeout(() => {
       onChange(value)
     }, debounce)
 
     return () => clearTimeout(timeout)
   }, [value])
 
   return (
     <Form.Control autoFocus ={focus} {...props} className= {className} onKeyDown={event => {
      if (event.key === "Enter" && onEnterFunc) {
          
      
      onEnterFunc(value);
      }
    }}  value={value} onChange={e => setValue(e.target.value)} />
   )
 }

 export function LightningSearch(props){


   return(
       <DebouncedInput
       value={props.globalFilter ?? ''}
       onChange={value => {props.setGlobalFilter(String(value))}}
       placeholder="Search"
       onEnterFunc={props.onEnterFunc}
       className={props.className}
       focus={props.focus}
     />
   )
}
