import React, { Component } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Card from 'react-bootstrap/Card';


import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from 'react-bootstrap/Spinner';


import Alert from 'react-bootstrap/Alert';
import { v4 as uuidv4 } from 'uuid';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {withRouter} from '../withRouter';

import {  faQuestion } from '@fortawesome/free-solid-svg-icons'
import Form from 'react-bootstrap/Form';
import { TwoChoiceModal } from '../ensureModal.js'

import { AddItemToCartModal } from './addItemToCartModal'

import { CartItemList, EmptyList } from './cartItemList'

import { OrderItemList } from './orderItemList'



import '../inventory.css';

let endpoint = "";





function AlertOrderStatus(props) {

  if (props.isError) {
    if (props.show) {
      window.setTimeout(() => {
        props.hideAlert()
      }, 10000)
    }
    return (
      <Alert show={props.show} variant="danger" onClose={props.hideAlert} dismissible>

        {props.mesage}

      </Alert>
    );
  } else {
    if (props.show) {
      window.setTimeout(() => {
        props.hideAlert()
      }, 10000)
    }
    return (
      <Alert show={props.show} variant="success" onClose={props.hideAlert} dismissible>
        {props.mesage}
      </Alert>
    );
  }

}




class EditOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      my_cart_items: [...this.props.cart_items],
      my_order_items: [],
      modalShow: false,
      modalError: "",
      items: [],
      order_create_return: "",
      showAlert: false,
      alertError: false,
      edit: false,
      order_details: {},
      selectedState: "",
      accounts: [],
      loading: true,
      qtyModalShow: false,
      vendor: {},
      deleteItemModalShow: false,
      itemToDelete: undefined
    }
    this.order_name = React.createRef()
    this.order_notes = React.createRef()
    this.moveAllItemsToOrder = this.moveAllItemsToOrder.bind(this)
    this.handleDeleteItemToCart = this.handleDeleteItemToCart.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)

  }
  componentDidUpdate(prevProps, prevState) {
    //console.log("HERE")
    if (this.props.cart_items != prevProps.cart_items) {
      //console.log("INSIDE")
      var tmp = this.props.cart_items.filter(e => !this.state.my_order_items.includes(e))
      this.setState({ my_cart_items: [...tmp] })
    }

  }


  getItem = (callback) => {
    let currentComponent = this;
    this.setState({ errorMessage: "Loading Data. Please Wait..." })
    axios.get(endpoint + "/api/get-all-items-short-less-cart",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          this.setState({

            items: res.data,


          });
          if (callback) {
            callback(res.data)
          }

        } else {
          currentComponent.setState({
            items: [],

          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          items: [],
          order_create_return: "error"
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
          }
        } else {
          currentComponent.setState({
            items: [],

          });
        }
      });
  };


  getOrderData = (id) => {
    let currentComponent = this;
    this.setState({ errorMessage: "Loading Data. Please Wait..." })
    axios.get(endpoint + "/api/get-order-items/" + id,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          currentComponent.setState({

            order_details: res.data,
            my_order_items: res.data.items ? res.data.items : [],
            selectedState: res.data.order_status,
            loading: false,
            vendor: res.data.vendor_notes || res.data.contact ? { vendor_notes: res.data.vendor_notes, contact: res.data.contact } : {}

          });
          currentComponent.order_name.current.value = res.data.order_name
          currentComponent.order_notes.current.value = res.data.order_notes
          currentComponent.vendor_name.value = res.data.vendor_name

          currentComponent.account_num.value = res.data.account_num

        } else {
          currentComponent.setState({
            order_details: {},
            loading: false

          });
        }
      }).catch(error => {
        // handle error 
        currentComponent.setState({
          order_details: {},
          order_create_return: "error",
          loading: false
        });
        if (error.response) {
          if (error.response.status === 401) {
            this.props.setLogcallback("", "")
            this.props.logincallback("Error! You have been logged out. Please log back in")
            currentComponent.props.navigate('/index.html')
          }
        } else {
          currentComponent.setState({
            order_details: {},

          });
        }
      });
  }

  /*
  my_cart_items:[...this.props.cart_items],
        my_order_items:[],
        modalShow:false,
        modalError:"",
        items:[],
        order_create_return:"",
        showAlert:false,
        alertError:false,
        edit:false,
        order_details:{},
        selectedState:"",
        accounts:[],
        loading:true,
        qtyModalShow:false,
        vendor:{}
        */

  componentWillUnmount() {
    if (this.props.innerPage) {
      this.props.saveEditWork({ my_cart_items: this.state.my_cart_items, my_order_items: this.state.my_order_items, order_details: this.state.order_details, vendor_name: this.vendor_name.value, order_name: this.order_name.current.value, order_notes: this.order_notes.current.value, account_num: this.account_num.value, selectedState: this.state.selectedState })
    }
  }

  componentDidMount() {

    if (this.props.order_details) {
      var order_details = this.props.order_details
      this.setState({
        edit: true,
        order_details: order_details,
        my_order_items: order_details.items ? order_details.items : [],
        selectedState: order_details.order_status,
        loading: false,
        vendor: order_details.vendor_notes || order_details.contact ? { vendor_notes: order_details.vendor_notes, contact: order_details.contact } : {}

      });
      this.order_name.current.value = order_details.order_name
      this.order_notes.current.value = order_details.order_notes
      this.vendor_name.value = order_details.vendor_name

      this.account_num.value = order_details.account_num

      if (Object.keys(this.props.priorData).length > 0) {
        this.setState({ my_cart_items: this.props.priorData.my_cart_items, my_order_items: this.props.priorData.my_order_items, order_details: this.props.priorData.order_details, selectedState: this.props.priorData.selectedState })
        this.vendor_name.value = this.props.priorData.vendor_name
        this.order_name.current.value = this.props.priorData.order_name
        this.order_notes.current.value = this.props.priorData.order_notes
        this.account_num.value = this.props.priorData.account_num
      }

      if (this.props.all_items_less_cart && this.props.all_items_less_cart.length > 0) {
        this.setState({
          items: this.props.all_items_less_cart,
        });
      } else {
        this.getItem(this.props.storeItems);
      }
    } else {
      this.setState({ loading: false })
      this.getItem();
    }
    this.getAccounts()


  }



  handleSubmit(item, exiting_item) {
    if (item.sku == "") {
      this.setState({
        modalError: "Error! SKU cannot be empty!"
      });
      return;
    }
    if (!(item.qty == "" && item.fba_qty == "" && item.cust_qty == "")) {

      var found = false
      this.state.my_order_items.forEach(order_item => {
        if (order_item.id == item.id) {
          found = true
        }
      })

      if (item.qty == '') {
        item.item_qty = 0
      }

      if (item.fba_qty == '') {
        item.fba_qty = 0
      }

      if (item.cust_qty == '') {
        item.cust_qtye = 0
      }





      if (!found) {
        this.setState({
          modalShow: false,
          modalError: "",
          my_order_items: [item, ...this.state.my_order_items]
        });
      } else {
        this.setState({
          modalError: "Error! Item is already in this order."
        });
      }


    } else {
      this.setState({
        modalError: "Error! Invalid Quantity"
      });
    }

  }

  moveItemToOrder = (my_item) => {

    var found = false
    this.state.my_order_items.forEach(order_item => {
      if (order_item.id == my_item.id) {
        found = true
      }
    })
    if (found) {
      this.setState({
        order_create_return: "Error! Item already exists in order",
        showAlert: true,
        alertError: true
      })
    } else {

      this.setState({
        my_cart_items: this.state.my_cart_items.filter(function (item) {

          return my_item.id !== item.id
        }),
        my_order_items: [my_item, ...this.state.my_order_items],
        showAlert: false
      });
    }

  }

  moveAllItemsToOrder = () => {

    var newCart = []
    var neworder = [...this.state.my_order_items]
    for (var i = 0; i < this.state.my_cart_items.length; i++) {
      var found = false
      for (var j = 0; j < this.state.my_order_items.length; j++) {
        if (this.state.my_cart_items[i].id == this.state.my_order_items[j].id) {
          found = true
        }
      }
      if (found) {
        newCart = [this.state.my_cart_items[i], ...newCart]
      } else {
        neworder = [this.state.my_cart_items[i], ...neworder]
      }
    }
    this.setState({
      my_cart_items: newCart,
      my_order_items: neworder
    });
  }

  handleDeleteItemToCart = () => {
    var my_item = this.state.itemToDelete
    this.setState({
      deleteItemModalShow: false, my_order_items: this.state.my_order_items.filter(function (item) {

        return my_item.id !== item.id
      }),

    });
    var found = false
    for (var i = 0; i < this.state.my_cart_items.length; i++) {
      if (this.state.my_cart_items[i].id === my_item.id) {
        found = true
      }
    }
    if (!found) {
      this.setState({ my_cart_items: [my_item, ...this.state.my_cart_items] })
    }
  }

  handleDeleteItem = () => {
    var my_item = this.state.itemToDelete
    this.setState({
      deleteItemModalShow: false, my_order_items: this.state.my_order_items.filter(function (item) {

        return my_item.id !== item.id
      }),

    });
  }


  updateItems = (type, my_item, new_field) => {
    if (type == 0) {
      if (this.state.edit) {
        this.setState({ deleteItemModalShow: true, itemToDelete: my_item })
      } else {
        this.setState({ itemToDelete: my_item }, this.handleDeleteItemToCart)
      }

    }

    if (type == 1) {
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { sku: new_field }) : el))
      });


    }

    if (type == 2) {
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { qty: new_field }) : el))
      });
    }

    if (type == 3) {
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { fba_qty: new_field }) : el))
      });
    }

    if (type == 4) {
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { cust_qty: new_field }) : el))
      });
    }
    if (type == 5) {
      var cost_val_use = new_field
      var index = cost_val_use.indexOf("$")
      if (index >= 0) {
        cost_val_use = cost_val_use.substring(index + 1, cost_val_use.length)
      }
      if (cost_val_use.length == 0) {
        cost_val_use = "0"
      }
      cost_val_use = parseFloat(cost_val_use)
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { item_cost: cost_val_use }) : el))
      });
    }

    if (type == 6) {
      this.setState({
        my_order_items: this.state.my_order_items.map(el => (el.id === my_item.id ? Object.assign({}, el, { orig_rec_qty: new_field }) : el))
      });
    }

  }

  getAccounts = () => {
    let currentComponent = this;

    axios.get(endpoint + "/api/get-account-nums",
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
        }
      }).then(res => {
        if (res.data) {
          currentComponent.setState({
            accounts: res.data,
          });
        }
      })
  };


  createOrder = (e) => {
    this.btn.setAttribute("disabled", "disabled");
    if (this.state.edit) {
      this.updateOrder(e);
      this.btn.removeAttribute("disabled");
      return;
    }
    e.preventDefault()



    if (this.order_name.current.value == "") {
      this.setState({
        order_create_return: "Error: Must Provide Order Name",
        showAlert: true,
        alertError: true
      })
      this.btn.removeAttribute("disabled");

      return
    }

    var payload = { order_name: this.order_name.current.value, order_notes: this.order_notes.current.value, vendor_name: this.vendor_name.value, account_num: this.account_num.value, items: this.state.my_order_items, order_id: uuidv4() }



    //console.log(payload)

    var component = this;
    axios.post(endpoint + '/api/create-order',
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {
        component.btn.removeAttribute("disabled");
        component.setState({
          order_create_return: "Successfully Created Order",
          showAlert: true,
          alertError: false,
          my_order_items: []
        })
        component.props.overwriteCart(component.state.my_cart_items);
        component.order_name.current.value = ""
        component.order_notes.current.value = ""
        component.vendor_name.value = ""

        component.account_num.value = ""
      })
      .catch(function (error) {
        component.setState({
          order_create_return: "Error Creating Order. Unknown Server Error",
          showAlert: true,
          alertError: true
        })
        component.btn.removeAttribute("disabled");

      });
  }

  getCost(items) {
    var res = 0

    items.forEach(item => {
      if (!item.cust_qty) {
        item.cust_qty = 0
      }
      if (!item.qty) {
        item.qty = 0
      }
      if (!item.fba_qty) {
        item.fba_qty = 0
      }
      if (item.item_cost > 0) {
        res += (item.item_cost * (parseInt(item.cust_qty) + parseInt(item.qty) + parseInt(item.fba_qty)))
      }
    })
    return Math.round(res * 100) / 100
  }


  updateOrder = (e) => {
    e.preventDefault()


    if (this.order_name.current.value == "") {
      this.setState({
        order_create_return: "Error: Must Provide Order Name",
        showAlert: true,
        alertError: true
      })
      return
    }

    var payload = {
      order_name: this.order_name.current.value, order_notes: this.order_notes.current.value, vendor_name: this.vendor_name.value,
      account_num: this.account_num.value, items: this.state.my_order_items,
      order_id: this.state.order_details.order_id, order_status: this.state.selectedState
    }


    var numFullReceive = 0
    var numPartialReceive = 0
    for (var i = 0; i < payload.items.length; i++) {

      if (parseInt(payload.items[i].orig_rec_qty) >= parseInt(payload.items[i].qty) + parseInt(payload.items[i].cust_qty) + parseInt(payload.items[i].fba_qty)) {
        payload.items[i].order_status = "RECEIVED"
        if (parseInt(payload.items[i].qty) + parseInt(payload.items[i].cust_qty) + parseInt(payload.items[i].fba_qty) > 0) {
          numFullReceive += 1
        }

      } else if (parseInt(payload.items[i].orig_rec_qty) > 0) {
        payload.items[i].order_status = "PARTIAL_RECEIVED"
        numPartialReceive += 1
      } else {
        payload.items[i].order_status = "ON_ORDER"
      }
    }
    if (this.state.selectedState != "CLOSED") {
      if (numFullReceive == payload.items.length) {
        payload.order_status = "RECEIVED"
      } else if (numPartialReceive + numFullReceive > 0) {
        payload.order_status = "PARTIAL_RECEIVED"
      } else if (payload.order_status == "PARTIAL_RECEIVED" || payload.order_status == "RECEIVED") {
        payload.order_status = "PLACED"
      }
    }

    if (this.props.innerPage && this.props.receiveStateCallback) {
      this.props.receiveStateCallback(this.state.order_details.order_id, payload.order_status)
    }





    var component = this;
    //console.log(payload)

    axios.put(endpoint + '/api/update-order/' + this.state.order_details.order_id,
      payload, {
      headers: {
        Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
      }
    })
      .then(function (response) {
        component.setState({
          order_create_return: "Successfully Updated Order",
          showAlert: true,
          alertError: false,
          my_order_items: []
        })
        component.props.overwriteCart(component.state.my_cart_items);
        component.order_name.current.value = ""
        component.vendor_name.value = ""

        component.account_num.value = ""
        window.setTimeout(() => {
          if (component.props.innerPage) {
            component.props.reloadParentData();
          } else {
            component.props.history.goBack()
          }
        }, 1500)
      })
      .catch(function (error) {
        component.setState({
          order_create_return: "Error Updating Order. Unknown Server Error",
          showAlert: true,
          alertError: true
        })
      });
  }


  StateVariableToDisplay(name) {
    if (name === "CREATED") {
      return "Created"
    } else if (name === "PLACED") {
      return "Placed"
    } else if (name === "CLOSED") {
      return "Closed"
    } else if (name === "PARTIAL_RECEIVED") {
      return "Partial"
    } else if (name === "RECEIVED") {
      return "Received"
    } else {
      return name
    }
  }



  render() {


    return (


      <div className={this.props.innerPage ? "wrapper" : "wrapper fullPageWrapper"} style={{ paddingTop: this.props.innerPage ? 0 : 75, backgroundColor: this.props.innerPage ? "white" : "#FaFaFa" }}>

        <AddItemToCartModal
          show={this.state.modalShow}
          onHide={() => this.setState({
            modalShow: false,
            modalError: ""
          })}

          my_handleSubmit={(e, i) => { this.handleSubmit(e, i) }}

          my_err={this.state.modalError}

          data={this.state.items}

        />





        <TwoChoiceModal onSubmitLeft={this.handleDeleteItemToCart} onSubmitRight={this.handleDeleteItem} show={this.state.deleteItemModalShow} onHide={() => this.setState({ deleteItemModalShow: false })}
          message="Do you want to delete the order item completely, or send the order item back to the cart?" title="Delete Order Item" rightMessage="Delete" leftMessage="Send To Cart" />

        <AlertOrderStatus show={this.state.showAlert} isError={this.state.alertError} mesage={this.state.order_create_return} hideAlert={() => this.setState({ showAlert: false })} />
        <div className={this.props.innerPage ? "customtable fulltable" : "customtable widetable"}>
          {!this.props.innerPage && <div className="headerBox">
            <Container fluid>
              <Row>
                <Col xs={8}>
                  {this.state.edit ? <h1 className="left-align">Edit Order</h1> : <h1 className="left-align">Create Order</h1>}

                </Col>
                <Col xs={4}>

                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="left"
                    overlay={
                      <Popover style={{ maxWidth: 400, overflowY: "scroll", maxHeight: "95vh", overflowX: "hidden" }} >
                        <Popover.Header as="h3">How to Use this Page</Popover.Header>
                        <Popover.Body>
                          <strong>Create/Edit Order</strong>
                          <hr />
                          <p>The point of this screen is to allow you to put together an order using selected items from your cart, and to edit existing orders. Whether or not this page is used to edit or create an order depends on how you got to this page. Whatever is entered in the text box name for the item will display on all order sheets. *Note* The same item cannot appear in your order twice, even if you change the item name.</p>

                          <strong>Creating An Order</strong>
                          <hr />
                          <p>When creating an order, enter an order name, vendor name, and an account number. Account number will automatically populate for a given vendor name, if the vendor/account mapping is set up in the settings page. Vendor name and account number can be left blank; order name cannot. When you finish adding all of your items to your order, click 'Create Order'</p>

                          <strong>Adding Cart Items To Order</strong>
                          <hr />
                          <p>To add a cart item to your order, double click the item. This lets you put items from any manufacturer in your cart at once, and then use this page to cherry pick the items to put in a specific vendor's order. You may also click 'Select All in Cart' to add all of the items in the cart to the order.</p>

                          <strong>Adding Other Items To Order</strong>
                          <hr />
                          <p>To add an item to your order that is not in your cart, click 'Add Other Item'. A modal will pop up; follow instructions on that screen. Note if you add a custom item, it will not be tracked on your E-Commerce sites. *Note* Any items currently in your cart or existing order will not appear in the modal.</p>

                          <strong>Edit Order Item</strong>
                          <hr />
                          <p>To edit an item in your order, click in the name/qty boxes, and enter the values you want. To remove an item from your order and put it back in your cart, click the delete icon. To later remove it from your cart, you will need to delete it from the cart side panel.</p>

                          <strong>Edit Order</strong>
                          <hr />
                          <p>When arriving on this page to edit an order, you can change any values you wish. Use the status drop down to move the order forward/backwards in progress. Click 'Save Changes' when you are finished, or click 'Cancel' to cancel your changes.</p>


                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button style={{ float: "right", color: "black" }} variant="light"><FontAwesomeIcon className="trashIcon" icon={faQuestion}></FontAwesomeIcon></Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Container>
          </div>}


          <div className="grayheader headerBox">
            <Container fluid>
              <Form style={{ padding: 10 }}>
                <Row style={{ textAlign: "left" }}>
                  <Form.Group as={Col} xl={3} lg={6} className="mb-2">
                    <Form.Label>Order Name</Form.Label>
                    <Form.Control autoFocus ref={this.order_name} type="string" placeholder="Order Name" />
                  </Form.Group>


                  <Form.Group as={Col} xl={3} lg={6} className="mb-2">
                    <Form.Label >Vendor Name</Form.Label>
                    <Form.Control ref={(input) => this.vendor_name = input}
                      type="string" placeholder="Vendor Name"
                      onChange={(e) => {
                        this.account_num.value = ""
                        this.setState({ vendor: {} })
                        this.state.accounts.forEach(item => {

                          if (e.target.value.toLowerCase() == item.vendor_name.toLowerCase()) {
                            this.account_num.value = item.account_num
                            this.setState({ vendor: item })


                          }
                        })
                      }
                      } />
                  </Form.Group>
                  <Form.Group as={Col} xl={2} lg={4} className="mb-2">
                    <Form.Label >Account #</Form.Label>
                    <Form.Control ref={(input) => this.account_num = input} type="string" placeholder="Account Number" />
                  </Form.Group>


                  <Col xl={2} lg={4} className="mb-2">
                    {this.state.edit &&
                      <Form.Group>
                        <Form.Label>Order Status</Form.Label>
                        <DropdownButton className="OrderStatusDropdown" variant="light" id="dropdown-basic-button" title={this.StateVariableToDisplay(this.state.selectedState)}>
                          <Dropdown.Item onClick={() => this.setState({ selectedState: "CREATED" })}>Created</Dropdown.Item>
                          <Dropdown.Item onClick={() => this.setState({ selectedState: "PLACED" })}>Placed</Dropdown.Item>
                          <Dropdown.Item onClick={() => this.setState({ selectedState: "CLOSED" })}>Closed </Dropdown.Item>
                        </DropdownButton>
                      </Form.Group>
                    }
                  </Col>

                  <Col xl={2} lg={4} className="align-items-end mb-2" style={{ display: "flex" }} >
                    <>
                      <Button ref={btn => { this.btn = btn; }} type="submit" variant="success" onClick={(e) => this.createOrder(e)} style={{ margin: 5 }}>{this.state.edit ? "Save" : "Create Order"}</Button>
                      {this.state.edit &&
                        <Button onClick={() => this.props.innerPage ? this.props.reloadParentData() : this.props.history.goBack()} style={{ margin: 5 }}>Cancel</Button>
                      }
                    </>
                  </Col>

                </Row>
              </Form>
            </Container>
          </div>

          <div className="lightgrayheader headerBox" style={{ textAlign: "left", paddingTop: 0, paddingBottom: 0 }}>
            <Container fluid>
              <Row>
                {(this.state.vendor.contact || this.state.vendor.vendor_notes) &&
                  <Col md={5}>
                    <Form style={{ paddingTop: 5, paddingBottom: 5 }}>
                      {this.state.vendor.contact && <Row>

                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label style={{ paddingRight: 10 }}>Vendor Contact Info:</Form.Label>
                          <Form.Label style={{ paddingRight: 10 }}>{this.state.vendor.contact ? this.state.vendor.contact : <em>No contact Info Available</em>}</Form.Label>
                        </Form.Group>

                      </Row>}
                      {this.state.vendor.vendor_notes && <Row>

                        <Form.Group style={{ marginBottom: 0 }}>
                          <Form.Label style={{ paddingRight: 10 }}>Vendor Notes:</Form.Label>
                          <Form.Label style={{ paddingRight: 10 }}>{this.state.vendor.vendor_notes ? this.state.vendor.vendor_notes : <em>There are no notes for this vendor</em>}</Form.Label>
                        </Form.Group>

                      </Row>}
                    </Form>
                  </Col>

                }
                <Col>
                  <Form.Group style={{ marginBottom: 0, padding: 10 }}>


                    <FloatingLabel controlId="floatingTextarea2" label="Order Notes">
                      <Form.Control
                        as="textarea"
                        placeholder="Order Notes"
                        style={{ height: '100px' }}
                        rows="3"
                        ref={this.order_notes}
                      />
                    </FloatingLabel>

                  </Form.Group>

                </Col>
              </Row>

            </Container>
          </div>
          <div className=" tablerow">
            <Container fluid>
              <Row >

                <Col xs={9} style={{paddingLeft:0}}>
                    <div className="customtable fulltable">
                      <div className="headerBox" style={{marginBottom:10}}>
                        <Row>
                          <Col md={4}>
                            <h2 className=" left-align small-tight-h2">Order Items</h2>
                          </Col>
                          <Col md={{ span: 4, offset: 4 }} sm={12}>
                            <strong style={{ display: "inline-block", marginTop: "5px" }}>Order Items: {this.state.my_order_items.length}; Cost: ${this.getCost(this.state.my_order_items).toLocaleString(undefined, { minimumFractionDigits: 2 })}</strong>
                          </Col>
                        </Row>
                        <Row style={{ justifyContent: "center" }}>
                          <small style={{ color: "gray" }}>Edits to 'Qty Received' and item cost will only update the record for this order, and will not update the stored inventory item.</small>
                        </Row>


                      </div>
                    </div>
                    {this.state.my_order_items.length ? <OrderItemList isInnerPage={this.props.innerPage} items={this.state.my_order_items} callback={(e, f, g) => this.updateItems(e, f, g)} /> : this.state.loading ? <Spinner animation="border" /> : <EmptyList my_text="No items in this order. Select items from the cart, or click 'Add Other Item'" />}
                </Col>

                <Col xs={3} style={{paddingRight:0}}>
                  <div className="customtable fulltable">
                    <div className="headerBox" style={{ marginBottom: 10, lineHeight: 1 }}>
                      <h2 className="left-align small-tight-h2">Cart Items</h2>
                      <small style={{ color: "gray" }}> To to delete an item from the cart, click the cart icon in the top header, and delete the item from there.</small>

                    </div>
                  </div>
                  <div className="Right-cart-panel Right-item-column">
                      <Button variant="info" id="allToOrderButton" onClick={() => this.moveAllItemsToOrder()}>Select All In Cart</Button>
                      <Button variant="info" id="addItemButton" onClick={() => this.setState({ modalShow: true })}>Add Other Item</Button>
                    <hr></hr>
                      {this.state.my_cart_items.length ? <CartItemList items={this.state.my_cart_items} itemRemoveCallback={this.moveItemToOrder} /> : <EmptyList my_text="Cart is Empty" />}

              </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditOrder);
