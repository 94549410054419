import React from "react";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import RechartLineGraph from '../charts/rechartLineGraph.js'
import Spinner from 'react-bootstrap/Spinner';

const UnitGraph = ({ units, tooltipCallback }) => {
    return (
        <>        {units ? <RechartLineGraph titleKey="Units Sold" syncId="mgraph" type={false}data={units} tooltipCallback = {tooltipCallback} isMoney={false}/> : <> <Spinner animation="border" /></>}
        </>

    );
}


export const UnitTab  = ({ProfitandRev}) =>{


    return (
        <div className="profitTabBox">
            <Container fluid>
                <Row>
                   
                <h3 style ={{textAlign:"left", marginBottom:15, fontSize:"1.5rem"}}>Monthly Units Sold</h3>
                <UnitGraph units={ProfitandRev.monthly_totals} tooltipCallback = {(props) =>{}} />
                   
                </Row>
            </Container>
        </div>
    );
}