import React, { Component } from "react";

import '../inventory.css';



import '../../../node_modules/react-vis/dist/style.css';
import { XYPlot, VerticalRectSeries, AreaSeries, FlexibleWidthXYPlot, XAxis, Crosshair, YAxis, DiscreteColorLegend, HorizontalGridLines, VerticalGridLines, LineSeries, Hint } from 'react-vis';




class StackedAreaChart extends Component {

  constructor(props) {
    super(props);





    this.state = {
      crosshairValues: null,
      line:[]

    };
  }




  componentDidMount() {


  }


  /*
  
type OrderGraphDataPoint struct {
Order_Date   string  `json:"order_date"`
Sales_Amount float64 `json:"sales_amount"`
}
type OrderGraphData struct {
Data_Points []OrderGraphDataPoint `json:"data_points"`
Data_Label  string                `json:"data_label"`
}

*/

  _onNearestX = (value, { index }) => {
    //this.setState({crosshairValues:value})
    
    value['others'] = []
    this.props.data.forEach(series => {



      for (var i = 0; i < series.data.length; i++) {
        var d = series.data[i]
        if (d.x0 == value.x0) {

          var newObj = {}
          newObj['title'] = series.label
          newObj['y'] = d.y
          if (series.label != value.title) {
            value['others'] = [...value['others'], newObj]
          } else {
            value['others'] = [newObj, ...value['others']]
          }
        }
      }

    })
    this.setState({
      crosshairValues: value
    })
  }


  

  defaultItemsFormat(value) {

    if (value) {
      var tmp = [{ title: "Week", value: value.x0 }]
      if (value.others.length > 1) {
        value.others.forEach(item => {
          tmp = [...tmp, { value: item.y, title: item.title }]

        })
      }
      return tmp;
    }

  }


  _onMouseLeave = () => {
    this.setState({ crosshairValues: null });
  };
  render() {


    return (
      <FlexibleWidthXYPlot
      onMouseLeave={v => {this.setState({line:[]})}}
        stackBy="y"
        height={480}
        xDomain={[1, 53]}
        yDomain={[0, this.props.largestValue]}

        margin={{ bottom: 100, left: 50 }}>

      <XAxis tickLabelAngle={-90} title="Week"
        />
        <YAxis title="Units Sold"  /*tickFormat={v => `$${v}`} */ />
        <HorizontalGridLines />
        {this.props.data.map(serie => (
          serie.label != "Total" && serie.data && <VerticalRectSeries
            data={serie.data}
            key = {serie.label}
            opacity={this.state.crosshairValues && this.state.crosshairValues.title == serie.label ? .75 : 0.50}
            stroke={this.state.crosshairValues && this.state.crosshairValues.title == serie.label ? "black" : "transparent"}
            color={serie.color}
            onValueMouseOver={this._onNearestX}
            onValueMouseOut={this._onMouseLeave}
            onNearestX={v => {this.setState({line:[v]})}}
          />

        ))}

{this.props.data &&<Crosshair 
      style={{line:{background: 'red'}}}
      values={[{ x:this.props.currWeek, y:1}]}>
      {this.state.line.length > 0 && Math.abs(this.state.line[0].x0 - this.props.currWeek) <= 1 ?<div className={this.props.currWeek > 10? "rv-crosshair__inner rv-crosshair__inner--left" : "rv-crosshair__inner rv-crosshair__inner--right"} style={{top: -14}}>
        <div className="rv-crosshair__inner__content">
          <div>
            <div className="rv-crosshair__title">
              <span className="rv-crosshair__title__title">Current Week: {this.props.currWeek}</span>
            </div>
          </div>
        </div>
      </div> : <div></div>}
        </Crosshair>}

        <VerticalGridLines />
        {this.props.data &&
          this.state.crosshairValues &&
          <Hint value={this.state.crosshairValues}
            format={this.defaultItemsFormat}
            align={{ horizontal: 'auto', vertical: 'top' }}
          >

          </Hint>


        }
        
        <DiscreteColorLegend style={{ marginTop: -75, textAlign: "center" }} colors={this.props.data.filter(function (data) { return data.label != "Total" }).map(data => data.color)} items={this.props.data.filter(function (data) { return data.label != "Total" }).map(data => data.label)}
          orientation="horizontal" />
      </FlexibleWidthXYPlot >





    )
  }
}
export default StackedAreaChart;

