import React, { Component } from "react";

import { useState, useEffect, useRef } from "react";
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Accordion from 'react-bootstrap/Accordion';

import Form from 'react-bootstrap/Form';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {Link} from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner';

import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV ,faClipboardList,faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'



import '../inventory.css';

const statusToColor = (status) => {
  var background = ""
  if (status == "CREATED") {
    background = "warning"
  } else if (status == "PLACED") {
    background = "primary"
  } else if (status == "CLOSED" || status == "RECEIVED") {
    background = "success"
  } else if (status == "PARTIAL_RECEIVED") {
    background = "danger"
  }
  return background
}

const statusToTextColor = (status) => {
  var background = ""
  if (status == "CREATED") {
    background = "dark"
  } else{
    background = "light"
  }
  return background
}

const statusToName = (status) => {
  var background = ""
  if (status == "CREATED") {
    background = "Created"
  } else if (status == "PLACED") {
    background = "Placed"
  } else if (status == "CLOSED"){
    background = "Closed"
  }else if(status == "RECEIVED"){
    background = "Received"
  } else if (status == "PARTIAL_RECEIVED") {
    background = "Partial"
  }
  return background
}

const getNextStatus = (status) => {
  if (status == "CREATED") {
    return "Placed"
  } else {
    return "Closed"
  }

}
const getNextStatusDB = (status) => {
  if (status == "CREATED") {
    return "PLACED"
  } else {
    return "CLOSED"
  }

}



const OrderCardMenu=({deleteCallBack, stateCallBack, item})=>(
  <OverlayTrigger trigger="click" placement="bottom-end"  rootClose overlay={ 
    <Popover id="popover-order-card-menu">
      <Popover.Body>
        <Container fluid className="NoRowFull">
          <Row>
            <Col style={{textAlign:"left"}}>
              {item.order_status != 'CLOSED' && item.order_status != 'RECEIVED' &&

                <Row>    
                  <Button variant="link" onClick={(e) => {e.stopPropagation();stateCallBack(item.order_id, getNextStatusDB(item.order_status))}} style={{color:"#1e63ad", padding:5, paddingLeft:5, paddingRight:5 }} disabled={item.order_status == 'CLOSED'} title={`Change Order Status to ${getNextStatus(item.order_status)}`} >Mark {getNextStatus(item.order_status)}</Button>
                </Row>
              }
              <Row>
                <Button variant="link" onClick={(e) =>{e.stopPropagation(); deleteCallBack(item.order_id)}} style={{color:"#1e63ad", padding:0, paddingLeft:5, paddingRight:5 }} >Delete</Button>   
              </Row>
            </Col>
          </Row>
        </Container>
      </Popover.Body>
    </Popover>}>
    <Button variant="link" style={{padding:0, color:"gray", fontSize:"0.9rem"}}><FontAwesomeIcon className="trashIcon" icon={faEllipsisV} title="More Options" /></Button>
  </OverlayTrigger>
) 

export const OrderCard = ({ item , downloadCallback, editCallback, stateCallBack, deleteCallBack, viewCallBack, receiveCallback,clickCallback,isSelected}) => {

    const cardRef = useRef(null)
    React.useEffect(() => {
      if(isSelected){
        setTimeout(() =>cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'end' }),0 )
      }
    }, [isSelected]);


return(

        <Card  ref={cardRef} key={item.order_id} onClick={()=>clickCallback(item.order_id)} className={`orderItem orderItem-${item.order_status} orderItem-${item.order_status}-${isSelected}`} border={statusToColor(item.order_status)}  >
          <Card.Header style={{padding:5, paddingLeft:10, margin:0 }}>
           

           <Container fluid>

              <Row>
                <Col xs={8} style={{padding:0}}>
                  <Card.Title style={{padding:0, margin:0 ,float: "left" }} > {item.order_name}</Card.Title>
                </Col>
                <Col xs={3} style={{textAlign:"right",padding:0}}>
                  <Card.Text style={{ fontSize: "1.2rem"}}><Badge text={statusToTextColor(item.order_status)} bg={statusToColor(item.order_status)}>{statusToName(item.order_status)}</Badge></Card.Text>
                  
                </Col>
                <Col xs={1} style={{padding:0, textAlign:"right"}}>
                  <OrderCardMenu stateCallBack={stateCallBack} deleteCallBack={deleteCallBack} item={item} />
                </Col>
                
              </Row>
            </Container>


            
               
                {/*<Col sm={4}>
                  <Button variant="link" onClick={(e) =>{e.stopPropagation(); deleteCallBack(item.order_id)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faTrash} title="Delete Order" /></Button>
                  <Button variant="link" onClick={(e) => {e.stopPropagation();viewCallBack(item.order_id)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faEye} title="View Order" /></Button>
                  <Button variant="link" onClick={(e) => {e.stopPropagation();downloadCallback(item.order_id)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faDownload} title="Download Purchase Order" /></Button>
                  <Button variant="link" onClick={(e) => {e.stopPropagation();editCallback(item.order_id)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faEdit} title="Edit Order" /></Button>
                  <Button variant="link" onClick={(e) => {e.stopPropagation();receiveCallback(item.order_id)}} style={{ float: "right" }}><FontAwesomeIcon className="trashIcon" icon={faShare} title="Receive Order" /></Button>

                </Col>*/}
             
          </Card.Header>
          <Card.Body style={{ padding: 5 }}>

            <Container fluid>
              <Row>
                <Col xs={10} style={{ padding:5}}>
                  <Card.Text style={{ float: "left", fontSize:".9rem" }}> Created: {item.order_date}</Card.Text>
                </Col>

                 {item.has_notes &&<Col style={{padding:0, textAlign:"right"}}>
                 <FontAwesomeIcon style={{color:"#3a6db8"}} className="trashIcon" icon={faClipboardList} title="This Order Has Notes" />
                </Col> }
                <Col  style={{padding:0, textAlign:"right"}}>
                   <Link onClick={(e)=>e.stopPropagation()} to={"/order-details/" + item.order_id} target="_blank"> <FontAwesomeIcon className="trashIcon" icon={ faExternalLinkAlt} title="Open in New Tab" /></Link>
                  
                   
                </Col>
               
               
              </Row>
            </Container>

          </Card.Body>

      </Card>
      
)}