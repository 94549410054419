import React , { Component } from "react";
import ReactDOM from "react-dom";
// import { timeDay } from "d3";
import axios from "axios";
import '../inventory.css';



import '../../../node_modules/react-vis/dist/style.css';
import {FlexibleWidthXYPlot , XAxis, Crosshair, YAxis,DiscreteColorLegend, Hint, RadialChart} from 'react-vis';


let endpoint = "";


class HomeChartRadial extends Component{
  
    constructor(props) {
        super(props);
    
        this.state = {
           value:false
           
        };
        this.defaultItemsFormat = this.defaultItemsFormat.bind(this)
    }


  

    componentDidMount(){
      
    
    }

   

      
     /*
     
type OrderGraphDataPoint struct {
	Order_Date   string  `json:"order_date"`
	Sales_Amount float64 `json:"sales_amount"`
}
type OrderGraphData struct {
	Data_Points []OrderGraphDataPoint `json:"data_points"`
	Data_Label  string                `json:"data_label"`
}

*/ 
    
_onNearestX = (value, {index}) => {

  this.setState({crosshairValues: this.props.items.map(series=>{
    for (var i = 0; i < series.data.length; i++){
      var d = series.data[i] 
      if(d.x.toDateString() == value.x.toDateString()){
               
          d['title']=series.label
          return d
      }
    }
  })
})
}

defaultItemsFormat(values) {
 
 
    if (values) {
      var ret = [{value: this.props.delimiter? this.props.delimiter + values.theta: "$" + (values.theta? values.theta.toLocaleString(undefined, { minimumFractionDigits: 2 }): "XX.XX"), title: values.label}
     ]
      if (values.percent){
        ret = [...ret, {value: values.percent + "%", title:this.props.text? this.props.text : "Percent of Profit" }]
      }else{
        ret = [...ret,  {value: Math.round((Math.abs(values.angle - values.angle0)/(Math.PI*2)*100))+"%", title:this.props.text? this.props.text : "Percent of Profit" }]
      }
      return ret
    }

}


_onMouseLeave = () => {
  this.setState({crosshairValues: []});
};
render() {
  const {value} = this.state;
  return (
   
    this.props.data &&
    <RadialChart
    //colorType="literal"
      className={'donut-chart-example'}
      innerRadius={100}
      colorType='literal'
      radius={140}
      getAngle={d => (d.percent? d.percent:d.theta)}
      data={this.props.data}
      onValueMouseOver={v => {if(this.props.setSelectedItem){this.props.setSelectedItem(v, this.props.chartID)}; this.setState({value: v})}}
      onSeriesMouseOut={() => this.setState({value: false})}
      width={300}
      height={300}
      padAngle={this.props.padAngle? this.props.padAngle : 0.04}
     
    >
      {value !== false && <Hint style={{zIndex:1001, maxWidth:"250px",overflowWrap: "break-word" , title:{whiteSpace:"normal"}}} format={this.defaultItemsFormat} value={value} />}
    </RadialChart>
    
  );
}
}
export default HomeChartRadial;
 
