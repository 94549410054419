import React, { Component }  from 'react';
import logo from '../logo.svg';
import axios from "axios";
import './inventory.css';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

let endpoint = "";

 class MySettings extends Component{
   constructor(props) {
    super(props);
    var new_username=React.createRef()
    var new_password1=React.createRef()
    var old_password=React.createRef()
    var new_password2=React.createRef()
   

    this.onChangeUsername = this.onChangeUsername.bind(this)
  }    



  onChangeUsername=(e)=>{
    e.preventDefault()

    if(this.new_username.value.length <= 5){
        this.props.updateAlert({errorMessage:"Error Changing Username - Must be longer than 5 characters",
                            showAlert:true,
                            isError:true})
        return
    }

    if (!this.new_username.value.match(/^[0-9a-z]+$/)){
        this.props.updateAlert({errorMessage:"Error Changing Username - Cannot have special characters",
        showAlert:true,
        isError:true})
        return
    
    }
    this.props.updateAlert({errorMessage:"Successfully Changed Username",
                            showAlert:true,
                            isError:false})

  }

  onChangePassword=(e)=>{
    e.preventDefault()
    if(this.new_password1.value != this.new_password2.value){
        this.props.updateAlert({errorMessage:"Error Changing Password - Passwords Don't Match",
        showAlert:true,
        isError:true})
        return;
    }
       var component = this;
        var payload={username:this.props.username, passwd:this.old_password.value, newpassword: this.new_password1.value}
          axios.post(endpoint + '/api/update-password',
          payload,{
           headers: {
             Authorization: 'Bearer ' + this.props.token //the token is a variable which holds the token
           }
          })
        .then(function (response) {
        
          component.props.updateAlert({errorMessage:"Successfully Changed Paassword",
                            showAlert:true,
                            isError:false})
          
          //console.log(response.headers);
        })
        .catch(function (error) {
            if(!error.response || error.response.status=="401"){
                component.props.updateAlert({errorMessage:"Error Changing Password - Either your old password is incorrect, or you have been logged out",
                showAlert:true,
                isError:true})
            }else{
                component.props.updateAlert({errorMessage:"Error Changing Password - Unknown Server Issue",
                showAlert:true,
                isError:true})
            
            }
           
            
        });
    


        

    
   

  }
      
  componentDidMount() { 
    
   
  }
     
  render(){
      return (
          <div className="tabbed-content">
              

              
              <Form onSubmit={this.onChangePassword}>
                <h2>Account Management</h2>
                <hr></hr>
                <h3>Change Password</h3>
                <Form.Text>Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</Form.Text>
                <Form.Group as={Row} style={{marginBottom:10}} controlId="formPlaintextPassword1">
                      <Form.Label column sm="4">
                          Current Password
                     </Form.Label> 
                      <Col sm="8">
                          <Form.Control ref ={(input) => this.old_password = input} type="password" autoComplete="current-password"  placeholder="Current Password" required/>
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginBottom:10}} controlId="formPlaintextPassword2">
                      <Form.Label column sm="4">
                          New Password 
                     </Form.Label>
                      <Col sm="8">
                          <Form.Control ref ={(input) => this.new_password1 = input} 
                                        type="password" placeholder="New Password" 
                                       pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" 
                                        autoComplete="new-password" 
                                        required/>
                      </Col>
                  </Form.Group>
                  <Form.Group as={Row} style={{marginBottom:10}} controlId="formPlaintextPassword3">
                      <Form.Label column sm="4">
                          New Password
                     </Form.Label>
                      <Col sm="8">
                          <Form.Control ref ={(input) => this.new_password2 = input} 
                          type="password" placeholder="Re-enter New Password" 
                          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" 
                          title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" 
                          autoComplete="new-password" 
                          required/>
                      </Col>
                  </Form.Group>
                  <Button type="submit" >Change Password</Button>
              </Form>



          </div>




      )
  }
 }

  export default MySettings;
  
  /*
  
  */