import React, { Component }  from 'react';
import logo from '../logo.svg';
import axios from "axios";
import './inventory.css';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import  Base  from '../assets/base.png'

/*
import  Ecom  from '../assets/ecom.png'
import  FBA  from '../assets/fba.png'
import  Customer  from '../assets/customer.png'
import  Custom  from '../assets/custom.png'
import  Rec  from '../assets/receive.png'
import Header from '../assets/header.png'
import Totals from '../assets/totals.png'
import FBAHold from '../assets/fba_hold.png'
*/
/*
import  FBASend  from '../assets/fba_send.PNG'
import  EditOrder  from '../assets/edit_order.PNG'
import  Cart  from '../assets/cart.PNG'
import  item_modal1  from '../assets/item_modal1.PNG'
import  item_modal2  from '../assets/item_modal2.PNG'
import  item_modal3  from '../assets/item_modal3.PNG'
import  item_modal4  from '../assets/item_modal4.PNG'
import  inv_link  from '../assets/inv_link.PNG'
import  history_all  from '../assets/history_all.PNG'
import  history  from '../assets/history.PNG'
import  summary  from '../assets/summary.PNG'

*/


/*
import  URL  from '../assets/url.PNG'

import  Accounts_Edit  from '../assets/accounts_edit.PNG'
import Accounts_Overview from '../assets/accounts_overview.PNG'

import Sales_Data_Toggle from '../assets/sales_data_toggle.PNG'
import Sales_Data_Expand from '../assets/sales_data_expand.PNG'
import Past_Orders from '../assets/past_orders.PNG'
import Order_Sites from '../assets/order_sites.PNG'
import Order_Dates from '../assets/order_date.PNG'

import Selling_Graphs from '../assets/selling_graphs.PNG'
*/
/*
import  GraphOptions  from '../assets/graph_options.PNG'
import  MoreGraphs  from '../assets/more_graphs.PNG'
import  RestockChanges  from '../assets/restock_changes.PNG'
import  OrderModal  from '../assets/order_modal.PNG'
import  LinkedItemModal  from '../assets/linked_item_modal.PNG'
import  POModal  from '../assets/po_modal.PNG'
import  OrderSummary  from '../assets/order_summary.PNG'
import DNR from '../assets/dnr_page.png'
*/
import  RestockReason  from '../assets/restock-reason.png'
import  RestockFilterColumns  from '../assets/restock-filter-columns.png'
import  RestockSalesCombined  from '../assets/restock-sales-combined.png'

import  NewItem from '../assets/new-component.png'
import  ItemComponent from '../assets/components.png'
import  ItemFilters from '../assets/item-filters.png'
import  MAP from '../assets/MAP.png'
import  Tags from '../assets/tags.png'
import  CostAndLocation from '../assets/cost-and-location.png'
import  SyncStatus from '../assets/sync-status.png'
import  SearchBar from '../assets/search-bar.png'




export function TutorialModal(props) {
    const [counter, setCounter] = React.useState(0);
    const [buttonFull, setButtonFull] = React.useState(45);


    const [isButtonDisabled, setDisabled] = React.useState(false)
   /* var data = [{title:"Overview" ,text:"With this new update, there are a few new features. Here's a look at a few of the key differences.", img:null},
                {title: "New Homescreen Chart", text:"New on the homescreen is a chart that informs of the number of orders placed yesterday compared to the daily average, and the number of orders currently placed as of today compared to the daily average. The definition of 'today' (I.E. the business start time/shipping cut off time) can be set in the application settings.", img:OrderSummary},
                
                
                {title: "Item Details Window Changes - Purchase Orders", text:"Within the item details window, there is a new tab that can be used to see the purchase orders that contain that item", img:POModal},
                {title: "Item Details Window Changes - Linked Items", text:"Within the item details window, there is a new tab called 'Linked Items'. This tab replaces the old Merge and Unmerge tabs. Clicking on a linked item in the left panel will show the item details. Click the '+' to link a new item; click 'Unlink' to unlink the selected item.", img:LinkedItemModal},
                
                {title: "Item Details Window Changes - Launch from Order Page", text:"While on the customer order page, double clicking an order will bring up a read-only version of the item details window.", img:OrderModal},


                {title: "Inventory Restock/Sales Data", text:"Both the Inventory Restock and Sales Data pages have some new features, including:",bullets: ["Breaking down 'on order quantity' into ecommerce and customer order quantities", "Enhanced reorder formulas", "Suggested order quantites broken out into FBA vs Ecommerce", "Auto-populating add-to-cart menu with suggested quantities", "Improved layouts"], img:RestockChanges},

                {title: "Item Sales Graphs", text:"More graphs are present on the item sales graph page, including a breakout of profit and revenue by month per selling site. Note - this is only viewable with the appropriate account permissions", img:MoreGraphs},

                {title: "Item Sales Graphs", text:"Options allow you to select the data shown in the graphs. Acces the options pane by clicking on the 'settings' button. Options include:", bullets:["Grouping sales by vendor", "Filtering items based on vendor", "Filtering items based on keyword"], img:GraphOptions},

                {title: "Final Note", text:"This tutorial will pop up on first login on a specific computer; if this tutorial pops up again for you, double click on the title, 'What's New' to exit the window.", img:null}
            ]*/
    /*var data = [{title: "Program Update", text:"The dashboard has been updated to the latest version. This new version includes some new page layouts, features, data improvements, and bugfixes. Some of these include",bullets: ["A brand new Purchase Order management screen, making it easier to manage your orders. For a walkthrough of the new features, click on the 'i' button on the top right of the Purchase Order screen.", "A new profit and loss graph screen, under the 'Finances' tab, showing a comparison of e-commerce revenue vs order spending. Note, not all users have access to this page.", "(Most) Pages are now mobile-friendly, allowing for easily viewing purchase orders, customer orders, items, item details, and more on your phone while on-the-go.","Tracking of Amazon returns and Amazon account fees.", "Improved shipping calculations", "More user-configurable settings, including store name, address, and phone numbers, all found in the settings page.",  "Faster page / data loading times", "Other bug fixes and improvements"]},]*/

    /*var data = [{title: "Program Update", text:"The dashboard has been updated to the latest version. This new version includes some small improvements, new features, and bugfixes.", img:null},
    {title:"Do Not Reorder Options", text: "To mark an item as Do Not Reorder, use the new checkboxes on the item properties page.", bullets: ["Using these checkboxes allows for DNR items to be filtered out of profit warnings", "Using these checkboxes allows for blocking these items from being re-ordered, preventing mistakes", "When a box is selected, an appropriate DNR label is added on to the SKU shown in the dashboard for visibility.", "Select the generic 'Do Not Reorder' box to mark as DNR for all platforms.","Select the 'DNR for E-commerce' to mark as DNR for store-fulfilled platforms only.","Select the 'DNR for FBA' to mark as DNR for FBA platforms only.", "DNR buttons were pre-populated using existing DNR labels. Going forward, manually entering a DNR label in the SKU will not mark the item as DNR" ], img:DNR},
    {title:"Hide From Profit Warnings", text: "To hide an item from appearing on the negative profit warning screen, select the new 'Hide from Profit Warnings' checkbox. This will prevent the item from re-appearing on the negative profit screen for 4 weeks. This feature is handy for hiding an item that recently had its price changed, recently adjusted, etc.", img:DNR},
    {title:"Other Changes", bullets:["Amazon Grade & Resell items will now auto-merge with the regular items, without affecting normal item quantity.", "Added in purchase region to the Customer order page. Added in as part of Order Number, and as a filter under the 'filter' button", "New prompt asks whether an item deleted from an order should be added back to the cart or deleted", "Fixed issue where splitting items caused wrong FBA quantity to be displayed", "Increased amount of per-item attributes shown on 'Linked Items' tab", "Improved data backup and recovery", "Other bug fixes and Improvements"]}]
    */

    var data = [{title:"Overview" ,text:"With this new major update, there are many new features. Here's a look at a few of the key differences.", img:null},
                {title: "New Quick Access features", text:"Located on the top header, you can now quickly search for parts and orders in the search bar, and be instantly brought to their details.", img:SearchBar},

                {title: "New Quick Access features", text:"Also located on the top header, you can now see the status of the inventory sync, and view any pending updates that are in the queue to be synced with the e-commerce sites.", img:SyncStatus},
                
                
                {title: "Purchase Orders", text:"Some small additional features have been added to the purchase order screens, including: ", bullets:["Option to include the cost price on the downloadable PO sheet", "Item locations (when available) listed on the receive page", "Custom message on PO's (such as 'Liftgate Required') can be configured in the settings page"],  img:CostAndLocation},


                {title: "Manage Inventory", text:"Within the manage inventory page, many large features have been added. Here is a look at a few of them." },
                
                {title: "Item Tags", text: "Custom tags can be applied to the items. These items can be searched for by the tags, and can be grouped by in the 'Item Sales Graph' page. For example, an item may have the tags 'Toy', 'Alliance', 'Game'", img:Tags},

                {title: "Item MAP Price", text: "A MAP price can be set for each item; This price is compared against the listing prices for the items; if any are in violation, it will be flagged on the home page.", img:MAP},

                {title: "Item Filters", text: "Several filters have been added to show a subset of the inventory based on quantity in stock, MAP violations, notes, and created date.", img:ItemFilters},

                {title: "Item Components", text: "Sometimes, multiple items can be sold together as a bundle. For example 1 table with 4 chairs. Or maybe even simpler, a pack of 4 gloves. To help manage the inventories of these seperate components, components making up an item can be defined. When the item is ordered, the components will appear in the orders, and when the components are received, the item will be updated by the correct quantity. If the components or item sell on an ecommerce platform, all quantities will be appropriately decremented.", img:ItemComponent},

                {title: "Add Non-Ecommerce Items", text: "If a component of an item is not sold by itself, but still should be tracked as a component, the item can be created as a stand-alone non-ecommerce item. Additionally, any other item (even if not a component) can be created here to keep track of.", img:NewItem},

                {title: "Inventory Restock/Sales Data", text:"Within the inventory sales and Restock pages, many features have been added. Here is a look at a few of them.", },

                {title: "Inventory Restock/Sales Data", text:"These 2 pages have been combined into 1, allowing for easier use. To enable restock suggestions, use the 'Restock Suggestions' toggle. ", img:RestockSalesCombined},

                {title: "Filters & Columns", text:"Numerous filters are listed under the filter icon to control the data seen. Additionally, numerous columns can be toggled on/off using the column selector", img:RestockFilterColumns},

                {title: "Formulas", text:"Restock formulas have been significantly enhanced, taking into account DNR and suggested order multiples among several other improvements. Additionally, hovering over the Status will offer an explanation.", img:RestockReason},

                {title: "Other changs", bullets:["Numerous bug fixes", "Numerous smaller features", "Brand new table libary built from scratch to replace old depricated library", "Brand new server & architecture to improve security, performance, and reliability"]},

                {title: "Final Note", text:"This tutorial will pop up on first login on a specific computer; if this tutorial pops up again for you, double click on the title, 'What's New' to exit the window.", img:null}
            ]
    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        >
        <Modal.Header >
            <Modal.Title id="contained-modal-title-vcenter" onDoubleClick={props.onHide}>
            What's New
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4> {data[counter].title}</h4>
            <p>
            {data[counter].text}
            </p>
            <ul>
            {data[counter].bullets && data[counter].bullets.map(item=> <li>{item}</li>)}
            </ul>
            {data[counter].img && <img src={data[counter].img} className="TutorialImg"/>}
        </Modal.Body>
        <Modal.Footer>
         {<small style={{float:"left", width:"75%"}}><em><s>22,574</s> 36,281 lines of code and counting</em></small> }
        {counter >0 && <Button   onClick={()=>setCounter(counter-1)}>Prev</Button>}
            {counter >=data.length-1? <Button disabled={isButtonDisabled} onClick={props.onHide}>Close</Button> : <Button  disabled={isButtonDisabled} onClick={()=>{setCounter(counter+1);setDisabled(true) ;setTimeout(() => setDisabled(false), 3000)}}>{isButtonDisabled? "Please Wait":"Next" }</Button>}
         
        </Modal.Footer>
        </Modal>
    );
}
      