import axios from "axios";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from "react-bootstrap/Spinner";
import GaugeChart from 'react-gauge-chart';
import StackedAreaChart from '../charts/stackedAreaChart.js';
import '../inventory.css';


export const ItemSalesTab = function (props) {

    const [history, setHistory] = useState([]);
    const [historyOverall, setHistoryOverall] = useState([]);

    const [historyYear, setHistoryYear] = useState([]);

    const [largest, setLargest] = useState(10);
    const [currWeek, setCurrWeek] = useState(0);
    const [myKey, setMyKey] = useState(0);
    const [overallAvg, setOverallAvg] = useState(false);

    const [otherData, setOtherData] = useState({ item_max_profit: 0, all_total_profit: 0, max_item_profit: 0, platform_profits: [] })

    const [loaded, setLoaded] = useState(true);
    const controller = React.useRef(null)

    const gauge = React.useMemo(() => <GaugeChart id="gauge-chart2"
        cornerRadius={1}
        arcsLength={[0.15, 0.35, 0.6]}
        colors={["#dc0e0e", "#00cd00"]}
        percent={Math.max(0, Math.min(Math.round((otherData.item_max_profit / (otherData.max_item_profit / 10)) * 100) / 100, 1))}
        textColor="#000000"
        formatTextValue={(value) => value}
        needleColor="#969696"
    />, [otherData.item_max_profit, otherData.max_item_profit, props.item, props.my_key])

    React.useEffect(() => {
        if (props.my_key == 'itemsales') {
            //getUpdates()
            setMyKey((myKey + 1) % 2)
        }
    }, [props.my_key]);
    useEffect(() => {
        return () => {
            if (controller.current) {
                controller.current.abort()
            }
        };
    }, []);
    React.useEffect(() => {
        controller.current = new AbortController();
        getUpdates()
    }, [props.item, overallAvg]);

    const getUpdates = () => {
        if (!props.item) {
            return
        }
        if (overallAvg && historyOverall.length > 0) {
            setHistory(historyOverall)
            return;
        } else if (!overallAvg && historyYear.length > 0) {
            setHistory(historyYear)
            return
        }
        setLoaded(false)
        axios.get('/api/get-item-stats/' + props.item.group_id + "/" + overallAvg, {
            headers: {
                Authorization: 'Bearer ' + props.token //the token is a variable which holds the token
            }, signal: controller.current.signal
        })
            .then(function (response) {
                setLoaded(true)
                if (response.data) {
                    // console.log(response.data)
                    setCurrWeek(response.data.current_week)
                    setOtherData({ item_max_profit: response.data.item_max_profit, all_total_profit: response.data.all_total_profit, max_item_profit: response.data.max_item_profit, platform_profits: response.data.platform_profits })

                    var formatted_data = []
                    var largestValue = 0
                    response.data.daily_data.forEach(series => {
                        if (series.data_label == "Total") {
                            series.data_points.forEach(datum => {
                                if (datum.y > largestValue) {
                                    largestValue = datum.y
                                }
                            })
                        }
                        formatted_data.push({
                            label: series.data_label,
                            color: series.data_color,
                            data: series.data_points.map(datum => {
                                var newObj = {}

                                newObj['x0'] = parseInt(datum.x)
                                newObj['x'] = parseInt(datum.x) + 1
                                newObj['y'] = datum.y
                                newObj['title'] = series.data_label
                                return newObj
                            }

                            )
                        })

                    })
                    setLargest(largestValue < 10 ? 10 : 5 * Math.floor((largestValue + 5) / 5))
                    setHistory(formatted_data)
                    if (overallAvg) {
                        setHistoryOverall(formatted_data)
                    } else {
                        setHistoryYear(formatted_data)
                    }
                } else {
                    console.log("No Response")
                    setHistory([])
                }

            })
            .catch(function (error) {
                if (axios.isCancel(error)) {
                    return;
                }
                console.log(error)
                setLoaded(true)
                setHistory([])

            });
    }
    return (
        <div>
            <Modal.Body className="ModalBody">
                <Container fluid>
                    {loaded && history.length > 1 ? <Row>

                        <Col  >
                            <div style={{ maxWidth: 175, margin: "auto" }}>
                                <Row style={{ justifyContent: "center" }}><h4>Sales Rating</h4></Row>
                                <Row>
                                    {gauge}
                                </Row>
                            </div>
                        </Col>
                        {otherData.platform_profits && otherData.platform_profits.map(item => (
                            <Col key={item.label} style={{ borderLeft: "2px solid lightgray", paddingLeft: 10, paddingRight: 10 }}>
                                <h4 style={{ textAlign: "center" }}>{item.label}</h4>
                                <h5 style={{ textAlign: "center" }}>Avg 4wk Profit</h5>
                                <p style={item.theta > 0 ? { color: "green", textAlign: "center", fontSize: "1.2rem" } : { color: "red", textAlign: "center", fontSize: "1.2rem" }}>${item.theta}</p>
                            </Col>



                        ))}
                    </Row> : <></>}
                    <hr />
                    {loaded && <Row style={{ justifyContent: "right" }}>
                        <Col >
                            <h4 style={{ textAlign: "center" }}>{overallAvg ? "Average Yearly" : "Past Year"} Sales </h4>
                        </Col>
                        <Col xs={3}>

                            {history.length > 1 && <BootstrapSwitchButton
                                checked={overallAvg}
                                onlabel='Overall Average'
                                offlabel='Past Year'
                                onstyle="primary"
                                offstyle="secondary"
                                width="150"
                                height="31"
                                onChange={(checked) => {
                                    setOverallAvg(checked)
                                }}
                            />}
                        </Col>
                    </Row>}

                    <Row>
                        <Col style={{ textAlign: "center" }}>
                            {loaded ? history.length > 1 ? <StackedAreaChart
                                data={history}
                                largestValue={largest}
                                currWeek={currWeek}
                                key={myKey}
                            /> : <p>There are no past sales for this item.</p> : <Spinner animation="border" />}
                        </Col>
                    </Row>

                </Container>
            </Modal.Body>
        </div>
    )
}