import React from 'react';

export const withTimer = (Component) => {
  const Wrapper = (props) => {
    const [clock, updateClock] = React.useState(0)
    const clock_ref = React.useRef(0)
    const [active, setActive] = React.useState(false)
   
  
    const update_clock=(new_time)=>{
      if(new_time >=0){
        updateClock(new_time-1)
        clock_ref.current=new_time
        setActive(true)
      }else{
        setActive(false)
      }
    }
  
    React.useEffect(() => {
      let  timer =  null
      if(active){
        timer = setInterval(()=> update_clock(clock_ref.current-1), 1000)
      }
  
      return () => {
          if (timer) {
            clearInterval(timer);
          }
      };
    }, [active]);
  

    
    return (
      <Component
        clock={clock}
        updateClock={update_clock}
        {...props}
        />
    );
  };
  
  return Wrapper;
};