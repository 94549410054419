export const columnsLinked = [
    {
        accessorKey: 'selling_site',
        header: 'Selling Site',
        enableSorting: true,
        width: .2,

        style: (cell, row) => {

            return {
                backgroundColor: row.original.item_color + "70"
            };
        }

    }, {
        accessorKey: 'item_start',
        header: 'Oldest Start Date',
        enableSorting: true,
        width: .2,

        style: (cell, row) => {

            return {
                backgroundColor: row.original.item_color + "70"
            };
        }
    },
    {
        accessorKey: 'web_price',
        header: 'Avg Site Selling Price',
        enableSorting: true,
        width: .15,
        cell: props => { return props.getValue() == 0 ? <span>-</span> : <span>${props.getValue()}</span> },
        style: (cell, row) => {

            return {
                backgroundColor: row.original.item_color + "70"
            };
        }
    }
]
