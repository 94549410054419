import React, { Component } from 'react';
// eslint-disable-next-line
import { Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { faArrowRight, faTrash, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';
import './header.css';
import Media from '../Media.js';



const changeText = (e, item) => {
  item.qty = e.target.value;
}


const ItemListEdit = ({ items, callback }) => (
  <>

    {items.map(item => (
      <Card key={item.id} className="item" bg="light" >
        <Card.Header style={{ padding: 10, paddingRight: 2, paddingBottom: 2 }}>


          <Form onSubmit={(e) => { e.preventDefault() }} key={`${item.id}${item.sku}`}>
            <Row>
              <Col xs={10}>
                <Form.Group >
                  <Form.Control as="textarea" rows="2" placeholder="Enter Sku"
                    onChange={(event) => { return true }} style={{ marginBottom: 5 }}
                    onBlur={(e) => callback(1, item, e.target.value)}
                    defaultValue={item.sku} />

                </Form.Group>
              </Col>
              <Col xs={{ span: 1, offset: 1 }}>
                <Button variant="link" onClick={() => callback(0, item, "")} style={{ float: "right", marginLeft: 180 }}><FontAwesomeIcon className="trashIcon" icon={faTrash} /></Button>
              </Col>
            </Row>


          </Form >





        </Card.Header>
        <Card.Body style={{ padding: 5 }}>


          <Form onSubmit={(e) => { e.preventDefault() }} key={`${item.id}${item.qty}${item.cust_qty}${item.fba_qty}`} >

            <Container fluid>
              <Row>
                <Col xs={4}>
                  <Form.Group >
                    <Form.Label> {item.ecommerce ? "ECom-Qty" : "Stock-Qty"}:</Form.Label>
                    <Form.Control type="number" placeholder="##"
                      onBlur={(e) => callback(2, item, e.target.value)}
                      style={{ width: 75, float: "left" }} defaultValue={item.qty} min="0" />
                  </Form.Group>

                </Col>

                <Col xs={4}>
                  <Form.Group >
                    <Form.Label> FBA-Qty:</Form.Label>
                    <Form.Control type="number" placeholder="##"
                      onBlur={(e) => callback(4, item, e.target.value)}
                      style={{ width: 75, float: "left" }} defaultValue={item.fba_qty} min="0" />
                  </Form.Group>
                </Col>
                <Col xs={4}>
                  <Form.Group >
                    <Form.Label> Cust-Qty:</Form.Label>
                    <Form.Control type="number" placeholder="##"
                      onBlur={(e) => callback(3, item, e.target.value)}
                      style={{ width: 75, float: "left" }} defaultValue={item.cust_qty} min="0" />
                  </Form.Group>
                </Col>


              </Row>
              <Row>

              </Row>
            </Container>
          </Form>


        </Card.Body>
      </Card>
    ))}

  </>

);



const ItemList = ({ items, callback, component }) => (
  <>

    {items.map(item => (
      !item.edit || item.edit == false ? <Card key={item.id} className="item" bg="light" >



        <Card.Body style={{ padding: 5 }}>
          <Media>
            <div style={{ width: 85, height: "100%" }} className="align-self-center mr-3">
              {item.ecommerce ? <img

                className="align-self-center mr-3"
                src={item.item_img_url}
                alt="Item Image"
                width="75"
                style={{ maxHeight: 75 }}
              /> : "Non-Ecommerce Item"}
            </div>
            <div>
              <Container fluid>
                <Row>
                  <Card.Title style={{ textAlign: "left" }}>{item.sku}</Card.Title>
                </Row>
                <hr style={{ margin: 0 }}></hr>
                <Row>
                  <Col xs={10}>
                    <Row> <small>{item.ecommerce ? "E-Commerce-Qty" : "Stock-Qty"}: {item.qty}</small> </Row>
                    <Row> <small>FBA-Qty: {item.fba_qty} </small></Row>
                    <Row> <small> Customer-Qty: {item.cust_qty}</small></Row>
                    <Row> <small> Per Unit cost: {item.item_cost > 0 ? <span>${item.item_cost}</span> : <span>?</span>}</small></Row>
                  </Col>
                  <Col xs={1}>

                    <Row>
                      <Button variant="link" onClick={() => { item.edit = true; component.forceUpdate() }} style={{ float: "right", padding: ".175rem .75rem" }}><FontAwesomeIcon className="trashIcon" icon={faEdit} /></Button>
                    </Row>
                    <Row>
                      <Button variant="link" onClick={() => callback(item,true)} style={{ float: "right", padding: ".175rem .75rem" }}><FontAwesomeIcon className="trashIcon" icon={faTrash} /></Button>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </Media>




        </Card.Body>
      </Card>

        :

        <Card key={item.id} className="item" bg="light" >
          <Card.Header style={{ padding: 10, paddingRight: 2, paddingBottom: 2 }}>


            <Form onSubmit={(e) => { e.preventDefault() }} key={`${item.id}${item.sku}`}>
              <Row>
                <Col xs={10}>
                  <Form.Group >
                    <Form.Control as="textarea" rows="2" placeholder="Enter Sku"
                      onChange={(event) => { return true }} style={{ marginBottom: 5 }}
                      onBlur={(e) => {item.sku = e.target.value;callback(item,false)}}
                      defaultValue={item.sku} />

                  </Form.Group>
                </Col>
                <Col xs={{ span: 1, offset: 1 }}>
                  <Button variant="link" onClick={() => { item.edit = false; component.forceUpdate() }} style={{ float: "right", marginLeft: 180 }}><FontAwesomeIcon className="trashIcon" icon={faCheck} /></Button>
                </Col>
              </Row>


            </Form >





          </Card.Header>
          <Card.Body style={{ padding: 5 }}>


            <Form onSubmit={(e) => { e.preventDefault() }} key={`${item.id}${item.qty}${item.cust_qty}${item.fba_qty}`} >

              <Container fluid>
                <Row>
                  <Col xs={3}>
                    <Form.Group >
                      <Form.Label> {item.ecommerce ? "ECom-Qty" : "Stock-Qty"}:</Form.Label>
                      <Form.Control size="sm" type="number" placeholder="##"
                        onBlur={(e) => {item.qty=e.target.value;callback(item, false)}}
                        style={{ width: 75, float: "left" }} defaultValue={item.qty} min="0" />
                    </Form.Group>

                  </Col>

                  <Col xs={3}>
                    <Form.Group >
                      <Form.Label> FBA-Qty:</Form.Label>
                      <Form.Control size="sm" type="number" placeholder="##"
                        onBlur={(e) => {item.fba_qty = e.target.value;callback(item, false)}}
                        style={{ width: 75, float: "left" }} defaultValue={item.fba_qty} min="0" />
                    </Form.Group>
                  </Col>
                  <Col xs={3}>
                    <Form.Group >
                      <Form.Label> Cust-Qty:</Form.Label>
                      <Form.Control size="sm" type="number" placeholder="##"
                        onBlur={(e) => {item.cust_qty = e.target.value; callback(item, false)}}
                        style={{ width: 75, float: "left" }} defaultValue={item.cust_qty} min="0" />
                    </Form.Group>
                  </Col>

                  <Col xs={3}>
                    <Form.Group >
                      <Form.Label> Cost Price:</Form.Label>
                      <Form.Control size="sm" type="number" step="0.01" placeholder="##"
                        onBlur={(e) => {item.item_cost = e.target.value;callback(item, false)}}
                        style={{ width: 75, float: "left" }} defaultValue={item.item_cost} min="0" />
                    </Form.Group>
                  </Col>


                </Row>
                {item.ecommerce && <Row>
                  <small>NOTE: Updating cost will update cost for inventory item</small>
                </Row>}
              </Container>
            </Form>


          </Card.Body>
        </Card>



    ))}

  </>

);


const EmptyList = () => (
  <>

    <Card className="item" bg="light" >

      <Card.Body>
        <Card.Title>Your Cart is Empty</Card.Title>


      </Card.Body>
    </Card>

  </>
);



class SideCart extends Component {
  state = {
    show: "none",
    slide: 'translate(100vw,0)',
    lastScrollY: 0,
    display: 'none',
    toastShow: false,
    lastSKU: "",
    lastQty: "",
    className: "hiddenCart",
    opacity: 0,
    marginTop: 60,
    height: 100,
    first:true
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    let scroll = {
      x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
    };

    // 3000px (arbitrary - put whatever point you need there.)
    this.setState({ marginTop: 60 - (scroll.y <= 60 ? scroll.y : 60) });
    if (scroll.y > 300) {
      this.setState({ marginTop: 51 });
    }

  };
  getCost(items) {
    var res = 0
    items.forEach(item => {
      if (item.item_cost > 0) {
        res += (item.item_cost * (parseInt(item.cust_qty) + parseInt(item.qty) + parseInt(item.fba_qty)))
      }
    })
    return Math.round(res * 100) / 100
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.cartShow != this.props.cartShow) {
      if (this.props.cartShow) {
        this.setState({ slide: 'translate(calc(100vw - 410px),0)', className: "shownCart", show: "block", opacity: 1 });

      } else {
        this.setState({ slide: 'translate(100vw,0)', className: "hiddenCart", show: "none", opacity: 0 });

      }
    }

    if (prevProps.items != this.props.items) {
      var newItem = this.props.items.filter(e => !prevProps.items.includes(e))
      if(this.state.first){
        this.setState({first:false})
      }else if (newItem.length == 1) {
        newItem = newItem[0]
        this.setState({
          toastShow: true,
          lastSKU: newItem.sku,
          lastQty: parseInt(newItem.qty) + parseInt(newItem.fba_qty) + parseInt(newItem.cust_qty)
        })

      }else if (newItem.length > 1){
        this.setState({
          toastShow: true,
          lastSKU: "Multiple Items",
          lastQty: ""
        })
      }
      this.forceUpdate()
    }

  }

  render() {

    function Example(props) {

      return (

        <Toast
          style={{
            position: 'fixed',
            top: 80,
            right: 0,
            maxWidth: 250,
            zIndex: 1002
          }}
          onClose={props.onHide} show={props.show} delay={2000} autohide>
          <Toast.Header>
            <strong className="mr-auto">New Item Added</strong>
          </Toast.Header>
          <strong className="mr-auto">{props.sku}</strong>
          <Toast.Body>Total Qty: {props.qty}</Toast.Body>
        </Toast>

      );
    }
    //transform: this.state.slide,
    var dynamicHeight = 'calc(100vh - ' + this.state.marginTop + 'px )';
    //`calc(100vh - ${this.state.marginTop})`

    return (

      <div>
        <Example onHide={() => this.setState({
          toastShow: false,
        })} show={this.state.toastShow}
          sku={this.state.lastSKU}
          qty={this.state.lastQty} />
        <div className={`slide_out_cart fixed-top ${this.state.className}`} bg="dark" variant="dark" style={{
          transform: this.state.slide, height: dynamicHeight,
          transition: 'transform .30s linear', opacity: this.state.opacity, marginTop: this.state.marginTop
        }}>
          <div id="headerBar">
            <Button variant="secondary" onClick={this.props.openCart} style={{ float: "left" }}><FontAwesomeIcon className="trashIcon" icon={faArrowRight} /></Button>
            <p id="totalCost">Total Cart Cost: ${this.getCost(this.props.items).toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
          </div>
          <div className="itemlist" style={{ clear: "left" }}>
            {this.props.items.length ? <ItemList items={this.props.items} callback={this.props.updateItems} component={this} /> : <EmptyList />}
          </div>
        </div>
      </div>
    )
  }
}
export default SideCart;