 import React, { Component } from "react";

import './inventory.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';






class EnsureModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false
    }
  }
    
 render() { 
 
    return (
    
   
      <Modal  
          show={ this.props.show}
          onHide={this.state.disableButton ? ()=>{} :this.props.onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
        >
        
        
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <h2>Confirm Action</h2> 
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.props.message}
            
          </Modal.Body>
          <Modal.Footer>
           
            <Button  disabled = {this.state.disableButton}
            onClick={()=>{
              this.props.onHide(); 
              if(this.props.onCancel){
                this.props.onCancel()
              }
            }
            }>Cancel</Button>
            <Button disabled = {this.state.disableButton} onClick={()=>{this.setState({disableButton:true}, ()=>this.props.onSubmit())}}>{this.state.disableButton ? "Please Wait ..." : "Confirm"}</Button>
          </Modal.Footer>
        </Modal>
       
);}}


export class TwoChoiceModal extends Component {

    
    
  render() { 
  
     return (
     
    
       <Modal  
           show={this.props.show}
           onHide={this.props.onHide}
           size="lg"
           aria-labelledby="contained-modal-title-vcenter"
           centered
           animation={false}
         >
         
         
           <Modal.Header closeButton>
             <Modal.Title id="contained-modal-title-vcenter">
             <h2>{this.props.title}</h2> 
             </Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {this.props.message}
             
           </Modal.Body>
           <Modal.Footer>
            
             <Button  onClick={this.props.onHide}>Cancel</Button>
             <Button variant="success" onClick={()=>{this.props.onSubmitLeft()}}>{this.props.leftMessage}</Button>
             <Button variant="success" onClick={()=>{this.props.onSubmitRight()}}>{this.props.rightMessage}</Button>
           </Modal.Footer>
         </Modal>
        
 );}}

export default EnsureModal;
