


export const addToQueryString = (iter, newkey, newvalue)=>{

   var  newRes={}
    while(true){
        var a=  iter.next()
        if(a.done){
            break
        }
    
       newRes[a.value[0]] = a.value[1]
    }
    newRes[newkey]=newvalue
    return newRes
}


export const deleteFromQueryString = (iter, key)=>{

    var  newRes={}
     while(true){
         var a=  iter.next()
         if(a.done){
             break
         }
     
         if(a.value[0] !== key){
            newRes[a.value[0]] = a.value[1]
         }
        
     }
     return newRes
 }

 export const clearQueryString = (iter)=>{
    return {}
 }