import React, { Component } from "react";
import axios from "axios";

import OrderDetails from './OrderDetails.js'
import { withRouter } from '../withRouter';






class DetailsIndex extends Component {

  constructor(props) {
    super(props);
    this.state={
        order_id:undefined
    }
  }
  


  componentDidMount() {
     const  id = this.props.params.id
     this.setState({order_id:id})
    }
    


  
render(){
    return (
        <div className="wrapper">
          <div className="customtable widetable">
            <div className="headerBox HideOnPrint">
           
                  <h1 className=" left-align ">Order Details</h1>
             
            </div>
            <OrderDetails cart_items = {this.props.cart_items} overwriteCart = {this.props.overwriteCart} itemcallback={this.props.itemcallback} token={this.props.token}setLogcallback={this.setLogcallback}   logincallback={this.logincallback} order_id = {this.state.order_id} /> 
          </div>
        </div>
    );
  }
}

export default withRouter(DetailsIndex);
