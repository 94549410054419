import React from "react";
import Form from 'react-bootstrap/Form';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {FilterByDate,createDate} from './dateFilter'

//    const changeFilterState = (filter, newState, callback, subFilter )=>{

const FormatInitialValue = (label)=>{
    return typeof label === "function" ? label() : label
}
const shouldBeDisabled = (dlist, elem)=>{
    for (var i =0; i < dlist.length; i++){
        if (dlist[i]===elem){
            return true
        }
    }
    return false
}
const FilterSegmentCheck = ({filterSeg,filterCallback,value,stateChange, disabledList}) => {
    return React.useMemo(()=>
        filterSeg.values.map((filter, index) =>(
        <Form.Group key={`formBasicCheckboxGroupKey-${index}-${filterSeg.id}`} controlId={`formBasicCheckbox-${index}-${filterSeg.id}`}>
            <Form.Check key={`formBasicCheckboxKey-${index}-${filterSeg.id}`} onChange={()=>{ stateChange(filterSeg, !value, filterCallback,filter)}} type="checkbox" label={filter} checked={FormatInitialValue(value)} disabled={shouldBeDisabled(disabledList,filterSeg.id)}/>
        </Form.Group>   
        ))
    , [value])    
}


const FilterSegmentBinary = ({filterSeg,filterCallback,value,stateChange, disabledList}) => {
    return React.useMemo(()=>
        <Form.Group key={`formBasicBinaryGroupKey-${filterSeg.id}`} controlId={`formBasicBinary-${filterSeg.id}`}>
            <Form.Label style={{display:"block"}}>{filterSeg.label}</Form.Label>
            <BootstrapSwitchButton
                checked={value}
                onlabel={filterSeg.values[0]}
                offlabel={filterSeg.values[1]}
                onstyle="primary"
                offstyle="secondary"
                width="100"
                height="31"
                onChange={(checked) => {
                    stateChange(filterSeg, checked, filterCallback,"")
                }}
            />
        </Form.Group>   
        
    , [value])  
}

const FilterDate= ({filterSeg,filterCallback,value,stateChange, disabledList}) => {
    return React.useMemo(()=>
        <FilterByDate hideLabel={true} getItems={(firstDate, secondDate)=>{stateChange(filterSeg, firstDate + "|" + secondDate, filterCallback, "")}}/>
    , [value])    
}


export const FilterSegment = ({filterSeg,filterCallback, value,stateChange, disabledList}) => {
    if( typeof  value === "function"){
        value=value()
    }
    if (filterSeg.type === "Check") {
       return <FilterSegmentCheck filterSeg = {filterSeg} filterCallback = {filterCallback} value={value} stateChange={stateChange} disabledList={disabledList}/>
    }else if (filterSeg.type === "Binary") {
        return <FilterSegmentBinary filterSeg = {filterSeg} filterCallback = {filterCallback} value={value} stateChange={stateChange} disabledList={disabledList}/>

    }else if (filterSeg.type=="Date"){
        return <FilterDate filterSeg = {filterSeg} filterCallback = {filterCallback} value={value} stateChange={stateChange} disabledList={disabledList}/>

    }
}