import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import RechartLineGraph from '../charts/rechartLineGraph.js'
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';


const ProfitOverview = ({ profitSummary }) => {
    return (
        <div className="FinancialSummaryBox" style={{ boxShadow: "1px 1px 4px 0px lightgrey", height: "auto" }}>
            {profitSummary.label && <h2 style={{ fontSize: "1rem", textAlign: "left" }}>{profitSummary.label} Profit Summary</h2>}
            <Table striped hover>
                <tbody>
                    <tr>
                        <td>Revenue</td>
                        <td style={{ textAlign: "right" }}>${profitSummary.totalRevenue.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                    </tr>
                    <tr>
                        <td>Cost of Goods</td>
                        <td style={{ textAlign: "right", color: "red" }}>-${profitSummary.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                    </tr>
                    <tr>
                        <td>Product Profit</td>
                        <td style={{ textAlign: "right" }}>${(profitSummary.totalRevenue - profitSummary.totalCost).toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                    </tr>
                    <tr>
                        <td>Shipping Fees</td>
                        <td style={{ textAlign: "right", color: "red" }}>-${profitSummary.totalShipping.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: "bold" }}>Net Profit</td>
                        <td style={{ textAlign: "right", fontWeight: "bold" }}>${profitSummary.totalProfit.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                    </tr>
                </tbody>
            </Table>
        </div>

    );
}

const ProfitGraph = ({ profit, tooltipCallback }) => {
    return (
        <>        {profit ? <RechartLineGraph titleKey="Profit" syncId="mgraph" type={false} data={profit} tooltipCallback={tooltipCallback} /> : <> <Spinner animation="border" /></>}
        </>

    );
}


const RevenueGraph = ({ profit, tooltipCallback }) => {
    return (
        <>        {profit ? <RechartLineGraph titleKey="Revenue" syncId="mgraph" type={false} data={profit} tooltipCallback={tooltipCallback} /> : <> <Spinner animation="border" /></>}
        </>

    );
}

const makeDefaultSummary = (profitData) => {

    var totalProfit = 0;
    var totalRevenue = 0;
    var totalCost = 0;
    var totalShipping = 0;
    var label = "Overall (" + profitData['monthly_summary'][0]['date'] + " - Now)";
    if(profitData.monthly_profit){
        profitData.monthly_profit.forEach((dataCategory) => {
            dataCategory.data_points.forEach((month) => {
                totalProfit += month['profit']
                totalRevenue += month['revenue']
                totalCost += month['item_cost']
                totalShipping += month['shipping_cost']
            })
        })
    }


    return { label, totalProfit, totalRevenue, totalCost, totalShipping }
}


const GetSummaryForDate = (profitData, date) => {
    var totalProfit = 0;
    var totalRevenue = 0;
    var totalCost = 0;
    var totalShipping = 0;
    profitData.monthly_profit.forEach((dataCategory) => {
        var monthData = dataCategory.data_points.filter((monthData) => monthData['x'] === date);
        if(monthData.length > 0){
            totalProfit += monthData[0]['profit']
            totalRevenue += monthData[0]['revenue']
            totalCost += monthData[0]['item_cost']
            totalShipping += monthData[0]['shipping_cost']
        }
    })
    
    var result =  { label:date, totalRevenue: totalRevenue, totalProfit: totalProfit, totalCost: totalCost, totalShipping: totalShipping }
    return result
}

const processTooltip = (active, label, ProfitandRev) => {
    if (active) {

        return GetSummaryForDate(ProfitandRev, label)
    }
    return makeDefaultSummary(ProfitandRev);
}

export const ProfitTab = ({ ProfitandRev }) => {

    const [overview, setOverview] = React.useState({});

    React.useEffect(() => {
        if (Object.keys(ProfitandRev).length > 0) {
            setOverview(makeDefaultSummary(ProfitandRev));
        }
    }, [ProfitandRev]);


    return (
        <div className="profitTabBox">
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <h3 style={{ textAlign: "left", marginBottom: 15, fontSize: "1.5rem" }}>Overview</h3>
                        {Object.keys(overview).length > 0 && <ProfitOverview profitSummary={overview} />}
                    </Col>
                    <Col md={7}>
                        <h3 style={{ textAlign: "left", marginBottom: 15, fontSize: "1.5rem" }}>Monthly Profit</h3>
                        <ProfitGraph profit={ProfitandRev.monthly_profit} tooltipCallback={(props) => {
                            setOverview(processTooltip(props.isTooltipActive, props.activeLabel, ProfitandRev))

                        }
                        } />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export const RevenueTab = ({ ProfitandRev }) => {

    const [overview, setOverview] = React.useState({});

    React.useEffect(() => {
        if (Object.keys(ProfitandRev).length > 0) {
            setOverview(makeDefaultSummary(ProfitandRev));
        }
    }, [ProfitandRev]);

    return (
        <div className="profitTabBox">
            <Container fluid>
                <Row>
                    <Col md={5}>
                        <h3 style={{ textAlign: "left", marginBottom: 15, fontSize: "1.5rem" }}>Overview</h3>
                        {Object.keys(overview).length > 0 && <ProfitOverview profitSummary={overview} />}
                    </Col>
                    <Col md={7}>
                        <h3 style={{ textAlign: "left", marginBottom: 15, fontSize: "1.5rem" }}>Monthly Revenue</h3>
                        <RevenueGraph profit={ProfitandRev.monthly_revenue} tooltipCallback={(props) => {
                            setOverview(processTooltip(props.isTooltipActive, props.activeLabel, ProfitandRev))

                        }
                        } />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}