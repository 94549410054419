import React from "react"


export const RestockFilters = (initialValues)=>{
     return React.useMemo(()=>
        ({
            id:"filterGroup",
            label:"",
            filters:[

                {
                    id:"specialChecks",
                    label:"",
                    filters:[

                        {
                            values:["Zero On Order"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"onOrderQtyIs0",
                            initialValue:initialValues["onOrderQtyIs0"]
                        }, {
                            values:["Sold This Week"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"itemsSoldThisWeek",
                            initialValue:initialValues["itemsSoldThisWeek"]

                        }, {
                            values:["Quantity = 0"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"quantityIs0",
                            initialValue:initialValues["quantityIs0"]

                        }, {
                            values:["Negative Profit"],
                            type:"Check",
                            label:"",
                            callbackName:"CustomFilter",
                            id:"profitIsNegative",
                            initialValue:initialValues["profitIsNegative"]

                        }
                    ]
                },
                {
                    id:"ReportType",
                    label:"",
                    filters:[
                        {
                            values:["Daily", "Weekly"],
                            type:"Binary",
                            label:"Report Type",
                            callbackName:"ReportType",
                            id:"ReportType",
                            initialValue:initialValues["ReportType"]

                        },
                    ]
                },
                {
                    id:"StockStatus",
                    label:"Restock Status",
                    selectAllButton:true,
                    filters:[
                        {
                            values:["Order Now"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"OrderNow",
                            initialValue:initialValues["orderNow"]

                        },{
                            values:["Order Soon"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"OrderSoon",
                            initialValue:initialValues["orderSoon"]

                        },{
                            values:["Low, On Order"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"LowOnOrder",
                            initialValue:initialValues["lowOnOrder"]

                        },{
                            values:["Good Stock"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"GoodStock",
                            initialValue:initialValues["goodStock"]

                        },{
                            values:["Overstock"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"Overstock",
                            initialValue:initialValues["overStock"]

                        },{
                            values:["Out Of Stock"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"OutOfStock",
                            initialValue:initialValues["outOfStock"]

                        },{
                            values:["Move To FBA"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"MoveToFBA",
                            initialValue:initialValues["moveToFBA"]

                        },{
                            values:["Send To FBA"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"SendToFBA",
                            initialValue:initialValues["sendToFBA"]

                        },{
                            values:["Other"],
                            type:"Check",
                            label:"",
                            callbackName:"RestockStatus",
                            id:"Unknown",
                            initialValue:initialValues["unknown"]

                        },
                    ]
                }
            ]
        })

    ,[])
}
