import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import CloseButton from 'react-bootstrap/CloseButton';

var AdvancedSearch = function ({ show, onHide, onSubmit, onCancel, existingFilters }) {

    const [filters, setFilters] = useState(existingFilters);
    const [error, setError] = useState("");
    const [ignoreCase, setIgnoreCase] = useState(false);
    const [initialFilters, setInitialFilters] = useState(existingFilters);
    let timer = null

    const fieldRef = React.useRef();
    const operatorRef = React.useRef();
    const valueRef = React.useRef();
    const fields = ["Item Name", "Item Sku", "Part Number", "Vendor"]
    const operators = ["Equals", "Contains", "Starts With", "Ends With", "Does Not Contain", "Does Not Start With", "Does Not End With"]

    const addNewFilter = (field, operator, value) => {
        if (!field || !operator || !value) {
            setError("Please fill out all fields.");
            timer = setTimeout(()=>{setError(""); clearInterval(timer)}, 5000)
            return;
        }
        setFilters([...filters, { field: field, operator: operator, value: value, ignoreCase: ignoreCase}])
        setIgnoreCase(false);
        setError("");
    }

    const removeFilter = (index) => {
        let newFilters = [...filters];
        newFilters.splice(index, 1);
        setFilters(newFilters);
    }
    


    React.useEffect(() => {
        if(!show){
            clearInterval(timer)
        }
        if (show) {
            setInitialFilters(existingFilters);
        }
        return () => {
            if (timer) {
              clearInterval(timer);
            }
        };
      }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            animation={false}
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h1 style={{fontSize:"2.0rem", marginBottom:0}}>Advanced Search</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div style={{ padding: 10, marginTop: 10, minHeight:300 }}>

                <div>
                    <h2 style={{fontSize:"1.5rem", marginBottom:10}}>Add New Search Filter</h2>
                    <Container fluid>
                        <Row>
                            <Col style={{ maxWidth: 250 }}>
                                <Form.Select ref={fieldRef}>
                                    {fields.map((field, index) => {
                                        return (
                                            <option key={index} value={field}>{field}</option>
                                        )
                                    })}
                                </Form.Select>

                            </Col>

                            <Col style={{ maxWidth: 250 }}>
                                <Form.Select ref={operatorRef}>
                                    {operators.map((operator, index) => {
                                        return (
                                            <option key={index} value={operator}>{operator}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>

                            <Col style={{ maxWidth: 250 }}>
                                <Form.Control ref={valueRef} type="text" />
                            </Col>
                           

                            <Col style={{ maxWidth: 250 }}>
                            <Form.Switch
                                id="ignore-case-switch"
                                label="Ignore Case"
                                style={{ textAlign: "left", float: "right", marginRight: 10 }}
                                checked={ignoreCase}
                                onChange={() => { setIgnoreCase(!ignoreCase) }}
                                />
                            </Col>
                            <Col style={{ maxWidth: 150 }}>
                                <Button onClick={() => addNewFilter(fieldRef.current.value, operatorRef.current.value, valueRef.current.value)}>Add Filter</Button>
                            </Col>


                        </Row>

                    </Container>


                </div>
                {error && <div style={{ color: "red", marginLeft:10 }}>{error}</div>}

                <div style={{marginTop:20}}>
                    <h2 style={{fontSize:"1.5rem", marginBottom:10}}>Current Search Filters</h2>
                    <div style={{paddingLeft:10, paddingRight:10}}>
                    {filters.map((filter, index) => {
                        return (
                            <div key={index} style={{alignItems:"center", display:"flex"}}>
                                <span>{filter.field}</span>
                                <span>&nbsp;<u>{filter.operator}</u></span>
                                <span>&nbsp;{filter.value}</span>
                                <span>&nbsp;{filter.ignoreCase ? " (Ignoring Case)" : ""}</span>
                                <CloseButton style={{width:".5rem", padding:3, marginLeft:9,paddingTop:5}} variant="light" className="rounded-circle" onClick={() => removeFilter(index)}/>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </div>
            </Modal.Body>

        <Modal.Footer>
            <Button onClick={()=>{setFilters(initialFilters); onCancel()}}>Cancel</Button>
            <Button type="submit" onClick={()=>onSubmit(filters)}>Save</Button>
        </Modal.Footer>

        </Modal>
    )
}



export default AdvancedSearch;
